import React from 'react';
import { Field, reduxForm } from 'redux-form';
import TagsInput from 'react-tagsinput';

import Config from '../../config/Config';

const ImageSearchForm = ({
  onSubmit,
  refTagsInput,
  onTagsRenderInput,
  imageSearchForm,
  onTagsChange,
  t,
}) => (
  <div className="imageSearchForm">
    <form>

      {/* Dummy field for preventing submit by enter key */}
      <input type="text" className="none" />

      <div className="item">
        <label htmlFor="tags">{t('image.searchForm.tags')}</label>
        <TagsInput
          ref={refTagsInput}
          renderInput={onTagsRenderInput}
          value={imageSearchForm.tags || []}
          onChange={onTagsChange}
          onlyUnique
        />
      </div>

      <div className="item">
        <label htmlFor="imageType">{t('image.searchForm.imageType')}</label>
        <div className="imageType">
          <div className="select-wrap">
            <Field
              name="image_type"
              component="select"
              onChange={() => {
                setTimeout(() => onSubmit(), 0);
              }}
            >
              <option value="">{t('common.selectAll')}</option>
              {
                Config.IMAGE_IMAGE_TYPES
                  .map(e => <option value={e.id} key={e.id}>{t(e.i18nKey)}</option>)
              }
            </Field>
          </div>
        </div>
      </div>


      <div className="item">
        <label htmlFor="license">{t('image.searchForm.license')}</label>
        <div className="license">
          <div className="select-wrap">
            <Field
              name="license_id"
              component="select"
              onChange={() => {
                setTimeout(() => onSubmit(), 0);
              }}
            >
              <option value="">{t('common.selectAll')}</option>
              {
                Config.IMAGE_LICENSES
                  .map(e => <option value={e.id} key={e.id}>{t(e.i18nKey)}</option>)
              }
            </Field>
          </div>
        </div>
      </div>

      <div className="item">
        <div className="isMyImage">

          <Field
            name="is_my_image"
            id="isMyImage"
            component="input"
            type="checkbox"
            onChange={() => {
              setTimeout(() => onSubmit(), 0);
            }}
          />
          <label className="checkbox" htmlFor="isMyImage">{t('image.searchForm.isMyImage')}</label>
        </div>
      </div>

    </form>
  </div>
);

export default reduxForm({ form: 'imageSearchForm' })(ImageSearchForm);
