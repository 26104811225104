import React from 'react';

const ImageGroupCreatePopup = props => (
  <div className="imageGroupCreatePopup">
    <div className="imageGroupCreatePopup-inner">
      <div className="items">

        <div className="item">
          <label htmlFor="imageGroupName">{props.t('image.imageGroupName')}</label>

          <div className="imageGroupName">
            <input type="text" ref={props.refImageGroupName} />
          </div>
        </div>

      </div>

      <div className="footer">
        <div className="left">
          <button type="button" onClick={props.onCancel}>Cancel</button>
        </div>
        <div className="right">
          <button type="button" onClick={props.onSubmit}>OK</button>
        </div>

        <div className="clear" />
      </div>
    </div>
  </div>
);

export default ImageGroupCreatePopup;
