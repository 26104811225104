import React from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { translate } from 'react-i18next';

import ContentFrameEditSection from '../../components/contentFrame/ContentFrameEditSection';
import { fetchArticles } from '../../actions/articleAction';

class ContentFrameEditSectionContainer extends React.Component {
  constructor(props) {
    super(props);

    this.updateForm = this.updateForm.bind(this);
    this.handleContentTypeClick = this.handleContentTypeClick.bind(this);
    this.handleImageSearchClick = this.handleImageSearchClick.bind(this);
    this.handleImageSubmitClick = this.handleImageSubmitClick.bind(this);
    this.handleImageCancelClick = this.handleImageCancelClick.bind(this);
    this.handleImageLinkURLChange = this.handleImageLinkURLChange.bind(this);
    this.handleArticleUpClick = this.handleArticleUpClick.bind(this);
    this.handleArticleDownClick = this.handleArticleDownClick.bind(this);
    this.handleArticleRemoveClick = this.handleArticleRemoveClick.bind(this);
    this.handleArticleAddClick = this.handleArticleAddClick.bind(this);
    this.handleArticleSubmitClick = this.handleArticleSubmitClick.bind(this);
    this.handleArticleCancelClick = this.handleArticleCancelClick.bind(this);
    this.handleArticleContentSizeChange = this.handleArticleContentSizeChange.bind(this);
    this.handleEmbedChange = this.handleEmbedChange.bind(this);

    this.state = {
      selectedContentType: null,
      contentFrameItems: null,
      isDisplayArticleSearchPopup: false,
      isDisplayImageSearchPopup: false,
      storedArticles: [],
    };
  }

  componentWillMount() {
    const { contentType, content } = this.props;

    const contentFrameItems = {
      image: [{ image_url: '', link_url: '' }],
      article: { content_size: 'small', articles: [] },
      embed: '',
      campaign: '',
    };

    if (contentType === 'embed' || contentType === 'campaign') {
      contentFrameItems[contentType] = content;
    } else if (content != null && content.length > 0) {
      if (contentType === 'image' && JSON.parse(content).length > 0) {
        contentFrameItems[contentType] = JSON.parse(content);
      } else if (contentType === 'article') {
        contentFrameItems[contentType] = JSON.parse(content);
      }
    }

    if (contentType === 'article') {
      const c = contentFrameItems[contentType];

      if (c.articles.length > 0) {
        const ids = c.articles.map(a => a.article_id);

        this.props.fetchArticles(
          { 'id[]': ids },
          [],
          null,
          e => this.setState({ storedArticles: e.articles }),
        );
      }
    }

    this.setState({ selectedContentType: contentType });
    this.setState({ contentFrameItems });
  }

  updateForm(contentType, contentFrameItems) {
    const { form, index } = this.props;

    const contentTypes = [].concat(form.content_types || []);
    contentTypes[index] = contentType;
    this.props.updateForm('content_types', contentTypes);


    const contents = [].concat(form.contents || []);

    if (contentTypes[index] === 'image' || contentTypes[index] === 'article') {
      contents[index] =
        JSON.stringify(contentFrameItems[contentType]);
    } else {
      contents[index] = contentFrameItems[contentType];
    }

    this.props.updateForm('contents', contents);
  }

  handleContentTypeClick(e) {
    this.setState({ selectedContentType: e.target.dataset.type });

    this.updateForm(e.target.dataset.type, this.state.contentFrameItems);
  }

  handleImageSearchClick() {
    this.setState({ isDisplayImageSearchPopup: true });
  }

  handleImageSubmitClick(image) {
    const contentFrameItems = Object.assign({}, this.state.contentFrameItems);

    contentFrameItems.image[0].image_url = image.url;

    this.setState({ contentFrameItems });
    this.setState({ isDisplayImageSearchPopup: false });

    this.updateForm(this.state.selectedContentType, contentFrameItems);
  }

  handleImageCancelClick() {
    this.setState({ isDisplayImageSearchPopup: false });
  }

  handleImageLinkURLChange(e) {
    const contentFrameItems = Object.assign({}, this.state.contentFrameItems);
    contentFrameItems.image[0].link_url = e.target.value;
    this.setState({ contentFrameItems });

    this.updateForm(this.state.selectedContentType, contentFrameItems);
  }

  handleArticleUpClick(e) {
    const contentFrameItems = Object.assign({}, this.state.contentFrameItems);
    const i = Number(e.target.dataset.key);

    if (i === 0) {
      return;
    }

    const articles = [].concat(contentFrameItems.article.articles);

    const target = articles.splice(i, 1);
    articles.splice(i - 1, 0, target[0]);
    contentFrameItems.article.articles = articles;

    this.setState({ contentFrameItems });

    this.updateForm(this.state.selectedContentType, contentFrameItems);
  }

  handleArticleDownClick(e) {
    const contentFrameItems = Object.assign({}, this.state.contentFrameItems);
    const i = Number(e.target.dataset.key);

    const articles = [].concat(contentFrameItems.article.articles);

    if (i >= articles.length - 1) {
      return;
    }

    const target = articles.splice(i, 1);
    articles.splice(i + 1, 0, target[0]);
    contentFrameItems.article.articles = articles;

    this.setState({ contentFrameItems });

    this.updateForm(this.state.selectedContentType, contentFrameItems);
  }

  handleArticleRemoveClick(e) {
    const contentFrameItems = Object.assign({}, this.state.contentFrameItems);
    const i = Number(e.target.dataset.key);

    const articles = [].concat(contentFrameItems.article.articles);

    articles.splice(i, 1);
    contentFrameItems.article.articles = articles;

    this.setState({ contentFrameItems });

    this.updateForm(this.state.selectedContentType, contentFrameItems);
  }

  handleArticleAddClick() {
    this.setState({ isDisplayArticleSearchPopup: true });
  }

  handleArticleSubmitClick(article) {
    const contentFrameItems = Object.assign({}, this.state.contentFrameItems);
    const articles = [].concat(contentFrameItems.article.articles);
    articles.push({ article_id: article.article_id });
    contentFrameItems.article.articles = articles;

    this.setState({ contentFrameItems });

    const storedArticles = [].concat(this.state.storedArticles);
    storedArticles.push(article);
    this.setState({ storedArticles });

    this.setState({ isDisplayArticleSearchPopup: false });

    this.updateForm(this.state.selectedContentType, contentFrameItems);
  }

  handleArticleCancelClick() {
    this.setState({ isDisplayArticleSearchPopup: false });
  }

  handleArticleContentSizeChange(e) {
    const contentFrameItems = Object.assign({}, this.state.contentFrameItems);
    contentFrameItems.article.content_size = e.target.options[e.target.selectedIndex].value;
    this.setState({ contentFrameItems });

    this.updateForm(this.state.selectedContentType, contentFrameItems);
  }

  handleEmbedChange(e) {
    const contentFrameItems = Object.assign({}, this.state.contentFrameItems);
    contentFrameItems.embed = e.target.value;
    this.setState({ contentFrameItems });

    this.updateForm(this.state.selectedContentType, contentFrameItems);
  }

  render() {
    const { t } = this.props;

    return (
      <div className="contentFrameEditSectionContainer">
        <ContentFrameEditSection
          contentType={this.state.selectedContentType}
          contentFrameItems={this.state.contentFrameItems}
          articles={this.state.storedArticles}
          onContentTypeClick={this.handleContentTypeClick}
          onImageSearchClick={this.handleImageSearchClick}
          onImageSubmitClick={this.handleImageSubmitClick}
          onImageCancelClick={this.handleImageCancelClick}
          onImageLinkURLChange={this.handleImageLinkURLChange}
          onArticleUpClick={this.handleArticleUpClick}
          onArticleDownClick={this.handleArticleDownClick}
          onArticleRemoveClick={this.handleArticleRemoveClick}
          onArticleAddClick={this.handleArticleAddClick}
          onArticleSubmitClick={this.handleArticleSubmitClick}
          onArticleCancelClick={this.handleArticleCancelClick}
          onArticleContentSizeChange={this.handleArticleContentSizeChange}
          onEmbedChange={this.handleEmbedChange}
          isDisplayImageSearchPopup={this.state.isDisplayImageSearchPopup}
          isDisplayArticleSearchPopup={this.state.isDisplayArticleSearchPopup}
          t={t}
        />
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    articles: state.articles,
  }
);

const mapDispatchToProps = dispatch => (
  {
    fetchArticles: (form, articles, offset, callback) =>
      dispatch(fetchArticles(form, articles, offset, callback)),
    updateForm: (key, value) => dispatch(change('contentFrameEditForm', key, value)),
  }
);

const contentFrameEditSectionContainer = translate()(ContentFrameEditSectionContainer);
export default connect(mapStateToProps, mapDispatchToProps)(contentFrameEditSectionContainer);
