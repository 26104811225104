import {
  FETCH_MEDIA_DISTRIBUTIONS_START,
  FETCH_MEDIA_DISTRIBUTIONS_COMPLETE,
} from '../actions/mediaDistributionAction';

const mediaDistributionsReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_MEDIA_DISTRIBUTIONS_START:
      return state;

    case FETCH_MEDIA_DISTRIBUTIONS_COMPLETE:
      return action.payload;

    default:
      return state;
  }
};

export default mediaDistributionsReducer;
