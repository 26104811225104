const appEnv = require(`./_env-${process.env.ENV_NAME}`).default;

const Config = {
  ENV: appEnv.ENV,
  NAMESPACE: appEnv.NAMESPACE,
  LOGO_IMAGE: appEnv.LOGO_IMAGE,

  WEB_ENDPOINT: appEnv.WEB_ENDPOINT,
  CMS_ENDPOINT: appEnv.CMS_ENDPOINT,
  API_ENDPOINT: appEnv.API_ENDPOINT,
  AUTH_ENDPOINT: appEnv.AUTH_ENDPOINT,
  MANAGE_ENDPOINT: appEnv.MANAGE_ENDPOINT,
  REPORT_ENDPOINT: appEnv.REPORT_ENDPOINT,

  ARTICLE_BRANDS: [
    { id: 'journey', name: 'Journey' },
    { id: 'kitchen', name: 'Kitchen' },
    { id: 'me', name: 'me' },
    { id: 'awake', name: 'Awake' },
    { id: 'local', name: 'JAPAN LOCAL' },
    { id: 'bee', name: 'Be' },
    { id: 'go', name: 'GO' },
    { id: 'takibito', name: 'TAKI BITO' },
    { id: 'summary', name: 'Summary' },
    { id: 'documentary', name: 'Documentary' },
    { id: 'pulse', name: 'Pulse' },
  ],

  ARTICLE_CONTENT_TYPES: [
    { id: 'curation', i18nKey: 'common.articleContentTypes.curation' },
    { id: 'original', i18nKey: 'common.articleContentTypes.original' },
    { id: 'media_partner', i18nKey: 'common.articleContentTypes.mediaPartner' },
    { id: 'pr', i18nKey: 'common.articleContentTypes.pr' },
    { id: 'contribution', i18nKey: 'common.articleContentTypes.contribution' },
    { id: 'event', i18nKey: 'common.articleContentTypes.event' },
  ],

  ARTICLE_STATUSES: [
    { id: 'draft', i18nKey: 'common.articleStatuses.draft' },
    { id: 'reserved', i18nKey: 'common.articleStatuses.reserved' },
    { id: 'posted', i18nKey: 'common.articleStatuses.posted' },
    { id: 'deleted', i18nKey: 'common.articleStatuses.deleted' },
  ],

  ARTICLE_INPUT_TYPES: [
    { id: 'credit', i18nKey: 'common.articleInputTypes.credit' },
    { id: 'profile', i18nKey: 'common.articleInputTypes.profile' },
    { id: 'relation_article', i18nKey: 'common.articleInputTypes.relationArticle' },
    { id: 'conversation', i18nKey: 'common.articleInputTypes.conversation' },
    { id: 'interview', i18nKey: 'common.articleInputTypes.interview' },
    { id: 'pinterest', i18nKey: 'common.articleInputTypes.pinterest' },
    { id: 'pagination', i18nKey: 'common.articleInputTypes.pagination' },
    { id: 'reference', i18nKey: 'common.articleInputTypes.reference' },
    { id: 'textbox', i18nKey: 'common.articleInputTypes.textbox' },
  ],

  ARTICLE_CONVERSATION_POSITIONS: [
    { id: 'left', i18nKey: 'article.conversation.positionLeft' },
    { id: 'right', i18nKey: 'article.conversation.positionRight' },
  ],

  ARTICLE_TEXTBOX_POSITIONS: [
    { id: 'left', i18nKey: 'article.textbox.positionLeft' },
    { id: 'center', i18nKey: 'article.textbox.positionCenter' },
    { id: 'right', i18nKey: 'article.textbox.positionRight' },
  ],

  ARTICLE_TABLE_OF_CONTENTS_OPTIONS: [
    { id: 'h2', i18nKey: 'article.tableOfContents.h2' },
    { id: 'h2_h3', i18nKey: 'article.tableOfContents.h2_h3' },
  ],

  ARTICLE_CREDIT_TYPES: [
    { id: 'license', name: 'License' },
    { id: 'reference', name: 'Reference' },
    { id: 'top_image', name: 'Top Image' },
    { id: 'photo', name: 'Photo' },
    { id: 'referenceJa', name: '参照元' },
    { id: 'sponsored', name: 'Sponsor' },
    { id: 'copyright', name: '©' },
    { id: 'free', name: 'Free' },
  ],

  ARTICLE_CREDIT_TYPE_VALUES: {
    license: 'Licensed material used with permission by ',
    reference: 'Reference: ',
    top_image: 'Top image: © ',
    photo: 'Photo by ',
    referenceJa: '参照元: ',
    sponsored: 'Sponsored by ',
    copyright: '© ',
    free: '',
  },

  ARTICLE_CALENDAR_STATUSES: [
    { id: 'draft', i18nKey: 'common.articleStatuses.draft' },
    { id: 'reserved', i18nKey: 'common.articleStatuses.reserved' },
    { id: 'posted', i18nKey: 'common.articleStatuses.posted' },
  ],

  MATCH_OR_NOT: [
    { id: 'match', i18nKey: 'common.match' },
    { id: 'not_match', i18nKey: 'common.notMatch' },
  ],

  IMAGE_IMAGE_TYPES: [
    { id: 'eye_catch', i18nKey: 'common.imageImageTypes.eyeCatch' },
    { id: 'article', i18nKey: 'common.imageImageTypes.article' },
    { id: 'material', i18nKey: 'common.imageImageTypes.material' },
    { id: 'spc', i18nKey: 'common.imageImageTypes.spc' },
  ],

  IMAGE_LICENSES: [
    { id: 1, i18nKey: 'common.imageLicenses.royaltyFree' },
    { id: 2, i18nKey: 'common.imageLicenses.rightsManaged' },
    { id: 3, i18nKey: 'common.imageLicenses.cc0' },
    { id: 4, i18nKey: 'common.imageLicenses.originalForArticle' },
    { id: 5, i18nKey: 'common.imageLicenses.licensed' },
    { id: 6, i18nKey: 'common.imageLicenses.originalForAd' },
    { id: 7, i18nKey: 'common.imageLicenses.providedByAdvertiser' },
    { id: 8, i18nKey: 'common.imageLicenses.editorial' },
    { id: 9, i18nKey: 'common.imageLicenses.stockPhoto' },
  ],

  AD_FRAME_DELIVERY_STATUSES: [
    { id: 'start', i18nKey: 'common.adFrameDeliveryStatuses.start' },
    { id: 'stop', i18nKey: 'common.adFrameDeliveryStatuses.stop' },
  ],

  MEDIA_EMBED_TYPES: [
    { id: 'facebook', i18nKey: 'common.mediaEmbedTypes.facebook' },
    { id: 'twitter', i18nKey: 'common.mediaEmbedTypes.twitter' },
    { id: 'instagram', i18nKey: 'common.mediaEmbedTypes.instagram' },
    { id: 'vimeo', i18nKey: 'common.mediaEmbedTypes.vimeo' },
    { id: 'youtube', i18nKey: 'common.mediaEmbedTypes.youtube' },
    { id: 'other', i18nKey: 'common.mediaEmbedTypes.other' },
  ],

  PERMISSIONS: {
    article: 'article',
    article_all_read: 'article_all_read',
    article_edit: 'article_edit',
    article_status_edit: 'article_status_edit',
    image: 'image',
    ad_frame: 'ad_frame',
    content_frame: 'content_frame',
    author: 'author',
    media: 'media',
    category: 'category',
    tag: 'tag',
    user: 'user',
  },

  FEATURE_CONTENT_TEMPLATE_TYPES: [
    { id: 'default', i18nKey: 'feature.contentTemplateDefault' },
    { id: 'flat', i18nKey: 'feature.contentTemplateFlat' },
    { id: 'no_image', i18nKey: 'feature.contentTemplateNoImage' },
  ],

  FEATURE_HEADER_TYPES: [
    { id: 'default', i18nKey: 'feature.headerTypeDefault' },
    { id: 'none', i18nKey: 'feature.headerTypeNone' },
    { id: 'transparent', i18nKey: 'feature.headerTypeTransparent' },
  ],

  FEATURE_CONTENT_TYPES: [
    'tag',
    'feature',
    'brand',
    'original',
  ],

  FEATURE_STATUSES: [
    { id: 'draft', i18nKey: 'feature.featureStatuses.draft' },
    { id: 'posted', i18nKey: 'feature.featureStatuses.posted' },
  ],

  MEDIA_TO_DISTRIBUTE_FEATURE: [
    { id: 'local', name: 'JAPAN LOCAL' },
  ],

  LANGUAGES: [
    { id: 'en', name: 'English' },
    { id: 'ja', name: '日本語' },
    { id: 'zh-TW', name: '中文 (繁體)' },
    { id: 'ko', name: '한국어' },
  ],
};

export default Config;
