import {
  FETCH_IMAGE_GROUPS_START,
  FETCH_IMAGE_GROUPS_COMPLETE,
} from '../actions/imageGroupAction';

const imageGroupsReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_IMAGE_GROUPS_START:
      return state;

    case FETCH_IMAGE_GROUPS_COMPLETE:
      return action.payload;

    default:
      return state;
  }
};

export default imageGroupsReducer;
