import { addSuccessAlert, addErrorAlert } from './alertAction';
import { removeLoading } from './loadingAction';
import {
  fetchMedias as fetchMediasAPI,
  fetchMedia as fetchMediaAPI,
  createMedia as createMediaAPI,
  updateMedia as updateMediaAPI,
  deleteMedia as deleteMediaAPI,
} from '../api/apiClient';
import i18n from '../config/i18n';

export const FETCH_MEDIAS_START = 'FETCH_MEDIAS_START';
export const FETCH_MEDIAS_COMPLETE = 'FETCH_MEDIAS_COMPLETE';
export const FETCH_MEDIA_START = 'FETCH_MEDIA_START';
export const FETCH_MEDIA_COMPLETE = 'FETCH_MEDIA_COMPLETE';
export const CREATE_MEDIA_START = 'CREATE_MEDIA_START';
export const CREATE_MEDIA_COMPLETE = 'CREATE_MEDIA_COMPLETE';
export const UPDATE_MEDIA_START = 'UPDATE_MEDIA_START';
export const UPDATE_MEDIA_COMPLETE = 'UPDATE_MEDIA_COMPLETE';
export const DELETE_MEDIA_START = 'DELETE_MEDIA_START';
export const DELETE_MEDIA_COMPLETE = 'DELETE_MEDIA_COMPLETE';

const fetchMediasStart = () => ({ type: FETCH_MEDIAS_START });

const fetchMediasComplete = res => (
  {
    type: FETCH_MEDIAS_COMPLETE,
    payload: res,
  }
);

const fetchMediaStart = () => ({ type: FETCH_MEDIA_START });

const fetchMediaComplete = res => ({
  type: FETCH_MEDIA_COMPLETE,
  payload: res,
});

const createMediaStart = () => ({ type: CREATE_MEDIA_START });

const createMediaComplete = res => ({
  type: CREATE_MEDIA_COMPLETE,
  payload: res,
});

const updateMediaStart = () => ({ type: UPDATE_MEDIA_START });

const updateMediaComplete = res => ({
  type: UPDATE_MEDIA_COMPLETE,
  payload: res,
});

const deleteMediaStart = () => ({ type: DELETE_MEDIA_START });

const deleteMediaComplete = () => ({
  type: DELETE_MEDIA_COMPLETE,
  payload: {},
});

export const fetchMedias = () => (
  (dispatch) => {
    dispatch(fetchMediasStart());

    fetchMediasAPI().then((res) => {
      dispatch(fetchMediasComplete(res.body));
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(addErrorAlert(i18n.t('messages.fetchMediaError', { message: res.text })));
    });
  }
);

export const fetchMedia = id => (
  (dispatch) => {
    dispatch(fetchMediaStart());

    fetchMediaAPI(id).then((res) => {
      dispatch(fetchMediaComplete(res.body));
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(addErrorAlert(i18n.t('messages.fetchMediaError', { message: res.text })));
    });
  }
);

export const createMedia = (form, callback) => (
  (dispatch) => {
    dispatch(createMediaStart());

    createMediaAPI(form).then((res) => {
      dispatch(createMediaComplete(res.body));
      dispatch(removeLoading());

      if (callback != null) {
        callback(res.body);
      } else {
        dispatch(addSuccessAlert(i18n.t('messages.createMediaSuccess')));
      }
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(removeLoading());
      dispatch(addErrorAlert(i18n.t('messages.createMediaError', { message: res.text })));
    });
  }
);

export const updateMedia = (form, callback) => (
  (dispatch) => {
    dispatch(updateMediaStart());

    updateMediaAPI(form).then((res) => {
      dispatch(updateMediaComplete(res.body));
      dispatch(removeLoading());
      dispatch(addSuccessAlert(i18n.t('messages.updateMediaSuccess')));

      if (callback != null) {
        callback(res.body);
      }
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(removeLoading());
      dispatch(addErrorAlert(i18n.t('messages.updateMediaError', { message: res.text })));
    });
  }
);

export const deleteMedia = id => (
  (dispatch) => {
    dispatch(deleteMediaStart());

    deleteMediaAPI(id).then(() => {
      dispatch(deleteMediaComplete());
      dispatch(removeLoading());
      dispatch(addSuccessAlert(i18n.t('messages.deleteMediaSuccess')));
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(removeLoading());
      dispatch(addErrorAlert(i18n.t('messages.deleteMediaError', { message: res.text })));
    });
  }
);
