import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, change } from 'redux-form';
import Autosuggest from 'react-autosuggest';
import { translate } from 'react-i18next';

import MediaEditForm from '../../components/media/MediaEditForm';
import { fetchCategories } from '../../actions/categoryAction';
import { fetchTags } from '../../actions/tagAction';

class MediaEditFormContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCategoryRemoveClick = this.handleCategoryRemoveClick.bind(this);
    this.handleCategoryAddClick = this.handleCategoryAddClick.bind(this);
    this.handleTagsChange = this.handleTagsChange.bind(this);
    this.handleTagsRenderInput = this.handleTagsRenderInput.bind(this);
    this.handleContentTypeChange = this.handleContentTypeChange.bind(this);
    this.handleEmbedTypeChange = this.handleEmbedTypeChange.bind(this);
    this.handleEmbedCodeAnalyticsChange = this.handleEmbedCodeAnalyticsChange.bind(this);
    this.handleEmbedCodeAnalyticsNewspassChange =
      this.handleEmbedCodeAnalyticsNewspassChange.bind(this);
    this.refTagsInput = this.refTagsInput.bind(this);

    this.state = {
      prevTagsValue: '',
    };
  }

  componentDidMount() {
    this.props.fetchCategories();
  }

  handleSubmit() {
    this.props.onSubmit(this.props.mediaEditForm);
  }

  handleCategoryRemoveClick(e) {
    const form = this.props.mediaEditForm;
    const i = Number(e.target.dataset.key);

    const categories = [].concat(form.feed_categories || []);

    categories.splice(i, 1);

    this.props.updateForm('feed_categories', categories);
  }

  handleCategoryAddClick() {
    const form = this.props.mediaEditForm;
    const categories = [].concat(form.feed_categories || []);

    categories.push(4);
    this.props.updateForm('feed_categories', categories);
  }

  handleTagsChange(tags) {
    this.props.updateForm('feed_tags', tags);
  }

  handleTagsRenderInput({ addTag, value, ref, onChange }) {
    const handleChange = (e, { newValue }) => {
      const tags = this.props.mediaEditForm.feed_tags || [];

      if (newValue.indexOf(',') >= 0 || newValue.indexOf('  ') >= 0) {
        const tag = newValue.replace(/,/g, '').trim();

        if (tag.length > 0 && !tags.includes(tag)) {
          this.handleTagsChange(tags.concat([tag]));
        }

        this.tagsInput.setState({ tag: '' });
      } else {
        onChange(e);
      }
    };

    const handleKeyUp = (e) => {
      const tags = this.props.mediaEditForm.feed_tags || [];
      const newValue = e.target.value.replace(/,/g, '').trim();

      if (e.key === 'Backspace') {
        if (this.state.prevTagsValue === '') {
          this.handleTagsChange(tags.slice(0, tags.length - 1));
        }
      }

      this.setState({ prevTagsValue: newValue });
    };

    const handleKeyPress = (e) => {
      const tags = this.props.mediaEditForm.feed_tags || [];
      const newValue = e.target.value.replace(/,/g, '').trim();

      if (e.key === 'Enter') {
        if (newValue.length > 0 && !tags.includes(newValue)) {
          this.handleTagsChange(tags.concat([newValue]));
        }

        this.tagsInput.setState({ tag: '' });
      }

      this.setState({ prevTagsValue: newValue });
    };

    const handleBlur = () => {
      this.tagsInput.setState({ tag: '' });
    };

    return (
      <Autosuggest
        ref={ref}
        suggestions={this.props.suggestTags.tags || []}
        shouldRenderSuggestions={v => v != null && v.trim().length > 0}
        getSuggestionValue={suggestion => suggestion.name}
        renderSuggestion={suggestion => <span>{suggestion.name}</span>}
        inputProps={{
          value,
          placeholder: this.props.t('media.tagsPlaceholder'),
          onChange: handleChange,
          onKeyUp: handleKeyUp,
          onKeyPress: handleKeyPress,
          onBlur: handleBlur,
        }}
        onSuggestionSelected={(e, { suggestion }) => {
          addTag(suggestion.name);
        }}
        onSuggestionsFetchRequested={(e) => {
          this.props.fetchTags({ prefix: e.value });
        }}
        onSuggestionsClearRequested={() => {}}
      />
    );
  }

  handleContentTypeChange(e) {
    const form = this.props.mediaEditForm;
    const key = e.target.dataset.key;

    let contentTypes = [].concat(form.feed_content_types || []);

    if (e.target.checked) {
      contentTypes.push(key);
      contentTypes.sort((a, b) => {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      });
    } else {
      contentTypes = contentTypes.filter(type => type !== key);
    }

    this.props.updateForm('feed_content_types', contentTypes);
  }

  handleEmbedTypeChange(e) {
    const form = this.props.mediaEditForm;
    const key = e.target.dataset.key;

    let embedTypes = [].concat(form.feed_embed_types || []);

    if (e.target.checked) {
      embedTypes.push(key);
      embedTypes.sort((a, b) => {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      });
    } else {
      embedTypes = embedTypes.filter(type => type !== key);
    }

    this.props.updateForm('feed_embed_types', embedTypes);
  }

  handleEmbedCodeAnalyticsChange(e) {
    const form = this.props.mediaEditForm;

    const embedCode = Object.assign({}, JSON.parse(form.embed_code) || {});
    embedCode.analytics = e.target.value;

    this.props.updateForm('embed_code', JSON.stringify(embedCode));
  }

  handleEmbedCodeAnalyticsNewspassChange(e) {
    const form = this.props.mediaEditForm;

    const embedCode = Object.assign({}, JSON.parse(form.embed_code) || {});
    embedCode.analytics_newspass = e.target.value;

    this.props.updateForm('embed_code', JSON.stringify(embedCode));
  }

  refTagsInput(ref) {
    this.tagsInput = ref;
  }

  render() {
    if (Number(this.props.id) !== this.props.media.id) {
      return null;
    }

    const {
      id,
      mediaEditForm,
      initialValues,
      media,
      categories,
      saveButton,
      closeButton,
      onCancel,
      t,
    } = this.props;

    if (saveButton != null && saveButton.onclick == null) {
      saveButton.onclick = this.handleSubmit;
    }

    if (closeButton != null && closeButton.onclick == null) {
      closeButton.onclick = () => onCancel();
    }

    return (
      <div className="mediaEditFormContainer">
        <MediaEditForm
          id={id}
          form={mediaEditForm}
          initialValues={initialValues}
          media={media}
          categories={categories.categories}
          onCategoryRemoveClick={this.handleCategoryRemoveClick}
          onCategoryAddClick={this.handleCategoryAddClick}
          onTagsChange={this.handleTagsChange}
          onTagsRenderInput={this.handleTagsRenderInput}
          onContentTypeChange={this.handleContentTypeChange}
          onEmbedTypeChange={this.handleEmbedTypeChange}
          onEmbedCodeAnalyticsChange={this.handleEmbedCodeAnalyticsChange}
          onEmbedCodeAnalyticsNewspassChange={this.handleEmbedCodeAnalyticsNewspassChange}
          refTagsInput={this.refTagsInput}
          t={t}
        />
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    categories: state.categories,
    suggestTags: state.tags,
    mediaEditForm:
      state.form.mediaEditForm != null ? state.form.mediaEditForm.values : null,
    initialValues: state.media,
  }
);

const mapDispatchToProps = dispatch => (
  {
    fetchCategories: () => dispatch(fetchCategories()),
    fetchTags: form => dispatch(fetchTags(form)),
    updateForm: (key, value) => dispatch(change('mediaEditForm', key, value)),
  }
);

const mediaEditFormContainer = reduxForm({ form: 'mediaEditForm', enableReinitialize: true })(translate()(MediaEditFormContainer));
export default connect(mapStateToProps, mapDispatchToProps)(mediaEditFormContainer);
