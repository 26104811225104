import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import ImageGroupCreatePopup from '../../components/image/ImageGroupCreatePopup';
import { createImageGroup } from '../../actions/imageGroupAction';
import { addLoading } from '../../actions/loadingAction';
import { addErrorAlert } from '../../actions/alertAction';

class ImageGroupCreatePopupContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.refImageGroupName = this.refImageGroupName.bind(this);
  }

  handleSubmit() {
    this.props.addLoading();
    this.props.createImageGroup({ name: this.imageGroupName.value }, this.props.onSubmit);
  }

  refImageGroupName(ref) {
    this.imageGroupName = ref;
  }

  render() {
    const { onCancel, t } = this.props;

    return (
      <div className="imageGroupCreatePopupContainer">
        <ImageGroupCreatePopup
          onSubmit={this.handleSubmit}
          onCancel={onCancel}
          refImageGroupName={this.refImageGroupName}
          t={t}
        />
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    imageGroup: state.imageGroup,
  }
);

const mapDispatchToProps = dispatch => (
  {
    createImageGroup: (form, callback) => dispatch(createImageGroup(form, callback)),
    addLoading: () => dispatch(addLoading()),
    addErrorAlert: value => dispatch(addErrorAlert(value)),
  }
);

const imageGroupCreatePopupContainer = translate()(ImageGroupCreatePopupContainer);
export default connect(mapStateToProps, mapDispatchToProps)(imageGroupCreatePopupContainer);
