import React from 'react';
import { Field } from 'redux-form';
import Config from '../../config/Config';
import { hasPermission, dateFormat, timeFormat } from '../../util/util';

const ArticlePublishSettingPopup = (props) => {
  const {
    form,
    onSubmit,
    onReserved,
    onCancel,
    permissions,
    t,
  } = props;

  return (
    <div className="articlePublishSettingPopup">
      <div className="articlePublishSettingPopup-inner">
        <p className="popupTitle">{t('article.publishSettingPopup.popTitle')}</p>
        <h4 className="popupGroupName">{t('article.publishSettingPopup.distributionOption')}</h4>
        <div className="wrapGroup">
          <div className="publishSettingItem">
            <div className="twitterPostingEnable">
              <Field
                name="twitter_posting_enable"
                id="twitterPostingEnable"
                component="input"
                type="checkbox"
              />
              <label className="checkbox" htmlFor="twitterPostingEnable">{t('article.publishSettingPopup.twitterPostingEnable')}</label>
            </div>
          </div>
          <div className="publishSettingItem">
            <div className="facebookPostingEnable">
              <Field
                name="facebook_posting_enable"
                id="facebookPostingEnable"
                component="input"
                type="checkbox"
              />
              <label className="checkbox" htmlFor="facebookPostingEnable">{t('article.publishSettingPopup.facebookPostingEnable')}</label>
            </div>
          </div>
        </div>
        <div className="wrapGroup">
          <div className="publishSettingItem">
            <div className="topViewEnable">
              <Field
                name="top_view_enable"
                id="topViewEnable"
                component="input"
                type="checkbox"
              />
              <label className="checkbox" htmlFor="topViewEnable">{t('article.publishSettingPopup.topViewEnable')}</label>
            </div>
          </div>

          <div className="publishSettingItem">
            <div className="mediaDistributionLINE">
              <Field
                name="line_distribution_enable"
                id="mediaDistributionLINE"
                component="input"
                type="checkbox"
              />
              <label className="checkbox" htmlFor="mediaDistributionLINE">{t('article.publishSettingPopup.mediaDistributionLINE')}</label>
            </div>
          </div>
        </div>

        <div className="publishSettingItem">
          <div className="abtestEnable">
            <Field name="abtest_enable" id="abtestEnable" component="input" type="checkbox" />
            <label className="checkbox" htmlFor="abtestEnable">{t('article.publishSettingPopup.abtestEnable')}</label>
          </div>
        </div>
        <div className="publishSettingItem">
          <h4 className="popupGroupName">{t('article.publishSettingPopup.postDate')}</h4>
          {
            hasPermission(Config.PERMISSIONS.article_status_edit, permissions) ?
              <div className="postDate">
                <Field
                  name="post_date"
                  component="input"
                  type="datetime-local"
                  format={value => (value != null ? dateFormat(value, 'yyyy-mm-dd\'T\'HH:MM') || '' : '')}
                />
              </div>
              :
              <div className="postDate">
                {timeFormat(form.post_date) || '-'}
              </div>
          }
        </div>
        <div className="publishSettingItem">
          <h4 className="popupGroupName">{t('article.publishSettingPopup.editDate')}</h4>
          <div className="editDate">
            <Field
              name="edit_date"
              component="input"
              type="date"
            />
          </div>
        </div>
        <div className="buttonWrapper">
          <button type="button" onClick={onCancel} className="cancelButton">{t('article.publishSettingPopup.cancel')}</button>
          {
            form.status === 'reserved' || form.status === 'posted'
              ? <button type="button" onClick={onSubmit} className="saveButton">{t('article.publishSettingPopup.save')}</button>
              : <button type="button" onClick={onReserved} className="saveButton">{t('article.publishSettingPopup.saveAsReserved')}</button>
          }
        </div>
        <div className="clear" />
      </div>
    </div>
  );
};

export default ArticlePublishSettingPopup;
