import React from 'react';
import { Field } from 'redux-form';
import TagsInput from 'react-tagsinput';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import ReactHLS from 'react-hls';

import ImageUploadPopupContainer from '../../containers/image/ImageUploadPopupContainer';
import ArticleGeotagUploadPopupContainer from '../../containers/article/ArticleGeotagUploadPopupContainer';
import VoiceUploadPopupContainer from '../../containers/voice/VoiceUploadPopupContainer';
import ArticlePublishSettingPopupContainer from '../../containers/article/ArticlePublishSettingPopupContainer';
import ArticleItemsEditForm from './ArticleItemsEditForm';
import Config from '../../config/Config';
import { getStrLength, copyToClipboard } from '../../util/util';

const ArticleEditForm = (props) => {
  if (props.form == null || Object.keys(props.form).length === 0) {
    return null;
  }

  const {
    id,
    form,
    article,
    authors,
    categories,
    features,
    onEyeCatchClick,
    onEyeCatchAddClick,
    onEyeCatchToMainClick,
    onEyeCatchRemoveClick,
    onOriginalRatioEyeCatchOpenClick,
    showOriginalRatioImage,
    onOriginalRatioEyeCatchRemoveClick,
    onOriginalRatioEyeCatchAddClick,
    onHeaderVideoURLsOpenClick,
    showHeaderVideoURLs,
    onTitlesAddClick,
    onTitlesToMainClick,
    onTitlesRemoveClick,
    onItemsAddClick,
    onItemsEditClick,
    onItemsCopyClick,
    onItemsUpClick,
    onItemsDownClick,
    onItemsRemoveClick,
    onCopyToClipboardClick,
    onContentTypeChange,
    onTagsChange,
    onTagsClick,
    onTagsRenderInput,
    onTagsRenderTag,
    onDescriptionKeyUp,
    onTwitterDescriptionKeyUp,
    onGeotagsRemoveClick,
    onGeotagsAddClick,
    onFeatureRemoveClick,
    onFeatureAddClick,
    onVoiceAddClick,
    onVoiceRemoveClick,
    onAdTitlesAddClick,
    onAdTitlesRemoveClick,
    onSectionSubmit,
    onSectionCancel,
    onSectionBlur,
    refDescription,
    refDescriptionLabel,
    refTwitterDescription,
    refTwitterDescriptionLabel,
    refTagsInput,
    selectedEyeCatch,
    selectedItemIndex,
    isDisplayImageUploadPopup,
    isDisplayOriginalRatioImageUploadPopup,
    isDisplayPublishSettingPopup,
    onImageUploadSubmit,
    onOriginalRatioImageUploadSubmit,
    onImageUploadCancel,
    isDisplayGeotagsUploadPopup,
    onGeotagsUploadSubmit,
    onGeotagsUploadCancel,
    isDisplayVoiceUploadPopup,
    onVoiceUploadSubmit,
    onVoiceUploadCancel,
    onPublishSettingSubmit,
    onPublishSettingReserved,
    onPublishSettingCancel,
    t,
  } = props;

  const imageUploadPopup = isDisplayImageUploadPopup ?
    (<ImageUploadPopupContainer
      onSubmit={onImageUploadSubmit}
      onCancel={onImageUploadCancel}
    />)
    : null;

  const originalRatioImageUploadPopup = isDisplayOriginalRatioImageUploadPopup ?
    (<ImageUploadPopupContainer
      onSubmit={onOriginalRatioImageUploadSubmit}
      onCancel={onImageUploadCancel}
    />)
    : null;

  const geotagUploadPopup = isDisplayGeotagsUploadPopup ?
    (<ArticleGeotagUploadPopupContainer
      articleID={Number(id)}
      onSubmit={onGeotagsUploadSubmit}
      onCancel={onGeotagsUploadCancel}
    />)
    : null;

  const voiceUploadPopup = isDisplayVoiceUploadPopup ?
    (<VoiceUploadPopupContainer
      articleID={Number(id)}
      onSubmit={onVoiceUploadSubmit}
      onCancel={onVoiceUploadCancel}
    />)
    : null;

  const publishSettingPopup = isDisplayPublishSettingPopup ?
    (<ArticlePublishSettingPopupContainer
      onSubmit={onPublishSettingSubmit}
      onReserved={onPublishSettingReserved}
      onCancel={onPublishSettingCancel}
      t={t}
    />)
    : null;

  const adTitleInfo = [];
  if (form.ad_titles != null) {
    form.ad_titles.forEach((e, i) => {
      adTitleInfo.push([]);
      const titles = (e == null ? [''] : e.split(/\n/));
      titles.some((title, j) => {
        if (j > 2) {
          return true;
        }
        let titleCount = null;
        if (title !== undefined) {
          titleCount = (
            <span>
              {t('article.adTitleCount', { row: j + 1, count: getStrLength(title) || 0 })}
            </span>
          );
        }
        let titleCountAlert = null;
        if (getStrLength(title || '') > 30) {
          titleCountAlert = (
            <span style={{ marginLeft: 10, color: '#f00' }}>
              {t('article.adTitleCountAlert')}
            </span>
          );
        }
        const ret = (
          <div key={j}>
            {titleCount}
            {titleCountAlert}
          </div>
        );
        adTitleInfo[i].push(ret);
        return false;
      });
    });
  }

  const onClickCopyUrl = (e) => {
    e.preventDefault();

    const articlePath = article.status === 'posted'
      ? `${Config.WEB_ENDPOINT}/${article.article_id}/${article.custom_url}`
      : `${Config.WEB_ENDPOINT}/preview/${article.article_id}/${article.preview_key}`;

    const previewUrls = [];
    (article.titles || ['']).forEach((_, titleIndex) => {
      (article.eye_catch_urls || ['']).forEach((__, eyeCatchIndex) => {
        previewUrls.push(`${articlePath}?title_index=${titleIndex + 1}&eye_catch_index=${eyeCatchIndex + 1}`);
      });
    });
    copyToClipboard(previewUrls.join('\n'));
    onCopyToClipboardClick();
  };

  return (
    <div className={`articleEditForm ${form.content_type !== 'pr' || Config.NAMESPACE !== '' ? 'isNotPR' : ''}`}>
      <form method="post">
        <div className="items">

          <div className="item">
            <label htmlFor="eyeCatchURLs">{t('article.eyeCatchURLs')}</label>
            <i
              className="fa fa-info-circle"
              data-for="eyeCatchURLs"
              data-tip={t('article.eyeCatchURLsTooltip')}
            />
            <ReactTooltip
              id="eyeCatchURLs"
              class="tooltip"
              place="right"
              effect="solid"
              multiline
            />

            <div className="eyeCatch">
              {imageUploadPopup}
              {originalRatioImageUploadPopup}
              {geotagUploadPopup}
              {voiceUploadPopup}
              {publishSettingPopup}

              <img
                src={
                  form.eye_catch_urls != null &&
                  form.eye_catch_urls[selectedEyeCatch] != null &&
                  form.eye_catch_urls[selectedEyeCatch] !== '' ?
                    form.eye_catch_urls[selectedEyeCatch] :
                    '/assets/images/no_image.png'
                }
                alt="eyeCatch"
              />

              <div className="buttons right">
                <div onClick={onEyeCatchToMainClick}>
                  <i
                    className="fa fa-arrow-circle-o-up"
                    data-for="eye_catch_urls_main"
                    data-tip={t('article.eyeCatchURLsToMainTooltip')}
                  />
                  <ReactTooltip
                    id="eye_catch_urls_main"
                    class="tooltip"
                    place="right"
                    effect="solid"
                  />
                </div>

                <div onClick={onEyeCatchRemoveClick}>
                  <i className="fa fa-trash-o" />
                </div>
              </div>

              <div className="pagenation">
                {form.eye_catch_urls != null ? form.eye_catch_urls.map((e, i) => (
                  <div
                    name="eyeCatch"
                    className={classNames({
                      button: true,
                      selected: i === selectedEyeCatch,
                    })}
                    key={`eyeCatch-${i}`}
                    data-key={i}
                    onClick={onEyeCatchClick}
                  >
                    {i === 0 ? t('common.main') : t('common.sub', { index: i })}
                  </div>
                )) : null}

                <div className="button" onClick={onEyeCatchAddClick}>
                  <i className="fa fa-plus" />
                </div>
              </div>

              <div className="hideEyeCatch">
                <Field name="hide_eye_catch" id="hideEyeCatch" component="input" type="checkbox" />
                <label className="checkbox" htmlFor="hideEyeCatch">{t('article.hideEyeCatch')}</label>
              </div>
            </div>
          </div>

          <div className="item originalRatioEyeCatchContainer">
            <label htmlFor="originalRatioEyeCatchURL">{t('article.originalRatioEyeCatchURL')}</label>
            <i
              className="fa fa-info-circle"
              data-for="originalRatioEyeCatchURL"
              data-tip={t('article.originalRatioEyeCatchURLTooltip')}
            />
            <ReactTooltip
              id="originalRatioEyeCatchURL"
              class="tooltip"
              place="right"
              effect="solid"
              multiline
            />

            <div className="buttons right">
              <div onClick={onOriginalRatioEyeCatchOpenClick}>
                <i className={classNames({
                  fa: true,
                  'fa-caret-up': showOriginalRatioImage,
                  'fa-caret-down': !showOriginalRatioImage,
                })}
                />
              </div>
            </div>

            <div className={classNames({
              eyeCatch: true,
              originalRatioEyeCatch: true,
              opened: showOriginalRatioImage,
            })}
            >
              <img
                src={
                  form.original_ratio_eye_catch_url != null &&
                  form.original_ratio_eye_catch_url !== '' ?
                    form.original_ratio_eye_catch_url :
                    '/assets/images/no_image.png'
                }
                alt="eyeCatch"
              />

              <div className="buttons right">
                <div onClick={onOriginalRatioEyeCatchRemoveClick}>
                  <i className="fa fa-trash-o" />
                </div>
              </div>

              <div className="pagenation">
                <div className="button" onClick={onOriginalRatioEyeCatchAddClick}>
                  <i className="fa fa-plus" />
                </div>
              </div>
            </div>
          </div>

          <div className="item headerVideoURLsContainer">
            <label htmlFor="headerVideoURLs">{t('article.headerVideoURLs')}</label>

            <div className="buttons right">
              <div onClick={onHeaderVideoURLsOpenClick}>
                <i className={classNames({
                  fa: true,
                  'fa-caret-up': showHeaderVideoURLs,
                  'fa-caret-down': !showHeaderVideoURLs,
                })}
                />
              </div>
            </div>

            <div className={classNames({
              headerVideoURLs: true,
              opened: showHeaderVideoURLs,
            })}
            >
              <div className="headerVideo">
                <div className="videoLable">Mobile</div>
                <Field
                  name="mobile_header_video_url"
                  component="input"
                  type="text"
                  placeholder={t('article.mobileVideoEyeCatchURLPlaceholder')}
                />
                <ReactHLS url={form.mobile_header_video_url} />
              </div>
              <div className="headerVideo">
                <div className="videoLable">PC</div>
                <Field
                  name="pc_header_video_url"
                  component="input"
                  type="text"
                  placeholder={t('article.pcVideoEyeCatchURLPlaceholder')}
                />
                <ReactHLS url={form.pc_header_video_url} />
              </div>
            </div>
          </div>

          <div className="item">
            <label htmlFor="titles">{t('article.titles')}</label>
            <i
              className="fa fa-info-circle"
              data-for="titles"
              data-tip={t('article.titlesTooltip')}
            />
            <ReactTooltip
              id="titles"
              class="tooltip"
              place="right"
              effect="solid"
              multiline
            />

            <div className="titles">
              {form.titles != null ? form.titles.map((e, i) => (
                <div key={`titles-${i}`}>
                  <div className="buttons right">
                    <div onClick={onTitlesToMainClick}>
                      <i
                        className="fa fa-arrow-circle-o-up"
                        data-for="titles_main"
                        data-tip={t('article.titlesToMainTooltip')}
                        data-key={i}
                      />
                      <ReactTooltip
                        id="titles_main"
                        class="tooltip"
                        place="right"
                        effect="solid"
                      />
                    </div>

                    <div onClick={onTitlesRemoveClick}>
                      <i className="fa fa-trash-o" data-key={i} />
                    </div>
                  </div>

                  <div className="content">
                    <div className="label">{ i === 0 ? t('common.main') : t('common.sub', { index: i }) }</div>
                    <Field name={`titles[${i}]`} component="input" type="text" />
                  </div>
                </div>
              )) : null}

              <button type="button" className="add" onClick={onTitlesAddClick}>
                <i className="fa fa-plus" />
              </button>
            </div>

            <div className="titleWithBrContainer">
              <label htmlFor="titleWithBr">{t('article.titleWithBr')}</label>
              <i
                className="fa fa-info-circle"
                data-for="titleWithBr"
                data-tip={t('article.titleWithBrTooltip')}
              />
              <ReactTooltip
                id="titleWithBr"
                class="tooltip"
                place="right"
                effect="solid"
                multiline
              />
              <div className="titleWithBr">
                <Field
                  name="title_with_br"
                  component="input"
                  type="text"
                  placeholder={t('article.titleWithBrPlaceholder')}
                />
              </div>
            </div>
          </div>

          <div className="item">
            <div className="left">
              <label htmlFor="author">{t('article.author')}</label>

              <div className="author">
                <div className="select-wrap">
                  <Field name="author_id" component="select">
                    {
                      authors != null ?
                        authors.map(author => (
                          <option value={author.id} key={author.id}>
                            {author.name}
                          </option>
                        )) : null
                    }
                  </Field>
                </div>
              </div>
            </div>

            <div className="right">
              <label htmlFor="category">{t('article.category')}</label>

              <div className="author">
                <div className="select-wrap">
                  <Field name="category_id" component="select">
                    {
                      categories != null ?
                        categories.map(category => (
                          <option value={category.id} key={category.id}>
                            {category.name}
                          </option>
                        )) : null
                    }
                  </Field>
                </div>
              </div>
            </div>

            <div className="clear" />
          </div>

          <div className="item">
            <label htmlFor="tableOfContents">{t('article.tableOfContents.title')}</label>
            <div className="tableOfContents">
              <div className="select-wrap">
                <Field name="table_of_contents" component="select">
                  <option value="" />
                  {
                    Config.ARTICLE_TABLE_OF_CONTENTS_OPTIONS
                      .map(option => (
                        <option key={option.id} value={option.id}>{t(option.i18nKey)}</option>
                      ))
                  }
                </Field>
              </div>
            </div>
          </div>

          <div className="item">
            <ArticleItemsEditForm
              id={id}
              form={form}
              selectedIndex={selectedItemIndex}
              onAddClick={onItemsAddClick}
              onEditClick={onItemsEditClick}
              onCopyClick={onItemsCopyClick}
              onUpClick={onItemsUpClick}
              onDownClick={onItemsDownClick}
              onRemoveClick={onItemsRemoveClick}
              onSubmit={onSectionSubmit}
              onCancel={onSectionCancel}
              onBlur={onSectionBlur}
              t={t}
            />
          </div>
        </div>

        <div className={`metaItems ${Config.NAMESPACE}`}>
          <div className="metaItem">
            <label htmlFor="url">{t('article.url')}</label>

            <div className="url">
              <span>{`${Config.WEB_ENDPOINT}/${id}/`}</span>
              <Field name="custom_url" component="input" type="text" />
              <button type="button" onClick={onClickCopyUrl}>{t('article.copyUrl')}</button>
            </div>
          </div>

          <div className="metaItem">
            <label htmlFor="brand">{t('article.brand')}</label>

            <div className="brand">
              <div className="select-wrap">
                <Field name="brand" component="select">
                  <option value="" />
                  {
                    Config.ARTICLE_BRANDS
                      .map(e => <option value={e.id} key={e.id}>{e.name}</option>)
                  }
                </Field>
              </div>
            </div>
          </div>

          <div className="metaItem">
            <label htmlFor="contentType">{t('article.contentType')}</label>

            <div className="contentType">
              <div className="select-wrap">
                <Field name="content_type" component="select" onChange={onContentTypeChange}>
                  {
                    Config.ARTICLE_CONTENT_TYPES
                      .map(e => <option value={e.id} key={e.id}>{t(e.i18nKey)}</option>)
                  }
                </Field>
              </div>
            </div>
          </div>

          <div className="metaItem">
            <label htmlFor="tags">{t('article.tags')}</label>

            <i
              className="fa fa-info-circle"
              data-for="tags"
              data-tip={t('article.tagsTooltip')}
            />
            <ReactTooltip
              id="tags"
              class="tooltip"
              place="right"
              effect="solid"
              multiline
            />

            <div className="tags">
              <TagsInput
                ref={refTagsInput}
                renderInput={onTagsRenderInput}
                renderTag={onTagsRenderTag}
                value={form.tags || []}
                onChange={onTagsChange}
                tagProps={
                  {
                    className: 'react-tagsinput-tag',
                    classNameRemove: 'react-tagsinput-remove',
                    onClick: onTagsClick,
                  }
                }
                onlyUnique
              />
            </div>
          </div>

          <div className="metaItem">
            <label htmlFor="seoTitle">{t('article.seoTitle')}</label>

            <i
              className="fa fa-info-circle"
              data-for="seo_title"
              data-tip={t('article.seoTitleTooltip')}
            />
            <ReactTooltip
              id="seo_title"
              class="tooltip"
              place="right"
              effect="solid"
              multiline
            />

            <div className="seoTitle">
              <Field name="seo_title" component="input" type="text" />
            </div>
          </div>

          <div className="metaItem">
            <label htmlFor="referralTitle">{t('article.referralTitle')}</label>
            <i
              className="fa fa-info-circle"
              data-for="referral_title"
              data-tip={t('article.referralTitleTooltip')}
            />
            <ReactTooltip
              id="referral_title"
              class="tooltip"
              place="right"
              effect="solid"
              multiline
            />

            <div className="referralTitle">
              <Field name="referral_title" component="input" type="text" />
            </div>
          </div>

          <div className="metaItem">
            <label htmlFor="socialTitle">{t('article.socialTitle')}</label>
            <i
              className="fa fa-info-circle"
              data-for="social_title"
              data-tip={t('article.socialTitleTooltip')}
            />
            <ReactTooltip
              id="social_title"
              class="tooltip"
              place="right"
              effect="solid"
              multiline
            />

            <div className="socialTitle">
              <Field name="social_title" component="input" type="text" />
            </div>
          </div>

          <div className="metaItem">
            <label htmlFor="description">{t('article.description')}</label>
            <i
              className="fa fa-info-circle"
              data-for="description"
              data-tip={t('article.descriptionTooltip')}
            />
            <ReactTooltip
              id="description"
              class="tooltip"
              place="right"
              effect="solid"
              multiline
            />

            <div className="description">
              <Field
                name="description"
                ref={refDescription}
                component="textarea"
                onKeyUp={onDescriptionKeyUp}
              />
              <div>
                <span ref={refDescriptionLabel}>
                  {t('article.descriptionCount', { count: form.description != null ? getStrLength(form.description) : 0 })}
                </span>
              </div>
            </div>
          </div>

          <div className="metaItem">
            <label htmlFor="twitterDescription">{t('article.twitterDescription')}</label>

            <div className="twitterDescription">
              <Field
                name="twitter_description"
                ref={refTwitterDescription}
                component="textarea"
                onKeyUp={onTwitterDescriptionKeyUp}
              />
              <div>
                <span ref={refTwitterDescriptionLabel}>
                  {t('article.descriptionCount', { count: form.twitter_description != null ? getStrLength(form.twitter_description) : 0 })}
                </span>
                {getStrLength(form.twitter_description || '') > 256 && (
                  <span style={{ marginLeft: 10, color: '#f00' }}>
                    {t('article.descriptionCountAlert')}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="metaItem">
            <label htmlFor="facebookDescription">{t('article.facebookDescription')}</label>

            <div className="facebookDescription">
              <Field
                name="facebook_description"
                component="textarea"
              />
            </div>
          </div>

          <div className="metaItem">
            <label htmlFor="embedCode">{t('article.embedCode')}</label>

            <div className="embedCode">
              <Field name="embed_code" component="textarea" />
            </div>
          </div>

          <div className="metaItem">
            <label htmlFor="geotags">{t('article.geotags')}</label>

            <div className="geotags">
              {form.article_geotags != null ? form.article_geotags.map((e, i) => (
                <div className="geotag" key={i}>
                  <div className="name">
                    {e.name}
                  </div>

                  <div className="buttons">
                    <div className="button">
                      <a href={e.url} target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-external-link" aria-hidden="true" />
                      </a>
                    </div>

                    <div className="button" data-key={i} onClick={onGeotagsRemoveClick}>
                      <i className="fa fa-trash-o" data-key={i} />
                    </div>
                  </div>
                </div>
              )) : null}

              <button type="button" onClick={onGeotagsAddClick}>
                <i className="fa fa-plus" />
              </button>
            </div>
          </div>

          <div className="metaItem">
            <label htmlFor="features">{t('article.features')}</label>

            <div className="features">
              {form.features != null ? form.features.map((e, i) => (
                <div className="feature" key={i}>
                  <div className="select-wrap">
                    <Field name={`features[${i}]`} component="select">
                      <option value="" key="" />
                      {
                        features != null ?
                          features.map(feature => (
                            <option value={feature.key} key={feature.key}>
                              {feature.name}
                            </option>
                          )) : null
                      }
                    </Field>
                  </div>

                  <div className="buttons">
                    <div className="button" data-key={i} onClick={onFeatureRemoveClick}>
                      <i className="fa fa-trash-o" data-key={i} />
                    </div>
                  </div>
                </div>
              )) : null}

              <button type="button" onClick={onFeatureAddClick}>
                <i className="fa fa-plus" />
              </button>
            </div>
          </div>

          <div className="metaItem">
            <label htmlFor="voice">{t('article.voice')}</label>

            {form.voice_url != null && form.voice_url.length > 0 ?
              (
                <div className="voice">
                  <audio src={form.voice_url} controls />
                  <div className="buttons">
                    <div className="button" onClick={onVoiceRemoveClick}>
                      <i className="fa fa-trash-o" />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="voice">
                  <button type="button" onClick={onVoiceAddClick}>
                    <i className="fa fa-plus" />
                  </button>
                </div>
              )
            }
          </div>

          <div className="metaItem">
            <label htmlFor="videoEmbedCode">{t('article.video')}</label>

            <div className="videoEmbedCode">
              <Field
                name="video"
                component="textarea"
              />
            </div>
          </div>

          <div className="metaItem">
            <label htmlFor="authorIDForAnalytics">{t('article.authorIDForAnalytics')}</label>

            <div className="authorIDForAnalytics">
              <div className="select-wrap">
                <Field name="author_id_for_analytics" component="select">
                  <option value="0" key="0" />
                  {
                    authors != null ?
                      authors.map(author => (
                        <option value={author.id} key={author.id}>
                          {author.name}
                        </option>
                      )) : null
                  }
                </Field>
              </div>
            </div>
          </div>

          <hr className="pr" />

          <div className="metaItem pr">
            <label htmlFor="advertiser">{t('article.advertiser')}</label>

            <div className="advertiser">
              <Field name="advertiser" component="input" type="text" />
            </div>
          </div>

          <div className="metaItem pr">
            <div className="adTitles">
              <label htmlFor="adTitle">{t('article.adTitle')}</label>
              <i
                className="fa fa-info-circle"
                data-for="adTitle"
                data-tip={t('article.adTitleTooltip')}
              />
              <ReactTooltip
                id="adTitle"
                class="tooltip"
                place="right"
                effect="solid"
                multiline
              />
              {form.ad_titles != null && form.ad_titles.map((e, i) => (
                <div className="adTitle" key={i}>
                  <div className="body">
                    <Field
                      name={`ad_titles[${i}]`}
                      component="textarea"
                    />
                    {adTitleInfo[i]}
                    {e != null && e.split(/\n/)[3] !== undefined && (
                      <div>
                        <span style={{ color: '#f00' }}>
                          {t('article.adTitleRowsAlert')}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="remove" onClick={onAdTitlesRemoveClick}>
                    <i className="fa fa-trash-o" data-key={i} />
                  </div>
                </div>
              ))}
              <button type="button" className="add" onClick={onAdTitlesAddClick}>
                <i className="fa fa-plus" />
              </button>
            </div>
          </div>
          <div className="metaItem pr">
            <div className="recommendAdEnable">
              <Field
                name="recommend_ad_enable"
                id="recommendAdEnable"
                component="input"
                type="checkbox"
              />
              <label className="checkbox" htmlFor="recommendAdEnable">{t('article.recommendAdEnable')}</label>
              <i
                className="fa fa-info-circle"
                data-for="recommendAdEnable"
                data-tip={t('article.recommendAdEnableTooltip')}
              />
              <ReactTooltip
                id="recommendAdEnable"
                class="tooltip"
                place="right"
                effect="solid"
                multiline
              />
            </div>
          </div>
          <div className="metaItem pr">
            <div className="frameAdEnable">
              <Field
                name="frame_ad_enable"
                id="frameAdEnable"
                component="input"
                type="checkbox"
              />
              <label className="checkbox" htmlFor="frameAdEnable">{t('article.frameAdEnable')}</label>
              <i
                className="fa fa-info-circle"
                data-for="frameAdEnable"
                data-tip={t('article.frameAdEnableTooltip')}
              />
              <ReactTooltip
                id="frameAdEnable"
                class="tooltip"
                place="right"
                effect="solid"
                multiline
              />
            </div>
          </div>

          <hr />

          <div className="metaItem">
            <label htmlFor="memo">{t('article.memo')}</label>

            <div className="memo">
              <Field name="memo" component="textarea" />
            </div>
          </div>

        </div>
      </form>
    </div>
  );
};

export default ArticleEditForm;
