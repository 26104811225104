const i18nZHTW = {
  layout: {
    sideMenu: {
      menus: {
        article: '文章編輯',
        calendar: 'Calendar',
        image: '圖片管理',
        adFrame: '廣告管理',
        contentFrame: '頁面管理',
        author: '作者',
        category: '主題類別',
        tag: '標籤',
        feature: 'Features',
        media: '合作媒體',
        mediaDistribution: '合作媒體發佈',
        user: '帳戶管理',
      },
    },
  },
  login: {
    footerHelp: '系統錯誤或是建議請用Slack聯絡技術團隊 #system.',
  },
  article: {
    eyeCatchURLs: '文章主圖URL',
    originalRatioEyeCatchURL: 'Eye Catch image for original ratio',
    hideEyeCatch: 'Hide Eye Catch image in the article page',
    headerVideoURLs: 'Eye Catch Videos',
    mobileVideoEyeCatchURLPlaceholder: 'Please input video url for mobile.',
    pcVideoEyeCatchURLPlaceholder: 'Please input video url for PC.',
    titles: '文章標題',
    titleWithBr: 'Title with br (max number of words in one line is 17. New line code is <br>)',
    titleWithBrPlaceholder: 'How travel evolves with tech<br>Interview with Airbnb’s design head',
    author: '作者',
    category: '主題類別',
    tableOfContents: {
      title: 'Table of Contents',
      h2: 'Display only h2',
      h2_h3: 'Display h2 and h3',
    },
    url: 'URL',
    copyUrl: 'Copy Preview URLs',
    video: 'Embed code for video article(YouTube)',
    brand: 'Brand',
    contentType: '內容形式',
    tags: '標籤',
    seoTitle: 'SEO 標題',
    referralTitle: '轉載用標題',
    socialTitle: '社群用標題',
    description: '文章摘要',
    twitterDescription: 'Twitter 文章摘要',
    facebookDescription: 'Facebook 文章摘要',
    advertiser: '廣告主名',
    adTitle: '框架廣告標題',
    recommendAdEnable: '在發佈時註冊TOP/推薦框架廣告',
    frameAdEnable: '發佈時註冊框架廣告',
    embedCode: '嵌入碼',
    features: 'Features',
    voice: 'Voice',
    geotags: 'Geotags',
    status: '文章狀態',
    postDate: '文章發佈日',
    topViewEnable: '文章置頂',
    abtestEnable: 'AB 測試啟用',
    twitterPostingEnable: 'Enable Twitter auto posting',
    facebookPostingEnable: 'Enable Facebook auto posting',
    mediaDistributionDisable: 'Disable media distribution',
    memo: '備忘錄',
    authorIDForAnalytics: 'Author for Analytics',
    eyeCatchURLsTooltip: 'AB 測試啟用時, 文章主圖會從主圖/副圖內的選項隨機顯示.<br>測試未啟用時，文章主圖將只顯示主圖.',
    originalRatioEyeCatchURLTooltip: 'This image will be displayed with original aspect ratio in the artice page instead of the image above',
    eyeCatchURLsToMainTooltip: '設定此圖為文章主圖.',
    titlesTooltip: 'AB 測試啟用時, 文章標題將從主標題跟副標題中隨機選取使用.<br>測試未啟用時，文章主標題將只顯示主標題.',
    titlesToMainTooltip: '設定為主標題',
    titleWithBrTooltip: 'Input title with new line using br tag. This is used for a title in the article page',
    tagsTooltip: 'Input tags for tag page and searching articles.<br>The tag you click and select is used for breadcrumbs in the article page.',
    seoTitleTooltip: '在搜索引擎結果內(如:Google)使用的SEO標題.<br>標題字元超過30字元時將有可能被截短.<br>若無輸入，系統機自動採用文章標題.<br> 在標題跟內文使用希望被搜尋到的關鍵字之效益較佳.',
    referralTitleTooltip: '在合作媒體如Smartnews, Gunosy上轉載用的標題<br>題字數超過30字時將有可能被截短.<br>若無輸入，系統機自動採用文章標題.',
    socialTitleTooltip: '在FACEBOOK或TWITTER等社群媒體上使用的標題.<br>標題字數超過25字元時將有可能被截短.<br>若無輸入，系統機自動採用文章標題.',
    descriptionTooltip: '在搜索引擎結果內(如:Google)使用的SEO文章摘要內容.<br>標題字數超過250字元時將有可能被截短.<br>在標題跟內文使用希望被搜尋到的關鍵字之效益較佳.',
    adTitleTooltip: 'Used for Frame Ad Title.<br>Please enter up to 3 lines, up to 30 characters per line.',
    recommendAdEnableTooltip: 'If checked, TOP/Recommendation and Frame ads will be registered with Google Ad Manager at the time of publication.<br>It will be registered soon after publication, but the delivery start will be 00:00 three days later.',
    frameAdEnableTooltip: 'If checked, framed frame ads will be registered with Google Ad Manager when published.<br>It will be registered and distributed soon after publishing.',
    descriptionCount: '字元: {{count}}',
    descriptionCountAlert: 'Please enter no more than 256 characters.',
    adTitleCount: 'Lines: {{row}}, Characters: {{count}}',
    adTitleCountAlert: 'Please enter no more than 30 characters.',
    adTitleRowsAlert: 'Please enter no more than 3 lines.',
    tagsPlaceholder: '輸入標籤',
    sentence: '文章主體',
    heading: '副標題',
    image: '圖片',
    embed: '嵌入',
    etc: '其他',
    imageURLPlaceholder: '圖片URL連結置入',
    imageAltPlaceholder: 'Input Alt text',
    embedPlaceholder: '嵌入碼置入',
    creditTypePlaceholder: '授權內容類型輸入',
    creditNamePlaceholder: '授權者姓名輸入',
    creditURLPlaceholder: '授權者網路連結輸入',
    creditAddPlaceholder: '新增授權者',
    authorNamePlaceholder: '簡歷顯示者姓名',
    profileImageURLPlaceholder: '簡歷顯示者圖像之URL',
    profileSetStructuredData: 'Set structued data',
    pinterestURLPlaceholder: 'Pinterest URL或ID輸入',
    paginationTitlePlaceholder: 'Input text for next page',
    referenceNamePlaceholder: 'Input reference',
    referenceURLPlaceholder: 'Input reference URL',
    insertSection: 'Insert new section',
    editExclusion: '{{userName}} 正在編輯中',
    articleHistory: '文章編輯紀錄',
    searchForm: {
      word: '搜尋',
      wordPlaceholder: '輸入任何欲搜尋文字或文章ID後,按 Enter',
      status: '文章狀態',
      brand: 'Brand',
      isMyArticle: '只顯示本人製作的文章',
      calendarArticleStatus: 'Status',
      calendarIsWorldtopics: 'WORLD TOPICS',
      calendarIsPR: 'PR',
    },
    searchPopup: {
      searchWord: '輸入任何欲搜尋文字或文章ID後,按 Enter',
    },
    geotagPopup: {
      url: 'Input google maps URL',
    },
    publishSettingPopup: {
      popTitle: 'Publish Settings',
      distributionOption: 'Distribution Option',
      twitterPostingEnable: 'Enable Twitter auto posting',
      facebookPostingEnable: 'Enable Facebook auto posting',
      topViewEnable: 'Display at the top',
      mediaDistributionExceptLINE: 'Media distribution except LINE',
      mediaDistributionLINE: 'Enable LINE posting',
      abtestEnable: 'Enable AB Test',
      postDate: 'Post date',
      EditDate: 'Edit date',
      cancel: 'Cancel',
      save: 'Save',
      saveAsReserved: 'Save as reserved',
    },
    statusBar: {
      posted: '已發佈',
      reserved: '已預約',
      deleted: '已刪除',
      report: 'Report',
    },
    header: {
      delete: 'Delete',
      download: 'Download',
      history: 'History',
      preview: 'Preview',
      overwrite: 'Overwrite',
      saveAsDraft: 'Save as draft',
      returnToDraft: 'Return to Draft',
      publishSettings: 'PublishSettings',
    },
  },
  image: {
    url: 'URL',
    tags: '標籤',
    userName: '使用者名稱',
    imageType: '圖片類別',
    license: '授權類型',
    source: '圖片所屬網站名',
    sourceURL: '圖片所屬網站URL',
    imageGroup: '圖片組',
    imageGroupName: '圖片組名',
    tagsPlaceholder: '輸入標籤',
    credit: 'Credit',
    creditName: 'Credit name',
    creditURL: 'Credit URL',
    hideCredit: 'Hide credit',
    isFullWidth: 'Display image with device width',
    searchForm: {
      tags: '標籤',
      imageType: '圖片類別',
      license: '授權類型',
      isMyImage: '只顯示本人上傳的圖片',
    },
    searchPopup: {
      searchWord: '輸入標籤並按 Enter',
    },
    uploadFile: '上傳檔案',
    draggableImage: '拖曳圖片檔至此',
  },
  voice: {
    uploadFile: '上傳檔案',
    draggableVoice: 'Drag and drop audio file here',
  },
  adFrame: {
    name: '廣告圖文組名',
    articleURL: '文章 URL',
    deliveryFromAt: '廣告上線日',
    deliveryToAt: '廣告截止日',
    deliveryStatus: '狀態',
    isDisplays: '顯示廣告圖文組',
    mobileImageURLs: '圖片URL (手機)',
    pcImageURLs: '圖片URL(電腦)',
    searchWord: '搜尋關鍵字',
    searchWordPlaceholder: '輸入搜尋關鍵字',
    deliveryAt: '上線期間',
    frame: '廣告圖文組',
  },
  contentFrame: {
    name: '內容框組',
    key: 'Key',
    contentTypes: '內容類型',
    imageURLPlaceholder: '輸入圖片URL連結',
    editSection: {
      campaignRemark: '廣告圖文組將會隨機顯示',
    },
  },
  author: {
    name: '姓名',
    key: 'Key',
    title: '職稱',
    profile: '個人檔案',
    instagramUrl: 'Instagram URL',
    twitterUrl: 'Twitter URL',
    facebookUrl: 'Facebook URL',
    youtubeUrl: 'YouTube URL',
    icon: '大頭照',
  },
  category: {
    name: '類別名',
    key: 'Key',
    description: '類別說明',
    seoName: '類別名 (SEO)',
    mobileHeaderImageURL: '類別背景圖 URL (手機)',
    pcHeaderImageURL: '類別背景圖 URL (電腦)',
    topViewEnable: '在首頁第一頁顯示',
    sortKey: '顯示順序',
  },
  tag: {
    name: '標籤',
    description: '說明',
    parentID: '母標籤',
    childIDs: '子標籤',
    listFlg: '顯示清單',
    exactMatchFlg: 'Display articles with exact match',
    searchTag: '輸入標籤並按 Enter',
  },
  feature: {
    name: 'Name',
    key: 'Key',
    status: 'Feature status',
    canonicalURL: 'Canonical URL',
    mediaDistribution: 'Media to display this feature',
    advertiserName: 'Advertiser name',
    advertiserImageURL: 'Advertiser image URL',
    title: 'Title',
    seoTitle: 'SEO title',
    ogTitle: 'OG title',
    description: 'Description',
    seoDescription: 'SEO description',
    headerLogo: 'Header logo',
    mobileHeaderImageURL: 'Header image URL (mobile)',
    tabletHeaderImageURL: 'Header image URL (tablet)',
    pcHeaderImageURL: 'Header image URL (desktop)',
    mobileHeaderVideoURL: 'Header video URL (mobile)',
    tabletHeaderVideoURL: 'Header video URL (tablet)',
    pcHeaderVideoURL: 'Header video URL (desktop)',
    ogImage: 'OG image',
    keywords: 'Keywords',
    metaKeywords: 'Meta keywords',
    contentType: 'Content type',
    contentValue: 'Content value',
    contentTemplate: 'Content template',
    contentTemplateDefault: 'Default',
    contentTemplateFlat: 'With image × flat',
    contentTemplateNoImage: 'No header image',
    headerType: 'Header type',
    headerTypeDefault: 'Default header',
    headerTypeNone: 'No Header',
    headerTypeTransparent: 'Transparent',
    backgroundColor: 'Background color',
    fontColor: 'Font color',
    articlePageText: 'Leading Text',
    articleFeatureBannerMobile: 'Imgae URL in feature artile(Mobile)',
    articleFeatureBannerPC: 'Imgae URL in feature artile(PC)',
    bannerBottomMobile: 'Banner bottom image URL in feature page(Mobile)',
    bannerBottomPC: 'Banner bottom image URL in feature page(PC)',
    bannerBottomLinkURL: 'Banner bottom link URL in feature page',
    bannerBottomOpenTab: 'Open banner link in new tab',
    featureListImageURL: 'Image URL for feature list',
    featureListViewEnable: 'Display at the feature list page',
    featureStatuses: {
      draft: 'Draft',
      posted: 'Posted',
    },
  },
  media: {
    name: '合作媒體名',
    key: 'Key',
    feedCategories: '轉載內容類別',
    feedTags: '轉載內容標籤',
    isTagExclude: '排除已選取之標籤',
    feedContentTypes: '轉載內容形式',
    feedEmbedTypes: '轉載嵌入類別',
    embedCode: '嵌入碼',
    adContent: 'Ad content',
    tagsPlaceholder: '輸入標籤',
  },
  mediaDistribution: {
    deleteDistributions: '移除',
    feedMedia: '合作媒體',
  },
  user: {
    name: '姓名',
    email: '電子郵件地址',
    role: '權限',
    lastAccessAt: '上次登入時間',
  },
  common: {
    createdAt: '創建於',
    updatedAt: '上次更新日',
    selectAll: '全選',
    match: 'MATCH',
    notMatch: 'NOT MATCH',
    main: '主圖標',
    sub: '副{{index}}',
    download: '下載',
    new: '開新',
    dateFormat: 'mm/dd/yyyy',
    timeFormat: 'mm/dd/yyyy HH:MM:ss',
    dailyQuotes: [
      'Change perspectives to bring about a “!!”: Not being bound to the conventions or standards of the world, we aspire to deliver a “realization” or “discovery” that usurps people’s expectations. Make an impactful impression that becomes a person’s new life recipe.',
      'To be a chef, sommelier and producer:  Be nimble and flexible, seeing things from a variety of perspectives. Accurately assess the positives and negatives, the best and most efficient way to execute.',
      'Break through doing what you like: You enjoy it so you’re willing to give it your all. Yet you chew on all the inputs and experiences, knowing the meat of the situation. Make tomorrow more delectable with honing of your craft.',
      'Know the five flavors, creating umami: It’s not enough to know one way of accomplishing. By knowing the extremities of views we can hone our own understanding to bring out the best.',
      'Observe, dig deep: Proper preparation isn’t enough. Listening to what’s simmering under the surface, have a nose for what information is important, keeping an eye on the situation.',
      'Pushing past the limit: Don’t be satisfied with “adequate” and give it another shake. Sometimes the heat makes the pay off all the more worth it.',
      'Prepare quickly and adroitly, serve while it’s hot: Start quicker than anyone, mash on the throttle. But make a smooth landing, surveying the land for the perfect opportunity.',
    ],
    repdigitQuote: 'Today is Repdigit day. More hungry, More foolish!',
    articleContentTypes: {
      curation: '外部篩選',
      original: '原創文章',
      mediaPartner: '合作媒體',
      pr: '廣告',
      contribution: '合作作家',
      event: '活動',
    },
    articleStatuses: {
      draft: '草稿',
      reserved: '已預約',
      posted: '已發佈',
      deleted: '已刪除',
    },
    articleInputTypes: {
      credit: '資料來源',
      profile: '個人檔案',
      relationArticle: '相關文章',
      conversation: 'Conversation',
      interview: '訪問',
      pinterest: 'Pinterest',
      pagination: 'Pagination',
      reference: 'Reference',
      textbox: 'Text Box',
    },
    conversation: {
      positionLeft: 'Align Left',
      positionRight: 'Align Right',
    },
    textbox: {
      positionLeft: 'Align Left',
      positionCenter: 'Align Center',
      positionRight: 'Align Right',
    },
    imageImageTypes: {
      eyeCatch: '文章主圖',
      article: '文章內圖',
      material: '素材圖',
      spc: 'FRAME',
    },
    imageLicenses: {
      royaltyFree: '無版權',
      rightsManaged: '單次授權',
      cc0: 'cc0',
      originalForArticle: '原創圖片(文章用)',
      licensed: '授權圖片',
      originalForAd: '原創圖片 (廣告用)',
      providedByAdvertiser: '廣告主提供',
      editorial: '採編',
      stockPhoto: '股票照片',
    },
    adFrameIsDisplays: {
      on: 'On',
      off: 'Off',
    },
    adFrameDeliveryStatuses: {
      start: '已上線',
      stop: '已暫停',
    },
    contentFrameContentTypes: {
      image: '圖片',
      article: '文章',
      embed: '堪入',
      campaign: '廣告管理',
    },
    mediaEmbedTypes: {
      facebook: 'Facebook',
      twitter: 'Twitter',
      instagram: 'Instagram',
      vimeo: 'Vimeo',
      youtube: 'Youtube',
      other: 'Other',
    },
  },
  messages: {
    authFailure: 'User authentication failed.',
    fetchArticleError: 'Article retrieval failed. {{message}}',
    fetchArticleHistoryError: 'Article history retrieval failed. {{message}}',
    fetchArticleDocxError: 'Docx file retrieval failed. {{message}}',
    createArticleSuccess: 'Article created.',
    createArticleError: 'Article creation failed. {{message}}',
    updateArticleSuccess: 'Article updated.',
    updateArticleError: 'Article update failed. {{message}}',
    deleteArticleSuccess: 'Article deleted.',
    deleteArticleError: 'Article delete failed. {{message}}',
    draftArticleSuccess: 'Article drafted.',
    draftArticleError: 'Article draft failed. {{message}}',
    fetchImageError: 'Image retrieval failed. {{message}}',
    fetchImageSourceError: 'Image site retrieval failed. {{message}}',
    fetchImageGroupError: 'Image group retrieval failed. {{message}}',
    createImageSuccess: 'Image uploaded.',
    createImageError: 'Image upload failed. {{message}}',
    createImageValidateError: 'Upload file not specified.',
    updateImageSuccess: 'Image updated.',
    updateImageError: 'Image update failed. {{message}}',
    deleteImageSuccess: 'Image deleted.',
    deleteImageError: 'Image deletion failed. {{message}}',
    createImageGroupSuccess: 'Image group created.',
    createImageGroupError: 'Image group creation failed. {{message}}',
    updateImageGroupSuccess: 'Image group updated.',
    updateImageGroupError: 'Image group update failed. {{message}}',
    createVoiceSuccess: 'Voice uploaded.',
    createVoiceError: 'Voice upload failed. {{message}}',
    createVoiceValidateError: 'Upload file not specified.',
    fetchAdFrameError: 'Ad frame retrieval failed. {{message}}',
    createAdFrameSuccess: 'Ad frame created.',
    createAdFrameError: 'Ad frame creation failed. {{message}}',
    updateAdFrameSuccess: 'Ad frame updated.',
    updateAdFrameError: 'Ad frame update failed. {{message}}',
    deleteAdFrameSuccess: 'Ad frame deleted.',
    deleteAdFrameError: 'Ad frame deletion failed. {{message}}',
    fetchContentFrameError: 'Content frame retrieval failed. {{message}}',
    createContentFrameSuccess: 'Content frame created.',
    createContentFrameError: 'Content frame creation failed. {{message}}',
    updateContentFrameSuccess: 'Content frame updated.',
    updateContentFrameError: 'Content frame update failed. {{message}}',
    deleteContentFrameSuccess: 'Content frame deleted.',
    deleteContentFrameError: 'Content frame deletion failed. {{message}}',
    fetchAuthorError: 'Author retrieval failed. {{message}}',
    createAuthorSuccess: 'Author created.',
    createAuthorError: 'Author creation failed. {{message}}',
    updateAuthorSuccess: 'Author updated.',
    updateAuthorError: 'Author update failed. {{message}}',
    deleteAuthorSuccess: 'Author deleted.',
    deleteAuthorError: 'Author deletion failed. {{message}}',
    fetchCategoryError: 'Category retrieval failed. {{message}}',
    createCategorySuccess: 'Category created.',
    createCategoryError: 'Category creation failed. {{message}}',
    updateCategorySuccess: 'Category updated.',
    updateCategoryError: 'Category update failed. {{message}}',
    deleteCategorySuccess: 'Category deleted.',
    deleteCategoryError: 'Category deletion failed. {{message}}',
    fetchTagError: 'Tag retrieval failed. {{message}}',
    createTagSuccess: 'Tag created.',
    createTagError: 'Tag creation failed. {{message}}',
    updateTagSuccess: 'Tag updated.',
    updateTagError: 'Tag update failed. {{message}}',
    deleteTagSuccess: 'Tag deleted.',
    deleteTagError: 'Tag deletion failed. {{message}}',
    fetchFeatureError: 'Feature retrieval failed. {{message}}',
    createFeatureSuccess: 'Feature created.',
    createFeatureError: 'Feature creation failed. {{message}}',
    updateFeatureSuccess: 'Feature updated.',
    updateFeatureError: 'Feature update failed. {{message}}',
    deleteFeatureSuccess: 'Feature deleted.',
    deleteFeatureError: 'Feature deletion failed. {{message}}',
    fetchMediaError: 'Media retrieval failed. {{message}}',
    createMediaSuccess: 'Media created.',
    createMediaError: 'Media creation failed. {{message}}',
    updateMediaSuccess: 'Media updated.',
    updateMediaError: 'Media update failed. {{message}}',
    deleteMediaSuccess: 'Media deleted.',
    deleteMediaError: 'Media deletion failed. {{message}}',
    fetchMediaDistributionError: 'Media distribution retrieval failed. {{message}}',
    createMediaDistributionSuccess: 'Media distribution created.',
    createMediaDistributionError: 'Media distribution creation failed. {{message}}',
    updateMediaDistributionSuccess: 'Media distribution updated.',
    updateMediaDistributionError: 'Media distribution update failed. {{message}}',
    deleteMediaDistributionSuccess: 'Media distribution deleted.',
    deleteMediaDistributionError: 'Media distribution deletion failed. {{message}}',
    fetchUserError: 'User retrieval failed. {{message}}',
    createUserSuccess: 'User created.',
    createUserError: 'User creation failed. {{message}}',
    updateUserSuccess: 'User updated.',
    updateUserError: 'User update failed. {{message}}',
    deleteUserSuccess: 'User deleted.',
    deleteUserError: 'User deletion failed. {{message}}',
    fetchPinterestError: 'Pinterest retrieval failed. {{message}}',
    fetchRoleError: 'Role retrieval failed. {{message}}',
    warningEditingSection: '因編輯視窗呈現開啟狀態, 目前無法存擋. 在存擋前按 OK或是Cancel確認修改的內容.',
    warningPastDateReservation: '已過日期無法被設定為上線日期.',
    warningRequirePostDate: '請設定上線日期.',
    warningPastEditDate: 'Cannot Save if the edit date is before the post date.',
    warningRequireImageCredit: 'Please set image credits to publish the article created after 2018/6/1.',
    discardEditingArticle: '內容尚未存檔，您希望離開頁面嗎?',
    restoreFromLocalStorage: '希望恢復原先前的自動存檔嗎?<br>按下Cancel後,自動存檔內容將被刪除.',
    googleMapsURLError: 'URL is invalid. Please enter the URL of Google Maps.',
    warningInvalidVideoEmbedCode: 'Embed code for video article is invalid. Please copy correct code in YouTube',
    warningTagTreeInvalid: 'Cannot set the same tag as parent tag and child tag.',
    setTagForBreadcrumbs: 'Set “{{message}}” as breadcrumb tag',
    warningNonManagedTagForBreadcrumbs: 'Cannot set non managed tag as breadcrumb tag',
    warningNonAdvertiser: '請設置廣告主名戶以保存廣告文章.',
    warningEditingAdContent: '沒有PR文章，無法設置廣告商，框架廣告和TOP/推薦廣告。請將PR設置為內容類型。',
    warningNonAdTitle: 'Please set Frame Ad Title to save PR article.',
    warningAdTitleNumExceeded: '已在框架邊框廣告標題的{{row}}行中輸入{{Count}}個字符。請再次設置30個字符或更少.',
    warningAdTitleRowExceeded: '幀框架廣告標題設置為4行或更多行。請再次設置3行或更少.',
    previewUrlCopied: 'Preview URL was copied.',
  },
};

export default i18nZHTW;
