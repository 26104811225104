import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import ArticleEditSection from '../../components/article/ArticleEditSection';
import { fetchPinterest } from '../../actions/pinterestAction';
import Config from '../../config/Config';

class ArticleEditSectionContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleInputTypeClick = this.handleInputTypeClick.bind(this);
    this.handleImageSearchClick = this.handleImageSearchClick.bind(this);
    this.handleImageSearchSubmit = this.handleImageSearchSubmit.bind(this);
    this.handleImageSearchCancel = this.handleImageSearchCancel.bind(this);
    this.handleImageUploadClick = this.handleImageUploadClick.bind(this);
    this.handleImageUploadSubmit = this.handleImageUploadSubmit.bind(this);
    this.handleImageUploadCancel = this.handleImageUploadCancel.bind(this);
    this.handleImageEditClick = this.handleImageEditClick.bind(this);
    this.handleImageEditSubmit = this.handleImageEditSubmit.bind(this);
    this.handleImageEditCancel = this.handleImageEditCancel.bind(this);
    this.handleEtcInputTypeChange = this.handleEtcInputTypeChange.bind(this);
    this.handleCreditTypeChange = this.handleCreditTypeChange.bind(this);
    this.handleCreditAddClick = this.handleCreditAddClick.bind(this);
    this.handleCreditRemoveClick = this.handleCreditRemoveClick.bind(this);
    this.handleProfileImageSearchClick = this.handleProfileImageSearchClick.bind(this);
    this.handleProfileImageUploadClick = this.handleProfileImageUploadClick.bind(this);
    this.handleRelatedArticleClick = this.handleRelatedArticleClick.bind(this);
    this.handleRelatedArticleSubmit = this.handleRelatedArticleSubmit.bind(this);
    this.handleRelatedArticleCancel = this.handleRelatedArticleCancel.bind(this);
    this.handleConversationPositionChange = this.handleConversationPositionChange.bind(this);
    this.handleConversationImageSearchClick = this.handleConversationImageSearchClick.bind(this);
    this.handleConversationImageUploadClick = this.handleConversationImageUploadClick.bind(this);
    this.handleInterviewImageSearchClick = this.handleInterviewImageSearchClick.bind(this);
    this.handleInterviewImageUploadClick = this.handleInterviewImageUploadClick.bind(this);
    this.handlePinterestClick = this.handlePinterestClick.bind(this);
    this.handleTextboxPositionChange = this.handleTextboxPositionChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    this.refImageSrc = this.refImageSrc.bind(this);
    this.refImageURL = this.refImageURL.bind(this);
    this.refImageAlt = this.refImageAlt.bind(this);
    this.refHideCredit = this.refHideCredit.bind(this);
    this.refIsFullWidth = this.refIsFullWidth.bind(this);
    this.refEmbed = this.refEmbed.bind(this);
    this.refEtcInputType = this.refEtcInputType.bind(this);
    this.refCreditType = this.refCreditType.bind(this);
    this.refCreditTypeKey = this.refCreditTypeKey.bind(this);
    this.refCreditName = this.refCreditName.bind(this);
    this.refCreditURL = this.refCreditURL.bind(this);
    this.refProfileImageSrc = this.refProfileImageSrc.bind(this);
    this.refProfileName = this.refProfileName.bind(this);
    this.refProfileURL = this.refProfileURL.bind(this);
    this.refSetStructuredData = this.refSetStructuredData.bind(this);
    this.refSpeakerName = this.refSpeakerName.bind(this);
    this.refConversationPosition = this.refConversationPosition.bind(this);
    this.refConversationImageSrc = this.refConversationImageSrc.bind(this);
    this.refInterviewImageSrc = this.refInterviewImageSrc.bind(this);
    this.refPinterestURL = this.refPinterestURL.bind(this);
    this.refPaginationTitle = this.refPaginationTitle.bind(this);
    this.refReferenceName = this.refReferenceName.bind(this);
    this.refReferenceURL = this.refReferenceURL.bind(this);
    this.refTextboxPosition = this.refTextboxPosition.bind(this);

    this.state = {
      articleItems: null,
      selectedInputType: null,
      isDisplayArticleSearchPopup: false,
      isDisplayImageSearchPopup: false,
      isDisplayImageUploadPopup: false,
      isDisplayImageEditPopup: false,
    };

    this.unmounted = false;
  }

  componentWillMount() {
    const item = this.props.articleItem;

    const articleItems = {
      sentence: { content: '' },
      heading: { content: '' },
      image: {
        content: '',
        meta: {
          link: {},
          credit: {},
          hide_credit: false,
          is_full_width: false,
        },
      },
      embed: { content: '' },
      credit: {
        content: [
          {
            items: [{ name: '' }],
            type_key: 'license',
            type_value: Config.ARTICLE_CREDIT_TYPE_VALUES.license,
          },
        ],
      },
      profile: {
        content: '',
        meta: {
          author_name: '',
          set_structured_data: false,
        },
      },
      relation_article: { content: '' },
      conversation: {
        content: '',
        meta: {
          image_url: '',
          position: 'left',
          speaker_name: '',
        },
      },
      interview: {
        content: '',
        meta: { image_url: '' },
      },
      pinterest: {
        content: '',
        quote_url: '',
        meta: { domain: '' },
      },
      pagination: { content: '' },
      reference: {
        content: '',
        meta: {
          reference_name: '',
          reference_url: '',
        },
      },
      textbox: {
        content: '',
        meta: {
          position: 'left',
        },
      },
    };

    articleItems[item.input_type] = item;

    this.setState({ articleItems });

    this.setState({ selectedInputType: item.input_type });
  }

  componentWillReceiveProps(nextProps) {
    const articleItems = Object.assign({}, this.state.articleItems);

    if (articleItems.pinterest !== nextProps.pinterest) {
      articleItems.pinterest = nextProps.pinterest;
      this.setState({ articleItems });
    }
  }

  componentWillUnmount() {
    if (!this.unmounted) {
      this.handleSubmit(this.props.onBlur);
    }
  }

  handleInputTypeClick(e) {
    const type = e.target.dataset.type;

    if (type === 'etc') {
      const etcType = this.etcInputType.options[this.etcInputType.selectedIndex].value;
      this.setState({ selectedInputType: etcType });
    } else {
      this.setState({ selectedInputType: type });
    }
  }

  handleImageSearchClick() {
    this.setState({ isDisplayImageSearchPopup: true });
  }

  handleImageSearchSubmit(image, inputType) {
    const articleItems = Object.assign({}, this.state.articleItems);
    const url = image.url;

    if (inputType === 'image') {
      articleItems.image.content = url;
      articleItems.image.meta.link.image_page_url = `/image/${this.props.id}/${image.image_id}`;
      if (image.credit) {
        articleItems.image.meta.credit = JSON.parse(image.credit);
      } else {
        articleItems.image.meta.credit = { name: '', url: '', hide_credit: false, is_full_width: false };
      }
    } else if (inputType === 'profile') {
      articleItems.profile.meta.profile_image_url = url;
    } else if (inputType === 'conversation') {
      articleItems.conversation.meta.image_url = url;
    } else if (inputType === 'interview') {
      articleItems.interview.meta.image_url = url;
    }

    this.setState({ articleItems });
    this.setState({ isDisplayImageSearchPopup: false });
  }

  handleImageSearchCancel() {
    this.setState({ isDisplayImageSearchPopup: false });
  }

  handleImageUploadClick() {
    this.setState({ isDisplayImageUploadPopup: true });
  }

  handleImageUploadSubmit(image, inputType) {
    const articleItems = Object.assign({}, this.state.articleItems);

    const url = image.url;
    if (inputType === 'image') {
      articleItems.image.content = url;
      articleItems.image.meta.link.image_page_url = `/image/${this.props.id}/${image.image_id}`;
      if (image.credit) {
        articleItems.image.meta.credit = JSON.parse(image.credit);
      }
    } else if (inputType === 'profile') {
      articleItems.profile.meta.profile_image_url = url;
    } else if (inputType === 'conversation') {
      articleItems.conversation.meta.image_url = url;
    } else if (inputType === 'interview') {
      articleItems.interview.meta.image_url = url;
    }

    this.setState({ articleItems });
    this.setState({ isDisplayImageUploadPopup: false });
  }

  handleImageUploadCancel() {
    this.setState({ isDisplayImageUploadPopup: false });
  }

  handleImageEditClick() {
    this.setState({ isDisplayImageEditPopup: true });
  }

  handleImageEditSubmit(image) {
    const articleItems = Object.assign({}, this.state.articleItems);
    if (image.credit) {
      articleItems.image.meta.credit = JSON.parse(image.credit);
    }
    this.setState({ articleItems });
    this.setState({ isDisplayImageEditPopup: false });
  }

  handleImageEditCancel() {
    this.setState({ isDisplayImageEditPopup: false });
  }

  handleEtcInputTypeChange(e) {
    this.setState({ selectedInputType: e.target.options[e.target.selectedIndex].value });
  }

  handleCreditTypeChange(e) {
    const inputType = e.target.options[e.target.selectedIndex].value;

    if (inputType === 'free') {
      this.creditType.disabled = false;
    } else {
      this.creditType.disabled = true;
    }

    this.creditTypeKey.value = inputType;
    this.creditType.value = Config.ARTICLE_CREDIT_TYPE_VALUES[inputType];
  }

  handleCreditAddClick() {
    const articleItems = Object.assign({}, this.state.articleItems);
    const creditItems = articleItems.credit.content[0].items;

    for (let i = 0; i < creditItems.length; i += 1) {
      creditItems[i].name = this.creditName[i].value;
      creditItems[i].url = this.creditURL[i].value;
    }

    creditItems.push({ name: '' });

    this.setState({ articleItems });
  }

  handleCreditRemoveClick(e) {
    if (this.state.articleItems.credit.content[0].items.length === 1) {
      return;
    }

    const articleItems = Object.assign({}, this.state.articleItems);
    const creditItems = articleItems.credit.content[0].items;

    for (let i = 0; i < creditItems.length; i += 1) {
      creditItems[i].name = this.creditName[i].value;
      creditItems[i].url = this.creditURL[i].value;
    }

    creditItems.splice(Number(e.target.dataset.key), 1);

    for (let i = 0; i < creditItems.length; i += 1) {
      this.creditName[i].value = creditItems[i].name;
      this.creditURL[i].value = creditItems[i].url;
    }

    this.setState({ articleItems });
  }

  handleProfileImageSearchClick() {
    this.setState({ isDisplayImageSearchPopup: true });
  }

  handleProfileImageUploadClick() {
    this.setState({ isDisplayImageUploadPopup: true });
  }

  handleRelatedArticleClick() {
    this.setState({ isDisplayArticleSearchPopup: true });
  }

  handleRelatedArticleSubmit(article) {
    const articleItems = Object.assign({}, this.state.articleItems);

    articleItems.relation_article = {
      article_url: `${Config.WEB_ENDPOINT}/${article.article_id}/${article.custom_url}`,
      content: article.titles != null ? article.titles[0] : '',
      image_url: article.eye_catch_urls != null ? article.eye_catch_urls[0] : '',
    };

    this.setState({ articleItems });
    this.setState({ isDisplayArticleSearchPopup: false });
  }

  handleRelatedArticleCancel() {
    this.setState({ isDisplayArticleSearchPopup: false });
  }

  handleConversationPositionChange(e) {
    const value = e.target.options[e.target.selectedIndex].value;
    this.refConversationPosition.value = value;
  }

  handleConversationImageSearchClick() {
    this.setState({ isDisplayImageSearchPopup: true });
  }

  handleConversationImageUploadClick() {
    this.setState({ isDisplayImageUploadPopup: true });
  }

  handleInterviewImageSearchClick() {
    this.setState({ isDisplayImageSearchPopup: true });
  }

  handleInterviewImageUploadClick() {
    this.setState({ isDisplayImageUploadPopup: true });
  }

  handleTextboxPositionChange(e) {
    const value = e.target.options[e.target.selectedIndex].value;
    this.refTextboxPosition.value = value;
  }

  handlePinterestClick() {
    const tmp = this.pinterestURL.value.split('/');

    if (tmp.length >= 5) {
      this.props.fetchPinterest(tmp[4]);
    } else {
      this.props.fetchPinterest(tmp[0]);
    }
  }

  handleSubmit(callback) {
    let item = {
      content: null,
      input_type: this.state.selectedInputType,
      media_type: '',
      meta: null,
      article_url: '',
      image_url: '',
      quote: '',
      quote_url: '',
      social_item: null,
    };

    const editors = window.tinymce.EditorManager.editors;
    const editorRegexp = /[\x00-\x1F\x7F-\x9F]/g;

    if (item.input_type === 'sentence') {
      item.content = editors[0].getContent();
      item.content = item.content.replace(editorRegexp, '');
    } else if (item.input_type === 'heading') {
      item.content = editors[1].getContent();
      item.content = item.content.replace(editorRegexp, '');
      item.meta = {};
    } else if (item.input_type === 'image') {
      const imageSrc = this.imageSrc.src;
      const imageURL = this.imageURL.value;
      const imageAlt = this.imageAlt.value;
      item.content = imageSrc;

      item.meta = { link: {} };
      if (imageURL !== '') {
        item.meta.link.url = imageURL;
      }
      if (imageAlt !== '') {
        item.meta.link.alt = imageAlt;
      }
      item.meta.link.image_page_url = this.state.articleItems.image.meta.link.image_page_url;

      if (this.state.articleItems.image.meta.credit) {
        const { name, url } = this.state.articleItems.image.meta.credit;
        item.meta.credit = {
          name,
          url,
          hide_credit: this.hideCredit.checked,
        };
      } else {
        item.meta.credit = {};
      }

      item.meta.is_full_width = this.isFullWidth.checked;
    } else if (item.input_type === 'embed') {
      item.content = this.embed.value;
      item.content = item.content.replace(/[\x00-\x09\x0B\x0C\x0E-\x1F\x7F-\x9F]/g, '');
      item.content = item.content.replace(/\/\/www\.instagram\.com\/embed\.js/g, '//platform.instagram.com/en_US/embeds.js');
      item.media_type = 'default';
    } else if (item.input_type === 'credit') {
      const items = [];

      for (let i = 0; i < this.state.articleItems.credit.content[0].items.length; i += 1) {
        const creditName = this.creditName[i].value;
        const creditURL = this.creditURL[i].value;

        if (creditName !== '') {
          if (creditURL !== '') {
            items.push({ name: creditName, url: creditURL });
          } else {
            items.push({ name: creditName });
          }
        }
      }

      if (items.length === 0) {
        items.push({ name: '' });
      }

      item.content = [{
        items,
        type_key: this.creditTypeKey.value,
        type_value: this.creditType.value,
      }];
    } else if (item.input_type === 'profile') {
      item.content = editors[2].getContent();
      item.content = item.content.replace(editorRegexp, '');

      item.meta = { author_name: this.profileName.value };
      if (this.profileURL.value !== '') {
        item.meta.author_link = this.profileURL.value;
      }
      if (this.profileImageSrc.src !== '') {
        item.meta.profile_image_url = this.profileImageSrc.src;
      }
      item.meta.set_structured_data = this.setStructuredData.checked;
    } else if (item.input_type === 'relation_article') {
      item = { ...item, ...this.state.articleItems.relation_article };
    } else if (item.input_type === 'conversation') {
      item.content = editors[3].getContent().replace(editorRegexp, '');
      item.meta = {
        image_url: this.conversationImageSrc.src,
        position: this.conversationPosition.options[this.conversationPosition.selectedIndex].value,
        speaker_name: this.speakerName.value,
      };
    } else if (item.input_type === 'interview') {
      item.content = editors[4].getContent();
      item.content = item.content.replace(editorRegexp, '');
      item.meta = { image_url: this.interviewImageSrc.src };
    } else if (item.input_type === 'pinterest') {
      item = { ...item, ...this.state.articleItems.pinterest };
    } else if (item.input_type === 'pagination') {
      item.content = this.paginationTitle.value;
    } else if (item.input_type === 'reference') {
      item.content = editors[5].getContent().replace(editorRegexp, '');
      item.meta = {
        reference_name: this.referenceName.value,
        reference_url: this.referenceURL.value,
      };
    } else if (item.input_type === 'textbox') {
      item.content = editors[6].getContent().replace(editorRegexp, '');
      item.meta = {
        position: this.textboxPosition.options[this.textboxPosition.selectedIndex].value,
      };
    }

    window.tinymce.EditorManager.remove();

    callback(item, this.props.selectedIndex);

    this.unmounted = true;
  }

  handleCancel(callback) {
    callback();

    this.unmounted = true;
  }

  refImageSrc(ref) {
    this.imageSrc = ref;
  }

  refImageURL(ref) {
    this.imageURL = ref;
  }

  refImageAlt(ref) {
    this.imageAlt = ref;
  }

  refHideCredit(ref) {
    this.hideCredit = ref;
  }

  refIsFullWidth(ref) {
    this.isFullWidth = ref;
  }

  refEmbed(ref) {
    this.embed = ref;
  }

  refEtcInputType(ref) {
    this.etcInputType = ref;
  }

  refCreditType(ref) {
    this.creditType = ref;
  }

  refCreditTypeKey(ref) {
    this.creditTypeKey = ref;
  }

  refCreditName(ref, i) {
    if (this.creditName == null) {
      this.creditName = {};
    }

    this.creditName[i] = ref;
  }

  refCreditURL(ref, i) {
    if (this.creditURL == null) {
      this.creditURL = {};
    }

    this.creditURL[i] = ref;
  }

  refProfileImageSrc(ref) {
    this.profileImageSrc = ref;
  }

  refProfileName(ref) {
    this.profileName = ref;
  }

  refProfileURL(ref) {
    this.profileURL = ref;
  }

  refSetStructuredData(ref) {
    this.setStructuredData = ref;
  }

  refSpeakerName(ref) {
    this.speakerName = ref;
  }

  refConversationPosition(ref) {
    this.conversationPosition = ref;
  }

  refConversationImageSrc(ref) {
    this.conversationImageSrc = ref;
  }

  refInterviewImageSrc(ref) {
    this.interviewImageSrc = ref;
  }

  refPinterestURL(ref) {
    this.pinterestURL = ref;
  }
  refPaginationTitle(ref) {
    this.paginationTitle = ref;
  }

  refReferenceName(ref) {
    this.referenceName = ref;
  }

  refReferenceURL(ref) {
    this.referenceURL = ref;
  }

  refTextboxPosition(ref) {
    this.textboxPosition = ref;
  }

  render() {
    const { articleItem, onSubmit, onCancel, t } = this.props;

    if (articleItem == null) {
      return null;
    }

    return (
      <div className="articleEditSectionContainer">
        <ArticleEditSection
          articleItems={this.state.articleItems}
          selectedInputType={this.state.selectedInputType}
          onSubmit={() => this.handleSubmit(onSubmit)}
          onCancel={() => this.handleCancel(onCancel)}
          onInputTypeClick={this.handleInputTypeClick}
          onImageSearchClick={this.handleImageSearchClick}
          onImageSearchSubmit={this.handleImageSearchSubmit}
          onImageSearchCancel={this.handleImageSearchCancel}
          onImageUploadClick={this.handleImageUploadClick}
          onImageUploadSubmit={this.handleImageUploadSubmit}
          onImageUploadCancel={this.handleImageUploadCancel}
          onImageEditClick={this.handleImageEditClick}
          onImageEditSubmit={this.handleImageEditSubmit}
          onImageEditCancel={this.handleImageEditCancel}
          onEtcInputTypeChange={this.handleEtcInputTypeChange}
          onCreditTypeChange={this.handleCreditTypeChange}
          onCreditAddClick={this.handleCreditAddClick}
          onCreditRemoveClick={this.handleCreditRemoveClick}
          onProfileImageSearchClick={this.handleProfileImageSearchClick}
          onProfileImageUploadClick={this.handleProfileImageUploadClick}
          onRelatedArticleClick={this.handleRelatedArticleClick}
          onRelatedArticleSubmit={this.handleRelatedArticleSubmit}
          onRelatedArticleCancel={this.handleRelatedArticleCancel}
          onConversationPositionChange={this.handleConversationPositionChange}
          onConversationImageSearchClick={this.handleConversationImageSearchClick}
          onConversationImageUploadClick={this.handleConversationImageUploadClick}
          onInterviewImageSearchClick={this.handleInterviewImageSearchClick}
          onInterviewImageUploadClick={this.handleInterviewImageUploadClick}
          onPinterestClick={this.handlePinterestClick}
          onTextboxPositionChange={this.handleTextboxPositionChange}
          isDisplayArticleSearchPopup={this.state.isDisplayArticleSearchPopup}
          isDisplayImageSearchPopup={this.state.isDisplayImageSearchPopup}
          isDisplayImageUploadPopup={this.state.isDisplayImageUploadPopup}
          isDisplayImageEditPopup={this.state.isDisplayImageEditPopup}
          refImageSrc={this.refImageSrc}
          refImageURL={this.refImageURL}
          refImageAlt={this.refImageAlt}
          refHideCredit={this.refHideCredit}
          refIsFullWidth={this.refIsFullWidth}
          refEmbed={this.refEmbed}
          refEtcInputType={this.refEtcInputType}
          refCreditType={this.refCreditType}
          refCreditTypeKey={this.refCreditTypeKey}
          refCreditName={this.refCreditName}
          refCreditURL={this.refCreditURL}
          refProfileImageSrc={this.refProfileImageSrc}
          refProfileName={this.refProfileName}
          refProfileURL={this.refProfileURL}
          refSetStructuredData={this.refSetStructuredData}
          refSpeakerName={this.refSpeakerName}
          refConversationPosition={this.refConversationPosition}
          refConversationImageSrc={this.refConversationImageSrc}
          refInterviewImageSrc={this.refInterviewImageSrc}
          refPinterestURL={this.refPinterestURL}
          refPaginationTitle={this.refPaginationTitle}
          refReferenceName={this.refReferenceName}
          refReferenceURL={this.refReferenceURL}
          refTextboxPosition={this.refTextboxPosition}
          t={t}
        />
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    pinterest: state.pinterest,
  }
);

const mapDispatchToProps = dispatch => (
  {
    fetchPinterest: id => dispatch(fetchPinterest(id)),
  }
);

const articleEditSectionContainer = translate()(ArticleEditSectionContainer);
export default connect(mapStateToProps, mapDispatchToProps)(articleEditSectionContainer);
