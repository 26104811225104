import React from 'react';
import classNames from 'classnames';

import ArticleSearchPopupContainer from '../../containers/article/ArticleSearchPopupContainer';
import ImageSearchPopupContainer from '../../containers/image/ImageSearchPopupContainer';

const ContentFrameEditSection = (props) => {
  const {
    contentType,
    contentFrameItems,
    articles,
    onContentTypeClick,
    onImageSearchClick,
    onImageSubmitClick,
    onImageCancelClick,
    onImageLinkURLChange,
    onArticleUpClick,
    onArticleDownClick,
    onArticleRemoveClick,
    onArticleAddClick,
    onArticleSubmitClick,
    onArticleCancelClick,
    onArticleContentSizeChange,
    onEmbedChange,
    isDisplayImageSearchPopup,
    isDisplayArticleSearchPopup,
    t,
  } = props;

  const imageSearchPopup = isDisplayImageSearchPopup ?
    (<ImageSearchPopupContainer
      onSubmit={onImageSubmitClick}
      onCancel={onImageCancelClick}
    />)
    : null;

  const articleSearchPopup = isDisplayArticleSearchPopup ?
    (<ArticleSearchPopupContainer
      onSubmit={onArticleSubmitClick}
      onCancel={onArticleCancelClick}
    />)
    : null;

  const articleFrame = contentFrameItems.article.articles.map((e, i) => {
    const a = articles != null ?
      articles.find(article => article.article_id === e.article_id) : null;

    if (a == null) {
      return null;
    }

    return (
      <div className="articleDetail" key={i}>
        <div className="content">
          <div className="buttons right">
            <div className="button" data-key={i} onClick={onArticleUpClick}>
              <i className="fa fa-arrow-up" />
            </div>
            <div className="button" data-key={i} onClick={onArticleDownClick}>
              <i className="fa fa-arrow-down" />
            </div>
            <div className="button" data-key={i} onClick={onArticleRemoveClick}>
              <i className="fa fa-trash-o" />
            </div>
          </div>

          <div className="left">
            <img
              src={
                a.eye_catch_urls != null && a.eye_catch_urls.length > 0 ?
                  a.eye_catch_urls[0] : null
              }
              alt="eyecatch"
            />

            <div className="title">
              {a.titles != null && a.titles.length > 0 ? a.titles[0] : null}
            </div>
          </div>

          <div className="clear" />
        </div>
      </div>
    );
  });

  return (
    <div className="contentFrameEditSection">
      <div className="editHeader">
        <div className="buttons">
          <div className="button" data-type="image" onClick={onContentTypeClick}>
            <i className={classNames({ fa: true, 'fa-picture-o': true, selected: contentType === 'image' })}>
              {t('common.contentFrameContentTypes.image')}
            </i>
          </div>

          <div className="button" data-type="article" onClick={onContentTypeClick}>
            <i className={classNames({ fa: true, 'fa-newspaper-o': true, selected: contentType === 'article' })}>
              {t('common.contentFrameContentTypes.article')}
            </i>
          </div>

          <div className="button" data-type="embed" onClick={onContentTypeClick}>
            <i className={classNames({ fa: true, 'fa-caret-square-o-right': true, selected: contentType === 'embed' })}>
              {t('common.contentFrameContentTypes.embed')}
            </i>
          </div>

          <div className="button" data-type="campaign" onClick={onContentTypeClick}>
            <i className={classNames({ fa: true, 'fa-newspaper-o': true, selected: contentType === 'campaign' })}>
              {t('common.contentFrameContentTypes.campaign')}
            </i>
          </div>
        </div>
      </div>

      <div className="body">
        {imageSearchPopup}
        {articleSearchPopup}

        <div className={contentType === 'image' ? 'editor' : 'editor none'}>
          <div className="image">
            <img
              src={contentFrameItems.image[0].image_url.length > 0 ?
                contentFrameItems.image[0].image_url : '/assets/images/no_image.png'}
              alt="content"
            />

            <div>
              <button type="button" onClick={onImageSearchClick}>Search</button>
            </div>

            <input
              name="link_url"
              type="text"
              placeholder={t('contentFrame.imageURLPlaceholder')}
              defaultValue={contentFrameItems.image[0].link_url}
              onChange={onImageLinkURLChange}
            />
          </div>
        </div>

        <div className={contentType === 'article' ? 'editor' : 'editor none'}>
          <div className="article">

            <div className="select-wrap">
              <select
                defaultValue={contentFrameItems.article.content_size}
                onChange={onArticleContentSizeChange}
              >
                <option value="small">Small</option>
                <option value="big">Big</option>
              </select>
            </div>

            {articleFrame}

            <div className="button" onClick={onArticleAddClick}>
              <i className="fa fa-plus" />
            </div>
          </div>
        </div>

        <div className={contentType === 'embed' ? 'editor' : 'editor none'}>
          <div className="embed">
            <textarea
              defaultValue={contentFrameItems.embed}
              onChange={onEmbedChange}
            />
          </div>
        </div>

        <div className={contentType === 'campaign' ? 'editor' : 'editor none'}>
          <div className="campaign">
            {t('contentFrame.editSection.campaignRemark')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentFrameEditSection;
