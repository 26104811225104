const i18nJA = {
  layout: {
    sideMenu: {
      menus: {
        article: '記事編集',
        calendar: '公開カレンダー',
        image: '画像管理',
        adFrame: 'アドフレーム管理',
        contentFrame: 'コンテンツフレーム管理',
        author: 'ライター管理',
        category: 'カテゴリ管理',
        tag: 'タグ管理',
        feature: '特集管理',
        media: 'メディア管理',
        mediaDistribution: 'メディア配信管理',
        user: 'アカウント管理',
      },
    },
  },
  login: {
    footerHelp: '不具合・改善要望などはSlack #systemチャンネルにお願いします',
  },
  article: {
    eyeCatchURLs: 'アイキャッチ画像',
    originalRatioEyeCatchURL: 'オリジナル比率用アイキャッチ画像',
    hideEyeCatch: 'アイキャッチを記事内で非表示にする',
    headerVideoURLs: 'アイキャッチ動画',
    mobileVideoEyeCatchURLPlaceholder: 'モバイル用動画URLを入力してください',
    pcVideoEyeCatchURLPlaceholder: 'PC用動画URLを入力してください',
    titles: '本文タイトル',
    titleWithBr: '改行付きタイトル (一行最大17文字。改行コードは<br>)',
    titleWithBrPlaceholder: 'サンフランシスコでトップにインタビュー<br>意外に知らない「Airbnb的デザインの考え方」',
    author: 'ライター',
    category: 'カテゴリー',
    tableOfContents: {
      title: '目次の設定',
      h2: 'h2のみを表示',
      h2_h3: 'h2とh3を表示',
    },
    url: 'URL',
    copyUrl: 'プレビューURLをコピー',
    video: '動画記事用埋め込みコード(YouTube)',
    brand: 'ブランド',
    contentType: 'コンテンツタイプ',
    tags: 'タグ',
    seoTitle: 'SEOタイトル',
    referralTitle: 'Referralタイトル',
    socialTitle: 'Socialタイトル',
    description: 'Description',
    twitterDescription: 'Twitter配信テキスト',
    facebookDescription: 'Facebook配信テキスト',
    advertiser: '広告主',
    adTitle: 'フレーム枠広告タイトル',
    recommendAdEnable: '公開時にTOP/レコメンド枠の広告を登録する',
    frameAdEnable: '公開時にフレーム枠の広告を登録する',
    embedCode: '埋込タグ',
    features: '特集',
    voice: 'ボイス',
    geotags: 'ジオタグ',
    status: 'ステータス',
    postDate: '公開日時',
    topViewEnable: 'TOPに表示',
    abtestEnable: 'ABテストをオンにする',
    twitterPostingEnable: 'Twitter自動投稿',
    facebookPostingEnable: 'Facebook自動投稿',
    mediaDistributionDisable: '外部メディア配信しない',
    memo: 'メモ',
    authorIDForAnalytics: 'Analytics用ライター',
    eyeCatchURLsTooltip: 'ABテストをオンにした場合、メイン／サブすべての画像の中からランダムに表示されます。<br>オフにした場合メイン画像のみが表示されます。',
    originalRatioEyeCatchURLTooltip: 'ここでアップした画像は元の画像比率を維持して記事内で優先的に表示されます。',
    eyeCatchURLsToMainTooltip: '表示中の画像をメイン画像にする',
    titlesTooltip: 'ABテストをオンにした場合、メイン／サブすべてのタイトルの中からランダムに表示されます。<br>オフにした場合メインタイトルのみが表示されます。',
    titlesToMainTooltip: 'メインタイトルにする',
    titleWithBrTooltip: 'brタグを入力して改行付きタイトルが設定できます。記事内のタイトルにのみ使用されます。',
    tagsTooltip: 'タグページや検索に利用されるタグを入力してください。<br>入力したタグをクリックして選択すると記事ページのパンくずリストに使用されます。',
    seoTitleTooltip: 'Googleなどの検索結果ページに掲載するタイトルを入力してください。<br>30文字を超えると、途中で切られる場合があります。<br>未入力の場合、本文タイトルが使用されます。<br>検索ヒットさせたいキーワードは本文または本文タイトルに入れたほうが効果があります。',
    referralTitleTooltip: 'Smartnews, Gunosyなど配信先メディアに掲載するタイトルを入力してください。<br>30文字を超えると、途中で切られる場合があります。<br>未入力の場合、本文タイトルが使用されます。',
    socialTitleTooltip: 'Facebook, TwitterなどSNSに掲載するタイトルを入力してください。<br>30文字を超えると、途中で切られる場合があります。<br>未入力の場合、本文タイトルが使用されます。',
    descriptionTooltip: 'Googleなどの検索結果ページに掲載する記事の説明文を入力してください。<br>250文字以内で入力してください。スマホの場合200文字前後で切られるため、重要な説明は先頭から200文字以内に含めてください。<br>検索ヒットさせたいキーワードは本文または本文タイトルに入れたほうが効果があります。',
    adTitleTooltip: 'フレーム枠広告のタイトルに使用されます。<br>1行最大30文字まで、最大3行まで入力してください。',
    recommendAdEnableTooltip: 'オンにすると、公開時にTOP/レコメンド枠の広告がGoogleAdManagerに登録されます。<br>公開後すぐに登録されますが、配信開始は3日後の00:00になります。',
    frameAdEnableTooltip: 'オンにすると、公開時にフレーム枠の広告がGoogleAdManagerに登録されます。<br>公開後すぐに登録・配信開始されます。',
    descriptionCount: '文字数: {{count}}',
    descriptionCountAlert: '設定可能な文字数を超えています。256文字以下にしてください。',
    adTitleCount: '{{row}}行目文字数: {{count}}',
    adTitleCountAlert: '設定可能な文字数を超えています。30文字以下にしてください。',
    adTitleRowsAlert: '設定可能な行数を超えています。3行以下にしてください。',
    tagsPlaceholder: 'タグを入力',
    sentence: '文章',
    heading: '見出し',
    image: '画像',
    embed: '埋込',
    etc: 'その他',
    imageURLPlaceholder: 'リンク先URLを入力',
    imageAltPlaceholder: 'altテキストを入力',
    embedPlaceholder: '埋込タグを入力',
    creditTypePlaceholder: 'クレジットタイプを入力',
    creditNamePlaceholder: 'クレジット名を入力',
    creditURLPlaceholder: 'クレジットURLを入力',
    creditAddPlaceholder: 'クレジットを追加',
    authorNamePlaceholder: '名前を入力',
    conversation: {
      positionLeft: '左寄せ',
      positionRight: '右寄せ',
    },
    textbox: {
      positionLeft: '左寄せ',
      positionCenter: '中央揃え',
      positionRight: '右寄せ',
    },
    profileImageURLPlaceholder: 'プロフィール画像URLを入力',
    profileSetStructuredData: '構造化データに設定',
    pinterestURLPlaceholder: 'Pinterest URLまたはIDを入力',
    paginationTitlePlaceholder: '次のページの見出しを入力',
    referenceNamePlaceholder: '引用元を入力してください。',
    referenceURLPlaceholder: '引用元のリンクを入力してください。',
    insertSection: 'ここにセクションを挿入',
    editExclusion: '{{userName}} が編集中です',
    articleHistory: '記事履歴',
    searchForm: {
      word: 'フリーワード検索',
      wordPlaceholder: 'ワードまたは記事IDを入力してEnter',
      status: 'ステータス',
      brand: 'ブランド',
      isMyArticle: '自分で作成した記事のみ表示',
      calendarArticleStatus: 'ステータス',
      calendarIsWorldtopics: 'WORLD TOPICS',
      calendarIsPR: 'PR',
    },
    searchPopup: {
      searchWord: 'ワードまたは記事IDを入力してEnter',
    },
    geotagPopup: {
      url: 'GoogleマップのURLを入力',
    },
    publishSettingPopup: {
      popTitle: '公開設定',
      distributionOption: '配信オプション',
      twitterPostingEnable: 'Twitter自動投稿',
      facebookPostingEnable: 'Facebook自動投稿',
      topViewEnable: 'TOPに表示',
      mediaDistributionExceptLINE: 'LINE以外の外部配信',
      mediaDistributionLINE: 'LINE配信',
      abtestEnable: 'A/Bテストを実施',
      postDate: '公開日時',
      editDate: '更新日',
      cancel: 'キャンセル',
      save: '保存',
      saveAsReserved: '予約保存',
    },
    statusBar: {
      posted: '公開済み',
      reserved: '公開予定',
      deleted: '削除済み',
      report: 'レポート',
    },
    header: {
      delete: '削除',
      download: 'ダウンロード',
      history: '履歴',
      preview: 'プレビュー',
      overwrite: '上書き保存',
      saveAsDraft: '下書き保存',
      returnToDraft: '下書きに戻す',
      publishSettings: '公開設定',
    },
  },
  image: {
    url: 'URL',
    tags: 'タグ',
    userName: 'ユーザ名',
    imageType: '画像種類',
    license: 'ライセンス',
    source: '画像サイト名',
    sourceURL: '画像サイトURL',
    imageGroup: '画像グループ',
    imageGroupName: '画像グループ名',
    tagsPlaceholder: 'タグを入力',
    credit: 'クレジット',
    creditName: 'クレジット名',
    creditURL: 'クレジットURL',
    hideCredit: 'クレジットを非表示にする',
    isFullWidth: 'デバイス幅いっぱいまで画像を表示する',
    searchForm: {
      tags: 'タグ',
      imageType: '画像種類',
      license: 'ライセンス',
      isMyImage: '自分でアップロードした画像のみ表示',
    },
    searchPopup: {
      searchWord: 'タグを入力してEnter',
    },
    uploadFile: 'アップロードファイル',
    draggableImage: '画像ファイルをドラッグアンドドロップしてください',
  },
  voice: {
    uploadFile: 'アップロードファイル',
    draggableVoice: 'Audioファイルをドラッグアンドドロップしてください',
  },
  adFrame: {
    name: '名前',
    articleURL: '記事URL',
    deliveryFromAt: '配信開始日時',
    deliveryToAt: '配信終了日時',
    deliveryStatus: '配信ステータス',
    isDisplays: 'フレーム表示',
    mobileImageURLs: '画像URL(mobile)',
    pcImageURLs: '画像URL(pc)',
    searchWord: '検索ワード',
    searchWordPlaceholder: '検索ワードを入力',
    deliveryAt: '配信期間',
    frame: 'フレーム',
  },
  contentFrame: {
    name: '名前',
    key: 'キー',
    contentTypes: 'コンテンツタイプ',
    imageURLPlaceholder: 'リンク先URLを入力',
    editSection: {
      campaignRemark: '配信中のアドフレームがランダムに表示されます',
    },
  },
  author: {
    name: '名前',
    key: 'キー',
    title: '肩書',
    profile: 'プロフィール',
    instagramUrl: 'Instagram URL',
    twitterUrl: 'Twitter URL',
    facebookUrl: 'Facebook URL',
    youtubeUrl: 'YouTube URL',
    icon: 'アイコン画像',
  },
  category: {
    name: '名前',
    key: 'キー',
    description: '説明',
    seoName: '名前(SEO)',
    mobileHeaderImageURL: 'ヘッダーイメージURL(mobile)',
    pcHeaderImageURL: 'ヘッダーイメージURL(pc)',
    topViewEnable: 'TOPページに表示する',
    sortKey: '表示順',
  },
  tag: {
    name: '名前',
    description: '説明',
    parentID: '親タグ',
    childIDs: '子タグ',
    listFlg: 'リスト表示',
    exactMatchFlg: '完全一致で記事を表示',
    searchTag: 'タグを入力してEnter',
  },
  feature: {
    name: '名前',
    key: 'キー',
    status: '特集ステータス',
    canonicalURL: 'Canonical URL',
    mediaDistribution: '配信先メディア',
    advertiserName: '広告主名',
    advertiserImageURL: '広告主画像URL',
    title: 'タイトル',
    seoTitle: 'SEOタイトル',
    ogTitle: 'OGタイトル',
    description: 'Description',
    seoDescription: 'SEO description',
    headerLogo: 'ヘッダーロゴ',
    mobileHeaderImageURL: 'ヘッダーイメージURL(mobile)',
    tabletHeaderImageURL: 'ヘッダーイメージURL(tablet)',
    pcHeaderImageURL: 'ヘッダーイメージURL(pc)',
    mobileHeaderVideoURL: 'ヘッダー動画URL(mobile)',
    tabletHeaderVideoURL: 'ヘッダー動画URL(tablet)',
    pcHeaderVideoURL: 'ヘッダー動画URL(pc)',
    ogImage: 'OGイメージ',
    keywords: 'キーワード',
    metaKeywords: 'メタキーワード',
    contentType: 'コンテンツタイプ',
    contentValue: 'コンテンツ設定値',
    contentTemplate: 'コンテンツテンプレート',
    contentTemplateDefault: 'デフォルト',
    contentTemplateFlat: 'ヘッダー画像あり × フラット',
    contentTemplateNoImage: 'ヘッダー画像なし',
    headerType: 'ヘッダータイプ',
    headerTypeDefault: 'ヘッダーあり',
    headerTypeNone: 'ヘッダーなし',
    headerTypeTransparent: '透明',
    backgroundColor: 'バックグラウンドカラー',
    fontColor: 'フォントカラー',
    articlePageText: '記事下誘導用テキスト',
    articleFeatureBannerMobile: '特集記事内画像URL(Mobile)',
    articleFeatureBannerPC: '特集記事内画像URL(PC)',
    bannerBottomMobile: '特集ページ下部バナー画像URL(Mobile)',
    bannerBottomPC: '特集ページ下部バナー画像URL(PC)',
    bannerBottomLinkURL: '特集ページ下部バナーリンク先URL',
    bannerBottomOpenTab: 'バナーのリンクを別タブで開く',
    featureListImageURL: '特集一覧用画像URL',
    featureListViewEnable: '特集一覧ページに表示する',
    featureStatuses: {
      draft: '下書き',
      posted: '公開',
    },
  },
  media: {
    name: '名前',
    key: 'キー',
    feedCategories: '配信カテゴリー',
    feedTags: '配信タグ',
    isTagExclude: '指定したタグを除外する',
    feedContentTypes: '配信コンテンツタイプ',
    feedEmbedTypes: '配信埋込タイプ',
    embedCode: '埋込タグ',
    adContent: '広告枠',
    tagsPlaceholder: 'タグを入力',
  },
  mediaDistribution: {
    deleteDistributions: '削除',
    feedMedia: '配信先',
  },
  user: {
    name: '名前',
    email: 'メールアドレス',
    role: 'ロール',
    lastAccessAt: '最新アクセス日時',
  },
  common: {
    createdAt: '作成日時',
    updatedAt: '更新日時',
    selectAll: '全て',
    match: '一致',
    notMatch: '除外',
    main: 'メイン',
    sub: 'サブ{{index}}',
    download: 'ダウンロード',
    new: '新規作成',
    dateFormat: 'yyyy/mm/dd',
    timeFormat: 'yyyy/mm/dd HH:MM:ss',
    dailyQuotes: [
      '『 視点を変えて「!!」を生み出す 』- 世の中の定説やスタンダードにとらわれず、人々の思考に風穴を開けるような「気づき」「発見」を社会に向けて発信する。刺激的な出会いが、その人にとっての新しいレシピとなるように。',
      '『シェフであり、ソムリエであり、生産者であり 』- 軽やかで気負いなく多角的な視点から物事をとらえよう。素材の良し悪しを的確に見極め、最適な調理法・提供法を判断できるように。',
      '『好きでやって突き抜ける』- 好きだからどこまでも注力する。けれど、インプットした知識や体験がきちんと咀嚼され、血肉とならなければ意味はない。研鑽を続けることで明日をもっとおいしくする。',
      '『 五味を知り、うま味をつくる 』- 物事の一方だけで知った気になってはいけない。対極あるいは多面的に理解を深めることで、自身の価値基準を醸成し、最適を見出せ。',
      '『 観察し、洞察せよ 』- 火加減だけが情報のすべてじゃない。鍋肌に耳をかたむけ、鼻を利かせること。相手の言葉の奥に想いを馳せて、目を配り、気を配ろう。',
      '『 限界にもうひと振り 』- 「全力を出し切った」「もう十分」では足りない。そこからさらにもうひと振り。舌にしびれる辛味も回を増すごとに快楽へと変わる。',
      '『 仕込みは手際よく最速で、サーヴは程よくアツアツで 』- プロジェクト着手は誰よりも早く、思いっきりアクセルを踏み込む。だが、着地は時流と潮目を読み、最適なタイミングを見定めて。',
    ],
    repdigitQuote: 'Today is Repdigit day. More hungry, More foolish!',
    articleContentTypes: {
      curation: 'キュレーション',
      original: 'オリジナル',
      mediaPartner: 'メディアパートナー',
      pr: 'PR',
      contribution: '寄稿',
      event: 'イベント',
    },
    articleStatuses: {
      draft: '下書き',
      reserved: '予約',
      posted: '公開',
      deleted: '削除',
    },
    articleInputTypes: {
      credit: 'クレジット',
      profile: 'プロフィール',
      relationArticle: '関連記事',
      interview: 'インタビュー',
      conversation: '対談形式',
      pinterest: 'Pinterest',
      pagination: 'ページネーション',
      reference: '引用',
      textbox: 'テキストボックス',
    },
    matchOrNot: {
      match: '一致',
      not_match: '除外',
    },
    imageImageTypes: {
      eyeCatch: 'アイキャッチ',
      article: '記事内',
      material: '素材',
      spc: 'FRAME',
    },
    imageLicenses: {
      royaltyFree: 'ロイヤリティフリー',
      rightsManaged: 'ライツマネージド',
      cc0: 'cc0',
      originalForArticle: 'オリジナル画像（記事用）',
      licensed: '許諾画像',
      originalForAd: 'オリジナル画像（広告用）',
      providedByAdvertiser: '広告主支給画像',
      editorial: 'エディトリアル',
      stockPhoto: 'ストックフォト（レンポジ）',
    },
    adFrameDeliveryStatuses: {
      start: '配信中',
      stop: '停止',
    },
    adFrameIsDisplays: {
      on: '表示する',
      off: '表示しない',
    },
    contentFrameContentTypes: {
      image: '画像',
      article: '記事',
      embed: '埋込',
      campaign: 'アドフレーム',
    },
    mediaEmbedTypes: {
      facebook: 'facebook',
      twitter: 'twitter',
      instagram: 'instagram',
      vimeo: 'vimeo',
      youtube: 'youtube',
      other: 'その他',
    },
  },
  messages: {
    authFailure: 'ユーザ認証に失敗しました。',
    fetchArticleError: '記事の取得に失敗しました。 {{message}}',
    fetchArticleHistoryError: '記事履歴の取得に失敗しました。 {{message}}',
    fetchArticleDocxError: 'Docxファイルの取得に失敗しました。 {{message}}',
    createArticleSuccess: '記事を作成しました。',
    createArticleError: '記事の作成に失敗しました。 {{message}}',
    updateArticleSuccess: '記事を更新しました。',
    updateArticleError: '記事の更新に失敗しました。 {{message}}',
    deleteArticleSuccess: '記事を削除しました。',
    deleteArticleError: '記事の削除に失敗しました。 {{message}}',
    draftArticleSuccess: '記事を下書き保存しました。',
    draftArticleError: '記事の下書き保存に失敗しました。 {{message}}',
    fetchImageError: '画像の取得に失敗しました。 {{message}}',
    fetchImageSourceError: '画像サイトの取得に失敗しました。 {{message}}',
    fetchImageGroupError: '画像グループの取得に失敗しました。 {{message}}',
    createImageSuccess: '画像をアップロードしました。',
    createImageError: '画像のアップロードに失敗しました。 {{message}}',
    createImageValidateError: 'アップロードファイルが指定されていません。',
    updateImageSuccess: '画像を更新しました。',
    updateImageError: '画像の更新に失敗しました。 {{message}}',
    deleteImageSuccess: '画像を削除しました。',
    deleteImageError: '画像の削除に失敗しました。 {{message}}',
    createImageGroupSuccess: '画像グループを作成しました。',
    createImageGroupError: '画像グループの作成に失敗しました。 {{message}}',
    updateImageGroupSuccess: '画像グループを更新しました。',
    updateImageGroupError: '画像グループの更新に失敗しました。 {{message}}',
    createVoiceSuccess: 'ボイスをアップロードしました。',
    createVoiceError: 'ボイスのアップロードに失敗しました。 {{message}}',
    createVoiceValidateError: 'アップロードファイルが指定されていません。',
    fetchAdFrameError: 'アドフレームの取得に失敗しました。 {{message}}',
    createAdFrameSuccess: 'アドフレームを作成しました。',
    createAdFrameError: 'アドフレームの作成に失敗しました。 {{message}}',
    updateAdFrameSuccess: 'アドフレームを更新しました。',
    updateAdFrameError: 'アドフレームの更新に失敗しました。 {{message}}',
    deleteAdFrameSuccess: 'アドフレームを削除しました。',
    deleteAdFrameError: 'アドフレームの削除に失敗しました。 {{message}}',
    fetchContentFrameError: 'コンテンツフレームの取得に失敗しました。 {{message}}',
    createContentFrameSuccess: 'コンテンツフレームを作成しました。',
    createContentFrameError: 'コンテンツフレームの作成に失敗しました。 {{message}}',
    updateContentFrameSuccess: 'コンテンツフレームを更新しました。',
    updateContentFrameError: 'コンテンツフレームの更新に失敗しました。 {{message}}',
    deleteContentFrameSuccess: 'コンテンツフレームを削除しました。',
    deleteContentFrameError: 'コンテンツフレームの削除に失敗しました。 {{message}}',
    fetchAuthorError: 'ライターの取得に失敗しました。 {{message}}',
    createAuthorSuccess: 'ライターを作成しました。',
    createAuthorError: 'ライターの作成に失敗しました。 {{message}}',
    updateAuthorSuccess: 'ライターを更新しました。',
    updateAuthorError: 'ライターの更新に失敗しました。 {{message}}',
    deleteAuthorSuccess: 'ライターを削除しました。',
    deleteAuthorError: 'ライターの削除に失敗しました。 {{message}}',
    fetchCategoryError: 'カテゴリーの取得に失敗しました。 {{message}}',
    createCategorySuccess: 'カテゴリーを作成しました。',
    createCategoryError: 'カテゴリーの作成に失敗しました。 {{message}}',
    updateCategorySuccess: 'カテゴリーを更新しました。',
    updateCategoryError: 'カテゴリーの更新に失敗しました。 {{message}}',
    deleteCategorySuccess: 'カテゴリーを削除しました。',
    deleteCategoryError: 'カテゴリーの削除に失敗しました。 {{message}}',
    fetchTagError: 'タグの取得に失敗しました。 {{message}}',
    createTagSuccess: 'タグを作成しました。',
    createTagError: 'タグの作成に失敗しました。 {{message}}',
    updateTagSuccess: 'タグを更新しました。',
    updateTagError: 'タグの更新に失敗しました。 {{message}}',
    deleteTagSuccess: 'タグを削除しました。',
    deleteTagError: 'タグの削除に失敗しました。 {{message}}',
    fetchFeatureError: '特集の取得に失敗しました。 {{message}}',
    createFeatureSuccess: '特集を作成しました。',
    createFeatureError: '特集の作成に失敗しました。 {{message}}',
    updateFeatureSuccess: '特集を更新しました。',
    updateFeatureError: '特集の更新に失敗しました。 {{message}}',
    deleteFeatureSuccess: '特集を削除しました。',
    deleteFeatureError: '特集の削除に失敗しました。 {{message}}',
    fetchMediaError: 'メディアの取得に失敗しました。 {{message}}',
    createMediaSuccess: 'メディアを作成しました。',
    createMediaError: 'メディアの作成に失敗しました。 {{message}}',
    updateMediaSuccess: 'メディアを更新しました。',
    updateMediaError: 'メディアの更新に失敗しました。 {{message}}',
    deleteMediaSuccess: 'メディアを削除しました。',
    deleteMediaError: 'メディアの削除に失敗しました。 {{message}}',
    fetchMediaDistributionError: 'メディア配信の取得に失敗しました。 {{message}}',
    createMediaDistributionSuccess: 'メディア配信を作成しました。',
    createMediaDistributionError: 'メディア配信の作成に失敗しました。 {{message}}',
    updateMediaDistributionSuccess: 'メディア配信を更新しました。',
    updateMediaDistributionError: 'メディア配信の更新に失敗しました。 {{message}}',
    deleteMediaDistributionSuccess: 'メディア配信を削除しました。',
    deleteMediaDistributionError: 'メディア配信の削除に失敗しました。 {{message}}',
    fetchUserError: 'ユーザーの取得に失敗しました。 {{message}}',
    createUserSuccess: 'ユーザーを作成しました。',
    createUserError: 'ユーザーの作成に失敗しました。 {{message}}',
    updateUserSuccess: 'ユーザーを更新しました。',
    updateUserError: 'ユーザーの更新に失敗しました。 {{message}}',
    deleteUserSuccess: 'ユーザーを削除しました。',
    deleteUserError: 'ユーザーの削除に失敗しました。 {{message}}',
    fetchPinterestError: 'Pinterestの取得に失敗しました。 {{message}}',
    fetchRoleError: 'ロールの取得に失敗しました。 {{message}}',
    warningEditingSection: 'セクションが開いた状態でSaveはおこなえません。OKまたはCancelをクリックしてセクションの編集を確定させてください。',
    warningPastDateReservation: '公開日時に過去の日時を設定して予約はできません。',
    warningRequirePostDate: '公開日時を設定してください。',
    warningPastEditDate: '公開日時よりも前に更新日を設定することはできません。',
    warningRequireImageCredit: '2018/6/1以降に作成された記事を公開するには、画像にクレジットを設定してください。',
    discardEditingArticle: '編集内容が保存されていません。破棄してよろしいですか？',
    restoreFromLocalStorage: '自動保存されたデータが存在します。復元しますか？<br>Cancelをクリックすると自動保存されたデータは破棄されます。',
    googleMapsURLError: 'URLに誤りがあります。GoogleマップのURLを入力してください。',
    warningInvalidVideoEmbedCode: '動画記事用の埋め込みコードが正しい形式ではありません。YouTubeで正しいコードを取得してください。',
    warningTagTreeInvalid: '親タグと子タグに同じタグは設定できません。',
    setTagForBreadcrumbs: '「{{message}}」をパンくず用のタグに設定しました。',
    warningNonManagedTagForBreadcrumbs: 'タグ管理されていないタグはパンくずに設定できません。',
    warningNonAdvertiser: 'PR記事を保存するには広告主を設定してください。',
    warningEditingAdContent: 'PR記事以外では広告主、フレーム枠広告タイトルの設定、TOP/レコメンド枠・フレーム枠の広告登録はできません。コンテンツタイプにPRを設定してください。',
    warningNonAdTitle: 'PR記事を保存するにはフレーム枠広告タイトルを設定してください。',
    warningAdTitleNumExceeded: 'フレーム枠広告タイトルの{{row}}行目に{{count}}文字入力されています。30文字以下で設定し直してください。',
    warningAdTitleRowExceeded: 'フレーム枠広告タイトルが4行以上設定されています。3行以下で設定し直してください。',
    previewUrlCopied: 'プレビューURLがコピーされました',
  },
};

export default i18nJA;
