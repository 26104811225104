import React from 'react';

const VoiceUploadPopup = props => (
  <div
    className="voiceUploadPopup"
    onDragStart={e => e.preventDefault()}
    onDragOver={e => e.preventDefault()}
    onDrop={e => e.preventDefault()}
  >
    <div className="voiceUploadPopup-inner">
      <div className="items">

        <div className="item">
          <label htmlFor="uploadFile">{props.t('voice.uploadFile')}</label>
          <div>
            {props.uploadFile != null ? props.uploadFile.name : ''}
            {props.uploadFile != null ?
              <span onClick={props.onFileRemove}>
                <i className="fa fa-trash-o" aria-hidden="true" />
              </span> : null}
          </div>
          <div
            className="voice"
            onDragStart={e => e.preventDefault()}
            onDragOver={(e) => {
              e.preventDefault();
              e.dataTransfer.dropEffect = 'copy';
            }}
            onDrop={props.onFileDrop}
          >
            {props.t('voice.draggableVoice')}
          </div>
          <div className="uploadVoiceTmp">
            <div id="uploadVoice">
              {props.uploadFileBody != null ?
                <img src={props.uploadFileBody} key="0" alt="uploadVoice" /> : null}
            </div>
            <canvas id="uploadVoiceCanvas" />
          </div>
        </div>

      </div>

      <div className="footer">
        <div className="left">
          <button type="button" onClick={props.onCancel}>Cancel</button>
        </div>
        <div className="right">
          <button type="button" onClick={props.onSubmit}>OK</button>
        </div>

        <div className="clear" />
      </div>
    </div>
  </div>
);

export default VoiceUploadPopup;
