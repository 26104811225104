import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, change } from 'redux-form';
import { translate } from 'react-i18next';

import { fetchFixedTags } from '../../actions/tagAction';
import TagEditForm from '../../components/tag/TagEditForm';

class TagEditFormContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTagChange = this.handleTagChange.bind(this);
    this.handleParentTagSuggestionSelected = this.handleParentTagSuggestionSelected.bind(this);
    this.handleParentTagInputChange = this.handleParentTagInputChange.bind(this);
    this.handleParentTagInputBlur = this.handleParentTagInputBlur.bind(this);
    this.handleChildTagSuggestionSelected = this.handleChildTagSuggestionSelected.bind(this);
    this.handleChildTagInputChange = this.handleChildTagInputChange.bind(this);
    this.handleChildTagInputBlur = this.handleChildTagInputBlur.bind(this);
    this.handleChildTagAddClick = this.handleChildTagAddClick.bind(this);
    this.handleChildTagUpClick = this.handleChildTagUpClick.bind(this);
    this.handleChildTagDownClick = this.handleChildTagDownClick.bind(this);
    this.handleChildTagRemoveClick = this.handleChildTagRemoveClick.bind(this);

    this.state = {
      parentInputValue: null,
      parentTag: null,
      childInputValue: null,
      childTag: null,
      addedChildTags: [],
    };
  }

  componentWillMount() {
    this.setState({ parentInputValue: null });
    this.setState({ parentTag: null });
    this.setState({ childInputValue: null });
    this.setState({ childTag: null });
    this.setState({ addedChildTags: [] });
  }

  componentWillReceiveProps(nextProps) {
    const { tags } = nextProps;
    const { id } = nextProps;

    if (this.state.parentInputValue == null && Number(id) === tags.id && tags.parent != null) {
      this.setState({ parentInputValue: tags.parent.name });
      this.setState({ parentTag: tags.parent });
    }
  }

  handleSubmit() {
    this.props.onSubmit(this.props.tagEditForm);
  }

  handleTagChange(e) {
    this.props.fetchFixedTags(e.value);
  }

  handleParentTagSuggestionSelected(e, { suggestionIndex }) {
    const selectedTag = this.props.suggestTags.tags[suggestionIndex];

    if (selectedTag != null) {
      this.setState({ parentInputValue: selectedTag.name });
      this.setState({ parentTag: selectedTag });
      this.props.updateForm('parent_id', selectedTag.id);
    }
  }

  handleParentTagInputChange(e) {
    if (e.target.value !== 0) {
      this.setState({ parentInputValue: e.target.value });
    }
  }

  handleParentTagInputBlur(e) {
    if (e.target.value.length === 0) {
      this.setState({ parentInputValue: '' });
      this.setState({ parentTag: null });
      this.props.updateForm('parent_id', 1);

      return;
    }

    const target = this.props.suggestTags.tags.find(tag => tag.name === e.target.value);

    if (target != null) {
      this.setState({ parentInputValue: target.name });
      this.setState({ parentTag: target });
      this.props.updateForm('parent_id', target.id);
    } else {
      const value = this.state.parentTag != null ? this.state.parentTag.name : '';
      this.setState({ parentInputValue: value });
    }
  }

  handleChildTagSuggestionSelected(e, { suggestionIndex }) {
    const selectedTag = this.props.suggestTags.tags[suggestionIndex];

    if (selectedTag != null) {
      this.setState({ childInputValue: selectedTag.name });
      this.setState({ childTag: selectedTag });
    }
  }

  handleChildTagInputChange(e) {
    if (e.target.value !== 0) {
      this.setState({ childInputValue: e.target.value });
    }
  }

  handleChildTagInputBlur(e) {
    if (e.target.value.length === 0) {
      this.setState({ childInputValue: '' });
      this.setState({ childTag: null });
      return;
    }

    const target = this.props.suggestTags.tags.find(tag => tag.name === e.target.value);

    if (target != null) {
      this.setState({ childInputValue: target.name });
      this.setState({ childTag: target });
    } else {
      const value = this.state.childTag != null ? this.state.childTag.name : '';
      this.setState({ childInputValue: value });
    }
  }

  handleChildTagAddClick() {
    if (this.state.childTag == null) {
      return;
    }

    if (this.props.tagEditForm.child_ids != null &&
      this.props.tagEditForm.child_ids.find(id => id === this.state.childTag.id) != null) {
      return;
    }

    const childIDs = [].concat(this.props.tagEditForm.child_ids || []);
    childIDs.push(this.state.childTag.id);
    this.props.updateForm('child_ids', childIDs);

    this.setState({ addedChildTags: this.state.addedChildTags.concat([this.state.childTag]) });

    this.setState({ childInputValue: '' });
    this.setState({ childTag: null });
  }

  handleChildTagUpClick(e) {
    const i = Number(e.target.dataset.key);

    if (i === 0) {
      return;
    }

    const childIDs = [].concat(this.props.tagEditForm.child_ids || []);
    const target = childIDs.splice(i, 1);
    childIDs.splice(i - 1, 0, target[0]);

    this.props.updateForm('child_ids', childIDs);
  }

  handleChildTagDownClick(e) {
    const i = Number(e.target.dataset.key);

    if (i === this.props.tagEditForm.child_ids.length - 1) {
      return;
    }

    const childIDs = [].concat(this.props.tagEditForm.child_ids || []);
    const target = childIDs.splice(i, 1);
    childIDs.splice(i + 1, 0, target[0]);

    this.props.updateForm('child_ids', childIDs);
  }

  handleChildTagRemoveClick(e) {
    const i = Number(e.target.dataset.key);

    const childIDs = [].concat(this.props.tagEditForm.child_ids || []);
    childIDs.splice(i, 1);

    this.props.updateForm('child_ids', childIDs);
  }

  render() {
    const {
      tagEditForm,
      initialValues,
      tag,
      tags,
      saveButton,
      closeButton,
      onCancel,
      suggestTags,
      t,
    } = this.props;

    if (saveButton != null && saveButton.onclick == null) {
      saveButton.onclick = this.handleSubmit;
    }

    if (closeButton != null && closeButton.onclick == null) {
      closeButton.onclick = () => onCancel();
    }

    return (
      <div className="tagEditFormContainer">
        <TagEditForm
          form={tagEditForm}
          initialValues={initialValues}
          tag={tag}
          childTags={tags.children != null ? tags.children.concat(this.state.addedChildTags) : null}
          suggestTags={suggestTags}
          onTagChange={this.handleTagChange}
          onParentTagSuggestionSelected={this.handleParentTagSuggestionSelected}
          onChildTagSuggestionSelected={this.handleChildTagSuggestionSelected}
          onChildTagAddClick={this.handleChildTagAddClick}
          onChildTagUpClick={this.handleChildTagUpClick}
          onChildTagDownClick={this.handleChildTagDownClick}
          onChildTagRemoveClick={this.handleChildTagRemoveClick}
          parentTagInputProps={
            {
              value: this.state.parentInputValue != null ? this.state.parentInputValue : '',
              onChange: this.handleParentTagInputChange,
              onBlur: this.handleParentTagInputBlur,
            }
          }
          childTagInputProps={
            {
              value: this.state.childInputValue != null ? this.state.childInputValue : '',
              onChange: this.handleChildTagInputChange,
              onBlur: this.handleChildTagInputBlur,
            }
          }
          t={t}
        />
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    tags: state.tags,
    suggestTags: state.suggestTags,
    tagEditForm: state.form.tagEditForm != null ? state.form.tagEditForm.values : null,
    initialValues: state.tag,
  }
);

const mapDispatchToProps = dispatch => (
  {
    fetchFixedTags: prefix => dispatch(fetchFixedTags(prefix)),
    updateForm: (key, value) => dispatch(change('tagEditForm', key, value)),
  }
);

const tagEditFormContainer = reduxForm({ form: 'tagEditForm', enableReinitialize: true })(translate()(TagEditFormContainer));
export default connect(mapStateToProps, mapDispatchToProps)(tagEditFormContainer);
