import {
  FETCH_IMAGES_START,
  FETCH_IMAGES_COMPLETE,
} from '../actions/imageAction';
import {
  FETCH_IMAGE_GROUP_IMAGES_START,
  FETCH_IMAGE_GROUP_IMAGES_COMPLETE,
} from '../actions/imageGroupAction';

const imagesReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_IMAGES_START:
      return state;

    case FETCH_IMAGES_COMPLETE:
      return action.payload;

    case FETCH_IMAGE_GROUP_IMAGES_START:
      return state;

    case FETCH_IMAGE_GROUP_IMAGES_COMPLETE:
      return action.payload;

    default:
      return state;
  }
};

export default imagesReducer;
