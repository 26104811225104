import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import classnames from 'classnames';
import dateformat from 'dateformat';
import { translate } from 'react-i18next';

import AdFrameList from '../../components/adFrame/AdFrameList';
import AdFrameEditContainer from './AdFrameEditContainer';
import { fetchAdFrames, createAdFrame } from '../../actions/adFrameAction';
import { addLoading } from '../../actions/loadingAction';
import { addSuccessAlert } from '../../actions/alertAction';
import Config from '../../config/Config';

class AdFrameContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleScroll = this.handleScroll.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.handleNewClick = this.handleNewClick.bind(this);
    this.handleAdFrameClick = this.handleAdFrameClick.bind(this);

    this.handleReportClick = this.handleReportClick.bind(this);
  }

  componentDidMount() {
    this.props.fetchAdFrames();
  }

  handleScroll() {
    const { adFrames } = this.props;

    const adFrameListBody = this.adFrameListContent;
    const elementHeight = Math.max(
      adFrameListBody.offsetHeight,
      adFrameListBody.clientHeight,
      adFrameListBody.offsetHeight,
    );
    if (
      adFrames.offset != null &&
      adFrameListBody.scrollTop + elementHeight >= adFrameListBody.scrollHeight
    ) {
      this.props.fetchAdFrames(
        { word: this.searchWord.value },
        adFrames.ad_frames,
        adFrames.offset,
      );
    }
  }

  handleSearchSubmit() {
    this.props.fetchAdFrames({ word: this.searchWord.value });
    this.adFrameListContent.scrollTop = 0;
  }

  handleNewClick() {
    const form = {
      name: 'no name',
    };

    this.props.addLoading();
    this.props.createAdFrame(form, (adFrame) => {
      this.searchWord.value = '';
      this.props.fetchAdFrames();
      this.props.router.push(`/ad_frame/${adFrame.id}`);
      this.props.addSuccessAlert(this.props.t('messages.createAdFrameSuccess'));
    });
  }

  handleAdFrameClick(id) {
    this.props.router.push(`/ad_frame/${id}`);
  }

  handleReportClick(e) {
    const adFrame = this.props.adFrames.ad_frames.find(a => a.id === Number(e.target.dataset.key));

    if (adFrame.article_url == null || adFrame.article_url.split('/').length < 4) {
      return;
    }

    const articleID = adFrame.article_url.split('/')[3];
    const startDate = dateformat(new Date(adFrame.delivery_from_at), 'yyyy-mm-dd');
    const endDate = dateformat(new Date(adFrame.delivery_to_at), 'yyyy-mm-dd');
    window.open(`${Config.REPORT_ENDPOINT}/queries/16?p_date_from=${startDate}&p_date_to=${endDate}&p_article_id=${articleID}&fullscreen`, '_blank');
  }

  render() {
    const { adFrames, params, t } = this.props;

    return (
      <div className="adFrameContainer">
        <div className={classnames({ list: true, narrow: params.id != null })}>

          <div className="header">
            <div className="buttons right">
              <button
                type="button"
                onClick={this.handleNewClick}
              >
                <i className="fa fa-plus" aria-hidden="true" />
                Create AdFrame
              </button>
            </div>

            <div className="item">
              <div className="item left">
                <label htmlFor="searchWord">{t('adFrame.searchWord')}</label>
                <div className="searchWord">
                  <input
                    type="text"
                    ref={(ref) => { this.searchWord = ref; }}
                    placeholder={t('adFrame.searchWordPlaceholder')}
                    onChange={() => {
                      setTimeout(() => this.handleSearchSubmit(), 0);
                    }}
                  />
                </div>
              </div>

              <div className="clear" />
            </div>
          </div>

          <div
            className="content"
            ref={(ref) => { this.adFrameListContent = ref; }}
            onScroll={this.handleScroll}
          >
            <AdFrameList
              id={params.id}
              adFrames={adFrames.ad_frames}
              onClick={this.handleAdFrameClick}
              onReportClick={this.handleReportClick}
              t={t}
            />
          </div>

        </div>

        <div className="detail">
          {params.id != null ? <AdFrameEditContainer id={params.id} /> : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    adFrames: state.adFrames,
    loading: state.loading,
  }
);

const mapDispatchToProps = dispatch => (
  {
    fetchAdFrames: (form, adFrames, offset) => dispatch(fetchAdFrames(form, adFrames, offset)),
    createAdFrame: (form, callback) => dispatch(createAdFrame(form, callback)),
    addLoading: () => dispatch(addLoading()),
    addSuccessAlert: value => dispatch(addSuccessAlert(value)),
  }
);

const adFrameContainer = withRouter(translate()(AdFrameContainer));
export default connect(mapStateToProps, mapDispatchToProps)(adFrameContainer);
