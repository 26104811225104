import {
  FETCH_PINTEREST_START,
  FETCH_PINTEREST_COMPLETE,
} from '../actions/pinterestAction';

const pinterestReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PINTEREST_START:
      return state;

    case FETCH_PINTEREST_COMPLETE:
      return action.payload;

    default:
      return state;
  }
};

export default pinterestReducer;
