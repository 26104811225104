import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, change } from 'redux-form';
import { translate } from 'react-i18next';

import FeatureEditForm from '../../components/feature/FeatureEditForm';

class FeatureEditFormContainer extends React.Component {
  render() {
    if (Number(this.props.id) !== this.props.feature.id) {
      return null;
    }

    const {
      featureEditForm,
      initialValues,
      feature,
      saveButton,
      closeButton,
      onSubmit,
      onCancel,
      t,
    } = this.props;

    if (saveButton != null && saveButton.onclick == null) {
      saveButton.onclick = () => onSubmit(this.props.featureEditForm);
    }

    if (closeButton != null && closeButton.onclick == null) {
      closeButton.onclick = () => onCancel();
    }

    return (
      <div className="featureEditFormContainer">
        <FeatureEditForm
          form={featureEditForm}
          initialValues={initialValues}
          feature={feature}
          t={t}
        />
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    featureEditForm:
      state.form.featureEditForm != null ? state.form.featureEditForm.values : null,
    initialValues: state.feature,
  }
);

const mapDispatchToProps = dispatch => (
  {
    updateForm: (key, value) => dispatch(change('featureEditForm', key, value)),
  }
);

const featureEditFormContainer = reduxForm({ form: 'featureEditForm', enableReinitialize: true })(translate()(FeatureEditFormContainer));
export default connect(mapStateToProps, mapDispatchToProps)(featureEditFormContainer);
