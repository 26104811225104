import {
  FETCH_ARTICLES_OF_CALENDAR_START,
  FETCH_ARTICLES_OF_CALENDAR_COMPLETE,
} from '../actions/articleAction';

const articlesCalendarReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ARTICLES_OF_CALENDAR_START:
      return state;

    case FETCH_ARTICLES_OF_CALENDAR_COMPLETE:
      return action.payload;

    default:
      return state;
  }
};

export default articlesCalendarReducer;
