import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, change } from 'redux-form';
import { translate } from 'react-i18next';

import ContentFrameEditForm from '../../components/contentFrame/ContentFrameEditForm';

class ContentFrameEditFormContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
  }

  handleSubmit() {
    this.props.onSubmit(this.props.contentFrameEditForm);
  }

  handleAddClick() {
    const form = this.props.contentFrameEditForm;

    const contentTypes =
      (form.content_types != null ? form.content_types : []).concat(['image']);
    const contents =
      (form.contents != null ? form.contents : [])
        .concat(JSON.stringify([{ image_url: '', link_url: '' }]));

    this.props.updateForm('content_types', contentTypes);
    this.props.updateForm('contents', contents);
  }

  handleRemoveClick(e) {
    const form = this.props.contentFrameEditForm;
    const i = Number(e.target.dataset.key);

    const contentTypes = [].concat(form.content_types);
    const contents = [].concat(form.contents);

    contentTypes.splice(i, 1);
    contents.splice(i, 1);

    this.props.updateForm('content_types', contentTypes);
    this.props.updateForm('contents', contents);
  }

  render() {
    if (Number(this.props.id) !== this.props.contentFrame.id) {
      return null;
    }

    const {
      id,
      contentFrameEditForm,
      initialValues,
      contentFrame,
      saveButton,
      closeButton,
      onCancel,
      t,
    } = this.props;

    if (saveButton != null && saveButton.onclick == null) {
      saveButton.onclick = this.handleSubmit;
    }

    if (closeButton != null && closeButton.onclick == null) {
      closeButton.onclick = () => onCancel();
    }

    return (
      <div className="contentFrameEditFormContainer">
        <ContentFrameEditForm
          id={id}
          form={contentFrameEditForm}
          initialValues={initialValues}
          contentFrame={contentFrame}
          onAddClick={this.handleAddClick}
          onRemoveClick={this.handleRemoveClick}
          t={t}
        />
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    contentFrameEditForm:
      state.form.contentFrameEditForm != null ? state.form.contentFrameEditForm.values : null,
    initialValues: state.contentFrame,
  }
);

const mapDispatchToProps = dispatch => (
  {
    updateForm: (key, value) => dispatch(change('contentFrameEditForm', key, value)),
  }
);

const contentFrameEditFormContainer = reduxForm({ form: 'contentFrameEditForm', enableReinitialize: true })(translate()(ContentFrameEditFormContainer));
export default connect(mapStateToProps, mapDispatchToProps)(contentFrameEditFormContainer);
