import React from 'react';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';

import { addErrorAlert } from '../../actions/alertAction';

import ArticleGeotagUploadPopup from '../../components/article/ArticleGeotagUploadPopup';

class ArticleGeotagUploadPopupContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.refURL = this.refURL.bind(this);
  }

  handleSubmit() {
    const geotag = {};
    geotag.url = decodeURI(this.url.value);
    const url = geotag.url.split('/');

    url.forEach((e, i) => {
      geotag.article_id = this.props.articleID;
      if (e === 'place' && i + 1 < url.length) {
        geotag.name = url[i + 1].replace(/\+/g, ' ');
      } else if (e.indexOf('@') === 0) {
        const params = e.split(',');

        if (params.length === 3) {
          geotag.longitude = parseFloat(params[0].replace(/@/g, ''));
          geotag.latitude = parseFloat(params[1]);
          geotag.zoom = parseFloat(params[2].replace(/z/g, ''));
        }
      }
    });

    if (Object.keys(geotag).length < 6) {
      this.props.addErrorAlert(this.props.t('messages.googleMapsURLError'));
      return;
    }

    this.props.onSubmit(geotag);
  }

  refURL(ref) {
    this.url = ref;
  }

  render() {
    const { onCancel, t } = this.props;

    return (
      <div className="articleGeotagUploadPopupContainer">
        <ArticleGeotagUploadPopup
          onSubmit={this.handleSubmit}
          onCancel={onCancel}
          refURL={this.refURL}
          t={t}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => (
  {
    addErrorAlert: value => dispatch(addErrorAlert(value)),
  }
);

export default connect(null, mapDispatchToProps)(translate()(ArticleGeotagUploadPopupContainer));
