import React from 'react';

const ArticleGeotagUploadPopup = props => (
  <div className="articleGeotagUploadPopup">
    <div className="articleGeotagUploadPopup-inner">
      <div className="items">

        <div className="item">
          <input
            type="text"
            ref={props.refURL}
            placeholder={props.t('article.geotagPopup.url')}
          />
        </div>

      </div>

      <div className="footer">
        <div className="left">
          <button type="button" onClick={props.onCancel}>Cancel</button>
        </div>
        <div className="right">
          <button type="button" onClick={props.onSubmit}>OK</button>
        </div>

        <div className="clear" />
      </div>
    </div>
  </div>
);

export default ArticleGeotagUploadPopup;
