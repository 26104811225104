import { addErrorAlert } from './alertAction';
import { fetchPinterest as fetchPinterestAPI } from '../api/apiClient';
import i18n from '../config/i18n';

export const FETCH_PINTEREST_START = 'FETCH_PINTEREST_START';
export const FETCH_PINTEREST_COMPLETE = 'FETCH_PINTEREST_COMPLETE';

const fetchPinterestStart = () => ({ type: FETCH_PINTEREST_START });

const fetchPinterestComplete = res => ({
  type: FETCH_PINTEREST_COMPLETE,
  payload: res,
});

export const fetchPinterest = id => (
  (dispatch) => {
    dispatch(fetchPinterestStart());

    fetchPinterestAPI(id).then((res) => {
      dispatch(fetchPinterestComplete(res.body));
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(addErrorAlert(i18n.t('messages.fetchPinterestError', { message: res.text })));
    });
  }
);
