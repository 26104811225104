import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { translate } from 'react-i18next';

import MediaList from '../../components/media/MediaList';
import MediaEditContainer from './MediaEditContainer';
import { fetchMedias, createMedia } from '../../actions/mediaAction';
import { addLoading } from '../../actions/loadingAction';
import { addSuccessAlert } from '../../actions/alertAction';

class MediaContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleMediaClick = this.handleMediaClick.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
  }

  componentDidMount() {
    this.props.fetchMedias();
  }

  handleMediaClick(id) {
    this.props.router.push(`/media/${id}`);
  }

  handleAddClick() {
    const form = {
      name: 'no name',
    };

    this.props.addLoading();
    this.props.createMedia(form, (media) => {
      this.props.fetchMedias();
      this.props.router.push(`/media/${media.id}`);
      this.props.addSuccessAlert(this.props.t('messages.createMediaSuccess'));
    });
  }

  render() {
    const { medias, params } = this.props;

    return (
      <div className="mediaContainer">
        <div className={classnames({ list: true, narrow: params.id != null })}>

          <div className="content">
            <MediaList
              id={params.id}
              medias={medias.medias}
              onClick={this.handleMediaClick}
            />

            <div className="button" onClick={this.handleAddClick}>
              <i className="fa fa-plus" />
            </div>
          </div>

        </div>

        <div className="detail">
          {params.id != null ? <MediaEditContainer id={params.id} /> : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    medias: state.medias,
    loading: state.loading,
  }
);

const mapDispatchToProps = dispatch => (
  {
    fetchMedias: () => dispatch(fetchMedias()),
    createMedia: (form, callback) => dispatch(createMedia(form, callback)),
    addLoading: () => dispatch(addLoading()),
    addSuccessAlert: value => dispatch(addSuccessAlert(value)),
  }
);

const mediaContainer = withRouter(translate()(MediaContainer));
export default connect(mapStateToProps, mapDispatchToProps)(mediaContainer);
