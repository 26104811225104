import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import classnames from 'classnames';
import Autosuggest from 'react-autosuggest';
import { translate } from 'react-i18next';

import TagList from '../../components/tag/TagList';
import TagEditContainer from './TagEditContainer';
import { fetchTags, createTag, fetchFixedTags } from '../../actions/tagAction';
import { addLoading } from '../../actions/loadingAction';
import { addSuccessAlert } from '../../actions/alertAction';

class TagContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleTagChange = this.handleTagChange.bind(this);
    this.handleTagSuggestionSelected = this.handleTagSuggestionSelected.bind(this);
    this.handleTagInputChange = this.handleTagInputChange.bind(this);
    this.handleTagInputBlur = this.handleTagInputBlur.bind(this);
    this.handleTagInputKeyPress = this.handleTagInputKeyPress.bind(this);

    this.handleNewClick = this.handleNewClick.bind(this);
    this.handleTagClick = this.handleTagClick.bind(this);

    this.state = {
      tagInputValue: null,
      tag: null,
    };
  }

  componentDidMount() {
    const { id } = this.props.params;
    this.props.fetchTags(id != null ? { id } : { id: 1 });
  }

  componentDidUpdate(prevProps) {
    const { id } = this.props.params;

    if (prevProps.params.id !== id) {
      this.props.fetchTags(id != null ? { id } : { id: 1 });
    }
  }

  handleTagChange(e) {
    this.props.fetchFixedTags(e.value);
  }

  handleTagSuggestionSelected(e, { suggestionIndex }) {
    const selectedTag = this.props.suggestTags.tags[suggestionIndex];

    if (selectedTag != null) {
      this.setState({ tagInputValue: selectedTag.name });
      this.setState({ tag: selectedTag });

      this.props.router.push(`/tag/${selectedTag.id}`);
    }
  }

  handleTagInputChange(e) {
    if (e.target.value !== 0) {
      this.setState({ tagInputValue: e.target.value });
    }
  }

  handleTagInputBlur(e) {
    if (e.target.value.length === 0) {
      this.setState({ tagInputValue: '' });
      this.setState({ tag: null });
      return;
    }

    const target = this.props.suggestTags.tags.find(tag => tag.name === e.target.value);

    if (target != null) {
      this.setState({ tagInputValue: target.name });
      this.setState({ tag: target });
    } else {
      const value = this.state.tag != null ? this.state.tag.name : '';
      this.setState({ tagInputValue: value });
    }
  }

  handleTagInputKeyPress(e) {
    if (e.key === 'Enter') {
      this.handleTagInputBlur(e);

      const target = this.props.suggestTags.tags.find(tag => tag.name === e.target.value);
      if (target != null) {
        this.props.router.push(`/tag/${target.id}`);
      }
    }
  }

  handleNewClick() {
    const { tags } = this.props;

    this.props.addLoading();
    this.props.createTag({ name: 'no name', parent_id: tags != null ? tags.id : 1 }, (tag) => {
      this.props.fetchTags({ id: tag.id });
      this.props.router.push(`/tag/${tag.id}`);
      this.props.addSuccessAlert(this.props.t('messages.createTagSuccess'));
    });

    this.tagListContent.scrollTop = 0;
  }

  handleTagClick(id) {
    if (id > 1) {
      this.props.router.push(`/tag/${id}`);
    } else {
      this.props.router.push('/tag');
    }
  }

  render() {
    const { tags, suggestTags, params, t } = this.props;

    return (
      <div className="tagContainer">
        <div className={classnames({ list: true, narrow: params.id != null })}>

          <div className="header">
            <Autosuggest
              suggestions={suggestTags.tags != null ? suggestTags.tags.map(e => e.name) : []}
              shouldRenderSuggestions={v => v != null}
              getSuggestionValue={suggestion => suggestion}
              renderSuggestion={suggestion => <span>{suggestion}</span>}
              inputProps={{
                value: this.state.tagInputValue != null ? this.state.tagInputValue : '',
                placeholder: t('tag.searchTag'),
                onChange: this.handleTagInputChange,
                onBlur: this.handleTagInputBlur,
                onKeyPress: this.handleTagInputKeyPress,
              }}
              onSuggestionSelected={this.handleTagSuggestionSelected}
              onSuggestionsFetchRequested={this.handleTagChange}
              onSuggestionsClearRequested={() => {}}
            />
          </div>

          <div
            className="content"
            ref={(ref) => { this.tagListContent = ref; }}
          >
            <TagList
              id={params.id}
              tags={tags}
              onClick={this.handleTagClick}
              onAddClick={this.handleNewClick}
              t={t}
            />
          </div>

        </div>

        <div className="detail">
          {params.id != null ? <TagEditContainer id={params.id} /> : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    tags: state.tags,
    suggestTags: state.suggestTags,
    loading: state.loading,
  }
);

const mapDispatchToProps = dispatch => (
  {
    fetchTags: form => dispatch(fetchTags(form)),
    createTag: (form, callback) => dispatch(createTag(form, callback)),
    fetchFixedTags: prefix => dispatch(fetchFixedTags(prefix)),
    addLoading: () => dispatch(addLoading()),
    addSuccessAlert: value => dispatch(addSuccessAlert(value)),
  }
);

const tagContainer = withRouter(translate()(TagContainer));
export default connect(mapStateToProps, mapDispatchToProps)(tagContainer);
