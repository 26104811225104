import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import UserEditFormContainer from './UserEditFormContainer';
import { fetchUser, fetchUsers, updateUser } from '../../actions/userAction';
import { addLoading } from '../../actions/loadingAction';

class UserEditContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleCloseClick = this.handleCloseClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.params.id != null) {
      this.props.fetchUser(this.props.params.id);
    }
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;

    if (user != null && Number(prevProps.params.id) !== user.id) {
      this.props.fetchUser(prevProps.params.id);
    }
  }

  handleCloseClick() {
    this.props.router.push('/user');
  }

  handleSubmit(form) {
    const newForm = { ...form };

    newForm.role_id = Number(newForm.role_id);

    this.props.addLoading();
    this.props.updateUser(newForm, () => this.props.fetchUsers());
  }

  render() {
    const { user, params } = this.props;

    if (user == null || params.id == null) {
      return null;
    }

    return (
      <div className="userEditContainer">

        <div className="header">
          <div className="left">
            <button ref={(ref) => { this.closeButton = ref; }}>
              <i className="fa fa-times" aria-hidden="true" />
              Close
            </button>
          </div>

          <div className="right">
            <button className="bangButton" ref={(ref) => { this.saveButton = ref; }}>Save</button>
          </div>

          <div className="clear" />
        </div>

        <div className="content">
          <UserEditFormContainer
            id={params.id}
            user={user}
            saveButton={this.saveButton}
            closeButton={this.closeButton}
            onSubmit={this.handleSubmit}
            onCancel={this.handleCloseClick}
            ref={(ref) => { this.userEditFormContainer = ref; }}
          />
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    user: state.user,
  }
);

const mapDispatchToProps = dispatch => (
  {
    fetchUser: id => dispatch(fetchUser(id)),
    fetchUsers: (form, users, offset) => dispatch(fetchUsers(form, users, offset)),
    updateUser: (form, callback) => dispatch(updateUser(form, callback)),
    addLoading: () => dispatch(addLoading()),
  }
);

const userEditContainer = withRouter(UserEditContainer);
export default connect(mapStateToProps, mapDispatchToProps)(userEditContainer);
