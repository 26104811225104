import {
  CREATE_VOICE_START,
  CREATE_VOICE_COMPLETE,
} from '../actions/voiceAction';

const voiceReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_VOICE_START:
      return state;

    case CREATE_VOICE_COMPLETE:
      return action.payload;

    default:
      return state;
  }
};

export default voiceReducer;
