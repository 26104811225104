import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import dateformat from 'dateformat';
import { fetchArticlesOfCalendar } from '../../actions/articleAction';
import { fetchAuthors } from '../../actions/authorAction';
import ArticleCalendarSearchForm from '../../components/article/ArticleCalendarSearchForm';
import ArticleCalendarWeek from '../../components/article/ArticleCalendarWeek';

class CalendarContainer extends React.Component {
  constructor(props) {
    super(props);
    this.createCalendarDaysArray = this.createCalendarDaysArray.bind(this);
    this.updateLastCalendar = this.updateLastCalendar.bind(this);
    this.updateNextCalendar = this.updateNextCalendar.bind(this);
    const date = new Date();
    date.setDate(1);
    this.state = {
      displayCalendarDate: date,
    };
  }

  componentDidMount() {
    const date = this.state.displayCalendarDate;
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const lastMonthFromDate = 20;
    const nextMonthToDate = 10;
    const fromDate = new Date(year, month - 2, lastMonthFromDate);
    const toDate = new Date(year, month, nextMonthToDate);
    const fromDateStr = dateformat(fromDate, 'yyyy-mm-dd');
    const toDateStr = dateformat(toDate, 'yyyy-mm-dd');

    this.props.fetchArticlesOfCalendar({ fromDate: fromDateStr, toDate: toDateStr });
    this.props.fetchAuthors();
  }

  createCalendarDaysArray(date) {
    const today = new Date();
    const todayFormat = dateformat(today, 'yyyy-mm-dd');
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const dayOfTheWeekOf1stDay = new Date(year, month - 1, 1).getDay();
    const dayOfTheWeekOfLastDay = new Date(year, month, 0).getDay();
    const numToShowThisMonthDays = new Date(year, month, 0).getDate();
    const numToShowLastMonthDays = dayOfTheWeekOf1stDay;
    const numToShowNextMonthDays = 6 - dayOfTheWeekOfLastDay;
    const lastMonthLastDay = new Date(year, month - 1, 0);
    const lastMonthLastDate = lastMonthLastDay.getDate();
    const nextMonthInitialDate = new Date(year, month, 1);
    const lastMonthFormat = dateformat(lastMonthLastDay, 'yyyy-mm');
    const nextMonthFormat = dateformat(nextMonthInitialDate, 'yyyy-mm');
    const thisMonthFormat = dateformat(date, 'yyyy-mm');
    const baseCalendarDaysArray = [];

    for (let i = 0; i < numToShowLastMonthDays; i += 1) {
      const dayObj = {};
      const day = `0${(lastMonthLastDate - i)}`.slice(-2);
      dayObj.day = `${lastMonthFormat}-${day}`;
      dayObj.main = false;
      dayObj.today = todayFormat === dayObj.day;
      baseCalendarDaysArray.unshift(dayObj);
    }
    for (let i = 0; i < numToShowThisMonthDays; i += 1) {
      const dayObj = {};
      const day = `0${(i + 1)}`.slice(-2);
      dayObj.day = `${thisMonthFormat}-${day}`;
      dayObj.main = true;
      dayObj.today = todayFormat === dayObj.day;
      baseCalendarDaysArray.push(dayObj);
    }
    for (let i = 0; i < numToShowNextMonthDays; i += 1) {
      const dayObj = {};
      const day = `0${(i + 1)}`.slice(-2);
      dayObj.day = `${nextMonthFormat}-${day}`;
      dayObj.main = false;
      dayObj.today = todayFormat === dayObj.day;
      baseCalendarDaysArray.push(dayObj);
    }
    const daysInWeekCnt = 7; // 1週間の日数
    const calendarDaysArray = []; // 週ごとに日を分割する配列

    for (let i = 0; i < Math.ceil(baseCalendarDaysArray.length / daysInWeekCnt); i += 1) {
      const j = i * daysInWeekCnt;
      const k = baseCalendarDaysArray.slice(j, j + daysInWeekCnt);
      calendarDaysArray.push(k);
    }
    return calendarDaysArray;
  }

  updateLastCalendar(e) {
    e.preventDefault();
    const date = this.state.displayCalendarDate;
    date.setMonth(date.getMonth() - 1);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const lastMonthFromDate = 20;
    const nextMonthToDate = 10;
    const fromDate = new Date(year, month - 2, lastMonthFromDate);
    const toDate = new Date(year, month, nextMonthToDate);
    const fromDateStr = dateformat(fromDate, 'yyyy-mm-dd');
    const toDateStr = dateformat(toDate, 'yyyy-mm-dd');

    this.props.fetchArticlesOfCalendar({ fromDate: fromDateStr, toDate: toDateStr });

    this.setState({ displayCalendarDate: date });
  }

  updateNextCalendar(e) {
    e.preventDefault();
    const date = this.state.displayCalendarDate;
    date.setMonth(date.getMonth() + 1);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const lastMonthFromDate = 20;
    const nextMonthToDate = 10;
    const fromDate = new Date(year, month - 2, lastMonthFromDate);
    const toDate = new Date(year, month, nextMonthToDate);
    const fromDateStr = dateformat(fromDate, 'yyyy-mm-dd');
    const toDateStr = dateformat(toDate, 'yyyy-mm-dd');

    this.props.fetchArticlesOfCalendar({ fromDate: fromDateStr, toDate: toDateStr });

    this.setState({ displayCalendarDate: date });
  }

  render() {
    const { articles, authors, permissions, articleCalendarSearchForm, t } = this.props;
    const date = this.state.displayCalendarDate;
    const calendarWeeks = this.createCalendarDaysArray(date);
    let searchValues = {};
    if (articleCalendarSearchForm && articleCalendarSearchForm.values) {
      searchValues = articleCalendarSearchForm.values;
    }

    return (
      <div className="articleCalendarContainer">
        <div className="header">
          <ArticleCalendarSearchForm
            t={t}
          />
        </div>
        <div className="content">
          <div className="calendar">
            <div className="calendarInfo">
              <div className="updateMonth toLastMonth">
                <a href="" onClick={this.updateLastCalendar}>{'<<'}</a>
              </div>
              <div className="calendarMonth">
                { dateformat(date, 'yyyy/mm') }
              </div>
              <div className="updateMonth toNextMonth">
                <a href="" onClick={this.updateNextCalendar}>{'>>'}</a>
              </div>
            </div>
            <div className="calendarWeekNameWrapper">
              <div className="calendarWeekName">
                Sun
              </div>
              <div className="calendarWeekName">
                Mon
              </div>
              <div className="calendarWeekName">
                Tue
              </div>
              <div className="calendarWeekName">
                Wed
              </div>
              <div className="calendarWeekName">
                Thu
              </div>
              <div className="calendarWeekName">
                Fri
              </div>
              <div className="calendarWeekName">
                Sat
              </div>
            </div>
            <div className="cal">
              { calendarWeeks.map((days, i) => (
                <ArticleCalendarWeek
                  articles={articles.articles}
                  days={days}
                  authors={authors.authors}
                  permissions={permissions}
                  searchValues={searchValues}
                  key={`articleCalendar-${i}`}
                />
              )) }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    articles: state.articlesOfCalendar,
    authors: state.authors,
    articleCalendarSearchForm: state.form.articleCalendarSearchForm,
    permissions: state.permissions,
  }
);

const mapDispatchToProps = dispatch => (
  {
    fetchArticlesOfCalendar: form => dispatch(fetchArticlesOfCalendar(form)),
    fetchAuthors: () => dispatch(fetchAuthors()),
  }
);

const calendarContainer = withRouter(translate()(CalendarContainer));
export default connect(mapStateToProps, mapDispatchToProps)(calendarContainer);
