import React from 'react';
import classnames from 'classnames';

const renderAuthor = (author, props) => {
  const {
    id,
    onClick,
  } = props;

  return (
    <li
      className={classnames({ item: true, selected: Number(id) === author.id })}
      key={`author-${author.id}`}
    >
      <div className="list-content" onClick={() => onClick(author.id)}>
        <img
          src={author.icon != null && author.icon.length > 0 ?
            author.icon : '/assets/images/no_image_small.png'}
          alt="icon"
        />

        <div className="description">
          <div>
            <span className="name">
              {`${author.name} (${author.key})`}
            </span>
            <span className="title">
              {author.title}
            </span>
          </div>
          <div>
            <span className="profile">
              {author.profile}
            </span>
          </div>
        </div>

      </div>
    </li>
  );
};

const AuthorList = (props) => {
  const { authors } = props;

  if (authors == null) {
    return null;
  }

  return (
    <div className="authorList">
      <ul>
        {authors.map(author => renderAuthor(author, props))}
      </ul>
    </div>
  );
};

export default AuthorList;
