import React from 'react';
import { Field, reduxForm } from 'redux-form';
import Config from '../../config/Config';

const ArticleCalendarSearchForm = ({ t }) => (
  <div className="articleCalendarSearchForm">
    <form>
      <div className="item">
        <label htmlFor="calendarArticlestatus">{t('article.searchForm.status')}</label>
        <div className="calendarArticlestatus">
          <div className="select-wrap">
            <Field
              name="status"
              component="select"
            >
              <option value="">{t('common.selectAll')}</option>
              {
                Config.ARTICLE_CALENDAR_STATUSES
                  .map(e => <option value={e.id} key={e.id}>{t(e.i18nKey)}</option>)
              }
            </Field>
          </div>
        </div>
      </div>

      <div className="item">
        <label htmlFor="calendarIsWorldtopics">{t('article.searchForm.calendarIsWorldtopics')}</label>
        <div className="calendarIsWorldtopics">
          <div className="select-wrap">
            <Field
              name="is_worldtopics"
              component="select"
            >
              <option value="">{t('common.selectAll')}</option>
              {
                Config.MATCH_OR_NOT
                  .map(e => <option value={e.id} key={e.id}>{t(e.i18nKey)}</option>)
              }
            </Field>
          </div>
        </div>
      </div>

      <div className="item">
        <label htmlFor="calendarIsPR">{t('article.searchForm.calendarIsPR')}</label>
        <div className="calendarIsPR">
          <div className="select-wrap">
            <Field
              name="is_pr"
              component="select"
            >
              <option value="">{t('common.selectAll')}</option>
              {
                Config.MATCH_OR_NOT
                  .map(e => <option value={e.id} key={e.id}>{t(e.i18nKey)}</option>)
              }
            </Field>
          </div>
        </div>
      </div>

      <div className="item">
        <div className="isMyArticle">
          <Field
            name="is_my_article"
            id="isMyArticle"
            component="input"
            type="checkbox"
          />
          <label className="checkbox" htmlFor="isMyArticle">{t('article.searchForm.isMyArticle')}</label>
        </div>
      </div>

    </form>
  </div>
);

export default reduxForm({ form: 'articleCalendarSearchForm' })(ArticleCalendarSearchForm);
