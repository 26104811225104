import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { translate } from 'react-i18next';

import FeatureList from '../../components/feature/FeatureList';
import FeatureEditContainer from './FeatureEditContainer';
import { fetchFeatures, createFeature } from '../../actions/featureAction';
import { addLoading } from '../../actions/loadingAction';
import { addSuccessAlert } from '../../actions/alertAction';
import Config from '../../config/Config';

class FeatureContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleNewClick = this.handleNewClick.bind(this);
    this.handleFeatureClick = this.handleFeatureClick.bind(this);
    this.handlePreviewClick = this.handlePreviewClick.bind(this);
  }

  componentDidMount() {
    this.props.fetchFeatures({ order_by: 'desc' });
  }

  handleNewClick() {
    this.props.router.push('/feature');
    this.props.addLoading();
    const defaultForm = {
      name: 'no name',
      content_type: Config.FEATURE_CONTENT_TYPES[0],
      content_template: Config.FEATURE_CONTENT_TEMPLATE_TYPES[0].id,
      header_type: Config.FEATURE_HEADER_TYPES[0].id,
      status: Config.FEATURE_STATUSES[0].id,
    };
    this.props.createFeature(defaultForm, (feature) => {
      this.props.fetchFeatures({ order_by: 'desc' });
      this.props.router.push(`/feature/${feature.id}`);
      this.props.addSuccessAlert(this.props.t('messages.createFeatureSuccess'));
    });
  }

  handleFeatureClick(id) {
    this.props.router.push(`/feature/${id}`);
  }

  handlePreviewClick(e) {
    const { feature } = this.props;

    if (feature.key != null) {
      if (feature.status === 'posted') {
        window.open(`${Config.WEB_ENDPOINT}/feature/${feature.key}`, '_blank');
      } else {
        window.open(`${Config.WEB_ENDPOINT}/preview_feature/${feature.key}/${feature.preview_key}`, '_blank');
      }
    }
    e.stopPropagation();
  }

  render() {
    const { features, params, t } = this.props;

    return (
      <div className="featureContainer">
        <div className={classnames({ list: true, narrow: params.id != null })}>

          <div className="header">
            <div className="buttons right">
              <button
                type="button"
                onClick={this.handleNewClick}
              >
                <i className="fa fa-plus" aria-hidden="true" />
                Create Feature
              </button>
            </div>

            <div className="clear" />
          </div>

          <div
            className="content"
            ref={(ref) => { this.featureListContent = ref; }}
          >
            <FeatureList
              id={params.id}
              features={features.features}
              onClick={this.handleFeatureClick}
              onPreviewClick={this.handlePreviewClick}
              t={t}
            />
          </div>

        </div>

        <div className="detail">
          {params.id != null ? <FeatureEditContainer id={params.id} /> : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    feature: state.feature,
    features: state.features,
    loading: state.loading,
  }
);

const mapDispatchToProps = dispatch => (
  {
    fetchFeatures: query => dispatch(fetchFeatures(query)),
    createFeature: (form, callback) => dispatch(createFeature(form, callback)),
    addLoading: () => dispatch(addLoading()),
    addSuccessAlert: value => dispatch(addSuccessAlert(value)),
  }
);

const featureContainer = withRouter(translate()(FeatureContainer));
export default connect(mapStateToProps, mapDispatchToProps)(featureContainer);
