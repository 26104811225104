import i18next from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';

import i18nEN from './_i18n_en';
import i18nJA from './_i18n_ja';
import i18nZHTW from './_i18n_zh-TW';
import i18nKO from './_i18n_ko';

const i18n = i18next
  .use(LngDetector)
  .init({
    resources: {
      en: {
        trans: i18nEN,
      },
      ja: {
        trans: i18nJA,
      },
      'zh-TW': {
        trans: i18nZHTW,
      },
      ko: {
        trans: i18nKO,
      },
    },

    fallbackLng: 'en',

    ns: ['trans'],

    defaultNS: 'trans',

    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },

    react: {
      wait: true,
    },
  });

export default i18n;
