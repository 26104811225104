import React from 'react';
import ArticleCalendarDay from '../../components/article/ArticleCalendarDay';

const ArticleCalendarWeek = (props) => {
  const {
    articles,
    days,
    authors,
    permissions,
    searchValues,
  } = props;

  return (
    <div className="articleCalendarWeek" key="articleCalendarWeek">
      {days.map((day, i) => (
        <ArticleCalendarDay
          articles={articles}
          day={day}
          authors={authors}
          permissions={permissions}
          searchValues={searchValues}
          key={`articleCalendarWeek-${i}`}
        />
      ))}
    </div>
  );
};

export default ArticleCalendarWeek;
