import React from 'react';
import classnames from 'classnames';

import { timeFormat, imageThumbnailURL } from '../../util/util';

const renderImage = (image, i, props) => {
  const {
    id,
    imageGroupID,
    onClick,
    onRemoveClick,
  } = props;

  return (
    <div
      className={classnames({ item: true, selected: Number(id) === image.image_id })}
      key={`image-${image.image_id}`}
      onClick={() => onClick(image.image_id)}
    >
      <div className="image">
        <div className="icon">
          <i
            className={classnames({ fa: true, 'fa-trash-o': true, none: imageGroupID === '0' })}
            aria-hidden="true"
            data-key={i}
            onClick={onRemoveClick}
          />
          <a href={image.url} target="_blank" rel="noopener noreferrer" onClick={e => e.stopPropagation()}>
            <i className="fa fa-external-link" aria-hidden="true" />
          </a>
        </div>

        <img
          src={
            image.url != null && image.url.length > 0 ?
              imageThumbnailURL(image.url, 'small') : '/assets/images/no_image_small.png'
          }
          alt="imagethumbnail"
        />
      </div>


      <div className="createUser">
        <i className="fa fa-user" />
        {image.user_name || '-'}
      </div>
      <div className="createdAt">
        <i className="fa fa-clock-o" />
        {timeFormat(image.created_at)}
      </div>
    </div>
  );
};

const ImageList = (props) => {
  const { images } = props;

  if (images == null) {
    return null;
  }

  return (
    <div className="imageList">
      {images.map((image, i) => renderImage(image, i, props))}
    </div>
  );
};

export default ImageList;
