import { addSuccessAlert, addErrorAlert } from './alertAction';
import { removeLoading } from './loadingAction';
import {
  fetchFeatures as fetchFeaturesAPI,
  fetchFeature as fetchFeatureAPI,
  createFeature as createFeatureAPI,
  updateFeature as updateFeatureAPI,
  deleteFeature as deleteFeatureAPI,
} from '../api/apiClient';
import i18n from '../config/i18n';

export const FETCH_FEATURES_START = 'FETCH_FEATURES_START';
export const FETCH_FEATURES_COMPLETE = 'FETCH_FEATURES_COMPLETE';
export const FETCH_FEATURE_START = 'FETCH_FEATURE_START';
export const FETCH_FEATURE_COMPLETE = 'FETCH_FEATURE_COMPLETE';
export const CREATE_FEATURE_START = 'CREATE_FEATURE_START';
export const CREATE_FEATURE_COMPLETE = 'CREATE_FEATURE_COMPLETE';
export const UPDATE_FEATURE_START = 'UPDATE_FEATURE_START';
export const UPDATE_FEATURE_COMPLETE = 'UPDATE_FEATURE_COMPLETE';
export const DELETE_FEATURE_START = 'DELETE_FEATURE_START';
export const DELETE_FEATURE_COMPLETE = 'DELETE_FEATURE_COMPLETE';

const fetchFeaturesStart = () => ({ type: FETCH_FEATURES_START });

const fetchFeaturesComplete = res => ({
  type: FETCH_FEATURES_COMPLETE,
  payload: res,
});

const fetchFeatureStart = () => ({ type: FETCH_FEATURE_START });

const fetchFeatureComplete = res => ({
  type: FETCH_FEATURE_COMPLETE,
  payload: res,
});

const createFeatureStart = () => ({ type: CREATE_FEATURE_START });

const createFeatureComplete = res => ({
  type: CREATE_FEATURE_COMPLETE,
  payload: res,
});

const updateFeatureStart = () => ({ type: UPDATE_FEATURE_START });

const updateFeatureComplete = res => ({
  type: UPDATE_FEATURE_COMPLETE,
  payload: res,
});

const deleteFeatureStart = () => ({ type: DELETE_FEATURE_START });

const deleteFeatureComplete = () => ({
  type: DELETE_FEATURE_COMPLETE,
  payload: {},
});

export const fetchFeatures = (query = {}) => (
  (dispatch) => {
    dispatch(fetchFeaturesStart());

    fetchFeaturesAPI(query).then((res) => {
      dispatch(fetchFeaturesComplete(res.body));
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(addErrorAlert(i18n.t('messages.fetchFeatureError', { message: res.text })));
    });
  }
);

export const fetchFeature = id => (
  (dispatch) => {
    dispatch(fetchFeatureStart());

    fetchFeatureAPI(id).then((res) => {
      dispatch(fetchFeatureComplete(res.body));
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(addErrorAlert(i18n.t('messages.fetchFeatureError', { message: res.text })));
    });
  }
);

export const createFeature = (form, callback) => (
  (dispatch) => {
    dispatch(createFeatureStart());

    createFeatureAPI(form).then((res) => {
      dispatch(createFeatureComplete(res.body));
      dispatch(removeLoading());
      dispatch(addSuccessAlert(i18n.t('messages.createFeatureSuccess')));

      if (callback != null) {
        callback(res.body);
      }
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(removeLoading());
      dispatch(addErrorAlert(i18n.t('messages.createFeatureError', { message: res.text })));
    });
  }
);

export const updateFeature = (form, callback) => (
  (dispatch) => {
    dispatch(updateFeatureStart());

    updateFeatureAPI(form).then((res) => {
      dispatch(updateFeatureComplete(res.body));
      dispatch(removeLoading());
      dispatch(addSuccessAlert(i18n.t('messages.updateFeatureSuccess')));

      if (callback != null) {
        callback(res.body);
      }
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(removeLoading());
      dispatch(addErrorAlert(i18n.t('messages.updateFeatureError', { message: res.text })));
    });
  }
);

export const deleteFeature = id => (
  (dispatch) => {
    dispatch(deleteFeatureStart());

    deleteFeatureAPI(id).then(() => {
      dispatch(deleteFeatureComplete());
      dispatch(removeLoading());
      dispatch(addSuccessAlert(i18n.t('messages.deleteFeatureSuccess')));
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(removeLoading());
      dispatch(addErrorAlert(i18n.t('messages.deleteFeatureError', { message: res.text })));
    });
  }
);
