import React from 'react';
import classnames from 'classnames';

import Config from '../../config/Config';
import { getName, timeFormat, hasPermission } from '../../util/util';

const renderArticle = (article, props) => {
  const {
    id,
    authors,
    permissions,
    onClick,
    onPreviewClick,
    onDownloadClick,
    onReportClick,
    onEditClick,
  } = props;

  const author =
    authors != null ? authors.find(e => e.id === article.author_id) : '';

  const classNames = { status: true };
  classNames[article.status] = true;

  return (
    <li
      className={classnames({ selected: Number(id) === article.article_id })}
      key={`article-${article.article_id}`}
    >
      <div className="list-content" onClick={() => onClick(article.article_id)}>

        <img
          src={
            article.eye_catch_urls != null &&
            article.eye_catch_urls.length > 0 &&
            article.eye_catch_urls[0] != null &&
            article.eye_catch_urls[0].length > 0 ?
              article.eye_catch_urls[0] : '/assets/images/no_image_small.png'
          }
          alt="eyeCatch"
        />

        <div className="description">
          <div>
            <span className={classnames(classNames)}>
              {getName(Config.ARTICLE_STATUSES, article.status)}
            </span>
            <span className="title">
              {article.titles != null && article.titles.length > 0 ? article.titles[0] : ''}
            </span>
          </div>

          <div className="toggle">
            {
              hasPermission(Config.PERMISSIONS.article_edit, permissions) ?
                <button onClick={onEditClick}>
                  <i className="fa fa-pencil" aria-hidden="true" />
                  Edit
                </button>
                : null
            }
            <button onClick={onPreviewClick}>
              <i className="fa fa-eye" aria-hidden="true" />
              Preview
            </button>
            <button onClick={onDownloadClick}>
              <i className="fa fa-download" aria-hidden="true" />
              Download
            </button>
            {article.status === 'posted' && (
              <button onClick={onReportClick}>
                <i className="fa fa-external-link" aria-hidden="true" />
                Report
              </button>
            )}

            <div className="writer_info">
              <span className="url">
                URL: {`${Config.WEB_ENDPOINT}/${article.article_id}/${article.custom_url}`}
              </span>
            </div>
          </div>

          <div className="writer_info">
            <span>ID: {article.article_id}</span>

            <span className="postDate">
              <i className="fa fa-clock-o" />
              {timeFormat(article.post_date) || '-'}
            </span>

            <span className="brand">
              <i className="fa fa-tag" />
              {getName(Config.ARTICLE_BRANDS, article.brand)}
            </span>

            <span className="author">
              <i className="fa fa-pencil" />
              {author != null ? author.name : null}
            </span>

            <span className="createUser">
              <i className="fa fa-user" />
              {`${article.create_user_name} / ${article.update_user_name}`}
            </span>
          </div>
        </div>

      </div>
    </li>
  );
};

const ArticleList = (props) => {
  const { articles } = props;

  if (articles == null) {
    return null;
  }

  return (
    <div className="articleList">
      <ul>
        {articles.map(article => renderArticle(article, props))}
      </ul>
    </div>
  );
};

export default ArticleList;
