import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import dateformat from 'dateformat';

import AdFrameEditFormContainer from './AdFrameEditFormContainer';
import { fetchAdFrame, fetchAdFrames, updateAdFrame } from '../../actions/adFrameAction';
import { addLoading } from '../../actions/loadingAction';

class AdFrameEditContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleCloseClick = this.handleCloseClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.params.id != null) {
      this.props.fetchAdFrame(this.props.params.id);
    }
  }

  componentDidUpdate(prevProps) {
    const { adFrame } = this.props;

    if (adFrame != null && Number(prevProps.params.id) !== adFrame.id) {
      this.props.fetchAdFrame(prevProps.params.id);
    }
  }

  handleCloseClick() {
    this.props.router.push('/ad_frame');
  }

  handleSubmit(form) {
    const newForm = { ...form };

    if (newForm.delivery_from_at != null && newForm.delivery_from_at.length === 16) {
      const date = new Date(newForm.delivery_from_at);
      newForm.delivery_from_at = dateformat(date, 'yyyy-mm-dd HH:MM:ss');
    }

    if (newForm.delivery_to_at != null && newForm.delivery_to_at.length === 16) {
      const date = new Date(newForm.delivery_to_at);
      newForm.delivery_to_at = dateformat(date, 'yyyy-mm-dd HH:MM:ss');
    }

    this.props.addLoading();
    this.props.updateAdFrame(newForm, () => this.props.fetchAdFrames());
  }

  render() {
    const { adFrame, params } = this.props;

    if (adFrame == null || params.id == null) {
      return null;
    }

    return (
      <div className="adFrameEditContainer">

        <div className="header">
          <div className="left">
            <button ref={(ref) => { this.closeButton = ref; }}>
              <i className="fa fa-times" aria-hidden="true" />
              Close
            </button>
          </div>

          <div className="right">
            <button className="bangButton" ref={(ref) => { this.saveButton = ref; }}>Save</button>
          </div>

          <div className="clear" />
        </div>

        <div className="content">
          <AdFrameEditFormContainer
            id={params.id}
            adFrame={adFrame}
            saveButton={this.saveButton}
            closeButton={this.closeButton}
            onSubmit={this.handleSubmit}
            onCancel={this.handleCloseClick}
            ref={(ref) => { this.adFrameEditFormContainer = ref; }}
          />
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    adFrame: state.adFrame,
  }
);

const mapDispatchToProps = dispatch => (
  {
    fetchAdFrame: id => dispatch(fetchAdFrame(id)),
    fetchAdFrames: (form, adFrames, offset) => dispatch(fetchAdFrames(form, adFrames, offset)),
    updateAdFrame: (form, callback) => dispatch(updateAdFrame(form, callback)),
    addLoading: () => dispatch(addLoading()),
  }
);

const adFrameEditContainer = withRouter(AdFrameEditContainer);
export default connect(mapStateToProps, mapDispatchToProps)(adFrameEditContainer);
