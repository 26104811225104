import { addSuccessAlert, addErrorAlert } from './alertAction';
import { removeLoading } from './loadingAction';
import {
  fetchImageGroups as fetchImageGroupsAPI,
  fetchImageGroup as fetchImageGroupAPI,
  fetchImageGroupImages as fetchImageGroupImagesAPI,
  createImageGroup as createImageGroupAPI,
  updateImageGroup as updateImageGroupAPI,
} from '../api/apiClient';
import i18n from '../config/i18n';

export const FETCH_IMAGE_GROUPS_START = 'FETCH_IMAGE_GROUPS_START';
export const FETCH_IMAGE_GROUPS_COMPLETE = 'FETCH_IMAGE_GROUPS_COMPLETE';
export const FETCH_IMAGE_GROUP_START = 'FETCH_IMAGE_GROUP_START';
export const FETCH_IMAGE_GROUP_COMPLETE = 'FETCH_IMAGE_GROUP_COMPLETE';
export const FETCH_IMAGE_GROUP_IMAGES_START = 'FETCH_IMAGE_GROUP_IMAGES_START';
export const FETCH_IMAGE_GROUP_IMAGES_COMPLETE = 'FETCH_IMAGE_GROUP_IMAGES_COMPLETE';
export const CREATE_IMAGE_GROUP_START = 'CREATE_IMAGE_GROUP_START';
export const CREATE_IMAGE_GROUP_COMPLETE = 'CREATE_IMAGE_GROUP_COMPLETE';
export const UPDATE_IMAGE_GROUP_START = 'UPDATE_IMAGE_GROUP_START';
export const UPDATE_IMAGE_GROUP_COMPLETE = 'UPDATE_IMAGE_GROUP_COMPLETE';
export const CREATE_IMAGE_GROUP_IMAGES_START = 'CREATE_IMAGE_GROUP_IMAGES_START';
export const CREATE_IMAGE_GROUP_IMAGES_COMPLETE = 'CREATE_IMAGE_GROUP_IMAGES_COMPLETE';
export const DELETE_IMAGE_GROUP_IMAGES_START = 'DELETE_IMAGE_GROUP_IMAGES_START';
export const DELETE_IMAGE_GROUP_IMAGES_COMPLETE = 'DELETE_IMAGE_GROUP_IMAGES_COMPLETE';

const fetchImageGroupsStart = () => ({ type: FETCH_IMAGE_GROUPS_START });

const fetchImageGroupsComplete = res => ({
  type: FETCH_IMAGE_GROUPS_COMPLETE,
  payload: res,
});

const fetchImageGroupStart = () => ({ type: FETCH_IMAGE_GROUP_START });

const fetchImageGroupComplete = res => ({
  type: FETCH_IMAGE_GROUP_COMPLETE,
  payload: res,
});

const fetchImageGroupImagesStart = () => ({ type: FETCH_IMAGE_GROUP_IMAGES_START });

const fetchImageGroupImagesComplete = (preImages, res) => {
  res.images = preImages.concat(res.images);

  return {
    type: FETCH_IMAGE_GROUP_IMAGES_COMPLETE,
    payload: res,
  };
};

const createImageGroupStart = () => ({ type: CREATE_IMAGE_GROUP_START });

const createImageGroupComplete = res => ({
  type: CREATE_IMAGE_GROUP_COMPLETE,
  payload: res,
});

const updateImageGroupStart = () => ({ type: UPDATE_IMAGE_GROUP_START });

const updateImageGroupComplete = res => ({
  type: UPDATE_IMAGE_GROUP_COMPLETE,
  payload: res,
});

export const fetchImageGroups = () => (
  (dispatch) => {
    dispatch(fetchImageGroupsStart());

    fetchImageGroupsAPI().then((res) => {
      dispatch(fetchImageGroupsComplete(res.body));
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(addErrorAlert(i18n.t('messages.fetchImageGroupError', { message: res.text })));
    });
  }
);

export const fetchImageGroup = id => (
  (dispatch) => {
    dispatch(fetchImageGroupStart());

    fetchImageGroupAPI(id).then((res) => {
      dispatch(fetchImageGroupComplete(res.body));
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(addErrorAlert(i18n.t('messages.fetchImageGroupError', { message: res.text })));
    });
  }
);

export const fetchImageGroupImages = (form = {}, preImages = [], offset = '') => (
  (dispatch) => {
    dispatch(fetchImageGroupImagesStart());

    fetchImageGroupImagesAPI(form, offset).then((res) => {
      dispatch(fetchImageGroupImagesComplete(preImages, res.body));
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(addErrorAlert(i18n.t('messages.fetchImageError', { message: res.text })));
    });
  }
);

export const createImageGroup = (form, callback) => (
  (dispatch) => {
    dispatch(createImageGroupStart());

    createImageGroupAPI(form).then((res) => {
      dispatch(createImageGroupComplete(res.body));
      dispatch(removeLoading());
      dispatch(addSuccessAlert(i18n.t('messages.createImageGroupSuccess')));

      if (callback != null) {
        callback(res.body);
      }
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(removeLoading());
      dispatch(addErrorAlert(i18n.t('messages.createImageGroupError', { message: res.text })));
    });
  }
);

export const updateImageGroup = form => (
  (dispatch) => {
    dispatch(updateImageGroupStart());

    updateImageGroupAPI(form).then((res) => {
      dispatch(updateImageGroupComplete(res.body));
      dispatch(removeLoading());
      dispatch(addSuccessAlert(i18n.t('messages.updateImageGroupSuccess')));
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(removeLoading());
      dispatch(addErrorAlert(i18n.t('messages.updateImageGroupError', { message: res.text })));
    });
  }
);

