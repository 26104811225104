import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import articleReducer from './articleReducer';
import articlesReducer from './articlesReducer';
import articlesCalendarReducer from './articlesCalendarReducer';
import articleHistoriesReducer from './articleHistoriesReducer';
import articleDocxReducer from './articleDocxReducer';
import imageReducer from './imageReducer';
import imagesReducer from './imagesReducer';
import imageGroupReducer from './imageGroupReducer';
import imageGroupsReducer from './imageGroupsReducer';
import imageTagsReducer from './imageTagsReducer';
import imageSourcesReducer from './imageSourcesReducer';
import voiceReducer from './voiceReducer';
import adFrameReducer from './adFrameReducer';
import adFramesReducer from './adFramesReducer';
import contentFrameReducer from './contentFrameReducer';
import contentFramesReducer from './contentFramesReducer';
import authorReducer from './authorReducer';
import authorsReducer from './authorsReducer';
import categoryReducer from './categoryReducer';
import categoriesReducer from './categoriesReducer';
import tagReducer from './tagReducer';
import tagsReducer from './tagsReducer';
import featureReducer from './featureReducer';
import featuresReducer from './featuresReducer';
import suggestTagsReducer from './suggestTagsReducer';
import mediaReducer from './mediaReducer';
import mediasReducer from './mediasReducer';
import mediaDistributionReducer from './mediaDistributionReducer';
import mediaDistributionsReducer from './mediaDistributionsReducer';
import userReducer from './userReducer';
import usersReducer from './usersReducer';
import pinterestReducer from './pinterestReducer';
import permissionsReducer from './permissionsReducer';
import rolesReducer from './rolesReducer';
import alertsReducer from './alertsReducer';
import loadingReducer from './loadingReducer';
import sessionReducer from './sessionReducer';
import editExclusionsReducer from './editExclusionsReducer';

export default combineReducers({
  article: articleReducer,
  articles: articlesReducer,
  articlesOfCalendar: articlesCalendarReducer,
  articleHistories: articleHistoriesReducer,
  articleDocx: articleDocxReducer,
  image: imageReducer,
  images: imagesReducer,
  imageGroup: imageGroupReducer,
  imageGroups: imageGroupsReducer,
  imageTags: imageTagsReducer,
  imageSources: imageSourcesReducer,
  voice: voiceReducer,
  adFrame: adFrameReducer,
  adFrames: adFramesReducer,
  contentFrame: contentFrameReducer,
  contentFrames: contentFramesReducer,
  author: authorReducer,
  authors: authorsReducer,
  category: categoryReducer,
  categories: categoriesReducer,
  tag: tagReducer,
  tags: tagsReducer,
  feature: featureReducer,
  features: featuresReducer,
  suggestTags: suggestTagsReducer,
  media: mediaReducer,
  medias: mediasReducer,
  mediaDistribution: mediaDistributionReducer,
  mediaDistributions: mediaDistributionsReducer,
  user: userReducer,
  users: usersReducer,
  pinterest: pinterestReducer,
  permissions: permissionsReducer,
  roles: rolesReducer,
  alerts: alertsReducer,
  loading: loadingReducer,
  session: sessionReducer,
  editExclusions: editExclusionsReducer,
  form: formReducer,
});

