import React from 'react';
import classnames from 'classnames';
import { dateFormat } from '../../util/util';
import ArticleCalendarItem from '../../components/article/ArticleCalendarItem';

const ArticleCalendarDay = (props) => {
  const {
    articles,
    day,
    authors,
    permissions,
    searchValues,
  } = props;
  let calendarDay = day.day.match(/-(\d+)$/)[1];
  calendarDay = calendarDay.replace(/^0/, '');
  let items = [];
  items = articles != null ? articles.filter(article => dateFormat(article.post_date, 'yyyy-mm-dd') === day.day) : articles;

  return (
    <div className={classnames({ articleCalendarDay: true, grayout: !day.main, today: day.today })} key="articleCalendarDay">
      <div className="dayName">
        {calendarDay}
      </div>
      {items != null ? items.map((item, i) => (
        <ArticleCalendarItem
          item={item}
          authors={authors}
          permissions={permissions}
          searchValues={searchValues}
          key={`articleCalendarDay-${i}`}
        />
      )) : null}
    </div>
  );
};

export default ArticleCalendarDay;
