import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import { fetchArticleDocx } from '../../actions/articleAction';
import { addLoading } from '../../actions/loadingAction';

class ArticleDocxContainer extends React.Component {
  componentWillMount() {
    this.props.addLoading();
    this.props.fetchArticleDocx(this.props.params.id);
  }

  render() {
    const { articleDocx, t } = this.props;

    const link = articleDocx != null && Object.keys(articleDocx).length > 0 ?
      (
        <a href={articleDocx}>
          {t('common.download')}
        </a>
      ) : null;

    return (
      <div className="articleDocxContainer">
        {link}
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    articleDocx: state.articleDocx,
  }
);

const mapDispatchToProps = dispatch => (
  {
    fetchArticleDocx: id => dispatch(fetchArticleDocx(id)),
    addLoading: () => dispatch(addLoading()),
  }
);

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ArticleDocxContainer));
