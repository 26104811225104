import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import FeatureEditFormContainer from './FeatureEditFormContainer';
import { fetchFeature, fetchFeatures, updateFeature } from '../../actions/featureAction';
import { addLoading } from '../../actions/loadingAction';

class FeatureEditContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleCloseClick = this.handleCloseClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.params.id != null) {
      this.props.fetchFeature(this.props.params.id);
    }
  }

  componentDidUpdate(prevProps) {
    const { feature } = this.props;

    if (feature != null && Number(prevProps.params.id) !== feature.id) {
      this.props.fetchFeature(prevProps.params.id);
    }
  }

  handleCloseClick() {
    this.props.router.push('/feature');
  }

  handleSubmit(form) {
    const newForm = { ...form };

    if (newForm.keywords != null && typeof newForm.keywords === 'string' && newForm.keywords.length > 0) {
      newForm.keywords = newForm.keywords.split(',');
    } else if (newForm.keywords === '') {
      newForm.keywords = null;
    }

    if (newForm.meta_keywords != null && typeof newForm.meta_keywords === 'string' && newForm.meta_keywords.length > 0) {
      newForm.meta_keywords = newForm.meta_keywords.split(',');
    } else if (newForm.meta_keywords === '') {
      newForm.meta_keywords = null;
    }

    if (newForm.banner_bottom_open_tab == null || newForm.banner_bottom_open_tab.length === 0) {
      newForm.banner_bottom_open_tab = false;
    }

    if (newForm.feature_list_view_enable == null || newForm.feature_list_view_enable.length === 0) {
      newForm.feature_list_view_enable = false;
    }

    this.props.addLoading();
    this.props.updateFeature(newForm, () => this.props.fetchFeatures({ order_by: 'desc' }));
  }

  render() {
    const { feature, params } = this.props;

    if (feature == null || params.id == null) {
      return null;
    }

    return (
      <div className="featureEditContainer">

        <div className="header">
          <div className="left">
            <button ref={(ref) => { this.closeButton = ref; }}>
              <i className="fa fa-times" aria-hidden="true" />
              Close
            </button>
          </div>

          <div className="right">
            <button className="bangButton" ref={(ref) => { this.saveButton = ref; }}>Save</button>
          </div>

          <div className="clear" />
        </div>

        <div className="content">
          <FeatureEditFormContainer
            id={params.id}
            feature={feature}
            saveButton={this.saveButton}
            closeButton={this.closeButton}
            onSubmit={this.handleSubmit}
            onCancel={this.handleCloseClick}
            ref={(ref) => { this.featureEditFormContainer = ref; }}
          />
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    feature: state.feature,
  }
);

const mapDispatchToProps = dispatch => (
  {
    fetchFeature: id => dispatch(fetchFeature(id)),
    fetchFeatures: query => dispatch(fetchFeatures(query)),
    updateFeature: (form, callback) => dispatch(updateFeature(form, callback)),
    addLoading: () => dispatch(addLoading()),
  }
);

const featureEditContainer = withRouter(FeatureEditContainer);
export default connect(mapStateToProps, mapDispatchToProps)(featureEditContainer);
