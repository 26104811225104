import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import MediaEditFormContainer from './MediaEditFormContainer';
import { fetchMedia, fetchMedias, updateMedia } from '../../actions/mediaAction';
import { addLoading } from '../../actions/loadingAction';

class MediaEditContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleCloseClick = this.handleCloseClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.params.id != null) {
      this.props.fetchMedia(this.props.params.id);
    }
  }

  componentDidUpdate(prevProps) {
    const { media } = this.props;

    if (media != null && Number(prevProps.params.id) !== media.id) {
      this.props.fetchMedia(prevProps.params.id);
    }
  }

  handleCloseClick() {
    this.props.router.push('/media');
  }

  handleSubmit(form) {
    const newForm = { ...form };

    let categories = [].concat(newForm.feed_categories || []);
    categories = categories.map(e => Number(e));
    categories.sort((a, b) => {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    });

    newForm.feed_categories = categories;

    this.props.addLoading();
    this.props.updateMedia(newForm, () => this.props.fetchMedias());
  }

  render() {
    const { media, params } = this.props;

    if (media == null || params.id == null) {
      return null;
    }

    let editFormContainer = null;
    if (media.id === Number(params.id)) {
      editFormContainer = (
        <MediaEditFormContainer
          id={params.id}
          media={media}
          saveButton={this.saveButton}
          closeButton={this.closeButton}
          onSubmit={this.handleSubmit}
          onCancel={this.handleCloseClick}
          ref={(ref) => { this.mediaEditFormContainer = ref; }}
        />
      );
    } else if (this.saveButton != null) {
      this.saveButton.onclick = null;
    }

    return (
      <div className="mediaEditContainer">

        <div className="header">
          <div className="left">
            <button ref={(ref) => { this.closeButton = ref; }}>
              <i className="fa fa-times" aria-hidden="true" />
              Close
            </button>
          </div>

          <div className="right">
            <button className="bangButton" ref={(ref) => { this.saveButton = ref; }}>Save</button>
          </div>

          <div className="clear" />
        </div>

        <div className="content">
          {editFormContainer}
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    media: state.media,
  }
);

const mapDispatchToProps = dispatch => (
  {
    fetchMedia: (id, callback) => dispatch(fetchMedia(id, callback)),
    fetchMedias:
      (form, medias, offset) => dispatch(fetchMedias(form, medias, offset)),
    updateMedia: (form, callback) => dispatch(updateMedia(form, callback)),
    addLoading: () => dispatch(addLoading()),
  }
);

const mediaEditContainer = withRouter(MediaEditContainer);
export default connect(mapStateToProps, mapDispatchToProps)(mediaEditContainer);
