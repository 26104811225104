import {
  FETCH_MEDIA_DISTRIBUTION_START,
  FETCH_MEDIA_DISTRIBUTION_COMPLETE,
  CREATE_MEDIA_DISTRIBUTION_START,
  CREATE_MEDIA_DISTRIBUTION_COMPLETE,
  UPDATE_MEDIA_DISTRIBUTION_START,
  UPDATE_MEDIA_DISTRIBUTION_COMPLETE,
  UPDATE_MEDIA_DISTRIBUTIONS_BULK_START,
  UPDATE_MEDIA_DISTRIBUTIONS_BULK_COMPLETE,
  DELETE_MEDIA_DISTRIBUTION_START,
  DELETE_MEDIA_DISTRIBUTION_COMPLETE,
} from '../actions/mediaDistributionAction';

const mediaDistributionReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_MEDIA_DISTRIBUTION_START:
      return state;

    case FETCH_MEDIA_DISTRIBUTION_COMPLETE:
      return action.payload;

    case CREATE_MEDIA_DISTRIBUTION_START:
      return state;

    case CREATE_MEDIA_DISTRIBUTION_COMPLETE:
      return action.payload;

    case UPDATE_MEDIA_DISTRIBUTION_START:
      return state;

    case UPDATE_MEDIA_DISTRIBUTION_COMPLETE:
      return action.payload;

    case UPDATE_MEDIA_DISTRIBUTIONS_BULK_START:
      return state;

    case UPDATE_MEDIA_DISTRIBUTIONS_BULK_COMPLETE:
      return action.payload;

    case DELETE_MEDIA_DISTRIBUTION_START:
      return state;

    case DELETE_MEDIA_DISTRIBUTION_COMPLETE:
      return action.payload;

    default:
      return state;
  }
};

export default mediaDistributionReducer;
