import { addSuccessAlert, addErrorAlert } from './alertAction';
import { removeLoading } from './loadingAction';
import {
  fetchMediaDistributions as fetchMediaDistributionsAPI,
  fetchMediaDistribution as fetchMediaDistributionAPI,
  createMediaDistribution as createMediaDistributionAPI,
  updateMediaDistribution as updateMediaDistributionAPI,
  updateMediaDistributionsBulk as updateMediaDistributionsBulkAPI,
  deleteMediaDistribution as deleteMediaDistributionAPI,
} from '../api/apiClient';
import i18n from '../config/i18n';

export const FETCH_MEDIA_DISTRIBUTIONS_START = 'FETCH_MEDIA_DISTRIBUTIONS_START';
export const FETCH_MEDIA_DISTRIBUTIONS_COMPLETE = 'FETCH_MEDIA_DISTRIBUTIONS_COMPLETE';
export const FETCH_MEDIA_DISTRIBUTION_START = 'FETCH_MEDIA_DISTRIBUTION_START';
export const FETCH_MEDIA_DISTRIBUTION_COMPLETE = 'FETCH_MEDIA_DISTRIBUTION_COMPLETE';
export const CREATE_MEDIA_DISTRIBUTION_START = 'CREATE_MEDIA_DISTRIBUTION_START';
export const CREATE_MEDIA_DISTRIBUTION_COMPLETE = 'CREATE_MEDIA_DISTRIBUTION_COMPLETE';
export const UPDATE_MEDIA_DISTRIBUTION_START = 'UPDATE_MEDIA_DISTRIBUTION_START';
export const UPDATE_MEDIA_DISTRIBUTION_COMPLETE = 'UPDATE_MEDIA_DISTRIBUTION_COMPLETE';
export const UPDATE_MEDIA_DISTRIBUTIONS_BULK_START = 'UPDATE_MEDIA_DISTRIBUTIONS_BULK_START';
export const UPDATE_MEDIA_DISTRIBUTIONS_BULK_COMPLETE = 'UPDATE_MEDIA_DISTRIBUTIONS_BULK_COMPLETE';
export const DELETE_MEDIA_DISTRIBUTION_START = 'DELETE_MEDIA_DISTRIBUTION_START';
export const DELETE_MEDIA_DISTRIBUTION_COMPLETE = 'DELETE_MEDIA_DISTRIBUTION_COMPLETE';

const fetchMediaDistributionsStart = () => ({ type: FETCH_MEDIA_DISTRIBUTIONS_START });

const fetchMediaDistributionsComplete = res => (
  {
    type: FETCH_MEDIA_DISTRIBUTIONS_COMPLETE,
    payload: res,
  }
);

const fetchMediaDistributionStart = () => ({ type: FETCH_MEDIA_DISTRIBUTION_START });

const fetchMediaDistributionComplete = res => ({
  type: FETCH_MEDIA_DISTRIBUTION_COMPLETE,
  payload: res,
});

const createMediaDistributionStart = () => ({ type: CREATE_MEDIA_DISTRIBUTION_START });

const createMediaDistributionComplete = res => ({
  type: CREATE_MEDIA_DISTRIBUTION_COMPLETE,
  payload: res,
});

const updateMediaDistributionStart = () => ({ type: UPDATE_MEDIA_DISTRIBUTION_START });

const updateMediaDistributionComplete = res => ({
  type: UPDATE_MEDIA_DISTRIBUTION_COMPLETE,
  payload: res,
});

const updateMediaDistributionsBulkStart = () => ({ type: UPDATE_MEDIA_DISTRIBUTIONS_BULK_START });

const updateMediaDistributionsBulkComplete = () => ({
  type: UPDATE_MEDIA_DISTRIBUTIONS_BULK_COMPLETE,
  payload: {},
});

const deleteMediaDistributionStart = () => ({ type: DELETE_MEDIA_DISTRIBUTION_START });

const deleteMediaDistributionComplete = () => ({
  type: DELETE_MEDIA_DISTRIBUTION_COMPLETE,
  payload: {},
});

export const fetchMediaDistributions = callback => (
  (dispatch) => {
    dispatch(fetchMediaDistributionsStart());

    fetchMediaDistributionsAPI().then((res) => {
      dispatch(fetchMediaDistributionsComplete(res.body));

      if (callback != null) {
        callback(res.body);
      }
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(addErrorAlert(i18n.t('messages.fetchMediaDistributionError', { message: res.text })));
    });
  }
);

export const fetchMediaDistribution = id => (
  (dispatch) => {
    dispatch(fetchMediaDistributionStart());

    fetchMediaDistributionAPI(id).then((res) => {
      dispatch(fetchMediaDistributionComplete(res.body));
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(addErrorAlert(i18n.t('messages.fetchMediaDistributionError', { message: res.text })));
    });
  }
);

export const createMediaDistribution = (form, callback) => (
  (dispatch) => {
    dispatch(createMediaDistributionStart());

    createMediaDistributionAPI(form).then((res) => {
      dispatch(createMediaDistributionComplete(res.body));
      dispatch(removeLoading());

      if (callback != null) {
        callback(res.body);
      } else {
        dispatch(addSuccessAlert(i18n.t('messages.createMediaDistributionSuccess')));
      }
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(removeLoading());
      dispatch(addErrorAlert(i18n.t('messages.createMediaDistributionError', { message: res.text })));
    });
  }
);

export const updateMediaDistribution = (form, callback) => (
  (dispatch) => {
    dispatch(updateMediaDistributionStart());

    updateMediaDistributionAPI(form).then((res) => {
      dispatch(updateMediaDistributionComplete(res.body));
      dispatch(removeLoading());

      if (callback != null) {
        callback(res.body);
      } else {
        dispatch(addSuccessAlert(i18n.t('messages.updateMediaDistributionSuccess')));
      }
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(removeLoading());
      dispatch(addErrorAlert(i18n.t('messages.updateMediaDistributionError', { message: res.text })));
    });
  }
);

export const updateMediaDistributionsBulk = (form, callback) => (
  (dispatch) => {
    dispatch(updateMediaDistributionsBulkStart());

    updateMediaDistributionsBulkAPI(form).then((res) => {
      dispatch(updateMediaDistributionsBulkComplete(res.body));
      dispatch(removeLoading());

      if (callback != null) {
        callback(res.body);
      }
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(removeLoading());
      dispatch(addErrorAlert(i18n.t('messages.updateMediaDistributionError', { message: res.text })));
    });
  }
);

export const deleteMediaDistribution = (id, callback) => (
  (dispatch) => {
    dispatch(deleteMediaDistributionStart());

    deleteMediaDistributionAPI(id).then(() => {
      dispatch(deleteMediaDistributionComplete());
      dispatch(removeLoading());

      if (callback != null) {
        callback();
      } else {
        dispatch(addSuccessAlert(i18n.t('messages.deleteMediaDistributionSuccess')));
      }
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(removeLoading());
      dispatch(addErrorAlert(i18n.t('messages.deleteMediaDistributionError', { message: res.text })));
    });
  }
);
