import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import MediaDistribution from '../../components/mediaDistribution/MediaDistribution';
import { fetchMedias } from '../../actions/mediaAction';
import {
  fetchMediaDistributions,
  createMediaDistribution,
  updateMediaDistribution,
  updateMediaDistributionsBulk,
  deleteMediaDistribution,
} from '../../actions/mediaDistributionAction';
import { addLoading } from '../../actions/loadingAction';
import { addSuccessAlert } from '../../actions/alertAction';

class MediaDistributionContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFeedMediaChange = this.handleFeedMediaChange.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleArticleAddClick = this.handleArticleAddClick.bind(this);
    this.handleArticleSubmitClick = this.handleArticleSubmitClick.bind(this);
    this.handleArticleCancelClick = this.handleArticleCancelClick.bind(this);

    this.state = {
      mediaDistributions: [],
      isDisplayArticleSearchPopup: false,
    };
  }

  componentDidMount() {
    this.props.fetchMedias();
    this.props.fetchMediaDistributions((mediaDistributions) => {
      this.setState({ mediaDistributions: mediaDistributions.media_distributions });
    });
  }

  handleSubmit() {
    const mediaDistributions = this.props.mediaDistributions.media_distributions;
    const editedMediaDistributions = this.state.mediaDistributions;

    const createDistributions = [];
    const updateDistributions = [];
    const deleteDistributions = [];

    editedMediaDistributions.forEach((e) => {
      if (e.id == null) {
        createDistributions.push(e);
      } else {
        const initial =
          mediaDistributions.find(d => d.id === e.id && d.feed_media_id !== e.feed_media_id);
        if (initial != null) {
          updateDistributions.push(e);
        }
      }
    });

    mediaDistributions.forEach((d) => {
      const edited = editedMediaDistributions.find(e => e.id === d.id);
      if (edited == null) {
        deleteDistributions.push(d.id);
      }
    });

    this.props.addLoading();
    this.props.updateMediaDistributionsBulk({
      create: createDistributions,
      update: updateDistributions,
      delete: deleteDistributions,
    }, () => {
      this.props.fetchMediaDistributions((distributions) => {
        this.setState({ mediaDistributions: distributions.media_distributions });
      });

      this.props.addSuccessAlert(this.props.t('messages.updateMediaDistributionSuccess'));
    });
  }

  handleFeedMediaChange(e) {
    const value = e.target.options[e.target.selectedIndex].value;
    const i = Number(e.target.dataset.key);

    const distributions = [].concat(this.state.mediaDistributions);
    distributions[i] = Object.assign({}, distributions[i]);
    distributions[i].feed_media_id = Number(value);

    this.setState({ mediaDistributions: distributions });
  }

  handleRemoveClick(e) {
    const i = Number(e.target.dataset.key);

    const distributions = [].concat(this.state.mediaDistributions);
    distributions.splice(i, 1);

    this.setState({ mediaDistributions: distributions });
  }

  handleArticleAddClick() {
    this.setState({ isDisplayArticleSearchPopup: true });
  }

  handleArticleSubmitClick(article) {
    const distributions = [].concat(this.state.mediaDistributions);
    distributions.push({ article, article_id: article.article_id, feed_media_id: 0, type: 'delete' });

    this.setState({ mediaDistributions: distributions });

    this.setState({ isDisplayArticleSearchPopup: false });
  }

  handleArticleCancelClick() {
    this.setState({ isDisplayArticleSearchPopup: false });
  }

  render() {
    const { medias, t } = this.props;

    return (
      <div className="mediaDistributionContainer">

        <div className="header">
          <div className="right">
            <button className="bangButton" onClick={this.handleSubmit}>Save</button>
          </div>

          <div className="clear" />
        </div>

        <div className="content">
          {medias != null ? (
            <MediaDistribution
              mediaDistributions={this.state.mediaDistributions}
              medias={medias.medias}
              onFeedMediaChange={this.handleFeedMediaChange}
              onRemoveClick={this.handleRemoveClick}
              onArticleAddClick={this.handleArticleAddClick}
              onArticleSubmitClick={this.handleArticleSubmitClick}
              onArticleCancelClick={this.handleArticleCancelClick}
              isDisplayArticleSearchPopup={this.state.isDisplayArticleSearchPopup}
              t={t}
            />
          ) : null}
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    medias: state.medias,
    mediaDistributions: state.mediaDistributions,
    loading: state.loading,
  }
);

const mapDispatchToProps = dispatch => (
  {
    fetchMedias: () => dispatch(fetchMedias()),
    fetchMediaDistributions: callback => dispatch(fetchMediaDistributions(callback)),
    createMediaDistribution: (form, callback) => dispatch(createMediaDistribution(form, callback)),
    updateMediaDistribution: (form, callback) => dispatch(updateMediaDistribution(form, callback)),
    updateMediaDistributionsBulk:
      (form, callback) => dispatch(updateMediaDistributionsBulk(form, callback)),
    deleteMediaDistribution: (form, callback) => dispatch(deleteMediaDistribution(form, callback)),
    addLoading: () => dispatch(addLoading()),
    addSuccessAlert: value => dispatch(addSuccessAlert(value)),
  }
);

const mediaDistributionContainer = withRouter(translate()(MediaDistributionContainer));
export default connect(mapStateToProps, mapDispatchToProps)(mediaDistributionContainer);
