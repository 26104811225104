import React from 'react';
import { Field } from 'redux-form';
import ReactHLS from 'react-hls';

import { timeFormat } from '../../util/util';
import Config from '../../config/Config';

const FeatureEditForm = (props) => {
  if (props.form == null || Object.keys(props.form).length === 0) {
    return null;
  }

  const {
    form,
    initialValues,
    feature,
    t,
  } = props;

  const imageURL = '/assets/images/no_image_small.png';

  return (
    <div className="featureEditForm">
      <form method="post">
        <div className="items">

          <div className="item">
            <label htmlFor="id">ID</label>
            <div className="id">
              {feature.id}
            </div>
          </div>

          <div className="item">
            <label htmlFor="name">{t('feature.name')}</label>
            <div className="name">
              <Field name="name" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="key">{t('feature.key')}</label>
            <div className="key">
              <Field name="key" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="featureStatus">{t('feature.status')}</label>
            <div className="featureStatus">
              <div className="select-wrap">
                <Field name="status" component="select">
                  {
                    Config.FEATURE_STATUSES
                      .map(e => <option value={e.id} key={e.id}>{t(e.i18nKey)}</option>)
                  }
                </Field>
              </div>
            </div>
          </div>

          <div className="item">
            <label htmlFor="canonicalURL">{t('feature.canonicalURL')}</label>
            <div className="canonicalURL">
              <Field name="canonical_url" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="featureMediaDistribution">{t('feature.mediaDistribution')}</label>
            <div className="featureMediaDistribution">
              <div className="select-wrap">
                <Field name="media_distribution" component="select">
                  <option value="" key="" />
                  {
                    Config.MEDIA_TO_DISTRIBUTE_FEATURE
                      .map(e => <option value={e.id} key={e.id}>{e.name}</option>)
                  }
                </Field>
              </div>
            </div>
          </div>

          <div className="item">
            <label htmlFor="contentType">{t('feature.contentType')}</label>
            <div className="contentType">
              <div className="select-wrap">
                <Field name="content_type" component="select">
                  {
                    Config.FEATURE_CONTENT_TYPES
                      .map(e => <option value={e} key={e}>{e}</option>)
                  }
                </Field>
              </div>
            </div>
          </div>

          <div className="item">
            <label htmlFor="contentValue">{t('feature.contentValue')}</label>
            <div className="contentValue">
              <Field name="content_value" component="textarea" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="contentTemplate">{t('feature.contentTemplate')}</label>
            <div className="contentTemplate">
              <div className="select-wrap">
                <Field name="content_template" component="select">
                  {
                    Config.FEATURE_CONTENT_TEMPLATE_TYPES
                      .map(e => <option value={e.id} key={e.id}>{t(e.i18nKey)}</option>)
                  }
                </Field>
              </div>
            </div>
          </div>

          <div className="item">
            <label htmlFor="headerType">{t('feature.headerType')}</label>
            <div className="headerType">
              <div className="select-wrap">
                <Field name="header_type" component="select">
                  {
                    Config.FEATURE_HEADER_TYPES
                      .map(e => <option value={e.id} key={e.id}>{t(e.i18nKey)}</option>)
                  }
                </Field>
              </div>
            </div>
          </div>

          <div className="item">
            <label htmlFor="title">{t('feature.title')}</label>
            <div className="title">
              <Field name="title" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="seoTitle">{t('feature.seoTitle')}</label>
            <div className="seoTitle">
              <Field name="seo_title" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="ogTitle">{t('feature.ogTitle')}</label>
            <div className="ogTitle">
              <Field name="og_title" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="description">{t('feature.description')}</label>
            <div className="description">
              <Field name="description" component="textarea" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="seoDescription">{t('feature.seoDescription')}</label>
            <div className="seoDescription">
              <Field name="seo_description" component="textarea" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="ogImage">{t('feature.ogImage')}</label>
            <img
              src={initialValues.og_image === '' ? imageURL : initialValues.og_image}
              alt="ogImage"
            />
            <div className="ogImage">
              <Field name="og_image" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="keywords">{t('feature.keywords')}</label>
            <div className="keywords">
              <Field name="keywords" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="metaKeywords">{t('feature.metaKeywords')}</label>
            <div className="metaKeywords">
              <Field name="meta_keywords" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="advertiserName">{t('feature.advertiserName')}</label>
            <div className="advertiserName">
              <Field name="advertiser_name" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="advertiserImageURL">{t('feature.advertiserImageURL')}</label>
            <img
              src={initialValues.advertiser_image_url === '' ? imageURL : initialValues.advertiser_image_url}
              alt="advertiserImageURL"
            />
            <div className="advertiserImageURL">
              <Field name="advertiser_image_url" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="headerLogo">{t('feature.headerLogo')}</label>
            <img
              src={initialValues.header_logo === '' ? imageURL : initialValues.header_logo}
              alt="headerLogo"
            />
            <div className="headerLogo">
              <Field name="header_logo" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="mobileHeaderImageURL">{t('feature.mobileHeaderImageURL')}</label>
            <img
              src={initialValues.mobile_header_image_url === '' ? imageURL : initialValues.mobile_header_image_url}
              alt="mobileHeaderImageURL"
            />
            <div className="mobileHeaderImageURL">
              <Field name="mobile_header_image_url" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="tabletHeaderImageURL">{t('feature.tabletHeaderImageURL')}</label>
            <img
              src={initialValues.tablet_header_image_url === '' ? imageURL : initialValues.tablet_header_image_url}
              alt="tabletHeaderImageURL"
            />
            <div className="tabletHeaderImageURL">
              <Field name="tablet_header_image_url" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="pcHeaderImageURL">{t('feature.pcHeaderImageURL')}</label>
            <img
              src={initialValues.pc_header_image_url === '' ? imageURL : initialValues.pc_header_image_url}
              alt="pcHeaderImageURL"
            />
            <div className="pcHeaderImageURL">
              <Field name="pc_header_image_url" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="mobileHeaderVideoURL">{t('feature.mobileHeaderVideoURL')}</label>
            <ReactHLS url={form.mobile_header_video_url} />
            <div className="mobileHeaderVideoURL">
              <Field name="mobile_header_video_url" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="tabletHeaderVideoURL">{t('feature.tabletHeaderVideoURL')}</label>
            <ReactHLS url={form.tablet_header_video_url} />
            <div className="tabletHeaderVideoURL">
              <Field name="tablet_header_video_url" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="pcHeaderVideoURL">{t('feature.pcHeaderVideoURL')}</label>
            <ReactHLS url={form.pc_header_video_url} />
            <div className="pcHeaderVideoURL">
              <Field name="pc_header_video_url" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="backgroundColor">{t('feature.backgroundColor')}</label>
            <div className="backgroundColor">
              <Field name="background_color" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="fontColor">{t('feature.fontColor')}</label>
            <div className="fontColor">
              <Field name="font_color" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="articlePageText">{t('feature.articlePageText')}</label>
            <div className="articlePageText">
              <Field name="article_page_text" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="articleFeatureBannerMobile">{t('feature.articleFeatureBannerMobile')}</label>
            <div className="articleFeatureBannerMobile">
              <img
                src={initialValues.article_feature_banner_mobile === '' ? imageURL : initialValues.article_feature_banner_mobile}
                alt="articleFeatureBannerMobile"
              />
              <Field name="article_feature_banner_mobile" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="articleFeatureBannerPC">{t('feature.articleFeatureBannerPC')}</label>
            <div className="articleFeatureBannerPC">
              <img
                src={initialValues.article_feature_banner_pc === '' ? imageURL : initialValues.article_feature_banner_pc}
                alt="articleFeatureBannerPC"
              />
              <Field name="article_feature_banner_pc" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="bannerBottomMobile">{t('feature.bannerBottomMobile')}</label>
            <div className="bannerBottomMobile">
              <img
                src={initialValues.banner_bottom_mobile === '' ? imageURL : initialValues.banner_bottom_mobile}
                alt="bannerBottomMobile"
              />
              <Field name="banner_bottom_mobile" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="bannerBottomPC">{t('feature.bannerBottomPC')}</label>
            <div className="bannerBottomPC">
              <img
                src={initialValues.banner_bottom_pc === '' ? imageURL : initialValues.banner_bottom_pc}
                alt="bannerBottomPC"
              />
              <Field name="banner_bottom_pc" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="bannerBottomLinkURL">{t('feature.bannerBottomLinkURL')}</label>
            <div className="bannerBottomLinkURL">
              <Field name="banner_bottom_link_url" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <div className="bannerBottomOpenTab">
              <Field
                name="banner_bottom_open_tab"
                id="bannerBottomOpenTab"
                component="input"
                type="checkbox"
                value
              />
              <label className="checkbox" htmlFor="bannerBottomOpenTab">{t('feature.bannerBottomOpenTab')}</label>
            </div>
          </div>

          <div className="item">
            <label htmlFor="featureListImageURL">{t('feature.featureListImageURL')}</label>
            <div className="featureListImageURL">
              <img
                src={initialValues.feature_list_image_url === '' ? imageURL : initialValues.feature_list_image_url}
                alt="featureListImageURL"
              />
              <Field name="feature_list_image_url" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <div className="featureListViewEnable">
              <Field
                name="feature_list_view_enable"
                id="featureListViewEnable"
                component="input"
                type="checkbox"
                value
              />
              <label className="checkbox" htmlFor="featureListViewEnable">{t('feature.featureListViewEnable')}</label>
            </div>
          </div>

          <div className="item">
            <label htmlFor="createdAt">{t('common.createdAt')}</label>
            <div className="createdAt">
              {timeFormat(feature.created_at)}
            </div>
          </div>

          <div className="item">
            <label htmlFor="updateAt">{t('common.updatedAt')}</label>
            <div className="updatedAt">
              {timeFormat(feature.updated_at)}
            </div>
          </div>

        </div>
      </form>
    </div>
  );
};

export default FeatureEditForm;
