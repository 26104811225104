import {
  FETCH_IMAGE_GROUP_START,
  FETCH_IMAGE_GROUP_COMPLETE,
  CREATE_IMAGE_GROUP_START,
  CREATE_IMAGE_GROUP_COMPLETE,
  UPDATE_IMAGE_GROUP_START,
  UPDATE_IMAGE_GROUP_COMPLETE,
} from '../actions/imageGroupAction';

const imageGroupReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_IMAGE_GROUP_START:
      return state;

    case FETCH_IMAGE_GROUP_COMPLETE:
      return action.payload;

    case CREATE_IMAGE_GROUP_START:
      return state;

    case CREATE_IMAGE_GROUP_COMPLETE:
      return action.payload;

    case UPDATE_IMAGE_GROUP_START:
      return state;

    case UPDATE_IMAGE_GROUP_COMPLETE:
      return action.payload;

    default:
      return state;
  }
};

export default imageGroupReducer;
