import React from 'react';
import classnames from 'classnames';
import { dateFormat, hasPermission } from '../../util/util';
import Config from '../../config/Config';

const getAuthor = (id, authors) => {
  if (authors == null) {
    return null;
  }

  return authors.find(e => e.id === id);
};

const itemClick = (e, permissions) => {
  if (!hasPermission(Config.PERMISSIONS.article_status_edit, permissions)) {
    e.preventDefault();
    return false;
  }
  return true;
};

const ArticleCalendarItem = (props) => {
  const {
    item,
    authors,
    permissions,
    searchValues,
  } = props;
  const isPR = item.content_type === 'pr';
  const isDraft = item.status === 'draft';
  const isPosted = item.status === 'posted';
  const isReserved = item.status === 'reserved';
  const isWT = item.features != null ? item.features.indexOf('worldtopics') >= 0 : false;
  const isMyArticle = permissions != null && item.create_user_id === permissions.id;
  const postDateTime = dateFormat(item.post_date, 'HH:MM');
  const authorID = item.author_id_for_analytics || item.author_id;
  const author = authors != null ? getAuthor(authorID, authors).name : null;
  const title = item.titles != null ? item.titles[0] : 'no title';
  let isShow = true;

  if (isShow && searchValues.status != null) {
    isShow = item.status === searchValues.status;
  }
  if (isShow && searchValues.is_worldtopics != null) {
    if (searchValues.is_worldtopics === 'match') {
      isShow = isWT;
    } else {
      isShow = !isWT;
    }
  }
  if (isShow && searchValues.is_pr != null) {
    if (searchValues.is_pr === 'match') {
      isShow = isPR;
    } else {
      isShow = !isPR;
    }
  }
  if (isShow && searchValues.is_my_article) {
    isShow = isMyArticle;
  }
  if (!isShow) {
    return null;
  }

  return (
    <a
      href={`/article/${item.article_id}/edit`}
      target="_blank"
      rel="noopener noreferrer"
      key="articleCalendarItem"
      onClick={e => itemClick(e, permissions)}
      className={classnames({
        articleCalendarItem: true,
        notAllowed: !hasPermission(Config.PERMISSIONS.article_status_edit, permissions),
        isPosted,
        isReserved,
        isDraft,
        isWT,
      })}
    >
      <div className="itemTop">
        <span className={classnames({ itemPR: true, isPR })}>PR</span>
        <span className="itemPostDateTime">{postDateTime}</span>
        <span className="itemAuthor">{` / ${author}`}</span>
      </div>
      <div className="itemBottom">
        <span className="itemTitle">{title}</span>
      </div>
    </a>
  );
};

export default ArticleCalendarItem;
