import React from 'react';
import { Field } from 'redux-form';
import TagsInput from 'react-tagsinput';

import Config from '../../config/Config';
import { timeFormat } from '../../util/util';

const MediaEditForm = (props) => {
  if (props.form == null || Object.keys(props.form).length === 0) {
    return null;
  }

  const {
    form,
    media,
    categories,
    onCategoryRemoveClick,
    onCategoryAddClick,
    onTagsChange,
    onTagsRenderInput,
    onContentTypeChange,
    onEmbedTypeChange,
    onEmbedCodeAnalyticsChange,
    onEmbedCodeAnalyticsNewspassChange,
    refTagsInput,
    t,
  } = props;

  const embedCode = JSON.parse(form.embed_code);

  return (
    <div className="mediaEditForm">
      <form method="post">
        <div className="items">

          <div className="item">
            <label htmlFor="id">ID</label>
            <div className="id">
              {media.id}
            </div>

            <label htmlFor="name">{t('media.name')}</label>
            <div className="name">
              <Field name="name" component="input" type="text" />
            </div>

            <label htmlFor="key">{t('media.key')}</label>
            <div className="key">
              <Field name="key" component="input" type="text" />
            </div>

            <label htmlFor="feedCategories">{t('media.feedCategories')}</label>
            <div className="feedCategories">
              {form.feed_categories != null ? form.feed_categories.map((e, i) => (
                <div className="feedCategory" key={i}>
                  <div className="select-wrap">
                    <Field name={`feed_categories[${i}]`} component="select">
                      {
                        categories != null ?
                          categories.map(category => (
                            <option value={category.id} key={category.id}>
                              {category.name}
                            </option>
                          )) : null
                      }
                    </Field>
                  </div>

                  <div className="buttons">
                    <div className="button" data-key={i} onClick={onCategoryRemoveClick}>
                      <i className="fa fa-trash-o" data-key={i} />
                    </div>
                  </div>
                </div>
              )) : null}

              <button type="button" onClick={onCategoryAddClick}>
                <i className="fa fa-plus" />
              </button>
            </div>

            <label htmlFor="feedTags">{t('media.feedTags')}</label>
            <div className="feedTags">
              <TagsInput
                ref={refTagsInput}
                renderInput={onTagsRenderInput}
                value={form.feed_tags || []}
                onChange={onTagsChange}
                onlyUnique
              />
            </div>

            <div className="isTagExclude">
              <Field
                name="is_tag_exclude"
                id="isTagExclude"
                component="input"
                type="checkbox"
              />
              <label className="checkbox" htmlFor="isTagExclude">{t('media.isTagExclude')}</label>
            </div>

            <label htmlFor="feedContentTypes">{t('media.feedContentTypes')}</label>
            <div className="feedContentTypes">
              {Config.ARTICLE_CONTENT_TYPES.map((e, i) => (
                <div key={i}>
                  <input
                    type="checkbox"
                    id={`feedContentTypes${i}`}
                    checked={form.feed_content_types != null ?
                      form.feed_content_types.find(type => type === e.id) != null : false}
                    data-key={e.id}
                    onChange={onContentTypeChange}
                  />
                  <label className="checkbox" htmlFor={`feedContentTypes${i}`}>
                    {t(e.i18nKey)}
                  </label>
                </div>
              ))}
            </div>

            <label htmlFor="feedEmbedTypes">{t('media.feedEmbedTypes')}</label>
            <div className="feedEmbedTypes">
              {Config.MEDIA_EMBED_TYPES.map((e, i) => (
                <div key={i}>
                  <input
                    type="checkbox"
                    id={`feedEmbedTypes${i}`}
                    checked={form.feed_embed_types != null ?
                      form.feed_embed_types.find(type => type === e.id) != null : false}
                    data-key={e.id}
                    onChange={onEmbedTypeChange}
                  />
                  <label className="checkbox" htmlFor={`feedEmbedTypes${i}`}>
                    {t(e.i18nKey)}
                  </label>
                </div>
              ))}
            </div>

            <label htmlFor="embedCode">{t('media.embedCode')}</label>
            <div className="embedCode">
              <textarea
                name="embed_code_analytics"
                defaultValue={embedCode.analytics}
                onChange={onEmbedCodeAnalyticsChange}
              />
            </div>

            <label htmlFor="embedCode">{t('media.embedCode')} (Newspass)</label>
            <div className="embedCode">
              <textarea
                name="embed_code_analytics_newspass"
                defaultValue={embedCode.analytics_newspass}
                onChange={onEmbedCodeAnalyticsNewspassChange}
              />
            </div>

            <label htmlFor="adContent">{t('media.adContent')}</label>
            <div className="adContent">
              <Field name="ad_content" component="textarea" />
            </div>

            <label htmlFor="createdAt">{t('common.createdAt')}</label>
            <div className="createdAt">
              {timeFormat(media.created_at)}
            </div>

            <label htmlFor="updateAt">{t('common.updatedAt')}</label>
            <div className="updatedAt">
              {timeFormat(media.updated_at)}
            </div>
          </div>

        </div>
      </form>
    </div>
  );
};

export default MediaEditForm;
