const i18nKO = {
  layout: {
    sideMenu: {
      menus: {
        article: '기사 편집',
        calendar: 'Calendar',
        image: '이미지 관리',
        adFrame: '광고 관리',
        contentFrame: '프레임 콘텐츠 관리',
        author: '작가 관리',
        category: '카테고리 관리',
        tag: '태그 관리',
        feature: 'Features',
        media: '미디어 관리',
        mediaDistribution: '미디어 유통관리',
        user: '계정 관리',
      },
    },
  },
  login: {
    footerHelp: '불편한 점이나 개선의 요구가 있으면 Slack #system 채널에서 말해주세요.',
  },
  article: {
    eyeCatchURLs: '아이켓치 이미지',
    originalRatioEyeCatchURL: 'Eye Catch image for original ratio',
    hideEyeCatch: 'Hide Eye Catch image in the article page',
    headerVideoURLs: 'Eye Catch Videos',
    mobileVideoEyeCatchURLPlaceholder: 'Please input video url for mobile.',
    pcVideoEyeCatchURLPlaceholder: 'Please input video url for PC.',
    titles: '본문 타이틀',
    titleWithBr: 'Title with br (max number of words in one line is 17. New line code is <br>)',
    titleWithBrPlaceholder: 'How travel evolves with tech<br>Interview with Airbnb’s design head',
    author: '작가 이름',
    category: '카테고리',
    tableOfContents: {
      title: 'Table of Contents',
      h2: 'Display only h2',
      h2_h3: 'Display h2 and h3',
    },
    url: 'URL',
    copyUrl: 'Copy Preview URLs',
    video: 'Embed code for video article(YouTube)',
    brand: 'Brand',
    contentType: '컨텐츠타입',
    tags: '테그',
    seoTitle: 'SEO타이틀',
    referralTitle: 'Referral타이틀',
    socialTitle: 'Social타이틀',
    description: 'Description',
    twitterDescription: 'Twitter description',
    facebookDescription: 'Facebook description',
    advertiser: '광고주',
    adTitle: '프레임 광고 제목',
    recommendAdEnable: '공개시 TOP / 리 코멘드 창 광고를 등록하기',
    frameAdEnable: '공개시 프레임 프레임 광고를 등록하기',
    embedCode: '삽입된 테그',
    features: 'Features',
    voice: 'Voice',
    geotags: 'Geotags',
    status: '기사 상태',
    postDate: '공개일',
    topViewEnable: 'TOP에 표시하기',
    abtestEnable: 'AB테스트를 활성화',
    twitterPostingEnable: 'Enable Twitter auto posting',
    facebookPostingEnable: 'Enable Facebook auto posting',
    mediaDistributionDisable: 'Disable media distribution',
    memo: '메모',
    authorIDForAnalytics: 'Author for Analytics',
    eyeCatchURLsTooltip: 'AB테스트를 활성화 했을경우 메인/서브 이미지에서 무작위로 표시됩니다.<br> 비활성화일 경우 메인 이미지만 표시됩니다.',
    originalRatioEyeCatchURLTooltip: 'This image will be displayed with original aspect ratio in the artice page instead of the image above',
    eyeCatchURLsToMainTooltip: '표시중인 이미지를 메인 이미지로 설정',
    titlesTooltip: 'AB테스트를 활성화 했을경우 메인/서브 타이틀이 무작위로 표시됩니다.<br> 비활성화일 경우 메인 타이틀만 표시됩니다.',
    titlesToMainTooltip: '메인 타이틀로 설정',
    titleWithBrTooltip: 'Input title with new line using br tag. This is used for a title in the article page',
    tagsTooltip: 'Input tags for tag page and searching articles.<br>The tag you click and select is used for breadcrumbs in the article page.',
    seoTitleTooltip: 'Google검색결과에 표시될 타이틀을 입력하십시오. <br> 문자가 30자를 초과하면 문장이 잘릴수 있습니다. <br> 미입력시 기사의 타이틀이 표시됩니다. <br> 검색되고 싶은 키워드는 본문 또는 타이틀에 포함하는편이 효과가 있습니다.',
    referralTitleTooltip: 'Smartnews, Gunosy등 미디어에 개제될 타이틀을 입력하세요。<br>문자가 30자를 초과하면 문장이 잘릴수 있습니다.<br>미입력시 기사의 타이틀이 표시됩니다.',
    socialTitleTooltip: 'Facebook, Twitter등SNS에 표시될 타이틀을 입력하세요.<br>문자가 30자를 초과하면 문장이 잘릴수 있습니다.<br>미입력시 기사의 타이틀이 표시됩니다.',
    descriptionTooltip: 'Google검색결과에 표시될 설명문을 입력하십시오.<br>문자는 250자 이하로 입력하십시오. (스마트폰의 경우 200자) 중요한 내용의 경우 200자 내외로 작성하십시오.<br> 검색되고 싶은 키워드는 본문 또는 타이틀에 포함하는편이 효과가 있습니다.',
    adTitleTooltip: 'Used for Frame Ad Title.<br>Please enter up to 3 lines, up to 30 characters per line.',
    recommendAdEnableTooltip: 'If checked, TOP/Recommendation and Frame ads will be registered with Google Ad Manager at the time of publication.<br>It will be registered soon after publication, but the delivery start will be 00:00 three days later.',
    frameAdEnableTooltip: 'If checked, framed frame ads will be registered with Google Ad Manager when published.<br>It will be registered and distributed soon after publishing.',
    descriptionCount: '문자수: {{count}}',
    descriptionCountAlert: 'Please enter no more than 256 characters.',
    adTitleCount: 'Lines: {{row}}, Characters: {{count}}',
    adTitleCountAlert: 'Please enter no more than 30 characters.',
    adTitleRowsAlert: 'Please enter no more than 3 lines.',
    tagsPlaceholder: '테그를 입력하세요',
    sentence: '문장',
    heading: '제목',
    image: '이미지',
    embed: '미디어삽입',
    etc: '그밖의 기능',
    imageURLPlaceholder: '링크URL을 입력하세요.',
    imageAltPlaceholder: 'Input Alt text',
    embedPlaceholder: '삽입코드를 입력하세요.',
    creditTypePlaceholder: '크레딧타입을 입력하세요.',
    creditNamePlaceholder: '크레딧명을 입력하세요.',
    creditURLPlaceholder: '크레딧URL을 입력하세요.',
    creditAddPlaceholder: '크레딧 추가',
    authorNamePlaceholder: '작성자명을 입력하세요.',
    profileImageURLPlaceholder: '프로필 이미지를 입력하세요.',
    profileSetStructuredData: 'Set structued data',
    pinterestURLPlaceholder: 'Pinterest URL 또는 ID을 입력하세요.',
    paginationTitlePlaceholder: 'Input text for next page',
    referenceNamePlaceholder: 'Input reference',
    referenceURLPlaceholder: 'Input reference URL',
    referenceNameAlert: 'Please input reference.',
    insertSection: '이곳에 세션을 삽입.',
    editExclusion: '{{userName}} 님이 편집중 입니다.',
    articleHistory: '기사 이력',
    searchForm: {
      word: '키워드 검색',
      wordPlaceholder: '단어 또는 기사ID로 검색',
      status: '상태별',
      brand: 'Brand',
      isMyArticle: '내가 작성한 기사만 표시',
      calendarArticleStatus: 'Status',
      calendarIsWorldtopics: 'WORLD TOPICS',
      calendarIsPR: 'PR',
    },
    searchPopup: {
      searchWord: '단어 또는 기사ID로 검색',
    },
    geotagPopup: {
      url: 'Input google maps URL',
    },
    publishSettingPopup: {
      popTitle: 'Publish Settings',
      distributionOption: 'Distribution Option',
      twitterPostingEnable: 'Enable Twitter auto posting',
      facebookPostingEnable: 'Enable Facebook auto posting',
      topViewEnable: 'Display at the top',
      mediaDistributionExceptLINE: 'Media distribution except LINE',
      mediaDistributionLINE: 'Enable LINE posting',
      abtestEnable: 'Enable AB Test',
      postDate: 'Post date',
      editDate: 'Edit date',
      cancel: 'Cancel',
      save: 'Save',
      saveAsReserved: 'Save as reserved',
    },
    statusBar: {
      posted: 'Posted',
      reserved: 'Reserved',
      deleted: 'Deleted',
      report: 'Report',
    },
    header: {
      delete: 'Delete',
      download: 'Download',
      history: 'History',
      preview: 'Preview',
      overwrite: 'Overwrite',
      saveAsDraft: 'Save as draft',
      returnToDraft: 'Return to Draft',
      publishSettings: 'PublishSettings',
    },
  },
  image: {
    url: 'URL',
    tags: '테그',
    userName: '유저 명',
    imageType: '이미지유형',
    license: '라이센스',
    source: '원본사이트이름',
    sourceURL: '원본사이트URL',
    imageGroup: '이미지그룹',
    imageGroupName: '이미지그룹명',
    tagsPlaceholder: '태그를 입력',
    credit: 'Credit',
    creditName: 'Credit name',
    creditURL: 'Credit URL',
    hideCredit: 'Hide credit',
    isFullWidth: 'Display image with device width',
    searchForm: {
      tags: '태그',
      imageType: '이미지유형',
      license: '라이센스',
      isMyImage: '자신이 업로드한 이미지만 보기',
    },
    searchPopup: {
      searchWord: '태그를 입력하고 Enter',
    },
    uploadFile: '파일 업로드',
    draggableImage: '이미지를 드레그하면 업로드 됩니다.',
  },
  voice: {
    uploadFile: '파일 업로드',
    draggableVoice: '음성을 드레그하면 업로드 됩니다.',
  },
  adFrame: {
    name: '이름',
    articleURL: '기사URL',
    deliveryFromAt: '발행 일',
    deliveryToAt: '발행 종료일',
    deliveryStatus: '발행 상태',
    isDisplays: '표시중',
    mobileImageURLs: '이미지URL(mobile)',
    pcImageURLs: '이미지URL(pc)',
    searchWord: '검색어',
    searchWordPlaceholder: '검색어 를 입력',
    deliveryAt: '발행기간',
    frame: '프레임',
  },
  contentFrame: {
    name: '이름',
    key: '키',
    contentTypes: '콘텐츠 형식',
    imageURLPlaceholder: '링크 URL을 입력',
    editSection: {
      campaignRemark: '발행중인 애드 프레임이 무작위로 나타납니다',
    },
  },
  author: {
    name: '이름',
    key: '키',
    title: '직힘',
    profile: '프로필',
    instagramUrl: 'Instagram URL',
    twitterUrl: 'Twitter URL',
    facebookUrl: 'Facebook URL',
    youtubeUrl: 'YouTube URL',
    icon: '아이콘 이미지',
  },
  category: {
    name: '이름',
    key: '키',
    description: '설명',
    seoName: '이름(SEO)',
    mobileHeaderImageURL: '해더이미지URL(mobile)',
    pcHeaderImageURL: '해더이미지URL(pc)',
    topViewEnable: 'TOP페이지 표시',
    sortKey: '순서',
  },
  tag: {
    name: '이름',
    description: '설명',
    parentID: '부모태그',
    childIDs: '자식태그',
    listFlg: '목록보기',
    exactMatchFlg: 'Display articles with exact match',
    searchTag: '태그를 입력하고 Enter',
  },
  feature: {
    name: 'Name',
    key: 'Key',
    status: 'Feature status',
    canonicalURL: 'Canonical URL',
    mediaDistribution: 'Media to display this feature',
    advertiserName: 'Advertiser name',
    advertiserImageURL: 'Advertiser image URL',
    title: 'Title',
    seoTitle: 'SEO title',
    ogTitle: 'OG title',
    description: 'Description',
    seoDescription: 'SEO description',
    headerLogo: 'Header logo',
    mobileHeaderImageURL: 'Header image URL (mobile)',
    tabletHeaderImageURL: 'Header image URL (tablet)',
    pcHeaderImageURL: 'Header image URL (desktop)',
    mobileHeaderVideoURL: 'Header video URL (mobile)',
    tabletHeaderVideoURL: 'Header video URL (tablet)',
    pcHeaderVideoURL: 'Header video URL (desktop)',
    ogImage: 'OG image',
    keywords: 'Keywords',
    metaKeywords: 'Meta keywords',
    contentType: 'Content type',
    contentValue: 'Content value',
    contentTemplate: 'Content template',
    contentTemplateDefault: 'Default',
    contentTemplateFlat: 'With image × flat',
    contentTemplateNoImage: 'No header image',
    headerType: 'Header type',
    headerTypeDefault: 'Default header',
    headerTypeNone: 'No Header',
    headerTypeTransparent: 'Transparent',
    backgroundColor: 'Background color',
    fontColor: 'Font color',
    articlePageText: 'Leading Text',
    articleFeatureBannerMobile: 'Imgae URL in feature artile(Mobile)',
    articleFeatureBannerPC: 'Imgae URL in feature artile(PC)',
    bannerBottomMobile: 'Banner bottom image URL in feature page(Mobile)',
    bannerBottomPC: 'Banner bottom image URL in feature page(PC)',
    bannerBottomLinkURL: 'Banner bottom link URL in feature page',
    bannerBottomOpenTab: 'Open banner link in new tab',
    featureListImageURL: 'Image URL for feature list',
    featureListViewEnable: 'Display at the feature list page',
    featureStatuses: {
      draft: 'Draft',
      posted: 'Posted',
    },
  },
  media: {
    name: '이름',
    key: '키',
    feedCategories: '발신 카테고리',
    feedTags: '발신 태그',
    isTagExclude: '지정된 태그를 제외',
    feedContentTypes: '발신 콘텐츠 형식',
    feedEmbedTypes: '삽입 유형',
    embedCode: '삽입 태그',
    adContent: 'Ad content',
    tagsPlaceholder: '태그를 입력',
  },
  mediaDistribution: {
    deleteDistributions: '삭제',
    feedMedia: '게재위치',
  },
  user: {
    name: '이름',
    email: '이메일주소',
    role: '롤',
    lastAccessAt: '최근 액세스 시간',
  },
  common: {
    createdAt: '작성시간',
    updatedAt: '업데이트 날짜',
    selectAll: '전부',
    match: 'MATCH',
    notMatch: 'NOT MATCH',
    main: '메인이미지',
    sub: '서브이미지{{index}}',
    download: '다운로드',
    new: '신규 작성',
    dateFormat: 'yyyy/mm/dd',
    timeFormat: 'yyyy/mm/dd HH:MM:ss',
    dailyQuotes: [
      '[관점을 바꾸어 !! 만들어가자] 이세상 만든 정설과 스탠다드에 얽매이지 않고, 사람들의 생각에 틈을 열어 [인사이트]를 발견하고 세상에 공표하자.흥미로운 만남, 그리고 그 만남이 새로운 인생레시피가 될수 있도록.',
      '[우리는 요리사이며, 소믈리에이며, 생산자이다] 다각적인 관점에서 사물의 좋음 나쁨을 파악하여, 최적의 조리법과 플레이팅을 할수 있도록.',
      '[즐기기 때문에 넘을수 있는 벽!]즐길수 있으니 주력할수 있다. 많은 정보를 Input 하여 지식과 경험이 피와 살이 될수 있도록 하자. 연구를 멈추지 말고 내일을 더욱더 맛있게 만들어가자.',
      '[다섯가지 맛을 알고 만들자] 짠맛 쓴맛 단맛 등 다각면적인 측면으로 이해함으로 자신의 진정한 가치를 찾자.',
      '[관찰하고 통찰하라] 불에 읶힌다고 전부 요리가아니듯 요리에 소리를 기울이고 향기를 즐기며, 주변에서 신경쓸수 있도록 하자.',
      '[최선을 했다면 한번더]  최선을 다했다면 여유를 가지고 한번도 돌아보자, 숨은맛을 추가하며 완성도를 한번도 올리자',
      '[양념은 빨리 음식은 따끈하게] 프로젝트착수는 빠르게 풀엑셀로, 프로젝트 완성을 경계점을 읽고 좋은 타이밍을 찾자.',
    ],
    repdigitQuote: 'Today is Repdigit day. More hungry, More foolish!',
    articleContentTypes: {
      curation: '큐레이션',
      original: '오리지널',
      mediaPartner: '메인 파트너',
      pr: 'PR',
      contribution: '기고문',
      event: '이벤트',
    },
    articleStatuses: {
      draft: '초안',
      reserved: '예약',
      posted: '공개',
      deleted: '삭제',
    },
    articleInputTypes: {
      credit: '크레딧',
      profile: '프로필',
      relationArticle: '관련기사',
      conversation: 'Conversation',
      interview: '인터뷰',
      pinterest: 'Pinterest',
      pagination: 'Pagination',
      reference: 'Reference',
      textbox: 'Text Box',
    },
    conversation: {
      positionLeft: 'Align Left',
      positionRight: 'Align Right',
    },
    textbox: {
      positionLeft: 'Align Left',
      positionRight: 'Align Right',
      positionCenter: 'Align Center',
    },
    imageImageTypes: {
      eyeCatch: '아이케치',
      article: '기사 내부',
      material: '소재',
      spc: 'FRAME',
    },
    imageLicenses: {
      royaltyFree: '로열티',
      rightsManaged: '카피라이트 관리',
      cc0: 'cc0',
      originalForArticle: '원본 이미지 (기사용)',
      licensed: '허락된 이미지',
      originalForAd: '원본 이미지 (광고용)',
      providedByAdvertiser: '광고주에게 받은 이미지 ',
      editorial: 'Editorial',
      stockPhoto: 'Stock photo',
    },
    adFrameDeliveryStatuses: {
      start: '전송중',
      stop: '중지',
    },
    adFrameIsDisplays: {
      on: '표시하기',
      off: '표시하지않기',
    },
    contentFrameContentTypes: {
      image: '이미지',
      article: '기사',
      embed: '삽입',
      campaign: '애드 프레임',
    },
    mediaEmbedTypes: {
      facebook: 'facebook',
      twitter: 'twitter',
      instagram: 'instagram',
      vimeo: 'vimeo',
      youtube: 'youtube',
      other: '그밖에',
    },
  },
  messages: {
    authFailure: '사용자 인증에 실패 했습니다.',
    fetchArticleError: '기사를 가져오는데 실패 했습니다. {{message}}',
    fetchArticleHistoryError: '기사이력을 가져오는데 실패 했습니다.   {{message}}',
    fetchArticleDocxError: 'Docx 파일을 가져오는데 실패 했습니다. {{message}}',
    createArticleSuccess: '기사를 작성 했습니다!',
    createArticleError: '문서작성에 실패 했습니다. {{message}}',
    updateArticleSuccess: '기사를 업데이트 했습니다.',
    updateArticleError: '기사 업데이트에 실패 했습니다. {{message}}',
    deleteArticleSuccess: 'Article deleted.',
    deleteArticleError: 'Article delete failed. {{message}}',
    draftArticleSuccess: 'Article drafted.',
    draftArticleError: 'Article draft failed. {{message}}',
    fetchImageError: '이미지를 가져오는데 실패 했습니다. {{message}}',
    fetchImageSourceError: '이미지 사이트의 취득에 실패했습니다. {{message}}',
    fetchImageGroupError: '이미지 그룹을 가져 오는 데 실패했습니다. {{message}}',
    createImageSuccess: '사진을 업로드했습니다.',
    createImageError: '이미지 업로드에 실패했습니다. {{message}}',
    createImageValidateError: '업로드 파일이 지정되어 있지 않습니다.',
    updateImageSuccess: '사진을 업데이트했습니다.',
    updateImageError: '이미지의 업데이트에 실패했습니다. {{message}}',
    deleteImageSuccess: '사진을 삭제했습니다.',
    deleteImageError: '이미지의 삭제에 실패했습니다. {{message}}',
    createImageGroupSuccess: '이미지 그룹을 만들었습니다.',
    createImageGroupError: '이미지 그룹을 만드는 데 실패했습니다. {{message}}',
    updateImageGroupSuccess: '이미지 그룹을 업데이트했습니다.',
    updateImageGroupError: '이미지 그룹의 업데이트에 실패했습니다. {{message}}',
    createVoiceSuccess: 'Voice uploaded.',
    createVoiceError: 'Voice upload failed. {{message}}',
    createVoiceValidateError: 'Upload file not specified.',
    fetchAdFrameError: '애드 프레임의 취득에 실패했습니다. {{message}}',
    createAdFrameSuccess: '애드 프레임을 만들었습니다.',
    createAdFrameError: '애드 프레임의 작성에 실패했습니다. {{message}}',
    updateAdFrameSuccess: '애드 프레임을 업데이트했습니다.',
    updateAdFrameError: '애드 프레임의 업데이트에 실패했습니다. {{message}}',
    deleteAdFrameSuccess: '애드 프레임을 삭제했습니다.',
    deleteAdFrameError: '애드 프레임의 삭제에 실패했습니다. {{message}}',
    fetchContentFrameError: '콘텐츠 프레임의 취득에 실패했습니다. {{message}}',
    createContentFrameSuccess: '콘텐츠 프레임을 만들었습니다.',
    createContentFrameError: '콘텐츠 프레임의 작성에 실패했습니다. {{message}}',
    updateContentFrameSuccess: '콘텐츠 프레임을 업데이트했습니다.',
    updateContentFrameError: '콘텐츠 프레임의 업데이트에 실패했습니다. {{message}}',
    deleteContentFrameSuccess: '콘텐츠 프레임을 삭제했습니다.',
    deleteContentFrameError: '콘텐츠 프레임의 삭제에 실패했습니다. {{message}}',
    fetchAuthorError: '작가목록을 가져오는데 실패 했습니다. {{message}}',
    createAuthorSuccess: '새로운 작가를 추가 했습니다.',
    createAuthorError: '작가의 추가에 실패했습니다. {{message}}',
    updateAuthorSuccess: '작가정보를 업데이트 했습니다.',
    updateAuthorError: '작가 업데이트에 실패했습니다. {{message}}',
    deleteAuthorSuccess: '작가를 삭제 했습니다.',
    deleteAuthorError: '작가삭제에 실패했습니다. {{message}}',
    fetchCategoryError: '카테고리 취득에 실패 했습니다. {{message}}',
    createCategorySuccess: '카테고리를 만들었습니다.',
    createCategoryError: '카테고리 작성에 실패했습니다. {{message}}',
    updateCategorySuccess: '카테고리를 업데이트했습니다.',
    updateCategoryError: '카테고리 업데이트에 실패했습니다. {{message}}',
    deleteCategorySuccess: '카테고리를 삭제했습니다.',
    deleteCategoryError: '카테고리 삭제에 실패했습니다. {{message}}',
    fetchTagError: '테그를 가져오는데 실패 했습니다. {{message}}',
    createTagSuccess: '태그를 작성했습니다.',
    createTagError: '태그 작성에 실패했습니다. {{message}}',
    updateTagSuccess: '태그를 업데이트 했습니다.',
    updateTagError: '태그 업데이트에 실패 했습니다. {{message}}',
    deleteTagSuccess: '태그를 제거했습니다.',
    deleteTagError: '태그 삭제에 실패했습니다. {{message}}',
    fetchFeatureError: 'Feature retrieval failed. {{message}}',
    createFeatureSuccess: 'Feature created.',
    createFeatureError: 'Feature creation failed. {{message}}',
    updateFeatureSuccess: 'Feature updated.',
    updateFeatureError: 'Feature update failed. {{message}}',
    deleteFeatureSuccess: 'Feature deleted.',
    deleteFeatureError: 'Feature deletion failed. {{message}}',
    fetchMediaError: '미디어를 가져 오는 데 실패했습니다. {{message}}',
    createMediaSuccess: '미디어를 만들었습니다.',
    createMediaError: '미디어 생성에 실패했습니다. {{message}}',
    updateMediaSuccess: '미디어를 업데이트했습니다.',
    updateMediaError: '미디어 업데이트에 실패했습니다. {{message}}',
    deleteMediaSuccess: '미디어를 삭제했습니다.',
    deleteMediaError: '미디어 삭제에 실패했습니다. {{message}}',
    fetchMediaDistributionError: '미디어 발실을 가져 오는 데 실패했습니다. {{message}}',
    createMediaDistributionSuccess: '미디어 발신을 만들었습니다.',
    createMediaDistributionError: '미디어 발신 작성에 실패했습니다. {{message}}',
    updateMediaDistributionSuccess: '미디어 발실을 업데이트했습니다.',
    updateMediaDistributionError: '미디어 발실 업데이트에 실패했습니다. {{message}}',
    deleteMediaDistributionSuccess: '미디어 발신을 삭제했습니다.',
    deleteMediaDistributionError: '미디어 발신을 삭제에 실패했습니다 {{message}}',
    fetchUserError: '사용자의 검색에 실패했습니다. {{message}}',
    createUserSuccess: '사용자를 만들었습니다.',
    createUserError: '사용자 생성에 실패했습니다. {{message}}',
    updateUserSuccess: '사용자를 업데이트했습니다.',
    updateUserError: '사용자의 업데이트에 실패했습니다. {{message}}',
    deleteUserSuccess: '사용자를 삭제했습니다.',
    deleteUserError: '사용자의 삭제에 실패했습니다. {{message}}',
    fetchPinterestError: 'Pinterest취득에 실패 했습니다. {{message}}',
    fetchRoleError: '롤을 가져 오는 데 실패했습니다. {{message}}',
    warningEditingSection: '섹션을 열고있는 상태에서는 저장할수 없습니다. OK 또는 Cancel 을 클릭하여 편집상태를 확정시켜 주세요.',
    warningPastDateReservation: '공개일시가 과거의 날짜로 설정되어 있어 예약할 수 없습니다.',
    warningRequirePostDate: '공개일시를 설정해주세요.',
    warningPastEditDate: 'Cannot Save if the edit date is before the post date.',
    warningRequireImageCredit: 'Please set image credits to publish the article created after 2018/6/1.',
    discardEditingArticle: '편집 내용이 저장되지 않습니다. 정말로 삭제 하시겠습니까?',
    restoreFromLocalStorage: '자동저장된 데이터가 있습니다. 복원 하시겠습니까?<br>취소하면 자동저장된 내용을 삭제됩니다.',
    googleMapsURLError: 'URL is invalid. Please enter the URL of Google Maps.',
    warningInvalidVideoEmbedCode: 'Embed code for video article is invalid. Please copy correct code in YouTube',
    warningTagTreeInvalid: 'Cannot set the same tag as parent tag and child tag.',
    setTagForBreadcrumbs: 'Set “{{message}}” as breadcrumb tag',
    warningNonManagedTagForBreadcrumbs: 'Cannot set non managed tag as breadcrumb tag',
    warningNonAdvertiser: 'PR 기사를 저장하려면 광고주를 설정하십시오.',
    warningEditingAdContent: '홍보 기사없이 광고주, 프레임 광고 및 TOP / 추천 광고를 설정할 수 없습니다. PR을 콘텐츠 유형으로 설정하십시오.',
    warningNonAdTitle: 'Please set Frame Ad Title to save PR article.',
    warningAdTitleNumExceeded: '프레임 프레임 광고 제목 {{row}} 라인에 {{count}} 문자 입력되어 있습니다. 30 자 이하로 재설정하십시오.',
    warningAdTitleRowExceeded: '프레임 프레임 광고 제목이 4 줄 이상 설정되어 있습니다. 3 행 다음으로 재설정하십시오.',
    previewUrlCopied: 'Preview URL was copied.',
  },
};

export default i18nKO;
