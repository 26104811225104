import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import ImageEditFormContainer from './ImageEditFormContainer';
import { fetchImage, updateImage } from '../../actions/imageAction';
import { addLoading } from '../../actions/loadingAction';

class ImageEditContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleCloseClick = this.handleCloseClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.params.imageID != null) {
      this.props.fetchImage(this.props.params.imageID);
    }
  }

  componentDidUpdate(prevProps) {
    const { image } = this.props;

    if (image != null && Number(prevProps.params.imageID) !== image.image_id) {
      this.props.fetchImage(prevProps.params.imageID);
    }
  }

  handleCloseClick() {
    this.props.router.push(`/image/${this.props.params.groupID}`);
  }

  handleSubmit(form) {
    const newForm = { ...form };
    newForm.license_id = parseInt(newForm.license_id, 10);
    const creditName = form.credit_name ? form.credit_name : '';
    const creditURL = form.credit_url ? form.credit_url : '';
    newForm.credit = JSON.stringify({ name: creditName, url: creditURL });

    this.props.addLoading();
    this.props.updateImage(newForm);
  }

  render() {
    const { image, params } = this.props;

    if (image == null || params.imageID == null) {
      return null;
    }

    return (
      <div className="imageEditContainer">

        <div className="header">
          <div className="left">
            <button ref={(ref) => { this.closeButton = ref; }}>
              <i className="fa fa-times" aria-hidden="true" />
              Close
            </button>
          </div>

          <div className="right">
            <button className="bangButton" ref={(ref) => { this.saveButton = ref; }}>Save</button>
          </div>

          <div className="clear" />
        </div>

        <div className="content">
          <ImageEditFormContainer
            id={params.imageID}
            image={image}
            saveButton={this.saveButton}
            closeButton={this.closeButton}
            onSubmit={this.handleSubmit}
            onCancel={this.handleCloseClick}
            ref={(ref) => { this.imageEditFormContainer = ref; }}
          />
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    image: state.image,
  }
);

const mapDispatchToProps = dispatch => (
  {
    fetchImage: id => dispatch(fetchImage(id)),
    updateImage: form => dispatch(updateImage(form)),
    addLoading: () => dispatch(addLoading()),
  }
);

const imageEditContainer = withRouter(ImageEditContainer);
export default connect(mapStateToProps, mapDispatchToProps)(imageEditContainer);
