import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import ContentFrameEditFormContainer from './ContentFrameEditFormContainer';
import { fetchContentFrame, fetchContentFrames, updateContentFrame } from '../../actions/contentFrameAction';
import { addLoading } from '../../actions/loadingAction';

class ContentFrameEditContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleCloseClick = this.handleCloseClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.params.id != null) {
      this.props.fetchContentFrame(this.props.params.id);
    }
  }

  componentDidUpdate(prevProps) {
    const { contentFrame } = this.props;

    if (contentFrame != null && Number(prevProps.params.id) !== contentFrame.id) {
      this.props.fetchContentFrame(prevProps.params.id);
    }
  }

  handleCloseClick() {
    this.props.router.push('/content_frame');
  }

  handleSubmit(form) {
    const newForm = { ...form };

    this.props.addLoading();
    this.props.updateContentFrame(newForm, () => this.props.fetchContentFrames());
  }

  render() {
    const { contentFrame, params } = this.props;

    if (contentFrame == null || params.id == null) {
      return null;
    }

    let editFormContainer = null;
    if (contentFrame.id === Number(params.id)) {
      editFormContainer = (
        <ContentFrameEditFormContainer
          id={params.id}
          contentFrame={contentFrame}
          saveButton={this.saveButton}
          closeButton={this.closeButton}
          onSubmit={this.handleSubmit}
          onCancel={this.handleCloseClick}
          ref={(ref) => { this.contentFrameEditFormContainer = ref; }}
        />
      );
    } else if (this.saveButton != null) {
      this.saveButton.onclick = null;
    }

    return (
      <div className="contentFrameEditContainer">

        <div className="header">
          <div className="left">
            <button ref={(ref) => { this.closeButton = ref; }}>
              <i className="fa fa-times" aria-hidden="true" />
              Close
            </button>
          </div>

          <div className="right">
            <button className="bangButton" ref={(ref) => { this.saveButton = ref; }}>Save</button>
          </div>

          <div className="clear" />
        </div>

        <div className="content">
          {editFormContainer}
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    contentFrame: state.contentFrame,
  }
);

const mapDispatchToProps = dispatch => (
  {
    fetchContentFrame: (id, callback) => dispatch(fetchContentFrame(id, callback)),
    fetchContentFrames:
      (form, contentFrames, offset) => dispatch(fetchContentFrames(form, contentFrames, offset)),
    updateContentFrame: (form, callback) => dispatch(updateContentFrame(form, callback)),
    addLoading: () => dispatch(addLoading()),
  }
);

const contentFrameEditContainer = withRouter(ContentFrameEditContainer);
export default connect(mapStateToProps, mapDispatchToProps)(contentFrameEditContainer);
