import React from 'react';
import { Field } from 'redux-form';

import ContentFrameEditSectionContainer from '../../containers/contentFrame/ContentFrameEditSectionContainer';
import { timeFormat } from '../../util/util';

const renderFrame = (contentFrame, i, props) => {
  const { id, form, onRemoveClick } = props;

  const contentType = contentFrame.content_types[i];
  const content = contentFrame.contents[i];

  return (
    <div className="contentFrame" key={i}>
      <div className="buttons">
        <div className="right">
          <div className="button" data-key={i} onClick={onRemoveClick}>
            <i className="fa fa-trash-o" />
          </div>
        </div>

        <div className="clear" />
      </div>

      <div className="editSection">
        <ContentFrameEditSectionContainer
          id={id}
          form={form}
          index={i}
          content={content}
          contentType={contentType}
        />
      </div>
    </div>
  );
};

const ContentFrameEditForm = (props) => {
  if (props.form == null || Object.keys(props.form).length === 0) {
    return null;
  }

  const {
    form,
    contentFrame,
    onAddClick,
    t,
  } = props;

  return (
    <div className="contentFrameEditForm">
      <form method="post">
        <div className="items">

          <div className="item">
            <label htmlFor="id">ID</label>
            <div className="id">
              {contentFrame.id}
            </div>

            <label htmlFor="name">{t('contentFrame.name')}</label>
            <div className="name">
              <Field name="name" component="input" type="text" />
            </div>

            <label htmlFor="key">{t('contentFrame.key')}</label>
            <div className="key">
              <Field name="key" component="input" type="text" />
            </div>

            <label htmlFor="createdAt">{t('common.createdAt')}</label>
            <div className="createdAt">
              {timeFormat(contentFrame.created_at)}
            </div>

            <label htmlFor="updateAt">{t('common.updatedAt')}</label>
            <div className="updatedAt">
              {timeFormat(contentFrame.updated_at)}
            </div>
          </div>

          <div className="item">
            {form.contents != null ?
              form.contents.map((e, i) => renderFrame(form, i, props)) : null}

            <div className="button" onClick={onAddClick}>
              <i className="fa fa-plus" />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContentFrameEditForm;
