import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { translate } from 'react-i18next';

import AuthorList from '../../components/author/AuthorList';
import AuthorEditContainer from './AuthorEditContainer';
import { fetchAuthors, createAuthor } from '../../actions/authorAction';
import { addLoading } from '../../actions/loadingAction';
import { addSuccessAlert } from '../../actions/alertAction';

class AuthorContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleNewClick = this.handleNewClick.bind(this);
    this.handleAuthorClick = this.handleAuthorClick.bind(this);
  }

  componentDidMount() {
    this.props.fetchAuthors();
  }

  handleNewClick() {
    this.props.router.push('/author');
    this.props.addLoading();
    this.props.createAuthor({ name: 'no name' }, (author) => {
      this.props.fetchAuthors();
      this.props.router.push(`/author/${author.id}`);
      this.props.addSuccessAlert(this.props.t('messages.createAuthorSuccess'));
    });
  }

  handleAuthorClick(id) {
    this.props.router.push(`/author/${id}`);
  }

  render() {
    const { authors, params, t } = this.props;

    return (
      <div className="authorContainer">
        <div className={classnames({ list: true, narrow: params.id != null })}>

          <div className="header">
            <div className="buttons right">
              <button
                type="button"
                onClick={this.handleNewClick}
              >
                <i className="fa fa-plus" aria-hidden="true" />
                Create Author
              </button>
            </div>

            <div className="clear" />
          </div>

          <div
            className="content"
            ref={(ref) => { this.authorListContent = ref; }}
          >
            <AuthorList
              id={params.id}
              authors={authors.authors}
              onClick={this.handleAuthorClick}
              t={t}
            />
          </div>

        </div>

        <div className="detail">
          {params.id != null ? <AuthorEditContainer id={params.id} /> : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    authors: state.authors,
    loading: state.loading,
  }
);

const mapDispatchToProps = dispatch => (
  {
    fetchAuthors: (form, authors, offset) => dispatch(fetchAuthors(form, authors, offset)),
    createAuthor: (form, callback) => dispatch(createAuthor(form, callback)),
    addLoading: () => dispatch(addLoading()),
    addSuccessAlert: value => dispatch(addSuccessAlert(value)),
  }
);

const authorContainer = withRouter(translate()(AuthorContainer));
export default connect(mapStateToProps, mapDispatchToProps)(authorContainer);
