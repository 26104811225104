import React from 'react';
import { Field } from 'redux-form';
import classnames from 'classnames';

import Config from '../../config/Config';
import { getName, timeFormat, dateFormat } from '../../util/util';

const renderFrame = (adFrame, i, props) => {
  const { onRemoveClick, onSearchClick, onStatusChange, t } = props;

  const mobileURL = adFrame.mobile_image_urls[i];
  const pcURL = adFrame.pc_image_urls[i];
  const isDisplay = adFrame.is_displays[i];

  return (
    <div className={classnames({ frame: true, disabled: !isDisplay })} key={i}>
      <div className="trash">
        <i
          className="fa fa-trash-o"
          aria-hidden="true"
          data-key={i}
          onClick={onRemoveClick}
        />
      </div>
      <div className="imageView">
        <div className="imageBlock" data-key={i} data-device="mobile" onClick={onSearchClick}>
          <span>mobile</span>
          <img
            className="image"
            src={mobileURL.length > 0 ? mobileURL : '/assets/images/no_image_small.png'}
            alt="adFrameMobileImage"
          />
        </div>

        <div className="imageBlock" data-key={i} data-device="pc" onClick={onSearchClick}>
          <span>pc</span>
          <img
            className="image"
            src={pcURL.length > 0 ? pcURL : '/assets/images/no_image_small.png'}
            alt="adFramePCImage"
          />
        </div>
      </div>
      <div>
        <label>{t('adFrame.isDisplays')}</label>
        <div className="select-wrap">
          <select defaultValue={isDisplay ? '1' : '0'} data-key={i} onChange={onStatusChange}>
            <option value="1">{t('common.adFrameIsDisplays.on')}</option>
            <option value="0">{t('common.adFrameIsDisplays.off')}</option>
          </select>
        </div>
      </div>
    </div>
  );
};

const AdFrameEditForm = (props) => {
  if (props.form == null || Object.keys(props.form).length === 0) {
    return null;
  }

  const {
    form,
    adFrame,
    onAddClick,
    t,
  } = props;

  return (
    <div className="adFrameEditForm">
      <form method="post">
        <div className="items">

          <div className="item">
            <label htmlFor="id">ID</label>
            <div className="id">
              {adFrame.id}
            </div>
          </div>

          <div className="item">
            <label htmlFor="name">{t('adFrame.name')}</label>
            <div className="name">
              <Field name="name" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="articleURL">{t('adFrame.articleURL')}</label>
            <div className="articleURL">
              <Field name="article_url" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="deliveryFromAt">{t('adFrame.deliveryFromAt')}</label>

            <div className="deliveryFromAt">
              <Field
                name="delivery_from_at"
                component="input"
                type="datetime-local"
                format={value => (value != null ? dateFormat(value, 'yyyy-mm-dd\'T\'HH:MM') || '' : '')}
              />
            </div>
          </div>

          <div className="item">
            <label htmlFor="deliveryToAt">{t('adFrame.deliveryToAt')}</label>

            <div className="deliveryToAt">
              <Field
                name="delivery_to_at"
                component="input"
                type="datetime-local"
                format={value => (value != null ? dateFormat(value, 'yyyy-mm-dd\'T\'HH:MM') || '' : '')}
              />
            </div>
          </div>

          <div className="item">
            <label htmlFor="deliveryStatus">{t('adFrame.deliveryStatus')}</label>
            <div className="deliveryStatus">
              {getName(Config.AD_FRAME_DELIVERY_STATUSES, adFrame.delivery_status)}
            </div>
          </div>

          <div className="item">
            <label htmlFor="adFrame">{t('adFrame.frame')}</label>
            <div>
              {form.is_displays != null ?
                form.is_displays.map((e, i) => renderFrame(form, i, props)) : null}
            </div>

            <div className="button" onClick={onAddClick}>
              <i className="fa fa-plus" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="createdAt">{t('common.createdAt')}</label>
            <div className="createdAt">
              {timeFormat(adFrame.created_at)}
            </div>
          </div>

          <div className="item">
            <label htmlFor="updateAt">{t('common.updatedAt')}</label>
            <div className="updatedAt">
              {timeFormat(adFrame.updated_at)}
            </div>
          </div>

        </div>
      </form>
    </div>
  );
};

export default AdFrameEditForm;
