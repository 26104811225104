import React from 'react';
import classnames from 'classnames';
import ReactHLS from 'react-hls';

import Config from '../../config/Config';
import { getName, timeFormat, dateFormat } from '../../util/util';

const getAuthor = (id, authors) => {
  if (authors == null) {
    return null;
  }

  return authors.find(e => e.id === id);
};

const getFeature = (key, features) => {
  if (features == null) {
    return null;
  }

  return features.find(e => e.key === key);
};

const getItemSentence = item => (
  `<div class="sentence">
    ${item.content}
  </div>`
);

const getItemHeading = item => (
  `<div class="heading">
    ${item.content}
  </div>`
);

const getItemImage = (item) => {
  let imageHTML;
  if (item.meta.link.url != null) {
    imageHTML =
      `<a href="${item.meta.link.url}" target="_blank" rel="noopener noreferrer">
          <img src="${item.content}" alt="content" />
      </a>`;
  } else {
    imageHTML = `<img src="${item.content}" alt="content" />`;
  }

  let creditHTML;
  if (item.meta.credit && !item.meta.credit.hide_credit) {
    if (item.meta.credit.url) {
      creditHTML =
        `<div class="credit">
          <a href=${item.meta.credit.url} target="_blank" rel="noopener noreferrer">
            ${item.meta.credit.name}
          </a>
        </div>`;
    } else {
      creditHTML =
        `<div class="credit">
          ${item.meta.credit.name}
        </div>`;
    }
  } else {
    creditHTML = '';
  }

  return (
    `<div class="${classnames({
      image: true,
      imageFullWidth: item.meta.is_full_width,
    })}">
      ${imageHTML}
      ${creditHTML}
    </div>`
  );
};

const getItemEmbed = item => (
  `<div class="embed">
    ${item.content}
  </div>`
);

const getItemCredit = (item) => {
  const contents = [];

  for (let i = 0; i < item.content.length; i += 1) {
    const c = item.content[i];

    const items = [];
    for (let j = 0; j < c.items.length; j += 1) {
      if (c.items[j].url != null) {
        items.push(`<a href="${c.items[j].url}" target="_blank" rel="noopener noreferrer">${c.items[j].name}</a>`);
      } else {
        items.push(`<span>${c.items[j].name}</span>`);
      }
    }

    contents.push(`<div class="content">
      ${c.type_value || ''} ${items.join(', ')}
    </div>`);
  }

  return (
    `<div class="credit">
      ${contents.join('')}
    </div>`
  );
};

const getItemProfile = item => (
  `<div class="profile">
    <div class="image">
      <img src=${item.meta.profile_image_url} alt="profile" />
    </div>
    <div class="content">
      <div class="name">${item.meta.author_name}</div>
      <div class="body">${item.content}</div>
    </div>
  </div>`
);

const getItemRelationAritcle = item => (
  `<div class="relationArticle">
    <div>
      <img src="${item.image_url}" alt="content" />
    </div>
    <div class="content">
      ${item.content}
    </div>
  </div>`
);

const getItemConversation = item => (
  `<div class="conversation ${item.meta.position === 'right' ? 'reverse' : ''}">
    <div class="speaker">
      <div class="speakerImage" style="background-image: url('${item.meta.image_url}')"></div>
    </div>
    <div class="content">
      <div class="speakerName">${item.meta.speaker_name}</div>
      <div class="contentInner">
        ${item.content}
      </div>
    </div>
  </div>`
);

const getItemInterview = item => (
  `<div class="interview">
    <div class="left">
      <img src="${item.meta.image_url}" alt="interview" />
    </div>
    <div class="right">
      ${item.content}
    </div>
  </div>`
);

const getItemPinterest = item => (
  item.content != null && item.content !== '' ?
    `<div class="pinterest">
      <div class="pinterest-container">
        <div class="img-pinterest-wrapper">
          <a href="${item.quote_url}" target="_blank" rel="noopener noreferrer">
            <div class="pin-it"></div>
          </a>
          <a href="${item.quote_url}" target="_blank" rel="noopener noreferrer">
            <img class="img-pinterest" src="${item.content}" alt="pinterest" />
          </a>
        </div>
        <div class="box-pinterest">
          <div class="meta-pinterest">
            <div class="pin-attribution">
              <a class="pin-original-site" href="${item.meta.original_site}" target="_blank" rel="noopener noreferrer">
                <span>&nbsp;Pinned from ${item.meta.domain}</span>
              </a>
            </div>
            <div class="credit-pinterest">
              <a href="${item.quote_url}" target="_blank" rel="noopener noreferrer">
                <div class="pinterest-logo"></div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>` : null
);

const getItemPagination = (item, hasTableOfContents) => (
  item.content != null && item.content !== '' && !hasTableOfContents ?
    `<div class="pagination">
      <div class="nextPage">
        次ページ
      </div>
      <div class="paginationTitle">
        ${item.content}
      </div>
    </div>` : null
);

const getItemReference = item => (
  item.content ?
    `<div class="reference">
      <div class="referenceContent">
        ${item.content}
      </div>
      <div class="referenceNameWrapper">
        <a class="referenceName" href="${item.meta.reference_url}" target="_blank" rel="noopener noreferrer">出典：${item.meta.reference_name}</a>
      </div>
    </div>` : null
);

const getItemTextbox = item => (
  item.content ?
    `<div class="textbox">
      <div class="textboxContent ${item.meta.position}">
        ${item.content}
      </div>
    </div>` : null
);

const getVideo = (article) => {
  if (article.video) {
    return <div dangerouslySetInnerHTML={{ __html: article.video }} />;
  } else if (article.mobile_header_video_url) {
    return (
      <ReactHLS url={article.mobile_header_video_url} />
    );
  }
  return null;
};

const getEyeCatch = (article) => {
  if (article.hide_eye_catch) {
    return null;
  } else if (article.original_ratio_eye_catch_url) {
    return (
      <img
        className="originalRatio"
        src={
          article.original_ratio_eye_catch_url != null
            ? article.original_ratio_eye_catch_url
            : '/assets/images/no_image.png'
        }
        alt="eyeCatch"
      />
    );
  }
  return (
    <img
      src={
        article.eye_catch_urls != null &&
        article.eye_catch_urls.length > 0 &&
        article.eye_catch_urls[0] != null &&
        article.eye_catch_urls[0].length > 0
          ? article.eye_catch_urls[0]
          : '/assets/images/no_image.png'
      }
      alt="eyeCatch"
    />
  );
};

const getItem = (articleItems, hasTableOfContents) => {
  if (articleItems == null) {
    return null;
  }

  const ret = [];

  for (let i = 0; i < articleItems.length; i += 1) {
    const item = articleItems[i];

    if (item.input_type === 'sentence') {
      ret.push(getItemSentence(item));
    } else if (item.input_type === 'heading') {
      ret.push(getItemHeading(item));
    } else if (item.input_type === 'image') {
      ret.push(getItemImage(item));
    } else if (item.input_type === 'embed') {
      ret.push(getItemEmbed(item));
    } else if (item.input_type === 'credit') {
      ret.push(getItemCredit(item));
    } else if (item.input_type === 'profile') {
      ret.push(getItemProfile(item));
    } else if (item.input_type === 'relation_article') {
      ret.push(getItemRelationAritcle(item));
    } else if (item.input_type === 'conversation') {
      ret.push(getItemConversation(item));
    } else if (item.input_type === 'interview') {
      ret.push(getItemInterview(item));
    } else if (item.input_type === 'pinterest') {
      ret.push(getItemPinterest(item));
    } else if (item.input_type === 'pagination') {
      ret.push(getItemPagination(item, hasTableOfContents));
    } else if (item.input_type === 'reference') {
      ret.push(getItemReference(item));
    } else if (item.input_type === 'textbox') {
      ret.push(getItemTextbox(item));
    }
  }

  return ret.join('');
};

const getItemBlock = (article, authors) => (
  <div className="itemBlock">
    <div className="item">
      <div className="bold">
        <img
          className="icon"
          src={getAuthor(article.author_id, authors) != null ? getAuthor(article.author_id, authors).icon : ''}
          alt={getAuthor(article.author_id, authors) != null ? getAuthor(article.author_id, authors).name : ''}
        />
        <span className="author">
          {getAuthor(article.author_id, authors) != null ? getAuthor(article.author_id, authors).name : ''}
        </span>
      </div>
    </div>

    <div className="item">
      <div className="postDate">{dateFormat(article.post_date) || '-'}</div>
    </div>
  </div>
);

const getTableOfContents = (article) => {
  if (!article.table_of_contents) return null;

  let selector;
  switch (article.table_of_contents) {
    case 'h2':
      selector = 'h2';
      break;
    case 'h2_h3':
      selector = 'h2, h3';
      break;
    default:
  }

  const parser = new DOMParser();

  const tableOfContents = [];
  for (let index = 0; index < article.article_items.length; index += 1) {
    const articleItem = article.article_items[index];
    if (articleItem.input_type === 'heading') {
      const element = parser.parseFromString(articleItem.content, 'text/html');
      const tag = element.querySelector(selector);
      if (tag && tag.innerText) {
        const tagName = tag.tagName.toLowerCase();
        const item = { tagName, content: tag.innerText };
        const lastItem = tableOfContents[tableOfContents.length - 1];
        if (tagName === 'h2') {
          tableOfContents.push(item);
        } else if (tagName === 'h3' && lastItem) {
          if (!lastItem.children) lastItem.children = [];
          lastItem.children.push(item);
        }
      }
    }
  }

  return (
    <div className="item">
      <div className="tableOfContents">
        <p>目次</p>
        <ul>
          {tableOfContents.map(item => (
            <li key={item.content}>
              <span>{item.content}</span>
              {item.children && (
                <ul>
                  {item.children.map(childItem => (
                    <li key={childItem.content}>
                      <span>{childItem.content}</span>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const ArticleDetail = ({ id, article, authors, features, t }) => {
  if (article == null || article.article_id !== Number(id)) {
    return null;
  }

  const headerVideo = getVideo(article);

  const articleTitle = article.titles != null && article.titles[0] != null ? article.titles[0] : 'no title';

  return (
    <div className="articleDetail">
      <div className="items">

        <div className="item">
          <div className="eyeCatch">
            {
              !headerVideo
                ? getEyeCatch(article)
                : headerVideo
            }
          </div>
        </div>

        <div className="item">
          <div
            className="title"
            dangerouslySetInnerHTML={
              article.title_with_br
                ? { __html: article.title_with_br }
                : null
            }
          >
            {
              article.title_with_br
                ? null
                : articleTitle
            }
          </div>
        </div>

        {getItemBlock(article, authors)}

        <div className="item">
          <div className="sponsored">
            {article.advertiser != null && article.advertiser !== '' ? `Sponsored by ${article.advertiser}` : ''}
          </div>
        </div>

        {getTableOfContents(article)}

        <div className="item">
          <div
            className="body"
            dangerouslySetInnerHTML={{
              __html: getItem(article.article_items, !!article.table_of_contents),
            }}
          />
        </div>
      </div>

      <div className="metaItems">
        <div className="metaItem">
          <label htmlFor="url">{t('article.url')}</label>
          <div className="url">
            {`${Config.WEB_ENDPOINT}/${article.article_id}/${article.custom_url}`}
          </div>
        </div>

        <div className="metaItem">
          <label htmlFor="brand">{t('article.brand')}</label>
          <div className="brand">
            {getName(Config.ARTICLE_BRANDS, article.brand)}
          </div>
        </div>

        <div className="metaItem">
          <label htmlFor="contentType">{t('article.contentType')}</label>
          <div className="contentType">
            {getName(Config.ARTICLE_CONTENT_TYPES, article.content_type)}
          </div>
        </div>

        <div className="metaItem">
          <label htmlFor="tags">{t('article.tags')}</label>
          <div className="tags">
            {article.tags != null ? article.tags.map(tag => <div className={classnames({ tag: true, selected: tag === article.tag_for_breadcrumbs })} key={`tags-${tag}`}>{tag}</div>) : ''}
          </div>
        </div>

        <div className="metaItem">
          <label htmlFor="seoTitle">{t('article.seoTitle')}</label>
          <div className="seoTitle">
            {article.seo_title}
          </div>
        </div>

        <div className="metaItem">
          <label htmlFor="referralTitle">{t('article.referralTitle')}</label>
          <div className="referralTitle">
            {article.referral_title}
          </div>
        </div>

        <div className="metaItem">
          <label htmlFor="socialTitle">{t('article.socialTitle')}</label>
          <div className="socialTitle">
            {article.social_title}
          </div>
        </div>

        <div className="metaItem">
          <label htmlFor="description">{t('article.description')}</label>
          <div className="description">
            {article.description}
          </div>
        </div>

        <div className="metaItem">
          <label htmlFor="twitterDescription">{t('article.twitterDescription')}</label>
          <div className="twitterDescription">
            {article.twitter_description}
          </div>
        </div>

        <div className="metaItem">
          <label htmlFor="facebookDescription">{t('article.facebookDescription')}</label>
          <div className="facebookDescription">
            {article.facebook_description}
          </div>
        </div>

        <div className="metaItem">
          <label htmlFor="advertiser">{t('article.advertiser')}</label>
          <div className="advertiser">
            {article.advertiser}
          </div>
        </div>

        <div className="metaItem">
          <label htmlFor="embedCode">{t('article.embedCode')}</label>
          <div className="embedCode">
            {article.embed_code}
          </div>
        </div>

        <div className="metaItem">
          <label htmlFor="feature">{t('article.features')}</label>
          <div className="feature">
            {
              article.features != null ? article.features.map(e => (
                getFeature(e, features) != null ? getFeature(e, features).name : ''
              )).join(', ') : ''
            }
          </div>
        </div>

        <div className="metaItem">
          <label htmlFor="videoEmbedCode">{t('article.video')}</label>
          <div className="videoEmbedCode">{article.video}</div>
        </div>

        <div className="metaItem">
          <label htmlFor="authorIDForAnalytics">{t('article.authorIDForAnalytics')}</label>
          <div className="authorIDForAnalytics">
            {article.authorIDForAnalytics}
            {getAuthor(article.author_id_for_analytics, authors) != null ? getAuthor(article.author_id_for_analytics, authors).name : ''}
          </div>
        </div>

        <div className="metaItem">
          <label htmlFor="status">{t('article.status')}</label>
          <div className="status">
            {getName(Config.ARTICLE_STATUSES, article.status)}
          </div>
        </div>

        <div className="metaItem">
          <label htmlFor="postDate">{t('article.postDate')}</label>
          <div className="postDate">
            {timeFormat(article.post_date) || '-'}
          </div>
        </div>

        <div className="metaItem">
          <label className={article.top_view_enable ? 'checked' : 'unchecked'} htmlFor="topViewEnable">{t('article.topViewEnable')}</label>
          <div className="topViewEnable">
            {article.top_view_enable}
          </div>
        </div>

        <div className="metaItem">
          <label className={article.abtest_enable ? 'checked' : 'unchecked'} htmlFor="abtestEnable">{t('article.abtestEnable')}</label>
          <div className="abtestEnable">
            {article.abtest_enable}
          </div>
        </div>

        <div className="metaItem">
          <label className={article.twitter_posting_enable ? 'checked' : 'unchecked'} htmlFor="twitterPostingEnable">{t('article.twitterPostingEnable')}</label>
          <div className="twitterPostingEnable">
            {article.twitter_posting_enable}
          </div>
        </div>

        <div className="metaItem">
          <label className={article.facebook_posting_enable ? 'checked' : 'unchecked'} htmlFor="facebookPostingEnable">{t('article.facebookPostingEnable')}</label>
          <div className="facebookPostingEnable">
            {article.facebook_posting_enable}
          </div>
        </div>

        <div className="metaItem">
          <label className={article.line_distribution_enable ? 'checked' : 'unchecked'} htmlFor="mediaDistributionLINE">{t('article.publishSettingPopup.mediaDistributionLINE')}</label>
          <div className="mediaDistributionLINE">
            {article.line_distribution_enable}
          </div>
        </div>

        <div className={classnames({ metaItem: true, none: article.content_type !== 'pr' })}>
          <label className={article.recommend_ad_enable ? 'checked' : 'unchecked'} htmlFor="recommendAdEnable">{t('article.recommendAdEnable')}</label>
          <div className="recommendAdEnable">
            {article.recommend_ad_enable}
          </div>
        </div>

        <div className={classnames({ metaItem: true, none: article.content_type !== 'pr' })}>
          <label className={article.frame_ad_enable ? 'checked' : 'unchecked'} htmlFor="frameAdEnable">{t('article.frameAdEnable')}</label>
          <div className="frameAdEnable">
            {article.frame_ad_enable}
          </div>
        </div>

        <div className={classnames({ metaItem: true, none: article.content_type !== 'pr' })}>
          <label htmlFor="adTitle">{t('article.adTitle')}</label>
          {article.ad_titles != null && article.ad_titles.map((e, i) => (
            <div className="adTitle" key={i}>
              {e}
            </div>
          ))}
        </div>

        <div className="metaItem">
          <label htmlFor="memo">{t('article.memo')}</label>
          <div className="memo">
            {article.memo}
          </div>
        </div>

        <div className="metaItem">
          <label htmlFor="createdAt">{t('common.createdAt')}</label>
          <div className="createdAt">
            {timeFormat(article.created_at)}
          </div>
        </div>

        <div className="metaItem">
          <label htmlFor="updateAt">{t('common.updatedAt')}</label>
          <div className="updatedAt">
            {timeFormat(article.updated_at)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleDetail;
