import { browserHistory } from 'react-router';
import Cookies from 'universal-cookie';
import request from 'superagent';

import Config from '../config/Config';

const createRequest = (req, callback) => (
  new Promise((resolve, reject) => {
    req.set('Authorization', `Bearer ${new Cookies().get('auth_token')}`)
      .set('Namespace', Config.NAMESPACE)
      .end((err, res) => {
        if (res == null) {
          callback('Connection timeout', { status: 500, text: 'Connection timeout.' }, resolve, reject);
        }

        console.log('API Response: %s %o', res.req != null ? res.req.url : null, {
          method: res.req != null ? res.req.method : null,
          url: res.req != null ? res.req.url : null,
          status: res.status,
          body: res.body,
        });
        callback(err, res, resolve, reject);
      });

    console.log('API Request: %s %o', req.url, {
      method: req.method,
      url: req.url,
    });
  })
);

const callbackProcess = (err, res, resolve, reject) => {
  if (err != null) {
    if (res.status === 401) {
      browserHistory.push('/login');
    } else {
      reject(res);
    }
  } else {
    resolve(res);
  }
};

export const fetchArticles = (form, offset) => {
  const query = Object.assign(form, { offset });
  const req = request.get(`${Config.API_ENDPOINT}/article`).query(query);

  return createRequest(req, (err, res, resolve, reject) => {
    if (err != null && res.status === 404) {
      resolve({ body: { articles: [] } });
    } else {
      callbackProcess(err, res, resolve, reject);
    }
  });
};

export const fetchArticlesWithCondition = (form, start) => {
  const sendForm = { type: 'cms', condition: form, start, limit: 50 };
  const req = request.post(`${Config.API_ENDPOINT}/article_with_condition`).send(sendForm);

  return createRequest(req, (err, res, resolve, reject) => {
    if (err != null && res.status === 404) {
      resolve({ body: { articles: [] } });
    } else {
      callbackProcess(err, res, resolve, reject);
    }
  });
};

export const fetchArticlesOfCalendar = (form) => {
  const req = request.post(`${Config.API_ENDPOINT}/article/calendar`).send(form);

  return createRequest(req, (err, res, resolve, reject) => {
    if (err != null && res.status === 404) {
      resolve({ body: { articles: [] } });
    } else {
      callbackProcess(err, res, resolve, reject);
    }
  });
};

export const fetchArticle = (id) => {
  const req = request.get(`${Config.API_ENDPOINT}/article/${id}`);
  return createRequest(req, callbackProcess);
};

export const fetchArticleHistories = (id) => {
  const req = request.get(`${Config.API_ENDPOINT}/article/${id}/history`);

  return createRequest(req, (err, res, resolve, reject) => {
    if (err != null && res.status === 404) {
      resolve({ body: { article_histories: [] } });
    } else {
      callbackProcess(err, res, resolve, reject);
    }
  });
};

export const fetchArticleDocx = (id) => {
  const req = request.get(`${Config.API_ENDPOINT}/article/${id}/docx`);
  return createRequest(req, callbackProcess);
};

export const createArticle = (form) => {
  const req = request.post(`${Config.API_ENDPOINT}/article`).send(form);
  return createRequest(req, callbackProcess);
};

export const updateArticle = (form) => {
  const req = request.put(`${Config.API_ENDPOINT}/article/${form.article_id}`).send(form);
  return createRequest(req, callbackProcess);
};

export const fetchImages = (form, offset) => {
  const query = Object.assign(form, { offset });
  const req = request.get(`${Config.API_ENDPOINT}/image`).query(query);

  return createRequest(req, (err, res, resolve, reject) => {
    if (err != null && res.status === 404) {
      resolve({ body: { images: [] } });
    } else {
      callbackProcess(err, res, resolve, reject);
    }
  });
};

export const fetchImage = (id) => {
  const req = request.get(`${Config.API_ENDPOINT}/image/${id}`);
  return createRequest(req, callbackProcess);
};

export const fetchImageGroups = () => {
  const req = request.get(`${Config.API_ENDPOINT}/image_group`);

  return createRequest(req, (err, res, resolve, reject) => {
    if (err != null && res.status === 404) {
      resolve({ body: { image_groups: [] } });
    } else {
      callbackProcess(err, res, resolve, reject);
    }
  });
};

export const fetchImageGroup = (id) => {
  const req = request.get(`${Config.API_ENDPOINT}/image_group/${id}`);
  return createRequest(req, callbackProcess);
};

export const fetchImageGroupImages = (id, offset) => {
  const req = request.get(`${Config.API_ENDPOINT}/image_group/${id}/image`).query({ offset });

  return createRequest(req, (err, res, resolve, reject) => {
    if (err != null && res.status === 404) {
      resolve({ body: { images: [] } });
    } else {
      callbackProcess(err, res, resolve, reject);
    }
  });
};

export const fetchImageTags = (prefix) => {
  const req = request.get(`${Config.API_ENDPOINT}/image_tag`).query({ prefix });

  return createRequest(req, (err, res, resolve, reject) => {
    if (err != null && res.status === 404) {
      resolve({ body: { image_tags: [] } });
    } else {
      callbackProcess(err, res, resolve, reject);
    }
  });
};

export const fetchImageSources = (prefix) => {
  const req = request.get(`${Config.API_ENDPOINT}/image_source`).query({ prefix });

  return createRequest(req, (err, res, resolve, reject) => {
    if (err != null && res.status === 404) {
      resolve({ body: { image_sources: [] } });
    } else {
      callbackProcess(err, res, resolve, reject);
    }
  });
};

export const createImage = (form) => {
  const req = request.post(`${Config.API_ENDPOINT}/image`).send(form);
  return createRequest(req, callbackProcess);
};

export const updateImage = (form) => {
  const req = request.put(`${Config.API_ENDPOINT}/image/${form.image_id}`).send(form);
  return createRequest(req, callbackProcess);
};

export const deleteImage = (id) => {
  const req = request.del(`${Config.API_ENDPOINT}/image/${id}`);
  return createRequest(req, callbackProcess);
};

export const createImageGroup = (form) => {
  const req = request.post(`${Config.API_ENDPOINT}/image_group`).send(form);
  return createRequest(req, callbackProcess);
};

export const updateImageGroup = (form) => {
  const req = request.put(`${Config.API_ENDPOINT}/image_group/${form.id}`).send(form);
  return createRequest(req, callbackProcess);
};

export const createVoice = (form) => {
  const req = request.post(`${Config.API_ENDPOINT}/voice`).send(form);
  return createRequest(req, callbackProcess);
};

export const fetchAdFrames = (form, offset) => {
  const query = Object.assign(form, { offset });
  const req = request.get(`${Config.API_ENDPOINT}/ad_frame`).query(query);

  return createRequest(req, (err, res, resolve, reject) => {
    if (err != null && res.status === 404) {
      resolve({ body: { ad_frames: [] } });
    } else {
      callbackProcess(err, res, resolve, reject);
    }
  });
};

export const fetchAdFrame = (id) => {
  const req = request.get(`${Config.API_ENDPOINT}/ad_frame/${id}`);
  return createRequest(req, callbackProcess);
};

export const createAdFrame = (form) => {
  const req = request.post(`${Config.API_ENDPOINT}/ad_frame`).send(form);
  return createRequest(req, callbackProcess);
};

export const updateAdFrame = (form) => {
  const req = request.put(`${Config.API_ENDPOINT}/ad_frame/${form.id}`).send(form);
  return createRequest(req, callbackProcess);
};

export const deleteAdFrame = (id) => {
  const req = request.del(`${Config.API_ENDPOINT}/ad_frame/${id}`);
  return createRequest(req, callbackProcess);
};

export const fetchContentFrames = () => {
  const req = request.get(`${Config.API_ENDPOINT}/content_frame`);

  return createRequest(req, (err, res, resolve, reject) => {
    if (err != null && res.status === 404) {
      resolve({ body: { content_frames: [] } });
    } else {
      callbackProcess(err, res, resolve, reject);
    }
  });
};

export const fetchContentFrame = (id) => {
  const req = request.get(`${Config.API_ENDPOINT}/content_frame/${id}`);
  return createRequest(req, callbackProcess);
};

export const createContentFrame = (form) => {
  const req = request.post(`${Config.API_ENDPOINT}/content_frame`).send(form);
  return createRequest(req, callbackProcess);
};

export const updateContentFrame = (form) => {
  const req = request.put(`${Config.API_ENDPOINT}/content_frame/${form.id}`).send(form);
  return createRequest(req, callbackProcess);
};

export const deleteContentFrame = (id) => {
  const req = request.del(`${Config.API_ENDPOINT}/content_frame/${id}`);
  return createRequest(req, callbackProcess);
};

export const fetchAuthors = () => {
  const req = request.get(`${Config.API_ENDPOINT}/author`);

  return createRequest(req, (err, res, resolve, reject) => {
    if (err != null && res.status === 404) {
      resolve({ body: { authors: [] } });
    } else {
      callbackProcess(err, res, resolve, reject);
    }
  });
};

export const fetchAuthor = (id) => {
  const req = request.get(`${Config.API_ENDPOINT}/author/${id}`);
  return createRequest(req, callbackProcess);
};

export const createAuthor = (form) => {
  const req = request.post(`${Config.API_ENDPOINT}/author`).send(form);
  return createRequest(req, callbackProcess);
};

export const updateAuthor = (form) => {
  const req = request.put(`${Config.API_ENDPOINT}/author/${form.id}`).send(form);
  return createRequest(req, callbackProcess);
};

export const deleteAuthor = (id) => {
  const req = request.del(`${Config.API_ENDPOINT}/author/${id}`);
  return createRequest(req, callbackProcess);
};

export const fetchCategories = () => {
  const req = request.get(`${Config.API_ENDPOINT}/category`);

  return createRequest(req, (err, res, resolve, reject) => {
    if (err != null && res.status === 404) {
      resolve({ body: { categories: [] } });
    } else {
      callbackProcess(err, res, resolve, reject);
    }
  });
};

export const fetchCategory = (id) => {
  const req = request.get(`${Config.API_ENDPOINT}/category/${id}`);
  return createRequest(req, callbackProcess);
};

export const createCategory = (form) => {
  const req = request.post(`${Config.API_ENDPOINT}/category`).send(form);
  return createRequest(req, callbackProcess);
};

export const updateCategory = (form) => {
  const req = request.put(`${Config.API_ENDPOINT}/category/${form.id}`).send(form);
  return createRequest(req, callbackProcess);
};

export const deleteCategory = (id) => {
  const req = request.del(`${Config.API_ENDPOINT}/category/${id}`);
  return createRequest(req, callbackProcess);
};

export const fetchTags = (form) => {
  const req = request.get(`${Config.API_ENDPOINT}/tag`).query(form);

  return createRequest(req, (err, res, resolve, reject) => {
    if (err != null && res.status === 404) {
      resolve({ body: { tags: [] } });
    } else {
      callbackProcess(err, res, resolve, reject);
    }
  });
};

export const fetchTag = (id) => {
  const req = request.get(`${Config.API_ENDPOINT}/tag/${id}`);
  return createRequest(req, callbackProcess);
};

export const fetchFixedTags = (prefix) => {
  const req = request.get(`${Config.API_ENDPOINT}/tag`).query({ prefix });

  return createRequest(req, (err, res, resolve, reject) => {
    if (err != null && res.status === 404) {
      resolve({ body: { tags: [] } });
    } else {
      callbackProcess(err, res, resolve, reject);
    }
  });
};

export const createTag = (form) => {
  const req = request.post(`${Config.API_ENDPOINT}/tag`).send(form);
  return createRequest(req, callbackProcess);
};

export const updateTag = (form) => {
  const req = request.put(`${Config.API_ENDPOINT}/tag/${form.id}`).send(form);
  return createRequest(req, callbackProcess);
};

export const deleteTag = (id) => {
  const req = request.del(`${Config.API_ENDPOINT}/tag/${id}`);
  return createRequest(req, callbackProcess);
};

export const fetchFeatures = (query) => {
  const req = request.get(`${Config.API_ENDPOINT}/feature`).query(query);

  return createRequest(req, (err, res, resolve, reject) => {
    if (err != null && res.status === 404) {
      resolve({ body: { features: [] } });
    } else {
      callbackProcess(err, res, resolve, reject);
    }
  });
};

export const fetchFeature = (id) => {
  const req = request.get(`${Config.API_ENDPOINT}/feature/${id}`);
  return createRequest(req, callbackProcess);
};

export const createFeature = (form) => {
  const req = request.post(`${Config.API_ENDPOINT}/feature`).send(form);
  return createRequest(req, callbackProcess);
};

export const updateFeature = (form) => {
  const req = request.put(`${Config.API_ENDPOINT}/feature/${form.id}`).send(form);
  return createRequest(req, callbackProcess);
};

export const deleteFeature = (id) => {
  const req = request.del(`${Config.API_ENDPOINT}/feature/${id}`);
  return createRequest(req, callbackProcess);
};

export const fetchMedias = () => {
  const req = request.get(`${Config.API_ENDPOINT}/media`);

  return createRequest(req, (err, res, resolve, reject) => {
    if (err != null && res.status === 404) {
      resolve({ body: { medias: [] } });
    } else {
      callbackProcess(err, res, resolve, reject);
    }
  });
};

export const fetchMedia = (id) => {
  const req = request.get(`${Config.API_ENDPOINT}/media/${id}`);
  return createRequest(req, callbackProcess);
};

export const createMedia = (form) => {
  const req = request.post(`${Config.API_ENDPOINT}/media`).send(form);
  return createRequest(req, callbackProcess);
};

export const updateMedia = (form) => {
  const req = request.put(`${Config.API_ENDPOINT}/media/${form.id}`).send(form);
  return createRequest(req, callbackProcess);
};

export const deleteMedia = (id) => {
  const req = request.del(`${Config.API_ENDPOINT}/media/${id}`);
  return createRequest(req, callbackProcess);
};

export const fetchMediaDistributions = () => {
  const req = request.get(`${Config.API_ENDPOINT}/media_distribution`);

  return createRequest(req, (err, res, resolve, reject) => {
    if (err != null && res.status === 404) {
      resolve({ body: { media_distributions: [] } });
    } else {
      callbackProcess(err, res, resolve, reject);
    }
  });
};

export const fetchMediaDistribution = (id) => {
  const req = request.get(`${Config.API_ENDPOINT}/media_distribution/${id}`);
  return createRequest(req, callbackProcess);
};

export const createMediaDistribution = (form) => {
  const req = request.post(`${Config.API_ENDPOINT}/media_distribution`).send(form);
  return createRequest(req, callbackProcess);
};

export const updateMediaDistribution = (form) => {
  const req = request.put(`${Config.API_ENDPOINT}/media_distribution/${form.id}`).send(form);
  return createRequest(req, callbackProcess);
};

export const updateMediaDistributionsBulk = (form) => {
  const req = request.put(`${Config.API_ENDPOINT}/media_distribution_bulk_update`).send(form);
  return createRequest(req, callbackProcess);
};

export const deleteMediaDistribution = (id) => {
  const req = request.del(`${Config.API_ENDPOINT}/media_distribution/${id}`);
  return createRequest(req, callbackProcess);
};

export const fetchUsers = (form, offset) => {
  const query = Object.assign(form, { offset });
  const req = request.get(`${Config.API_ENDPOINT}/user`).query(query);

  return createRequest(req, (err, res, resolve, reject) => {
    if (err != null && res.status === 404) {
      resolve({ body: { users: [] } });
    } else {
      callbackProcess(err, res, resolve, reject);
    }
  });
};

export const fetchUser = (id) => {
  const req = request.get(`${Config.API_ENDPOINT}/user/${id}`);
  return createRequest(req, callbackProcess);
};

export const createUser = (form) => {
  const req = request.post(`${Config.API_ENDPOINT}/user`).send(form);
  return createRequest(req, callbackProcess);
};

export const updateUser = (form) => {
  const req = request.put(`${Config.API_ENDPOINT}/user/${form.id}`).send(form);
  return createRequest(req, callbackProcess);
};

export const deleteUser = (id) => {
  const req = request.del(`${Config.API_ENDPOINT}/user/${id}`);
  return createRequest(req, callbackProcess);
};

export const fetchPinterest = (id) => {
  const req = request.get(`${Config.API_ENDPOINT}/pinterest/${id}`);

  return createRequest(req, (err, res, resolve, reject) => {
    if (err != null && res.status === 404) {
      resolve({ body: { pinterest: {} } });
    } else {
      callbackProcess(err, res, resolve, reject);
    }
  });
};

export const fetchPermissions = (token) => {
  const req = request.get(`${Config.API_ENDPOINT}/permission`).query({ token });
  return createRequest(req, callbackProcess);
};

export const fetchRoles = () => {
  const req = request.get(`${Config.API_ENDPOINT}/role`);
  return createRequest(req, callbackProcess);
};

export const createEditExclusion = (form) => {
  const req = request.post(`${Config.API_ENDPOINT}/edit_exclusion`).send(form);

  return createRequest(req, (err, res, resolve, reject) => {
    if (err != null && res.status === 404) {
      resolve({ body: { edit_exclusions: [] } });
    } else {
      callbackProcess(err, res, resolve, reject);
    }
  });
};

export const deleteEditExclusion = (form) => {
  const req = request.del(`${Config.API_ENDPOINT}/edit_exclusion`).send(form);

  return createRequest(req, (err, res, resolve, reject) => {
    if (err != null && res.status === 404) {
      resolve({ body: { edit_exclusions: [] } });
    } else {
      callbackProcess(err, res, resolve, reject);
    }
  });
};
