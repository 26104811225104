import React from 'react';
import classnames from 'classnames';
import Config from '../../config/Config';
import { getName } from '../../util/util';

const renderFeature = (feature, props) => {
  const {
    id,
    onClick,
    onPreviewClick,
  } = props;

  let imageURL = '/assets/images/no_image_small.png';
  if (feature.og_image != null && feature.og_image.length > 0) {
    imageURL = feature.og_image;
  } else if (feature.tablet_header_image_url != null
    && feature.tablet_header_image_url.length > 0) {
    imageURL = feature.tablet_header_image_url;
  } else if (feature.header_logo != null && feature.header_logo.length > 0) {
    imageURL = feature.header_logo;
  }

  const classNames = { status: true };
  classNames[feature.status] = true;

  return (
    <li
      className={classnames({ item: true, selected: Number(id) === feature.id })}
      key={`feature-${feature.id}`}
    >
      <div className="list-content" onClick={() => onClick(feature.id)}>
        <img src={imageURL} alt="headerLogo" />

        <div className="description">
          <div>
            <span className="name">
              {`${feature.name} (${feature.key})`}
            </span>
            <span className={classnames(classNames)}>
              {getName(Config.FEATURE_STATUSES, feature.status)}
            </span>
          </div>

          <p className="title">
            {feature.title}
          </p>

          <div className="toggle">
            <button onClick={onPreviewClick}>
              <i className="fa fa-eye" aria-hidden="true" />
              Preview
            </button>
          </div>
          <div>
            <span className="description">
              {feature.description}
            </span>
          </div>
        </div>

      </div>
    </li>
  );
};

const FeatureList = (props) => {
  const { features } = props;

  if (features == null) {
    return null;
  }

  return (
    <div className="featureList">
      <ul>
        {features.map(feature => renderFeature(feature, props))}
      </ul>
    </div>
  );
};

export default FeatureList;
