import { addSuccessAlert, addErrorAlert } from './alertAction';
import { removeLoading } from './loadingAction';
import {
  createVoice as createVoiceAPI,
} from '../api/apiClient';
import i18n from '../config/i18n';

export const CREATE_VOICE_START = 'CREATE_VOICE_START';
export const CREATE_VOICE_COMPLETE = 'CREATE_VOICE_COMPLETE';

const createVoiceStart = () => ({ type: CREATE_VOICE_START });

const createVoiceComplete = res => ({
  type: CREATE_VOICE_COMPLETE,
  payload: res,
});

export const createVoice = (form, callback) => (
  (dispatch) => {
    dispatch(createVoiceStart());

    createVoiceAPI(form).then((res) => {
      dispatch(createVoiceComplete(res.body));
      dispatch(removeLoading());
      dispatch(addSuccessAlert(i18n.t('messages.createVoiceSuccess')));

      if (callback != null) {
        callback(res.body.url);
      }
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(removeLoading());
      dispatch(addErrorAlert(i18n.t('messages.createVoiceError', { message: res.text })));
    });
  }
);
