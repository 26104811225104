import {
  FETCH_FEATURES_START,
  FETCH_FEATURES_COMPLETE,
} from '../actions/featureAction';

const featuresReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_FEATURES_START:
      return state;

    case FETCH_FEATURES_COMPLETE:
      return action.payload;

    default:
      return state;
  }
};

export default featuresReducer;
