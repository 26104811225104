import React from 'react';

import MediaDistributionList from './MediaDistributionList';
import ArticleSearchPopupContainer from '../../containers/article/ArticleSearchPopupContainer';

const MediaDistribution = (props) => {
  const {
    mediaDistributions,
    medias,
    onFeedMediaChange,
    onRemoveClick,
    onArticleAddClick,
    onArticleSubmitClick,
    onArticleCancelClick,
    isDisplayArticleSearchPopup,
    t,
  } = props;

  const articleSearchPopup = isDisplayArticleSearchPopup ?
    (<ArticleSearchPopupContainer
      onSubmit={onArticleSubmitClick}
      onCancel={onArticleCancelClick}
    />)
    : null;

  return (
    <div className="mediaDistribution">
      {articleSearchPopup}

      <div className="items">
        <div className="item">
          <label htmlFor="deleteDistributions">{t('mediaDistribution.deleteDistributions')}</label>
          <div className="deleteDistributions">
            <MediaDistributionList
              mediaDistributions={mediaDistributions}
              medias={medias}
              onFeedMediaChange={onFeedMediaChange}
              onRemoveClick={onRemoveClick}
              t={t}
            />

            <div className="button" onClick={onArticleAddClick}>
              <i className="fa fa-plus" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaDistribution;
