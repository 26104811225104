import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import classnames from 'classnames';
import Autosuggest from 'react-autosuggest';
import { translate } from 'react-i18next';

import ImageList from '../../components/image/ImageList';
import ImageEditContainer from './ImageEditContainer';
import ImageGroupCreatePopupContainer from './ImageGroupCreatePopupContainer';
import ImageSearchPopupContainer from './ImageSearchPopupContainer';
import ImageUploadPopupContainer from './ImageUploadPopupContainer';
import ImageSearchForm from '../../components/image/ImageSearchForm';
import { fetchImages, fetchImageTags, updateImage } from '../../actions/imageAction';
import { fetchImageGroups } from '../../actions/imageGroupAction';
import { addLoading } from '../../actions/loadingAction';

class ImageContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleScroll = this.handleScroll.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.handleImageGroupChange = this.handleImageGroupChange.bind(this);
    this.handleImageGroupCreateClick = this.handleImageGroupCreateClick.bind(this);
    this.handleImageGroupCreateSubmit = this.handleImageGroupCreateSubmit.bind(this);
    this.handleImageGroupCreateCancel = this.handleImageGroupCreateCancel.bind(this);
    this.handleImageSearchClick = this.handleImageSearchClick.bind(this);
    this.handleImageSearchSubmit = this.handleImageSearchSubmit.bind(this);
    this.handleImageSearchCancel = this.handleImageSearchCancel.bind(this);
    this.handleImageUploadClick = this.handleImageUploadClick.bind(this);
    this.handleImageUploadSubmit = this.handleImageUploadSubmit.bind(this);
    this.handleImageUploadCancel = this.handleImageUploadCancel.bind(this);
    this.handleImageClick = this.handleImageClick.bind(this);
    this.handleImageRemoveClick = this.handleImageRemoveClick.bind(this);

    this.handleTagsChange = this.handleTagsChange.bind(this);
    this.handleTagsRenderInput = this.handleTagsRenderInput.bind(this);

    this.refImageGroup = this.refImageGroup.bind(this);
    this.refTagsInput = this.refTagsInput.bind(this);

    this.state = {
      prevTagsValue: '',
      isDisplayImageGroupCreatePopup: false,
      isDisplayImageSearchPopup: false,
      isDisplayImageUploadPopup: false,
    };
  }

  componentDidMount() {
    this.props.fetchImages({ image_group_id: this.props.params.groupID });
    this.props.fetchImageGroups();
  }

  componentWillUpdate(nextProps) {
    const current = this.props.location.pathname;
    const next = nextProps.location.pathname;

    if (current !== next && next === '/image') {
      this.props.fetchImages();
    }
  }

  handleTagsChange(tags) {
    this.props.updateForm('tags', tags);
    setTimeout(() => this.handleSearchSubmit(), 0);
  }

  handleTagsRenderInput({ addTag, value, ref, onChange }) {
    const handleChange = (e, { newValue }) => {
      const tags = this.props.imageSearchForm.tags || [];

      if (newValue.indexOf(',') >= 0 || newValue.indexOf('  ') >= 0) {
        const tag = newValue.replace(/,/g, '').trim();

        if (tag.length > 0 && !tags.includes(tag)) {
          this.handleTagsChange(tags.concat([tag]));
        }

        this.tagsInput.setState({ tag: '' });
      } else {
        onChange(e);
      }
    };

    const handleKeyUp = (e) => {
      const tags = this.props.imageSearchForm.tags || [];
      const newValue = e.target.value.replace(/,/g, '').trim();

      if (e.key === 'Backspace') {
        if (this.state.prevTagsValue === '') {
          this.handleTagsChange(tags.slice(0, tags.length - 1));
        }
      }

      this.setState({ prevTagsValue: newValue });
    };

    const handleKeyPress = (e) => {
      const tags = this.props.imageSearchForm.tags || [];
      const newValue = e.target.value.replace(/,/g, '').trim();

      if (e.key === 'Enter') {
        if (newValue.length > 0 && !tags.includes(newValue)) {
          this.handleTagsChange(tags.concat([newValue]));
        }

        this.tagsInput.setState({ tag: '' });
      }

      this.setState({ prevTagsValue: newValue });
    };

    const handleBlur = () => {
      this.tagsInput.setState({ tag: '' });
    };

    return (
      <Autosuggest
        ref={ref}
        suggestions={this.props.suggestTags.tags || []}
        shouldRenderSuggestions={v => v != null && v.trim().length > 0}
        getSuggestionValue={suggestion => suggestion}
        renderSuggestion={suggestion => <span>{suggestion}</span>}
        inputProps={{
          value,
          placeholder: this.props.t('image.tagsPlaceholder'),
          onChange: handleChange,
          onKeyUp: handleKeyUp,
          onKeyPress: handleKeyPress,
          onBlur: handleBlur,
        }}
        onSuggestionSelected={(e, { suggestion }) => {
          addTag(suggestion);
        }}
        onSuggestionsFetchRequested={(e) => {
          this.props.fetchImageTags(e.value);
        }}
        onSuggestionsClearRequested={() => {}}
      />
    );
  }

  handleScroll() {
    const { images, imageSearchForm, params } = this.props;

    if (params.groupID != null ?
      Number(params.groupID) !== images.image_group_id : this.imageGroup.value !== '0') {
      return;
    }

    const imageListBody = this.imageListContent;
    const elementHeight = Math.max(
      imageListBody.offsetHeight,
      imageListBody.clientHeight,
      imageListBody.offsetHeight,
    );
    if (
      images.offset != null &&
      imageListBody.scrollTop + elementHeight >= imageListBody.scrollHeight
    ) {
      const form = imageSearchForm != null ?
        Object.assign(imageSearchForm, { image_group_id: this.imageGroup.value }) : null;

      this.props.fetchImages(
        form,
        images.images,
        images.offset,
      );
    }
  }

  handleSearchSubmit() {
    const form = this.props.imageSearchForm != null ?
      Object.assign(this.props.imageSearchForm, { image_group_id: this.imageGroup.value }) : null;

    this.props.fetchImages(form);
    this.imageListContent.scrollTop = 0;
  }

  handleImageGroupChange(e) {
    this.props.fetchImages({ image_group_id: e.target.options[e.target.selectedIndex].value });

    this.props.router.push(`/image/${e.target.options[e.target.selectedIndex].value}`);

    this.imageListContent.scrollTop = 0;

    this.props.updateForm('tags', null);
    this.props.updateForm('image_type', null);
    this.props.updateForm('is_my_image', null);
  }

  handleImageGroupCreateClick() {
    this.setState({ isDisplayImageGroupCreatePopup: true });
  }

  handleImageGroupCreateSubmit(imageGroup) {
    this.props.fetchImageGroups();
    this.props.router.push(`/image/${imageGroup.id}`);
    this.setState({ isDisplayImageGroupCreatePopup: false });

    this.props.updateForm('tags', null);
    this.props.updateForm('image_type', null);
    this.props.updateForm('is_my_image', null);
  }

  handleImageGroupCreateCancel() {
    this.setState({ isDisplayImageGroupCreatePopup: false });
  }

  handleImageSearchClick() {
    this.setState({ isDisplayImageSearchPopup: true });
  }

  handleImageSearchSubmit(e) {
    if (e.image_group_ids == null) {
      e.image_group_ids = [Number(this.props.params.groupID)];
    } else if (!e.image_group_ids.includes(Number(this.props.params.groupID))) {
      e.image_group_ids.push(Number(this.props.params.groupID));
    }

    this.props.updateImage(e, () => {
      this.props.fetchImages({ image_group_id: this.imageGroup.value });
      this.setState({ isDisplayImageSearchPopup: false });

      this.imageListContent.scrollTop = 0;

      this.props.updateForm('tags', null);
      this.props.updateForm('image_type', null);
      this.props.updateForm('is_my_image', null);
    });
  }

  handleImageSearchCancel() {
    const form = this.props.imageSearchForm != null ?
      Object.assign(this.props.imageSearchForm, { image_group_id: this.imageGroup.value }) : null;

    this.props.fetchImages(form);
    this.setState({ isDisplayImageSearchPopup: false });
  }

  handleImageUploadClick() {
    this.setState({ isDisplayImageUploadPopup: true });
  }

  handleImageUploadSubmit() {
    this.props.fetchImages({ image_group_id: this.imageGroup.value });
    this.setState({ isDisplayImageUploadPopup: false });

    this.imageListContent.scrollTop = 0;

    this.props.updateForm('tags', null);
    this.props.updateForm('image_type', null);
    this.props.updateForm('is_my_image', null);
  }

  handleImageUploadCancel() {
    this.setState({ isDisplayImageUploadPopup: false });
  }

  handleImageClick(id) {
    this.props.router.push(`/image/${this.props.params.groupID || 0}/${id}`);
  }

  handleImageRemoveClick(e) {
    e.stopPropagation();
    const i = Number(e.target.dataset.key);
    const { images } = this.props.images;

    const groupIDs = [];
    images[i].image_group_ids.forEach((id) => {
      if (id !== Number(this.props.params.groupID)) {
        groupIDs.push(id);
      }
    });
    images[i].image_group_ids = groupIDs;

    this.props.updateImage(images[i], () => {
      const form = this.props.imageSearchForm != null ?
        Object.assign(this.props.imageSearchForm, { image_group_id: this.imageGroup.value }) : null;

      this.props.fetchImages(form);
    });

    this.props.router.push(`/image/${this.props.params.groupID || 0}`);
  }

  refImageGroup(ref) {
    this.imageGroup = ref;
  }

  refTagsInput(ref) {
    this.tagsInput = ref;
  }

  render() {
    const { images, imageGroups, suggestTags, imageSearchForm, params, t } = this.props;

    const imageGroupCreatePopup = this.state.isDisplayImageGroupCreatePopup ?
      (<ImageGroupCreatePopupContainer
        onSubmit={this.handleImageGroupCreateSubmit}
        onCancel={this.handleImageGroupCreateCancel}
      />)
      : null;

    const imageSearchPopup = this.state.isDisplayImageSearchPopup ?
      (<ImageSearchPopupContainer
        onSubmit={this.handleImageSearchSubmit}
        onCancel={this.handleImageSearchCancel}
      />)
      : null;

    const imageUploadPopup = this.state.isDisplayImageUploadPopup ?
      (<ImageUploadPopupContainer
        onSubmit={this.handleImageUploadSubmit}
        onCancel={this.handleImageUploadCancel}
        imageGroupID={this.imageGroup.value}
      />)
      : null;

    return (
      <div className="imageContainer">
        <div className={classnames({ list: true, narrow: params.imageID != null })}>
          <div className="imageSearchGroup">
            <div className="groupName">
              <label htmlFor="imageGroup">{t('image.imageGroup')}</label>
              <div className="select-wrap">
                <select
                  ref={this.refImageGroup}
                  value={params.groupID || '0'}
                  onChange={this.handleImageGroupChange}
                >
                  <option value="0" key="0">{t('common.selectAll')}</option>
                  {imageGroups.image_groups != null ?
                    imageGroups.image_groups
                      .map(e => <option value={e.id} key={e.id}>{e.name}</option>) : null
                  }
                </select>
              </div>
            </div>
            <div className="buttons">
              <button
                type="button"
                onClick={this.handleImageGroupCreateClick}
              >
                <i className="fa fa-plus" aria-hidden="true" />
                Create Group
              </button>

              <button
                className={classnames({ none: params.groupID != null ? params.groupID === '0' : true })}
                type="button"
                onClick={this.handleImageSearchClick}
              >
                <i className="fa fa-search" aria-hidden="true" />
                Add Image
              </button>

              <button
                type="button"
                onClick={this.handleImageUploadClick}
              >
                <i className="fa fa-upload" aria-hidden="true" />
                Upload Image
              </button>
            </div>
          </div>
          <div className="header">
            {imageGroupCreatePopup}
            {imageSearchPopup}
            {imageUploadPopup}
            {/* Pass suggestTag to call ImageSearchForm#render() */}
            <ImageSearchForm
              onSubmit={this.handleSearchSubmit}
              refTagsInput={this.refTagsInput}
              onTagsRenderInput={this.handleTagsRenderInput}
              imageSearchForm={imageSearchForm}
              suggestTags={suggestTags}
              onTagsChange={this.handleTagsChange}
              t={t}
            />
          </div>
          <div
            className="content"
            ref={(ref) => { this.imageListContent = ref; }}
            onScroll={this.handleScroll}
          >
            {images.image_group_id === (params.groupID == null ? 0 : Number(params.groupID)) ?
              (<ImageList
                id={params.imageID}
                imageGroupID={this.imageGroup != null ? this.imageGroup.value : '0'}
                images={images.images}
                onClick={this.handleImageClick}
                onRemoveClick={this.handleImageRemoveClick}
              />) : null}
          </div>
        </div>
        <div className={classnames({ detail: true, none: params.imageID == null })}>
          {params.imageID != null ? <ImageEditContainer id={params.imageID} /> : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    imageGroups: state.imageGroups,
    images: state.images,
    suggestTags: state.imageTags,
    imageSearchForm: state.form.imageSearchForm != null ?
      state.form.imageSearchForm.values || {} : {},
    loading: state.loading,
  }
);

const mapDispatchToProps = dispatch => (
  {
    fetchImageGroups: () => dispatch(fetchImageGroups()),
    fetchImages: (form, images, offset) => dispatch(fetchImages(form, images, offset)),
    fetchImageTags: value => dispatch(fetchImageTags(value)),
    updateImage: (form, callback) => dispatch(updateImage(form, callback)),
    updateForm: (key, value) => dispatch(change('imageSearchForm', key, value)),
    addLoading: () => dispatch(addLoading()),
  }
);

const imageContainer = withRouter(translate()(ImageContainer));
export default connect(mapStateToProps, mapDispatchToProps)(imageContainer);
