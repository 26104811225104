const i18nEN = {
  layout: {
    sideMenu: {
      menus: {
        article: 'Articles',
        calendar: 'Calendar',
        image: 'Images',
        adFrame: 'Ad frames',
        contentFrame: 'Content frames',
        author: 'Authors',
        category: 'Categories',
        tag: 'Tags',
        feature: 'Features',
        media: 'Media',
        mediaDistribution: 'Media distribution',
        user: 'User accounts',
      },
    },
  },
  login: {
    footerHelp: 'For any bug reports or suggestions please contact #system via Slack.',
  },
  article: {
    eyeCatchURLs: 'Eye Catch image',
    originalRatioEyeCatchURL: 'Eye Catch image for original ratio',
    hideEyeCatch: 'Hide Eye Catch image in the article page',
    headerVideoURLs: 'Eye Catch Videos',
    mobileVideoEyeCatchURLPlaceholder: 'Please input video url for mobile.',
    pcVideoEyeCatchURLPlaceholder: 'Please input video url for PC.',
    titles: 'Headline',
    titleWithBr: 'Title with br (max number of words in one line is 17. New line code is <br>)',
    titleWithBrPlaceholder: 'How travel evolves with tech<br>Interview with Airbnb’s design head',
    author: 'Author',
    category: 'Category',
    tableOfContents: {
      title: 'Table of Contents',
      h2: 'Display only h2',
      h2_h3: 'Display h2 and h3',
    },
    url: 'URL',
    copyUrl: 'Copy Preview URLs',
    video: 'Embed code for video article(YouTube)',
    brand: 'Brand',
    contentType: 'Content type',
    tags: 'Tags',
    seoTitle: 'SEO title',
    referralTitle: 'Referral title',
    socialTitle: 'Social title',
    description: 'Description',
    twitterDescription: 'Twitter description',
    facebookDescription: 'Facebook description',
    advertiser: 'Advertiser',
    adTitle: 'Frame Ad Title',
    recommendAdEnable: 'Register TOP/Recommend Ad when this article published',
    frameAdEnable: 'Register Frame Ad when this article published',
    embedCode: 'Embed code',
    features: 'Features',
    voice: 'Voice',
    geotags: 'Geotags',
    status: 'Status',
    postDate: 'Post date',
    topViewEnable: 'Display at the top',
    abtestEnable: 'Enable AB Test',
    twitterPostingEnable: 'Enable Twitter auto posting',
    facebookPostingEnable: 'Enable Facebook auto posting',
    mediaDistributionDisable: 'Disable media distribution',
    mediaDistributionExceptLINE: 'Media distribution except LINE',
    mediaDistributionLINE: 'Enable LINE posting',
    memo: 'Memo',
    authorIDForAnalytics: 'Author for Analytics',
    eyeCatchURLsTooltip: 'When AB Test is enabled, the displayed Eye Catch is randomly selected from the main/sub images.<br>When disabled only the main image is used.',
    originalRatioEyeCatchURLTooltip: 'This image will be displayed with original aspect ratio in the artice page instead of the image above',
    eyeCatchURLsToMainTooltip: 'Set displayed image as the main image.',
    titlesTooltip: 'When AB Test is enabled, the headline is randomly selected from the main/sub headlines.<br>When disabled only the main is used.',
    titlesToMainTooltip: 'Set as main headline',
    titleWithBrTooltip: 'Input title with new line using br tag. This is used for a title in the article page',
    tagsTooltip: 'Input tags for tag page and searching articles.<br>The tag you click and select is used for breadcrumbs in the article page.',
    seoTitleTooltip: 'The title that will be used in search engine results (like Google).<br>If over 70 characters the title may be truncated.<br>If unused the headline will be used.<br>It’s more effective to include keywords you want picked up by search in the headline and body.',
    referralTitleTooltip: 'Title for news apps like Smartnews, Gunosy<br>If over 70 characters it may be truncated<br>If unused the headline will be used.',
    socialTitleTooltip: 'The title that will be used in social media like Facebook, Twitter.<br>If over 25 characters the title may be truncated.<br>If unused the headline will be used.',
    descriptionTooltip: 'The description that will be used in search engine results (like Google).<br>If over 250 characters it may be truncated.<br>For more search engine hits, it’s more effective to put the keywords in the headline or body.',
    adTitleTooltip: 'Used for Frame Ad Title.<br>Please enter up to 3 lines, up to 30 characters per line.',
    recommendAdEnableTooltip: 'If checked, TOP/Recommendation and Frame ads will be registered with Google Ad Manager at the time of publication.<br>It will be registered soon after publication, but the delivery start will be 00:00 three days later.',
    frameAdEnableTooltip: 'If checked, framed frame ads will be registered with Google Ad Manager when published.<br>It will be registered and distributed soon after publishing.',
    descriptionCount: 'Characters: {{count}}',
    descriptionCountAlert: 'Please enter no more than 256 characters.',
    adTitleCount: 'Lines: {{row}}, Characters: {{count}}',
    adTitleCountAlert: 'Please enter no more than 30 characters.',
    adTitleRowsAlert: 'Please enter no more than 3 lines.',
    tagsPlaceholder: 'Input tags',
    sentence: 'Paragraph',
    heading: 'Subhead',
    image: 'Image',
    embed: 'Embed',
    etc: 'Other',
    imageURLPlaceholder: 'Input URL link',
    imageAltPlaceholder: 'Input Alt text',
    embedPlaceholder: 'Input embed code',
    creditTypePlaceholder: 'Input attribution type',
    creditNamePlaceholder: 'Input attribution name',
    creditURLPlaceholder: 'Input attribution URL',
    creditAddPlaceholder: 'Add attribution',
    authorNamePlaceholder: 'Input author',
    conversation: {
      positionLeft: 'Align Left',
      positionRight: 'Align Right',
    },
    textbox: {
      positionLeft: 'Align Left',
      positionCenter: 'Align Center',
      positionRight: 'Align Right',
    },
    profileImageURLPlaceholder: 'Input profile image URL',
    profileSetStructuredData: 'Set structued data',
    pinterestURLPlaceholder: 'Input Pinterest URL or ID',
    paginationTitlePlaceholder: 'Input text for next page',
    referenceNamePlaceholder: 'Input reference',
    referenceURLPlaceholder: 'Input reference URL',
    insertSection: 'Insert new section',
    editExclusion: '{{userName}} is currently editing',
    articleHistory: 'Article history',
    searchForm: {
      word: 'Search',
      wordPlaceholder: 'Input words or article ID and hit Enter',
      status: 'Status',
      brand: 'Brand',
      isMyArticle: 'Only display articles I created',
      calendarArticleStatus: 'Status',
      calendarIsWorldtopics: 'WORLD TOPICS',
      calendarIsPR: 'PR',
    },
    searchPopup: {
      searchWord: 'Input words or article ID and hit Enter',
    },
    geotagPopup: {
      url: 'Input google maps URL',
    },
    publishSettingPopup: {
      popTitle: 'Publish Settings',
      distributionOption: 'Distribution Option',
      twitterPostingEnable: 'Enable Twitter auto posting',
      facebookPostingEnable: 'Enable Facebook auto posting',
      topViewEnable: 'Display at the top',
      mediaDistributionExceptLINE: 'Media distribution except LINE',
      mediaDistributionLINE: 'Enable LINE posting',
      abtestEnable: 'Enable AB Test',
      postDate: 'Post date',
      editDate: 'Edit date',
      cancel: 'Cancel',
      save: 'Save',
      saveAsReserved: 'Save as reserved',
    },
    statusBar: {
      posted: 'Posted',
      reserved: 'Reserved',
      deleted: 'Deleted',
      report: 'Report',
    },
    header: {
      delete: 'Delete',
      download: 'Download',
      history: 'History',
      preview: 'Preview',
      overwrite: 'Overwrite',
      saveAsDraft: 'Save as draft',
      returnToDraft: 'Return to Draft',
      publishSettings: 'PublishSettings',
    },
  },
  image: {
    url: 'URL',
    tags: 'Tags',
    userName: 'User name',
    imageType: 'Image type',
    license: 'License',
    source: 'Image site name',
    sourceURL: 'Image site URL',
    imageGroup: 'Image group',
    imageGroupName: 'Image group name',
    tagsPlaceholder: 'Input tags',
    credit: 'Credit',
    creditName: 'Credit name',
    creditURL: 'Credit URL',
    hideCredit: 'Hide credit',
    isFullWidth: 'Display image with device width',
    searchForm: {
      tags: 'Tag',
      imageType: 'Image type',
      license: 'License',
      isMyImage: 'Only display images I’ve uploaded',
    },
    searchPopup: {
      searchWord: 'Input tag and hit Enter',
    },
    uploadFile: 'Upload file',
    draggableImage: 'Drag and drop image file here',
  },
  voice: {
    uploadFile: 'Upload file',
    draggableVoice: 'Drag and drop audio file here',
  },
  adFrame: {
    name: 'Name',
    articleURL: 'Article URL',
    deliveryFromAt: 'Live start date',
    deliveryToAt: 'Live end date',
    deliveryStatus: 'Status',
    isDisplays: 'Display',
    mobileImageURLs: 'Image URL (mobile)',
    pcImageURLs: 'Image URL (desktop)',
    searchWord: 'Search word',
    searchWordPlaceholder: 'Input search word',
    deliveryAt: 'Live duration',
    frame: 'Frame',
  },
  contentFrame: {
    name: 'Name',
    key: 'Key',
    contentTypes: 'Content type',
    imageURLPlaceholder: 'Input image target URL',
    editSection: {
      campaignRemark: 'Ad frames that are live will be randomly displayed',
    },
  },
  author: {
    name: 'Name',
    key: 'Key',
    title: 'Title',
    profile: 'Profile',
    instagramUrl: 'Instagram URL',
    twitterUrl: 'Twitter URL',
    facebookUrl: 'Facebook URL',
    youtubeUrl: 'YouTube URL',
    icon: 'Icon image',
  },
  category: {
    name: 'Name',
    key: 'Key',
    description: 'Description',
    seoName: 'Name (SEO)',
    mobileHeaderImageURL: 'Header image URL (mobile)',
    pcHeaderImageURL: 'Header image URL (desktop)',
    topViewEnable: 'Display at the top',
    sortKey: 'Display order',
  },
  tag: {
    name: 'Name',
    description: 'Description',
    parentID: 'Parent tag',
    childIDs: 'Child tag',
    listFlg: 'Display list',
    exactMatchFlg: 'Display articles with exact match',
    searchTag: 'Input tag and hit Enter',
  },
  feature: {
    name: 'Name',
    key: 'Key',
    status: 'Feature status',
    canonicalURL: 'Canonical URL',
    mediaDistribution: 'Media to display this feature',
    advertiserName: 'Advertiser name',
    advertiserImageURL: 'Advertiser image URL',
    title: 'Title',
    seoTitle: 'SEO title',
    ogTitle: 'OG title',
    description: 'Description',
    seoDescription: 'SEO description',
    headerLogo: 'Header logo',
    mobileHeaderImageURL: 'Header image URL (mobile)',
    tabletHeaderImageURL: 'Header image URL (tablet)',
    pcHeaderImageURL: 'Header image URL (desktop)',
    mobileHeaderVideoURL: 'Header video URL (mobile)',
    tabletHeaderVideoURL: 'Header video URL (tablet)',
    pcHeaderVideoURL: 'Header video URL (desktop)',
    ogImage: 'OG image',
    keywords: 'Keywords',
    metaKeywords: 'Meta keywords',
    contentType: 'Content type',
    contentValue: 'Content value',
    contentTemplate: 'Content template',
    contentTemplateDefault: 'Default',
    contentTemplateFlat: 'With image × flat',
    contentTemplateNoImage: 'No header image',
    headerType: 'Header type',
    headerTypeDefault: 'Default header',
    headerTypeNone: 'No Header',
    headerTypeTransparent: 'Transparent',
    backgroundColor: 'Background color',
    fontColor: 'Font color',
    articlePageText: 'Leading Text',
    articleFeatureBannerMobile: 'Imgae URL in feature artile(Mobile)',
    articleFeatureBannerPC: 'Imgae URL in feature artile(PC)',
    bannerBottomMobile: 'Banner bottom image URL in feature page(Mobile)',
    bannerBottomPC: 'Banner bottom image URL in feature page(PC)',
    bannerBottomLinkURL: 'Banner bottom link URL in feature page',
    bannerBottomOpenTab: 'Open banner link in new tab',
    featureListImageURL: 'Image URL for feature list',
    featureListViewEnable: 'Display at the feature list page',
    featureStatuses: {
      draft: 'Draft',
      posted: 'Posted',
    },
  },
  media: {
    name: 'Name',
    key: 'Key',
    feedCategories: 'Distribution category',
    feedTags: 'Distribution tag',
    isTagExclude: 'Exclude selected tags',
    feedContentTypes: 'Distribution content type',
    feedEmbedTypes: 'Distribution embed type',
    embedCode: 'Embed code',
    adContent: 'Ad content',
    tagsPlaceholder: 'Input tags',
  },
  mediaDistribution: {
    deleteDistributions: 'Remove',
    feedMedia: 'Distribution',
  },
  user: {
    name: 'Name',
    email: 'Email address',
    role: 'Role',
    lastAccessAt: 'Last accessed',
  },
  common: {
    createdAt: 'Created At',
    updatedAt: 'Updated At',
    selectAll: 'All',
    match: 'MATCH',
    notMatch: 'NOT MATCH',
    main: 'Main',
    sub: 'Sub{{index}}',
    download: 'Download',
    new: 'Create new',
    dateFormat: 'mm/dd/yyyy',
    timeFormat: 'mm/dd/yyyy HH:MM:ss',
    dailyQuotes: [
      'Change perspectives to bring about a “!!”: Not being bound to the conventions or standards of the world, we aspire to deliver a “realization” or “discovery” that usurps people’s expectations. Make an impactful impression that becomes a person’s new life recipe.',
      'To be a chef, sommelier and producer:  Be nimble and flexible, seeing things from a variety of perspectives. Accurately assess the positives and negatives, the best and most efficient way to execute.',
      'Break through doing what you like: You enjoy it so you’re willing to give it your all. Yet you chew on all the inputs and experiences, knowing the meat of the situation. Make tomorrow more delectable with honing of your craft.',
      'Know the five flavors, creating umami: It’s not enough to know one way of accomplishing. By knowing the extremities of views we can hone our own understanding to bring out the best.',
      'Observe, dig deep: Proper preparation isn’t enough. Listening to what’s simmering under the surface, have a nose for what information is important, keeping an eye on the situation.',
      'Pushing past the limit: Don’t be satisfied with “adequate” and give it another shake. Sometimes the heat makes the pay off all the more worth it.',
      'Prepare quickly and adroitly, serve while it’s hot: Start quicker than anyone, mash on the throttle. But make a smooth landing, surveying the land for the perfect opportunity.',
    ],
    repdigitQuote: 'Today is Repdigit day. More hungry, More foolish!',
    articleContentTypes: {
      curation: 'Curation',
      original: 'Original',
      mediaPartner: 'Media partner',
      pr: 'PR',
      contribution: 'Contribution',
      event: 'Event',
    },
    articleStatuses: {
      draft: 'Draft',
      reserved: 'Reserved',
      posted: 'Posted',
      deleted: 'Deleted',
    },
    articleInputTypes: {
      credit: 'Attribution',
      profile: 'Profile',
      relationArticle: 'Related article',
      interview: 'Interview',
      conversation: 'Conversation',
      pinterest: 'Pinterest',
      pagination: 'Pagination',
      reference: 'Reference',
      textbox: 'Text Box',
    },
    imageImageTypes: {
      eyeCatch: 'Eye Catch',
      article: 'Inside article',
      material: 'Assets',
      spc: 'FRAME',
    },
    imageLicenses: {
      royaltyFree: 'Royalty-free',
      rightsManaged: 'Rights managed',
      cc0: 'cc0',
      originalForArticle: 'Original image (for article)',
      licensed: 'Licensed image',
      originalForAd: 'Original image (advertising)',
      providedByAdvertiser: 'Provided by advertiser',
      editorial: 'Editorial',
      stockPhoto: 'Stock photo',
    },
    adFrameDeliveryStatuses: {
      start: 'Live',
      stop: 'Stopped',
    },
    adFrameIsDisplays: {
      on: 'On',
      off: 'Off',
    },
    contentFrameContentTypes: {
      image: 'Image',
      article: 'Article',
      embed: 'Embed',
      campaign: 'Ad frame',
    },
    mediaEmbedTypes: {
      facebook: 'Facebook',
      twitter: 'Twitter',
      instagram: 'Instagram',
      vimeo: 'Vimeo',
      youtube: 'Youtube',
      other: 'Other',
    },
  },
  messages: {
    authFailure: 'User authentication failed.',
    fetchArticleError: 'Article retrieval failed. {{message}}',
    fetchArticleHistoryError: 'Article history retrieval failed. {{message}}',
    fetchArticleDocxError: 'Docx file retrieval failed. {{message}}',
    createArticleSuccess: 'Article created.',
    createArticleError: 'Article creation failed. {{message}}',
    updateArticleSuccess: 'Article updated.',
    updateArticleError: 'Article update failed. {{message}}',
    deleteArticleSuccess: 'Article deleted.',
    deleteArticleError: 'Article delete failed. {{message}}',
    draftArticleSuccess: 'Article drafted.',
    draftArticleError: 'Article draft failed. {{message}}',
    fetchImageError: 'Image retrieval failed. {{message}}',
    fetchImageSourceError: 'Image site retrieval failed. {{message}}',
    fetchImageGroupError: 'Image group retrieval failed. {{message}}',
    createImageSuccess: 'Image uploaded.',
    createImageError: 'Image upload failed. {{message}}',
    createImageValidateError: 'Upload file not specified.',
    updateImageSuccess: 'Image updated.',
    updateImageError: 'Image update failed. {{message}}',
    deleteImageSuccess: 'Image deleted.',
    deleteImageError: 'Image deletion failed. {{message}}',
    createImageGroupSuccess: 'Image group created.',
    createImageGroupError: 'Image group creation failed. {{message}}',
    updateImageGroupSuccess: 'Image group updated.',
    updateImageGroupError: 'Image group update failed. {{message}}',
    createVoiceSuccess: 'Voice uploaded.',
    createVoiceError: 'Voice upload failed. {{message}}',
    createVoiceValidateError: 'Upload file not specified.',
    fetchAdFrameError: 'Ad frame retrieval failed. {{message}}',
    createAdFrameSuccess: 'Ad frame created.',
    createAdFrameError: 'Ad frame creation failed. {{message}}',
    updateAdFrameSuccess: 'Ad frame updated.',
    updateAdFrameError: 'Ad frame update failed. {{message}}',
    deleteAdFrameSuccess: 'Ad frame deleted.',
    deleteAdFrameError: 'Ad frame deletion failed. {{message}}',
    fetchContentFrameError: 'Content frame retrieval failed. {{message}}',
    createContentFrameSuccess: 'Content frame created.',
    createContentFrameError: 'Content frame creation failed. {{message}}',
    updateContentFrameSuccess: 'Content frame updated.',
    updateContentFrameError: 'Content frame update failed. {{message}}',
    deleteContentFrameSuccess: 'Content frame deleted.',
    deleteContentFrameError: 'Content frame deletion failed. {{message}}',
    fetchAuthorError: 'Author retrieval failed. {{message}}',
    createAuthorSuccess: 'Author created.',
    createAuthorError: 'Author creation failed. {{message}}',
    updateAuthorSuccess: 'Author updated.',
    updateAuthorError: 'Author update failed. {{message}}',
    deleteAuthorSuccess: 'Author deleted.',
    deleteAuthorError: 'Author deletion failed. {{message}}',
    fetchCategoryError: 'Category retrieval failed. {{message}}',
    createCategorySuccess: 'Category created.',
    createCategoryError: 'Category creation failed. {{message}}',
    updateCategorySuccess: 'Category updated.',
    updateCategoryError: 'Category update failed. {{message}}',
    deleteCategorySuccess: 'Category deleted.',
    deleteCategoryError: 'Category deletion failed. {{message}}',
    fetchTagError: 'Tag retrieval failed. {{message}}',
    createTagSuccess: 'Tag created.',
    createTagError: 'Tag creation failed. {{message}}',
    updateTagSuccess: 'Tag updated.',
    updateTagError: 'Tag update failed. {{message}}',
    deleteTagSuccess: 'Tag deleted.',
    deleteTagError: 'Tag deletion failed. {{message}}',
    fetchFeatureError: 'Feature retrieval failed. {{message}}',
    createFeatureSuccess: 'Feature created.',
    createFeatureError: 'Feature creation failed. {{message}}',
    updateFeatureSuccess: 'Feature updated.',
    updateFeatureError: 'Feature update failed. {{message}}',
    deleteFeatureSuccess: 'Feature deleted.',
    deleteFeatureError: 'Feature deletion failed. {{message}}',
    fetchMediaError: 'Media retrieval failed. {{message}}',
    createMediaSuccess: 'Media created.',
    createMediaError: 'Media creation failed. {{message}}',
    updateMediaSuccess: 'Media updated.',
    updateMediaError: 'Media update failed. {{message}}',
    deleteMediaSuccess: 'Media deleted.',
    deleteMediaError: 'Media deletion failed. {{message}}',
    fetchMediaDistributionError: 'Media distribution retrieval failed. {{message}}',
    createMediaDistributionSuccess: 'Media distribution created.',
    createMediaDistributionError: 'Media distribution creation failed. {{message}}',
    updateMediaDistributionSuccess: 'Media distribution updated.',
    updateMediaDistributionError: 'Media distribution update failed. {{message}}',
    deleteMediaDistributionSuccess: 'Media distribution deleted.',
    deleteMediaDistributionError: 'Media distribution deletion failed. {{message}}',
    fetchUserError: 'User retrieval failed. {{message}}',
    createUserSuccess: 'User created.',
    createUserError: 'User creation failed. {{message}}',
    updateUserSuccess: 'User updated.',
    updateUserError: 'User update failed. {{message}}',
    deleteUserSuccess: 'User deleted.',
    deleteUserError: 'User deletion failed. {{message}}',
    fetchPinterestError: 'Pinterest retrieval failed. {{message}}',
    fetchRoleError: 'Role retrieval failed. {{message}}',
    warningEditingSection: 'Cannot Save while an edit box is still open. Click OK or Cancel to confirm the changes to the edit box prior to saving.',
    warningPastDateReservation: 'Cannot schedule post to a past date.',
    warningRequirePostDate: 'Please set the post date.',
    warningRequireImageCredit: 'Please set image credits to publish the article created after 2018/6/1.',
    discardEditingArticle: 'Contents not saved. Do you wish to discard changes?',
    restoreFromLocalStorage: 'Autosaved data exists. Do you wish to restore?<br>If you click Cancel the autosaved data will be deleted.',
    googleMapsURLError: 'URL is invalid. Please enter the URL of Google Maps.',
    warningInvalidVideoEmbedCode: 'Embed code for video article is invalid. Please copy correct code in YouTube',
    warningTagTreeInvalid: 'Cannot set the same tag as parent tag and child tag.',
    setTagForBreadcrumbs: 'Set “{{message}}” as breadcrumb tag',
    warningNonManagedTagForBreadcrumbs: 'Cannot set non managed tag as breadcrumb tag',
    warningNonAdvertiser: 'Please set Advertiser to save PR article',
    warningEditingAdContent: 'Cannot set Advertiser, Frame Ad and TOP/Recommend Ad without PR Article. Please set PR into Content type.',
    warningNonAdTitle: 'Please set Frame Ad Title to save PR article.',
    warningAdTitleNumExceeded: '{{Count}} characters have been entered on the {{row}} line of the Frame Ad Title. Please set again with 30 characters or less.',
    warningAdTitleRowExceeded: 'Frame Ad Title is set 4 or more lines. Please set again with 3 lines or less.',
    previewUrlCopied: 'Preview URL was copied.',
  },
};

export default i18nEN;
