import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { translate } from 'react-i18next';

import ContentFrameList from '../../components/contentFrame/ContentFrameList';
import ContentFrameEditContainer from './ContentFrameEditContainer';
import { fetchContentFrames, createContentFrame } from '../../actions/contentFrameAction';
import { addLoading } from '../../actions/loadingAction';
import { addSuccessAlert } from '../../actions/alertAction';

class ContentFrameContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleContentFrameClick = this.handleContentFrameClick.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
  }

  componentDidMount() {
    this.props.fetchContentFrames();
  }

  handleContentFrameClick(id) {
    this.props.router.push(`/content_frame/${id}`);
  }

  handleAddClick() {
    const form = {
      name: 'no name',
    };

    this.props.addLoading();
    this.props.createContentFrame(form, (contentFrame) => {
      this.props.fetchContentFrames();
      this.props.router.push(`/content_frame/${contentFrame.id}`);
      this.props.addSuccessAlert(this.props.t('messages.createContentFrameSuccess'));
    });
  }

  render() {
    const { contentFrames, params } = this.props;

    return (
      <div className="contentFrameContainer">
        <div className={classnames({ list: true, narrow: params.id != null })}>

          <div className="content">
            <ContentFrameList
              id={params.id}
              contentFrames={contentFrames.content_frames}
              onClick={this.handleContentFrameClick}
            />

            <div className="button" onClick={this.handleAddClick}>
              <i className="fa fa-plus" />
            </div>
          </div>

        </div>

        <div className="detail">
          {params.id != null ? <ContentFrameEditContainer id={params.id} /> : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    contentFrames: state.contentFrames,
    loading: state.loading,
  }
);

const mapDispatchToProps = dispatch => (
  {
    fetchContentFrames: () => dispatch(fetchContentFrames()),
    createContentFrame: (form, callback) => dispatch(createContentFrame(form, callback)),
    addLoading: () => dispatch(addLoading()),
    addSuccessAlert: value => dispatch(addSuccessAlert(value)),
  }
);

const contentFrameContainer = withRouter(translate()(ContentFrameContainer));
export default connect(mapStateToProps, mapDispatchToProps)(contentFrameContainer);
