import { addSuccessAlert, addErrorAlert } from './alertAction';
import { removeLoading } from './loadingAction';
import {
  fetchAdFrames as fetchAdFramesAPI,
  fetchAdFrame as fetchAdFrameAPI,
  createAdFrame as createAdFrameAPI,
  updateAdFrame as updateAdFrameAPI,
  deleteAdFrame as deleteAdFrameAPI,
} from '../api/apiClient';
import i18n from '../config/i18n';

export const FETCH_AD_FRAMES_START = 'FETCH_AD_FRAMES_START';
export const FETCH_AD_FRAMES_COMPLETE = 'FETCH_AD_FRAMES_COMPLETE';
export const FETCH_AD_FRAME_START = 'FETCH_AD_FRAME_START';
export const FETCH_AD_FRAME_COMPLETE = 'FETCH_AD_FRAME_COMPLETE';
export const CREATE_AD_FRAME_START = 'CREATE_AD_FRAME_START';
export const CREATE_AD_FRAME_COMPLETE = 'CREATE_AD_FRAME_COMPLETE';
export const UPDATE_AD_FRAME_START = 'UPDATE_AD_FRAME_START';
export const UPDATE_AD_FRAME_COMPLETE = 'UPDATE_AD_FRAME_COMPLETE';
export const DELETE_AD_FRAME_START = 'DELETE_AD_FRAME_START';
export const DELETE_AD_FRAME_COMPLETE = 'DELETE_AD_FRAME_COMPLETE';

const fetchAdFramesStart = () => ({ type: FETCH_AD_FRAMES_START });

const fetchAdFramesComplete = (preAdFrames, res) => {
  res.ad_frames = preAdFrames.concat(res.ad_frames);

  return {
    type: FETCH_AD_FRAMES_COMPLETE,
    payload: res,
  };
};

const fetchAdFrameStart = () => ({ type: FETCH_AD_FRAME_START });

const fetchAdFrameComplete = res => ({
  type: FETCH_AD_FRAME_COMPLETE,
  payload: res,
});

const createAdFrameStart = () => ({ type: CREATE_AD_FRAME_START });

const createAdFrameComplete = res => ({
  type: CREATE_AD_FRAME_COMPLETE,
  payload: res,
});

const updateAdFrameStart = () => ({ type: UPDATE_AD_FRAME_START });

const updateAdFrameComplete = res => ({
  type: UPDATE_AD_FRAME_COMPLETE,
  payload: res,
});

const deleteAdFrameStart = () => ({ type: DELETE_AD_FRAME_START });

const deleteAdFrameComplete = () => ({
  type: DELETE_AD_FRAME_COMPLETE,
  payload: {},
});

export const fetchAdFrames = (form = {}, preAdFrames = [], offset = '') => (
  (dispatch) => {
    dispatch(fetchAdFramesStart());

    fetchAdFramesAPI(form, offset).then((res) => {
      dispatch(fetchAdFramesComplete(preAdFrames, res.body));
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(addErrorAlert(i18n.t('messages.fetchAdFrameError', { message: res.text })));
    });
  }
);

export const fetchAdFrame = id => (
  (dispatch) => {
    dispatch(fetchAdFrameStart());

    fetchAdFrameAPI(id).then((res) => {
      dispatch(fetchAdFrameComplete(res.body));
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(addErrorAlert(i18n.t('messages.fetchAdFrameError', { message: res.text })));
    });
  }
);

export const createAdFrame = (form, callback) => (
  (dispatch) => {
    dispatch(createAdFrameStart());

    createAdFrameAPI(form).then((res) => {
      dispatch(createAdFrameComplete(res.body));
      dispatch(removeLoading());

      if (callback != null) {
        callback(res.body);
      } else {
        dispatch(addSuccessAlert(i18n.t('messages.createAdFrameSuccess')));
      }
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(removeLoading());
      dispatch(addErrorAlert(i18n.t('messages.createAdFrameError', { message: res.text })));
    });
  }
);

export const updateAdFrame = (form, callback) => (
  (dispatch) => {
    dispatch(updateAdFrameStart());

    updateAdFrameAPI(form).then((res) => {
      dispatch(updateAdFrameComplete(res.body));
      dispatch(removeLoading());
      dispatch(addSuccessAlert(i18n.t('messages.updateAdFrameSuccess')));

      if (callback != null) {
        callback(res.body);
      }
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(removeLoading());
      dispatch(addErrorAlert(i18n.t('messages.updateAdFrameError', { message: res.text })));
    });
  }
);

export const deleteAdFrame = id => (
  (dispatch) => {
    dispatch(deleteAdFrameStart());

    deleteAdFrameAPI(id).then(() => {
      dispatch(deleteAdFrameComplete());
      dispatch(removeLoading());
      dispatch(addSuccessAlert(i18n.t('messages.deleteAdFrameSuccess')));
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(removeLoading());
      dispatch(addErrorAlert(i18n.t('messages.deleteAdFrameError', { message: res.text })));
    });
  }
);
