import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, change } from 'redux-form';
import { translate } from 'react-i18next';

import UserEditForm from '../../components/user/UserEditForm';

class UserEditFormContainer extends React.Component {
  render() {
    if (Number(this.props.id) !== this.props.user.id) {
      return null;
    }

    const {
      userEditForm,
      initialValues,
      user,
      roles,
      saveButton,
      closeButton,
      onSubmit,
      onCancel,
      t,
    } = this.props;

    if (saveButton != null && saveButton.onclick == null) {
      saveButton.onclick = () => onSubmit(this.props.userEditForm);
    }

    if (closeButton != null && closeButton.onclick == null) {
      closeButton.onclick = () => onCancel();
    }

    return (
      <div className="userEditFormContainer">
        <UserEditForm
          form={userEditForm}
          initialValues={initialValues}
          user={user}
          roles={roles.roles}
          t={t}
        />
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    roles: state.roles,
    userEditForm: state.form.userEditForm != null ? state.form.userEditForm.values : null,
    initialValues: state.user,
  }
);

const mapDispatchToProps = dispatch => (
  {
    updateForm: (key, value) => dispatch(change('userEditForm', key, value)),
  }
);

const userEditFormContainer = reduxForm({ form: 'userEditForm', enableReinitialize: true })(translate()(UserEditFormContainer));
export default connect(mapStateToProps, mapDispatchToProps)(userEditFormContainer);
