import React from 'react';
import { Field, reduxForm } from 'redux-form';

import { hasPermission } from '../../util/util';
import Config from '../../config/Config';

const ArticleSearchForm = ({ permissions, onSubmit, t }) => (
  <div className="articleSearchForm">
    <form>
      {/* Dummy field for preventing submit by enter key */}
      <input type="text" className="none" />

      <div className="item">
        <label htmlFor="word">{t('article.searchForm.word')}</label>
        <div className="word">
          <Field
            name="word"
            component="input"
            type="text"
            placeholder={t('article.searchForm.wordPlaceholder')}
            onBlur={onSubmit}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.target.blur();
              }
            }}
          />
        </div>
      </div>

      <div className="item">
        <label htmlFor="status">{t('article.searchForm.status')}</label>
        <div className="status">
          <div className="select-wrap">
            <Field
              name="status"
              component="select"
              onChange={() => {
                setTimeout(() => onSubmit(), 0);
              }}
            >
              <option value="">{t('common.selectAll')}</option>
              {
                Config.ARTICLE_STATUSES
                  .map(e => <option value={e.id} key={e.id}>{t(e.i18nKey)}</option>)
              }
            </Field>
          </div>
        </div>
      </div>

      <div className="item">
        <label htmlFor="brand">{t('article.searchForm.brand')}</label>
        <div className="brand">
          <div className="select-wrap">
            <Field
              name="brand"
              component="select"
              onChange={() => {
                setTimeout(() => onSubmit(), 0);
              }}
            >
              <option value="">{t('common.selectAll')}</option>
              {
                Config.ARTICLE_BRANDS
                  .map(e => <option value={e.id} key={e.id}>{t(e.name)}</option>)
              }
            </Field>
          </div>
        </div>
      </div>

      <div className="item">
        {
          hasPermission(Config.PERMISSIONS.article_all_read, permissions) ?
            <div className="isMyArticle">
              <Field
                name="is_my_article"
                id="isMyArticle"
                component="input"
                type="checkbox"
                onChange={() => {
                  setTimeout(() => onSubmit(), 0);
                }}
              />
              <label className="checkbox" htmlFor="isMyArticle">{t('article.searchForm.isMyArticle')}</label>
            </div>
            : null
        }
      </div>

    </form>
  </div>
);

export default reduxForm({ form: 'articleSearchForm' })(ArticleSearchForm);
