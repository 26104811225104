import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import VoiceUploadPopup from '../../components/voice/VoiceUploadPopup';
import { createVoice } from '../../actions/voiceAction';
import { addLoading } from '../../actions/loadingAction';
import { addErrorAlert } from '../../actions/alertAction';

class VoiceUploadPopupContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFileDrop = this.handleFileDrop.bind(this);
    this.handleFileRemove = this.handleFileRemove.bind(this);

    this.state = {
      uploadFile: null,
      uploadFileBody: null,
    };
  }

  handleSubmit() {
    if (this.state.uploadFileBody == null) {
      this.props.addErrorAlert(this.props.t('messages.createVoiceValidateError'));
      return;
    }

    this.props.addLoading();

    const tmp = this.state.uploadFileBody.split(';base64,');

    this.props.createVoice(
      {
        body: tmp[1],
        format: tmp[0].substring(5),
        article_id: this.props.articleID,
      },
      this.props.onSubmit,
    );
  }

  handleFileDrop(e) {
    e.preventDefault();

    Object.keys(e.dataTransfer.files).forEach((key) => {
      const f = e.dataTransfer.files[key];

      if (f.type.match('audio.*')) {
        const uploadFile = f;

        const reader = new FileReader();
        reader.onload = (ev) => {
          this.state.uploadFileBody = ev.target.result;
          this.forceUpdate();
        };
        reader.readAsDataURL(f);

        this.setState({ uploadFile });
      }
    });
  }

  handleFileRemove() {
    this.setState({ uploadFile: null });
    this.setState({ uploadFileBody: null });
  }

  render() {
    const { onCancel, t } = this.props;

    return (
      <div className="voiceUploadPopupContainer">
        <VoiceUploadPopup
          onSubmit={this.handleSubmit}
          onCancel={onCancel}
          uploadFile={this.state.uploadFile}
          uploadFileBody={this.state.uploadFileBody}
          onFileDrop={this.handleFileDrop}
          onFileRemove={this.handleFileRemove}
          t={t}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => (
  {
    createVoice: (form, callback) => dispatch(createVoice(form, callback)),
    addLoading: () => dispatch(addLoading()),
    addErrorAlert: value => dispatch(addErrorAlert(value)),
  }
);

export default connect(null, mapDispatchToProps)(translate()(VoiceUploadPopupContainer));
