import React from 'react';
import { Field } from 'redux-form';

import { timeFormat } from '../../util/util';

const CategoryEditForm = (props) => {
  if (props.form == null || Object.keys(props.form).length === 0) {
    return null;
  }

  const {
    form,
    initialValues,
    category,
    t,
  } = props;

  return (
    <div className="categoryEditForm">
      <form method="post">
        <div className="items">

          <div className="item">
            <label htmlFor="id">ID</label>
            <div className="id">
              {category.id}
            </div>
          </div>

          <div className="item">
            <label htmlFor="name">{t('category.name')}</label>
            <div className="name">
              <Field name="name" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="key">{t('category.key')}</label>
            <div className="key">
              <Field name="key" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="description">{t('category.description')}</label>
            <div className="description">
              <Field name="description" component="textarea" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="seoName">{t('category.seoName')}</label>
            <div className="seoName">
              <Field name="seo_name" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="mobileHeaderImageURL">{t('category.mobileHeaderImageURL')}</label>
            <img src={initialValues.mobile_header_image_url} alt="mobileHeaderImageURL" />
            <div className="mobileHeaderImageURL">
              <Field name="mobile_header_image_url" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="pcHeaderImageURL">{t('category.pcHeaderImageURL')}</label>
            <img src={initialValues.pc_header_image_url} alt="pcHeaderImageURL" />
            <div className="pcHeaderImageURL">
              <Field name="pc_header_image_url" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <div className="topViewEnable">
              <Field
                name="top_view_enable"
                id="topViewEnable"
                component="input"
                type="checkbox"
              />
              <label className="checkbox" htmlFor="topViewEnable">{t('category.topViewEnable')}</label>
            </div>
          </div>

          <div className="item">
            <label htmlFor="sortKey">{t('category.sortKey')}</label>
            <div className="sortKey">
              <Field name="sort_key" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="createdAt">{t('common.createdAt')}</label>
            <div className="createdAt">
              {timeFormat(category.created_at)}
            </div>
          </div>

          <div className="item">
            <label htmlFor="updateAt">{t('common.updatedAt')}</label>
            <div className="updatedAt">
              {timeFormat(category.updated_at)}
            </div>
          </div>

        </div>
      </form>
    </div>
  );
};

export default CategoryEditForm;
