import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, Route, browserHistory } from 'react-router';
import { I18nextProvider } from 'react-i18next';

import AppContainer from './containers/AppContainer';
import LoginContainer from './containers/login/LoginContainer';
import HomeContainer from './containers/home/HomeContainer';
import ArticleContainer from './containers/article/ArticleContainer';
import ArticleDetail from './components/article/ArticleDetail';
import ArticleEditContainer from './containers/article/ArticleEditContainer';
import ArticleHistoryContainer from './containers/article/ArticleHistoryContainer';
import ArticleDocxContainer from './containers/article/ArticleDocxContainer';
import ArticleCalendarContainer from './containers/article/ArticleCalendarContainer';
import ImageContainer from './containers/image/ImageContainer';
import ImageEditContainer from './containers/image/ImageEditContainer';
import AdFrameContainer from './containers/adFrame/AdFrameContainer';
import AdFrameEditContainer from './containers/adFrame/AdFrameEditContainer';
import ContentFrameContainer from './containers/contentFrame/ContentFrameContainer';
import ContentFrameEditContainer from './containers/contentFrame/ContentFrameEditContainer';
import AuthorContainer from './containers/author/AuthorContainer';
import AuthorEditContainer from './containers/author/AuthorEditContainer';
import CategoryContainer from './containers/category/CategoryContainer';
import CategoryEditContainer from './containers/category/CategoryEditContainer';
import TagContainer from './containers/tag/TagContainer';
import TagEditContainer from './containers/tag/TagEditContainer';
import FeatureContainer from './containers/feature/FeatureContainer';
import FeatureEditContainer from './containers/feature/FeatureEditContainer';
import MediaContainer from './containers/media/MediaContainer';
import MediaEditContainer from './containers/media/MediaEditContainer';
import MediaDistributionContainer from './containers/mediaDistribution/MediaDistributionContainer';
import UserContainer from './containers/user/UserContainer';
import UserEditContainer from './containers/user/UserEditContainer';
import NotFound from './components/error/NotFound';
import ConfigureStore from './store/ConfigureStore';
import i18n from './config/i18n';

const store = ConfigureStore();

const dom = (
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <Router history={browserHistory}>
        <Route component={AppContainer}>

          <Route path="/login" component={LoginContainer} />

          <Route path="/" component={HomeContainer}>

            <Route path="/article" component={ArticleContainer}>
              <Route path="/article/:id" component={ArticleDetail} />
            </Route>
            <Route path="/article/:id/edit" component={ArticleEditContainer} />
            <Route path="/article/:id/history/:revision" component={ArticleHistoryContainer} />
            <Route path="/article/:id/docx" component={ArticleDocxContainer} />
            <Route path="/article_calendar" component={ArticleCalendarContainer} />

            <Route path="/image" component={ImageContainer}>
              <Route path="/image/:groupID" component={ImageContainer}>
                <Route path="/image/:groupID/:imageID" component={ImageEditContainer} />
              </Route>
            </Route>

            <Route path="/ad_frame" component={AdFrameContainer}>
              <Route path="/ad_frame/:id" component={AdFrameEditContainer} />
            </Route>

            <Route path="/content_frame" component={ContentFrameContainer}>
              <Route path="/content_frame/:id" component={ContentFrameEditContainer} />
            </Route>

            <Route path="/author" component={AuthorContainer}>
              <Route path="/author/:id" component={AuthorEditContainer} />
            </Route>

            <Route path="/category" component={CategoryContainer}>
              <Route path="/category/:id" component={CategoryEditContainer} />
            </Route>

            <Route path="/tag" component={TagContainer}>
              <Route path="/tag/:id" component={TagEditContainer} />
            </Route>

            <Route path="/feature" component={FeatureContainer}>
              <Route path="/feature/:id" component={FeatureEditContainer} />
            </Route>

            <Route path="/media" component={MediaContainer}>
              <Route path="/media/:id" component={MediaEditContainer} />
            </Route>

            <Route path="/media_distribution" component={MediaDistributionContainer} />

            <Route path="/user" component={UserContainer}>
              <Route path="/user/:id" component={UserEditContainer} />
            </Route>

            <Route path="*" component={NotFound} />
          </Route>

        </Route>
      </Router>
    </I18nextProvider>
  </Provider>
);

ReactDOM.render(dom, document.getElementById('app'));
