import {
  FETCH_MEDIA_START,
  FETCH_MEDIA_COMPLETE,
  CREATE_MEDIA_START,
  CREATE_MEDIA_COMPLETE,
  UPDATE_MEDIA_START,
  UPDATE_MEDIA_COMPLETE,
  DELETE_MEDIA_START,
  DELETE_MEDIA_COMPLETE,
} from '../actions/mediaAction';

const mediaReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_MEDIA_START:
      return state;

    case FETCH_MEDIA_COMPLETE:
      return action.payload;

    case CREATE_MEDIA_START:
      return state;

    case CREATE_MEDIA_COMPLETE:
      return action.payload;

    case UPDATE_MEDIA_START:
      return state;

    case UPDATE_MEDIA_COMPLETE:
      return action.payload;

    case DELETE_MEDIA_START:
      return state;

    case DELETE_MEDIA_COMPLETE:
      return action.payload;

    default:
      return state;
  }
};

export default mediaReducer;
