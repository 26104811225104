import { addSuccessAlert, addErrorAlert } from './alertAction';
import { removeLoading } from './loadingAction';
import {
  fetchContentFrames as fetchContentFramesAPI,
  fetchContentFrame as fetchContentFrameAPI,
  createContentFrame as createContentFrameAPI,
  updateContentFrame as updateContentFrameAPI,
  deleteContentFrame as deleteContentFrameAPI,
} from '../api/apiClient';
import i18n from '../config/i18n';

export const FETCH_CONTENT_FRAMES_START = 'FETCH_CONTENT_FRAMES_START';
export const FETCH_CONTENT_FRAMES_COMPLETE = 'FETCH_CONTENT_FRAMES_COMPLETE';
export const FETCH_CONTENT_FRAME_START = 'FETCH_CONTENT_FRAME_START';
export const FETCH_CONTENT_FRAME_COMPLETE = 'FETCH_CONTENT_FRAME_COMPLETE';
export const CREATE_CONTENT_FRAME_START = 'CREATE_CONTENT_FRAME_START';
export const CREATE_CONTENT_FRAME_COMPLETE = 'CREATE_CONTENT_FRAME_COMPLETE';
export const UPDATE_CONTENT_FRAME_START = 'UPDATE_CONTENT_FRAME_START';
export const UPDATE_CONTENT_FRAME_COMPLETE = 'UPDATE_CONTENT_FRAME_COMPLETE';
export const DELETE_CONTENT_FRAME_START = 'DELETE_CONTENT_FRAME_START';
export const DELETE_CONTENT_FRAME_COMPLETE = 'DELETE_CONTENT_FRAME_COMPLETE';

const fetchContentFramesStart = () => ({ type: FETCH_CONTENT_FRAMES_START });

const fetchContentFramesComplete = res => (
  {
    type: FETCH_CONTENT_FRAMES_COMPLETE,
    payload: res,
  }
);

const fetchContentFrameStart = () => ({ type: FETCH_CONTENT_FRAME_START });

const fetchContentFrameComplete = res => ({
  type: FETCH_CONTENT_FRAME_COMPLETE,
  payload: res,
});

const createContentFrameStart = () => ({ type: CREATE_CONTENT_FRAME_START });

const createContentFrameComplete = res => ({
  type: CREATE_CONTENT_FRAME_COMPLETE,
  payload: res,
});

const updateContentFrameStart = () => ({ type: UPDATE_CONTENT_FRAME_START });

const updateContentFrameComplete = res => ({
  type: UPDATE_CONTENT_FRAME_COMPLETE,
  payload: res,
});

const deleteContentFrameStart = () => ({ type: DELETE_CONTENT_FRAME_START });

const deleteContentFrameComplete = () => ({
  type: DELETE_CONTENT_FRAME_COMPLETE,
  payload: {},
});

export const fetchContentFrames = () => (
  (dispatch) => {
    dispatch(fetchContentFramesStart());

    fetchContentFramesAPI().then((res) => {
      dispatch(fetchContentFramesComplete(res.body));
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(addErrorAlert(i18n.t('messages.fetchContentFrameError', { message: res.text })));
    });
  }
);

export const fetchContentFrame = id => (
  (dispatch) => {
    dispatch(fetchContentFrameStart());

    fetchContentFrameAPI(id).then((res) => {
      dispatch(fetchContentFrameComplete(res.body));
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(addErrorAlert(i18n.t('messages.fetchContentFrameError', { message: res.text })));
    });
  }
);

export const createContentFrame = (form, callback) => (
  (dispatch) => {
    dispatch(createContentFrameStart());

    createContentFrameAPI(form).then((res) => {
      dispatch(createContentFrameComplete(res.body));
      dispatch(removeLoading());

      if (callback != null) {
        callback(res.body);
      } else {
        dispatch(addSuccessAlert(i18n.t('messages.createContentFrameSuccess')));
      }
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(removeLoading());
      dispatch(addErrorAlert(i18n.t('messages.createContentFrameError', { message: res.text })));
    });
  }
);

export const updateContentFrame = (form, callback) => (
  (dispatch) => {
    dispatch(updateContentFrameStart());

    updateContentFrameAPI(form).then((res) => {
      dispatch(updateContentFrameComplete(res.body));
      dispatch(removeLoading());
      dispatch(addSuccessAlert(i18n.t('messages.updateContentFrameSuccess')));

      if (callback != null) {
        callback(res.body);
      }
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(removeLoading());
      dispatch(addErrorAlert(i18n.t('messages.updateContentFrameError', { message: res.text })));
    });
  }
);

export const deleteContentFrame = id => (
  (dispatch) => {
    dispatch(deleteContentFrameStart());

    deleteContentFrameAPI(id).then(() => {
      dispatch(deleteContentFrameComplete());
      dispatch(removeLoading());
      dispatch(addSuccessAlert(i18n.t('messages.deleteContentFrameSuccess')));
    }).catch((res) => {
      console.error('response: %o', res);
      dispatch(removeLoading());
      dispatch(addErrorAlert(i18n.t('messages.deleteContentFrameError', { message: res.text })));
    });
  }
);
