import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, change } from 'redux-form';
import { translate } from 'react-i18next';

import AuthorEditForm from '../../components/author/AuthorEditForm';

class AuthorEditFormContainer extends React.Component {
  render() {
    if (Number(this.props.id) !== this.props.author.id) {
      return null;
    }

    const {
      authorEditForm,
      initialValues,
      author,
      saveButton,
      closeButton,
      onSubmit,
      onCancel,
      t,
    } = this.props;

    if (saveButton != null && saveButton.onclick == null) {
      saveButton.onclick = () => onSubmit(this.props.authorEditForm);
    }

    if (closeButton != null && closeButton.onclick == null) {
      closeButton.onclick = () => onCancel();
    }

    return (
      <div className="authorEditFormContainer">
        <AuthorEditForm
          form={authorEditForm}
          initialValues={initialValues}
          author={author}
          t={t}
        />
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    authorEditForm: state.form.authorEditForm != null ? state.form.authorEditForm.values : null,
    initialValues: state.author,
  }
);

const mapDispatchToProps = dispatch => (
  {
    updateForm: (key, value) => dispatch(change('authorEditForm', key, value)),
  }
);

const authorEditFormContainer = reduxForm({ form: 'authorEditForm', enableReinitialize: true })(translate()(AuthorEditFormContainer));
export default connect(mapStateToProps, mapDispatchToProps)(authorEditFormContainer);
