import React from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { translate } from 'react-i18next';

import ArticleSearchPopup from '../../components/article/ArticleSearchPopup';
import { fetchArticlesWithCondition } from '../../actions/articleAction';

class ArticleSearchPopupContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleSearch = this.handleSearch.bind(this);
    this.refSearchWord = this.refSearchWord.bind(this);
  }

  componentWillMount() {
    this.props.fetchArticlesWithCondition({ word: '', status: 'posted', sort: 'post_date' });
    this.props.updateForm('searchWord', '');
  }

  handleSearch() {
    this.props.fetchArticlesWithCondition({ word: this.searchWord.value, status: 'posted' });
  }

  refSearchWord(ref) {
    this.searchWord = ref;
  }

  render() {
    const { articles, onSubmit, onCancel, t } = this.props;

    return (
      <div className="articleSearchPopupContainer">
        <ArticleSearchPopup
          articles={articles}
          onSearch={this.handleSearch}
          onSubmit={onSubmit}
          onCancel={onCancel}
          refSearchWord={this.refSearchWord}
          t={t}
        />
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    articles: state.articles,
  }
);

const mapDispatchToProps = dispatch => (
  {
    updateForm: (key, value) => dispatch(change('articleEditForm', key, value)),
    fetchArticlesWithCondition: form => dispatch(fetchArticlesWithCondition(form)),
  }
);

const articleSearchPopupContainer = translate()(ArticleSearchPopupContainer);
export default connect(mapStateToProps, mapDispatchToProps)(articleSearchPopupContainer);
