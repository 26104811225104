import React from 'react';
import { Field } from 'redux-form';
import TagsInput from 'react-tagsinput';
import Autosuggest from 'react-autosuggest';

import Config from '../../config/Config';
import { timeFormat, imageThumbnailURL } from '../../util/util';

const ImageEditForm = (props) => {
  if (props.form == null || Object.keys(props.form).length === 0) {
    return null;
  }

  const {
    form,
    image,
    onTagsChange,
    onTagsRenderInput,
    refTagsInput,
    suggestSources,
    onSourceChange,
    onSourceSuggestionSelected,
    sourceInputProps,
    t,
  } = props;

  return (
    <div className="imageEditForm">
      <form method="post">
        <div className="items">

          <div className="item">
            <div className="image">
              <img
                src={
                  image.url != null && image.url.length > 0 ?
                    imageThumbnailURL(image.url, 'medium') : '/assets/images/no_image.png'
                }
                alt="imagethumbnail"
              />
            </div>
          </div>

          <div className="item">
            <label htmlFor="id">ID</label>
            <div className="id">
              {image.image_id}
            </div>
          </div>

          <div className="item">
            <label htmlFor="url">{t('image.url')}</label>
            <div className="url">
              {image.url}
            </div>
          </div>

          <div className="item">
            <label htmlFor="tags">{t('image.tags')}</label>

            <div className="tags">
              <TagsInput
                ref={refTagsInput}
                renderInput={onTagsRenderInput}
                value={form.tags || []}
                onChange={onTagsChange}
                onlyUnique
              />
            </div>
          </div>

          <div className="item">
            <label htmlFor="userName">{t('image.userName')}</label>
            <div className="userName">
              {image.user_name || '-'}
            </div>
          </div>

          <div className="item">
            <label htmlFor="imageType">{t('image.imageType')}</label>

            <div className="imageType">
              <div className="select-wrap">
                <Field name="image_type" component="select">
                  {
                    Config.IMAGE_IMAGE_TYPES
                      .map(e => <option value={e.id} key={e.id}>{t(e.i18nKey)}</option>)
                  }
                </Field>
              </div>
            </div>
          </div>

          <div className="item">
            <label htmlFor="license">{t('image.license')}</label>

            <div className="license">
              <div className="select-wrap">
                <Field name="license_id" component="select">
                  {
                    Config.IMAGE_LICENSES
                      .map(e => <option value={e.id} key={e.id}>{t(e.i18nKey)}</option>)
                  }
                </Field>
              </div>
            </div>
          </div>

          <div className="item">
            <label htmlFor="source">{t('image.source')}</label>

            <div className="source">
              <Autosuggest
                suggestions={suggestSources.sources || []}
                shouldRenderSuggestions={v => v != null}
                getSuggestionValue={suggestion => suggestion}
                renderSuggestion={suggestion => <span>{suggestion}</span>}
                inputProps={sourceInputProps}
                onSuggestionSelected={onSourceSuggestionSelected}
                onSuggestionsFetchRequested={onSourceChange}
                onSuggestionsClearRequested={() => {}}
              />
            </div>
          </div>

          <div className="item">
            <label htmlFor="creditName">{t('image.creditName')}</label>

            <div className="creditName">
              <Field name="credit_name" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="creditURL">{t('image.creditURL')}</label>

            <div className="creditURL">
              <Field name="credit_url" component="input" type="text" />
            </div>
          </div>

          <div className="item">
            <label htmlFor="createdAt">{t('common.createdAt')}</label>
            <div className="createdAt">
              {timeFormat(image.created_at)}
            </div>
          </div>

          <div className="item">
            <label htmlFor="updateAt">{t('common.updatedAt')}</label>
            <div className="updatedAt">
              {timeFormat(image.updated_at)}
            </div>
          </div>

        </div>
      </form>
    </div>
  );
};

export default ImageEditForm;
