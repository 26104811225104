import {
  FETCH_AD_FRAME_START,
  FETCH_AD_FRAME_COMPLETE,
  CREATE_AD_FRAME_START,
  CREATE_AD_FRAME_COMPLETE,
  UPDATE_AD_FRAME_START,
  UPDATE_AD_FRAME_COMPLETE,
  DELETE_AD_FRAME_START,
  DELETE_AD_FRAME_COMPLETE,
} from '../actions/adFrameAction';

const adFrameReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_AD_FRAME_START:
      return state;

    case FETCH_AD_FRAME_COMPLETE:
      return action.payload;

    case CREATE_AD_FRAME_START:
      return state;

    case CREATE_AD_FRAME_COMPLETE:
      return action.payload;

    case UPDATE_AD_FRAME_START:
      return state;

    case UPDATE_AD_FRAME_COMPLETE:
      return action.payload;

    case DELETE_AD_FRAME_START:
      return state;

    case DELETE_AD_FRAME_COMPLETE:
      return action.payload;

    default:
      return state;
  }
};

export default adFrameReducer;
