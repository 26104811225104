import React from 'react';

import { timeFormat } from '../../util/util';

const renderMediaDistribution = (mediaDistribution, i, props) => {
  const {
    medias,
    onFeedMediaChange,
    onRemoveClick,
    t,
  } = props;

  const { article } = mediaDistribution;
  const title = article.titles != null && article.titles.length > 0 ?
    article.titles[0] : null;
  const eyeCatch = article.eye_catch_urls != null && article.eye_catch_urls.length > 0 ?
    article.eye_catch_urls[0] : '/assets/images/no_image_small.png';

  return (
    <li key={`mediaDistribution-${i}`}>
      <div className="list-content">
        <div className="trash right">
          <div className="button" data-key={i} onClick={onRemoveClick}>
            <i className="fa fa-times" />
          </div>
        </div>

        <div className="left">
          <div className="article">
            <div className="image">
              <img src={eyeCatch} alt={title} />
            </div>
            <div
              className="body"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </div>

          <div className="feedMedia">
            {t('mediaDistribution.feedMedia')}:
            <div className="select-wrap">
              <select
                defaultValue={mediaDistribution.feed_media_id}
                data-key={i}
                onChange={onFeedMediaChange}
              >
                <option value="0" key="0">{t('common.selectAll')}</option>
                {
                  medias != null ?
                    medias.map(media => (
                      <option value={media.id} key={media.id}>
                        {media.name}
                      </option>
                    )) : null
                }
              </select>
            </div>
          </div>

          <div>
            <span className="createUserName">
              <i className="fa fa-user" />
              {mediaDistribution.create_user_name}
            </span>

            <span className="updatedAt">
              <i className="fa fa-clock-o" />
              {timeFormat(mediaDistribution.updated_at)}
            </span>
          </div>
        </div>

        <div className="clear" />
      </div>
    </li>
  );
};

const MediaDistributionList = (props) => {
  const { mediaDistributions } = props;

  if (mediaDistributions == null) {
    return null;
  }

  return (
    <div className="mediaDistributionList">
      <ul>
        {mediaDistributions
          .map((mediaDistribution, i) => renderMediaDistribution(mediaDistribution, i, props))}
      </ul>
    </div>
  );
};

export default MediaDistributionList;
