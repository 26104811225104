export const ADD_ALERT = 'ADD_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

const addAlert = (text, style, onSubmit = null, onCancel = null) => ({
  type: ADD_ALERT,
  text,
  style,
  onSubmit,
  onCancel,
});

export const addSuccessAlert = text => addAlert(text, 'success');

export const addErrorAlert = text => addAlert(text, 'error');

export const addConfirmAlert = (text, onSubmit, onCancel) => addAlert(text, 'confirm', onSubmit, onCancel);

export const removeAlert = id => ({
  type: REMOVE_ALERT,
  id,
});
