import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, change } from 'redux-form';
import { translate } from 'react-i18next';

import AdFrameEditForm from '../../components/adFrame/AdFrameEditForm';
import ImageSearchPopupContainer from '../image/ImageSearchPopupContainer';

class AdFrameEditFormContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleImageSearchClick = this.handleImageSearchClick.bind(this);
    this.handleImageSearchSubmit = this.handleImageSearchSubmit.bind(this);
    this.handleImageSearchCancel = this.handleImageSearchCancel.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);

    this.state = {
      isDisplayImageSearchPopup: false,
      selectedImage: null,
    };
  }

  handleAddClick() {
    const form = this.props.adFrameEditForm;

    const frameIDs =
      (form.frame_ids != null ? form.frame_ids : []).concat([0]);
    const isDisplays =
      (form.is_displays != null ? form.is_displays : []).concat([true]);
    const mobileImageURLs =
      (form.mobile_image_urls != null ? form.mobile_image_urls : []).concat(['']);
    const pcImageURLs =
      (form.pc_image_urls != null ? form.pc_image_urls : []).concat(['']);

    while (frameIDs.length < isDisplays.length) {
      frameIDs.push(0);
    }

    this.props.updateForm('frame_ids', frameIDs);
    this.props.updateForm('is_displays', isDisplays);
    this.props.updateForm('mobile_image_urls', mobileImageURLs);
    this.props.updateForm('pc_image_urls', pcImageURLs);
  }

  handleRemoveClick(e) {
    const form = this.props.adFrameEditForm;
    const i = Number(e.target.dataset.key);

    const frameIDs = [].concat(form.frame_ids != null ? form.frame_ids : []);
    const isDisplays = [].concat(form.is_displays);
    const mobileImageURLs = [].concat(form.mobile_image_urls);
    const pcImageURLs = [].concat(form.pc_image_urls);

    while (frameIDs.length < isDisplays.length) {
      frameIDs.push(0);
    }

    frameIDs.splice(i, 1);
    isDisplays.splice(i, 1);
    mobileImageURLs.splice(i, 1);
    pcImageURLs.splice(i, 1);

    this.props.updateForm('frame_ids', frameIDs);
    this.props.updateForm('is_displays', isDisplays);
    this.props.updateForm('mobile_image_urls', mobileImageURLs);
    this.props.updateForm('pc_image_urls', pcImageURLs);
  }

  handleImageSearchClick(e) {
    this.setState({ isDisplayImageSearchPopup: true });
    this.setState({ selectedImage: e.target.dataset });
  }

  handleImageSearchSubmit(e) {
    const form = this.props.adFrameEditForm;
    const i = Number(this.state.selectedImage.key);

    if (this.state.selectedImage.device === 'mobile') {
      const mobileImageURLs = [].concat(form.mobile_image_urls || []);

      mobileImageURLs[i] = e.url;
      this.props.updateForm('mobile_image_urls', mobileImageURLs);
    } else {
      const pcImageURLs = [].concat(form.pc_image_urls || []);

      pcImageURLs[i] = e.url;
      this.props.updateForm('pc_image_urls', pcImageURLs);
    }

    this.setState({ isDisplayImageSearchPopup: false });
  }

  handleImageSearchCancel() {
    this.setState({ isDisplayImageSearchPopup: false });
  }

  handleStatusChange(e) {
    const form = this.props.adFrameEditForm;
    const i = Number(e.target.dataset.key);
    const value = e.target.options[e.target.selectedIndex].value;

    const isDisplays = [].concat(form.is_displays || []);

    isDisplays[i] = value === '1';
    this.props.updateForm('is_displays', isDisplays);
  }

  render() {
    if (this.props.adFrameEditForm == null ||
      Number(this.props.id) !== this.props.adFrameEditForm.id) {
      return null;
    }

    const {
      adFrameEditForm,
      initialValues,
      adFrame,
      saveButton,
      closeButton,
      onSubmit,
      onCancel,
      t,
    } = this.props;

    if (saveButton != null && saveButton.onclick == null) {
      saveButton.onclick = () => onSubmit(this.props.adFrameEditForm);
    }

    if (closeButton != null && closeButton.onclick == null) {
      closeButton.onclick = () => onCancel();
    }

    const imageSearchPopup = this.state.isDisplayImageSearchPopup ?
      (<ImageSearchPopupContainer
        onSubmit={this.handleImageSearchSubmit}
        onCancel={this.handleImageSearchCancel}
      />)
      : null;

    return (
      <div className="adFrameEditFormContainer">
        {imageSearchPopup}

        <AdFrameEditForm
          form={adFrameEditForm}
          initialValues={initialValues}
          adFrame={adFrame}
          onAddClick={this.handleAddClick}
          onRemoveClick={this.handleRemoveClick}
          onSearchClick={this.handleImageSearchClick}
          onStatusChange={this.handleStatusChange}
          t={t}
        />
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    adFrameEditForm: state.form.adFrameEditForm != null ? state.form.adFrameEditForm.values : null,
    initialValues: state.adFrame,
  }
);

const mapDispatchToProps = dispatch => (
  {
    updateForm: (key, value) => dispatch(change('adFrameEditForm', key, value)),
  }
);

const adFrameEditFormContainer = reduxForm({ form: 'adFrameEditForm', enableReinitialize: true })(translate()(AdFrameEditFormContainer));
export default connect(mapStateToProps, mapDispatchToProps)(adFrameEditFormContainer);
