import React from 'react';
import classnames from 'classnames';

const renderMedia = (media, props) => {
  const {
    id,
    onClick,
  } = props;

  return (
    <li
      className={classnames({ item: true, selected: Number(id) === media.id })}
      key={`media-${media.id}`}
    >
      <div className="list-content" onClick={() => onClick(media.id)}>
        <div>
          <span className="name">
            {media.name}
          </span>
        </div>
      </div>
    </li>
  );
};

const MediaList = (props) => {
  const { medias } = props;

  if (medias == null) {
    return null;
  }

  return (
    <div className="mediaList">
      <ul>
        {medias.map(media => renderMedia(media, props))}
      </ul>
    </div>
  );
};

export default MediaList;
