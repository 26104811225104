import {
  FETCH_AD_FRAMES_START,
  FETCH_AD_FRAMES_COMPLETE,
} from '../actions/adFrameAction';

const adFramesReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_AD_FRAMES_START:
      return state;

    case FETCH_AD_FRAMES_COMPLETE:
      return action.payload;

    default:
      return state;
  }
};

export default adFramesReducer;
