import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { translate } from 'react-i18next';

import CategoryList from '../../components/category/CategoryList';
import CategoryEditContainer from './CategoryEditContainer';
import { fetchCategories, createCategory } from '../../actions/categoryAction';
import { addLoading } from '../../actions/loadingAction';
import { addSuccessAlert } from '../../actions/alertAction';

class CategoryContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleNewClick = this.handleNewClick.bind(this);
    this.handleCategoryClick = this.handleCategoryClick.bind(this);
  }

  componentDidMount() {
    this.props.fetchCategories();
  }

  handleNewClick() {
    this.props.router.push('/category');
    this.props.addLoading();
    this.props.createCategory({ name: 'no name' }, (category) => {
      this.props.fetchCategories();
      this.props.router.push(`/category/${category.id}`);
      this.props.addSuccessAlert(this.props.t('messages.createCategorySuccess'));
    });
  }

  handleCategoryClick(id) {
    this.props.router.push(`/category/${id}`);
  }

  render() {
    const { categories, params, t } = this.props;

    return (
      <div className="categoryContainer">
        <div className={classnames({ list: true, narrow: params.id != null })}>

          <div className="header">
            <div className="buttons right">
              <button
                type="button"
                onClick={this.handleNewClick}
              >
                <i className="fa fa-plus" aria-hidden="true" />
                Create Category
              </button>
            </div>

            <div className="clear" />
          </div>

          <div
            className="content"
            ref={(ref) => { this.categoryListContent = ref; }}
          >
            <CategoryList
              id={params.id}
              categories={categories.categories}
              onClick={this.handleCategoryClick}
              t={t}
            />
          </div>

        </div>

        <div className="detail">
          {params.id != null ? <CategoryEditContainer id={params.id} /> : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    categories: state.categories,
    loading: state.loading,
  }
);

const mapDispatchToProps = dispatch => (
  {
    fetchCategories:
      (form, categories, offset) => dispatch(fetchCategories(form, categories, offset)),
    createCategory: (form, callback) => dispatch(createCategory(form, callback)),
    addLoading: () => dispatch(addLoading()),
    addSuccessAlert: value => dispatch(addSuccessAlert(value)),
  }
);

const categoryContainer = withRouter(translate()(CategoryContainer));
export default connect(mapStateToProps, mapDispatchToProps)(categoryContainer);
