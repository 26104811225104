import {
  FETCH_CONTENT_FRAME_START,
  FETCH_CONTENT_FRAME_COMPLETE,
  CREATE_CONTENT_FRAME_START,
  CREATE_CONTENT_FRAME_COMPLETE,
  UPDATE_CONTENT_FRAME_START,
  UPDATE_CONTENT_FRAME_COMPLETE,
  DELETE_CONTENT_FRAME_START,
  DELETE_CONTENT_FRAME_COMPLETE,
} from '../actions/contentFrameAction';

const contentFrameReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CONTENT_FRAME_START:
      return state;

    case FETCH_CONTENT_FRAME_COMPLETE:
      return action.payload;

    case CREATE_CONTENT_FRAME_START:
      return state;

    case CREATE_CONTENT_FRAME_COMPLETE:
      return action.payload;

    case UPDATE_CONTENT_FRAME_START:
      return state;

    case UPDATE_CONTENT_FRAME_COMPLETE:
      return action.payload;

    case DELETE_CONTENT_FRAME_START:
      return state;

    case DELETE_CONTENT_FRAME_COMPLETE:
      return action.payload;

    default:
      return state;
  }
};

export default contentFrameReducer;
