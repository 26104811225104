import React from 'react';
import classNames from 'classnames';
import { translate } from 'react-i18next';

import Config from '../../config/Config';

class LoginContainer extends React.Component {
  getQuote(repdigit) {
    if (repdigit) {
      return this.props.t('common.repdigitQuote');
    }

    const quotes = this.props.t('common.dailyQuotes', { returnObjects: true });
    return quotes[(new Date()).getDate() % quotes.length];
  }

  isRepdigit() {
    const today = new Date();
    const date = today.getDate().toString();
    const month = (today.getMonth() + 1).toString();

    return date.replace(new RegExp(month, 'g'), '') === '';
  }

  render() {
    const { t } = this.props;

    const repdigit = this.isRepdigit();

    return (
      <div className={classNames({ loginContainer: true, repdigit })}>
        <div className="login-wrap">

          <div className="header">
            <img src={Config.LOGO_IMAGE} alt="tabi labo" />
            <div>{this.getQuote(repdigit)}</div>
          </div>

          <div className="form">
            <h1>Login</h1>
            <div className="alert">
              {this.props.location.query.status === 'Failed' ? t('messages.authFailure') : null}
            </div>
            <div>
              <a href={Config.AUTH_ENDPOINT}>
                <i className="fa fa-google" />
                <span>Sign in with Google</span>
              </a>
            </div>
          </div>

          <div className="footer">
            <div className="left">
              <a href={Config.WEB_ENDPOINT} target="_blank" rel="noopener noreferrer">Web</a>/
              <a href={Config.CMS_ENDPOINT} target="_blank" rel="noopener noreferrer">CMS</a>/
              <a href={Config.MANAGE_ENDPOINT} target="_blank" rel="noopener noreferrer">Manage</a>/
              <a href={Config.REPORT_ENDPOINT} target="_blank" rel="noopener noreferrer">Report</a>
            </div>
            <div className="right">
              {t('login.footerHelp')}
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default translate()(LoginContainer);
