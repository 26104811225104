import {
  FETCH_FEATURE_START,
  FETCH_FEATURE_COMPLETE,
  CREATE_FEATURE_START,
  CREATE_FEATURE_COMPLETE,
  UPDATE_FEATURE_START,
  UPDATE_FEATURE_COMPLETE,
  DELETE_FEATURE_START,
  DELETE_FEATURE_COMPLETE,
} from '../actions/featureAction';

const featureReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_FEATURE_START:
      return state;

    case FETCH_FEATURE_COMPLETE:
      return action.payload;

    case CREATE_FEATURE_START:
      return state;

    case CREATE_FEATURE_COMPLETE:
      return action.payload;

    case UPDATE_FEATURE_START:
      return state;

    case UPDATE_FEATURE_COMPLETE:
      return action.payload;

    case DELETE_FEATURE_START:
      return state;

    case DELETE_FEATURE_COMPLETE:
      return action.payload;

    default:
      return state;
  }
};

export default featureReducer;
