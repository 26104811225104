import React from 'react';
import classnames from 'classnames';

const renderContentFrame = (contentFrame, props) => {
  const {
    id,
    onClick,
  } = props;

  return (
    <li
      className={classnames({ item: true, selected: Number(id) === contentFrame.id })}
      key={`contentFrame-${contentFrame.id}`}
    >
      <div className="list-content" onClick={() => onClick(contentFrame.id)}>
        <div>
          <span className="name">
            {contentFrame.name}
          </span>
        </div>
      </div>
    </li>
  );
};

const ContentFrameList = (props) => {
  const { contentFrames } = props;

  if (contentFrames == null) {
    return null;
  }

  return (
    <div className="contentFrameList">
      <ul>
        {contentFrames.map(contentFrame => renderContentFrame(contentFrame, props))}
      </ul>
    </div>
  );
};

export default ContentFrameList;
