import React from 'react';
import classnames from 'classnames';

import Config from '../../config/Config';
import { getName, timeFormat } from '../../util/util';

const renderAdFrame = (adFrame, props) => {
  const {
    id,
    onClick,
    onReportClick,
    t,
  } = props;

  const articleLink = adFrame.article_url.length > 0 ? (
    <a href={adFrame.article_url} target="_blank" rel="noopener noreferrer" onClick={e => e.stopPropagation()}>
      <i className="fa fa-external-link" aria-hidden="true" />
    </a>
  ) : null;

  const classNames = { status: true };
  classNames[adFrame.delivery_status] = true;

  return (
    <li
      className={classnames({ item: true, selected: Number(id) === adFrame.id })}
      key={`adFrame-${adFrame.id}`}
    >
      <div className="list-content" onClick={() => onClick(adFrame.id)}>
        <img
          src={
            adFrame.mobile_image_urls != null &&
            adFrame.mobile_image_urls.length > 0 &&
            adFrame.mobile_image_urls[0] != null &&
            adFrame.mobile_image_urls[0].length > 0 ?
              adFrame.mobile_image_urls[0] : '/assets/images/no_image_small.png'
          }
          alt="mobileImageURLs"
        />

        <div className="description">
          <div>
            <span className={classnames(classNames)}>
              {getName(Config.AD_FRAME_DELIVERY_STATUSES, adFrame.delivery_status)}
            </span>
            <span className="name">
              {adFrame.name}
            </span>
          </div>

          <div className="toggle">
            <button data-key={adFrame.id} onClick={onReportClick}>
              <i className="fa fa-external-link" aria-hidden="true" />
              Report
            </button>
          </div>

          <div>
            <span className="articleURL">
              URL: {adFrame.article_url}
            </span>
            {articleLink}
          </div>

          <div>
            <span className="deliveryAt">
              {t('adFrame.deliveryAt')}:&nbsp;
              {`${timeFormat(adFrame.delivery_from_at)} 〜 ${timeFormat(adFrame.delivery_to_at)}`}
            </span>
          </div>
        </div>

      </div>
    </li>
  );
};

const AdFrameList = (props) => {
  const { adFrames } = props;

  if (adFrames == null) {
    return null;
  }

  return (
    <div className="adFrameList">
      <ul>
        {adFrames.map(adFrame => renderAdFrame(adFrame, props))}
      </ul>
    </div>
  );
};

export default AdFrameList;
